import {
  FETCH_BUKRS_START,
  FETCH_BUKRS_SUCCESS,
  FETCH_BUKRS_FAILURE,
  FETCHED_BUKRS,
  CLEAR_ERRORS,
} from './bukrsTypes';

const initialState = {
  bukrs: [],
  error: null,
  isFetchingBukrs: false,
  hasFetchedBukrs: false,
};

const bukrsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BUKRS_START:
      return {
        ...state,
        isFetchingBukrs: true,
        error: null,
      };
    case FETCH_BUKRS_SUCCESS:
      return {
        ...state,
        isFetchingBukrs: false,
        hasFetchedBukrs: true,
        bukrs: action.payload.bukrs,
        error: null,
      };
    case FETCH_BUKRS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        hasFetchedBukrs: false,
        isFetchingBukrs: false,
        bukrs: [],
      };
    case FETCHED_BUKRS:
      return {
        ...state,
        hasFetchedBukrs: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default bukrsReducer;

import React, { useEffect } from 'react';
import { useTable, useAbsoluteLayout, useRowSelect } from 'react-table';
import { useSticky } from 'react-table-sticky';

import IndeterminateCheckbox from '../../../checkbox/IndeterminateCheckbox';
import { NoDataTable } from '../../../no-data/NoDataTable';

const AbwesenModalTable = ({ columns, data, updateMyData, onRowSelect }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      autoResetSelectedRows: false,
    },
    useSticky,
    useAbsoluteLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          ),
          width: 10,
        },
        ...columns,
      ]);
    }
  );

  useEffect(() => {
    onRowSelect(selectedFlatRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFlatRows.length]);

  return (
    <div>
      {rows.length === 0 ? (
        <NoDataTable />
      ) : (
        <div className='tableWrap'>
          <table
            {...getTableProps()}
            className='table sticky'
            id='abwesenModalTable'
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='tr'>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps();
                    headerProps.style = {
                      width: `${column.width}px`,
                      textAlign: 'left',
                    };

                    return (
                      <th {...headerProps} className='th header'>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='body'>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      let props = cell.getCellProps();
                      props.style = {
                        width: `${cell.column.width}px`,
                      };

                      if (
                        cell.column.id === 'awart' ||
                        cell.column.id === 'atext' ||
                        cell.column.id === 'unit'
                      ) {
                        props.style['background'] = '#D5EBFF';
                      }

                      return (
                        <td {...props} className='td'>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AbwesenModalTable;

import API from '../../api/api';

import {
  FETCHED_WAGE_TYPE,
  FETCH_WAGE_TYPE_START,
  FETCH_WAGE_TYPE_FAILURE,
  FETCH_WAGE_TYPE_SUCCESS,
  FETCH_WAGE_TYPES_START,
  FETCH_WAGE_TYPES_SUCCESS,
  FETCH_WAGE_TYPES_FAILURE,
} from './wageTypeTypes';

export const fetchWageTypeStart = () => {
  return {
    type: FETCH_WAGE_TYPE_START,
  };
};

export const fetchWageTypeFailure = (error, wagetype, cellBeingEdited) => {
  return {
    type: FETCH_WAGE_TYPE_FAILURE,
    payload: { error, wagetype, cellBeingEdited },
  };
};

export const fetchWageTypeSuccess = (wageType, cellBeingEdited) => {
  return {
    type: FETCH_WAGE_TYPE_SUCCESS,
    payload: { wageType, cellBeingEdited },
  };
};

export const setHasFetchedWageType = (fetched) => {
  return {
    type: FETCHED_WAGE_TYPE,
    payload: fetched,
  };
};

export const fetchWageTypesStart = () => {
  return {
    type: FETCH_WAGE_TYPES_START,
  };
};

export const fetchWageTypesFailure = (error, wagetypes) => {
  return {
    type: FETCH_WAGE_TYPES_FAILURE,
    payload: error,
  };
};

export const fetchWageTypesSuccess = (wageTypes) => {
  return {
    type: FETCH_WAGE_TYPES_SUCCESS,
    payload: wageTypes,
  };
};

export const fetchWageType = (auth, month, searchData, cellBeingEdited) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    if (searchData.wagetype === '') {
      dispatch(
        fetchWageTypeSuccess(
          {
            currency: '',
            lgart: '',
            lgtxt: '',
            unit: '',
          },
          cellBeingEdited
        )
      );
    } else {
      dispatch(fetchWageTypeStart());

      try {
        const res = await API.get('/SIF/time/wagetype', config);
        dispatch(fetchWageTypeSuccess(res.data, cellBeingEdited));
      } catch (error) {
        if (error.response) {
          let msg =
            error.response.status === 404
              ? 'Nicht gefunden'
              : 'Ein unbekannter Fehler ist aufgetreten';
          dispatch(
            fetchWageTypeFailure(msg, searchData.wagetype, cellBeingEdited)
          );
        } else {
          dispatch(
            fetchWageTypeFailure(
              'Ein unbekannter Fehler ist aufgetreten',
              searchData.wagetype,
              cellBeingEdited
            )
          );
        }
      }
    }
  };
};

export const fetchWageTypes = (auth, month) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchWageTypesStart());

    try {
      const res = await API.get('/SIF/time/wagetypes', config);
      dispatch(fetchWageTypesSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchWageTypesFailure(msg));
      } else {
        dispatch(
          fetchWageTypesFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

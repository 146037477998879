import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Row, Col } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

import { LANGTEXT } from '../../modalTypes';
import {
  STUNDEN,
  ABWESEN,
  INFO_TIMESTAMPS,
  INFO_HOURS,
} from '../../modalParentTypes';

const LangModal = ({
  hideModal,
  currentModals,
  cell,
  parent,
  times,
  tempTimestamps,
  tempHours,
  currentInfoDate,
  absences,
  setChangedAbsencesData,
  setChangedTimesData,
  updateHour,
  updateTimestamp,
  updateLongText,
  isInfoReadOnly,
  timesPost: { timeColumns, absenceColumns, periodClosed, onlyDisplay },
  areTimesLocked,
}) => {
  const { TextArea } = Input;
  const [longText, setLongText] = useState('');

  const isLangTextReadOnlyBasedOnParent = () => {
    if (parent === STUNDEN) {
      return (
        timeColumns[cell.column.id].isReadonly ||
        periodClosed ||
        areTimesLocked ||
        onlyDisplay
      );
    } else if (parent === ABWESEN) {
      return (
        absenceColumns[cell.column.id].isReadonly ||
        periodClosed ||
        areTimesLocked ||
        onlyDisplay
      );
    }
    return (
      isInfoReadOnly ||
      cell.row.original.isApproved ||
      periodClosed ||
      areTimesLocked ||
      onlyDisplay
    );
  };

  const [isLangTextReadOnly] = useState(() =>
    isLangTextReadOnlyBasedOnParent()
  );

  useEffect(() => {
    let monthlyCells = [];

    if (parent === STUNDEN) {
      monthlyCells = times[cell.row.index - 2].cells;
    } else if (parent === ABWESEN) {
      monthlyCells = absences[cell.row.index - 2].cells;
    }

    if (parent === STUNDEN || parent === ABWESEN) {
      for (const [key, value] of Object.entries(monthlyCells)) {
        if (key === cell.column.id) {
          let long = '';
          value.longText.forEach((text) => {
            long += `${text}\n`;
          });
          setLongText(long);
          break;
        }
      }
    } else if (parent === INFO_TIMESTAMPS) {
      for (const [key, value] of Object.entries(tempTimestamps)) {
        if (key === currentInfoDate) {
          let long = '';
          value[cell.row.index].longText.forEach((text) => {
            long += `${text}\n`;
          });
          setLongText(long);
          break;
        }
      }
    } else if (parent === INFO_HOURS) {
      for (const [key, value] of Object.entries(tempHours)) {
        if (key === currentInfoDate) {
          let long = '';
          value[cell.row.index].longText.forEach((text) => {
            long += `${text}\n`;
          });
          setLongText(long);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = ({ target: { value } }) => {
    setLongText(value);
  };

  const setLongTextToEditingCell = () => {
    const longTextToSave =
      longText.trim() === '' ? [] : longText.trim().split('\n');
    if (parent === STUNDEN) {
      updateLongText(
        cell.column.id,
        cell.row.index - 2,
        longTextToSave,
        STUNDEN
      );
    } else if (parent === ABWESEN) {
      updateLongText(
        cell.column.id,
        cell.row.index - 2,
        longTextToSave,
        ABWESEN
      );
    } else if (parent === INFO_HOURS) {
      updateHour(longTextToSave, 'longText', cell.row.index, currentInfoDate);
    } else if (parent === INFO_TIMESTAMPS) {
      updateTimestamp(
        longTextToSave,
        'longText',
        cell.row.index,
        currentInfoDate
      );
    }
  };

  const onClose = (shouldSetLongText) => {
    if (shouldSetLongText && !isLangTextReadOnly) {
      setLongTextToEditingCell();
    }
    hideModal(LANGTEXT);
  };

  const onKeyUp = (e) => {
    let text = e.target.value.replace('\n', '');

    if (text.length > 72) {
      var chunks = text.match(/.{1,72}/g);
      e.target.value = chunks.join('\n');
    }
  };

  return (
    <Modal
      visible={currentModals.hasOwnProperty(LANGTEXT) ? true : false}
      style={{ top: '155px' }}
      title={
        <Row align="middle">
          <Col flex="auto">
            <h3 style={{ marginBottom: '0' }}>Langtext</h3>
            {/* <p style={{ margin: 0, fontSize: '13px', fontWeight: 'normal' }}>
              Personalnummer:
              <span style={{ marginLeft: '10px', color: 'RGB(24, 144, 255)' }}>
                1044
              </span>
            </p> */}
          </Col>
          <Col flex="25px">
            <CalendarOutlined style={{ color: 'RGB(24, 144, 255)' }} />
          </Col>
          <Col flex="80px">
            <p
              style={{
                color: 'RGB(24, 144, 255)',
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '0',
              }}
            >
              {parent === STUNDEN || parent === ABWESEN
                ? moment(cell.column.id).format('DD.MM.YYYY')
                : moment(currentInfoDate).format('DD.MM.YYYY')}
            </p>
          </Col>
        </Row>
      }
      closable={false}
      onCancel={() => onClose(true)}
      width={800}
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          Abbrechen
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onClose(true)}
          disabled={isLangTextReadOnly}
        >
          Fertig
        </Button>,
      ]}
    >
      <TextArea
        rows={10}
        value={longText}
        onChange={onChange}
        onKeyUp={onKeyUp}
        disabled={isLangTextReadOnly}
      />
    </Modal>
  );
};

export default LangModal;

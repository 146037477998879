import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Radio, Row, Col, message } from 'antd';

import { SAVE_LAYOUT } from '../../modalTypes';

import {
  saveVorlage,
  toggleEditedVorlage,
} from '../../../../redux/vorlage/vorlageActions';

const SaveLayoutModal = ({
  currentModals,
  hideModal,
  saveVorlage,
  timePost,
  user,
  loading,
  savedLayout,
  toggleEditedVorlage,
  error,
}) => {
  const [value, setValue] = useState('pernr');

  useEffect(() => {
    if (savedLayout) {
      toggleEditedVorlage();
      hideModal(SAVE_LAYOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedLayout]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const onClose = () => {
    hideModal(SAVE_LAYOUT);
  };

  const onDone = () => {
    const { profile, pernr } = user;

    if (value === 'pernr') {
      saveVorlage(user, { pernr, profile }, timePost);
    } else {
      saveVorlage(user, { profile }, timePost);
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: '0' }}>Zeiterfassung</h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(SAVE_LAYOUT) ? true : false}
      onCancel={() => onClose(true)}
      width={400}
      footer={[
        <Button key='back' onClick={() => onClose(false)}>
          Abbrechen
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => onDone()}
        >
          Fertig
        </Button>,
      ]}
    >
      <Row style={{ marginBottom: '1rem', fontWeight: '500' }}>
        Wie solite die Vorlage gespeichert werden?
      </Row>
      <Row>
        <Radio.Group onChange={onChange} value={value}>
          <Radio style={radioStyle} value={'pernr'}>
            Auf Personalnummer {user.pernr || ''}
          </Radio>
          <Radio style={radioStyle} value={'profile'}>
            Auf Benutzername {user.ename || ''}
          </Radio>
        </Radio.Group>
      </Row>
    </Modal>
  );
};

const mapStateToProps = ({
  auth: { user },
  vorlage: { loading, error, savedLayout },
  time: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
  },
}) => ({
  user,
  timePost: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
  },
  loading,
  error,
  savedLayout,
});

const mapDispatchToProps = (dispatch) => ({
  saveVorlage: (auth, params, time) =>
    dispatch(saveVorlage(auth, params, time)),
  toggleEditedVorlage: () => dispatch(toggleEditedVorlage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SaveLayoutModal);

export const getDaysArray = (year, monthIndex) => {
  const date = new Date(year, monthIndex, 1);
  const result = [];
  while (date.getMonth() === monthIndex) {
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    let month = monthIndex < 9 ? '0' + (monthIndex + 1) : monthIndex + 1;
    result.push(`${year}-${month}-${day}`);
    date.setDate(date.getDate() + 1);
  }
  return result;
};

import React from 'react';
import { connect } from 'react-redux';
import { useTable, useAbsoluteLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { hideModal } from '../../../../redux/modals/modalActions';

import { KOKRS } from '../../modalTypes';

const BukrsTable = ({ columns, data, hideModal, selectedKokrs }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSticky,
    useAbsoluteLayout
  );

  const onRowSelect = (row) => {
    selectedKokrs(row.values.kokrs);
    hideModal(KOKRS);
  };

  return (
    <div className='tableWrap'>
      <table {...getTableProps()} className='table sticky' id='bukrsTable'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className='tr'>
              {headerGroup.headers.map((column) => {
                const headerProps = column.getHeaderProps();
                headerProps.style = {
                  width: `${column.width}px`,
                  textAlign: 'left',
                };

                return (
                  <th {...headerProps} className='th header'>
                    {column.render('Header')}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className='body'>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={() => onRowSelect(row)}>
                {row.cells.map((cell) => {
                  let props = cell.getCellProps();
                  props.style = {
                    width: `${cell.column.width}px`,
                  };

                  return (
                    <td {...props} className='td'>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: (type) => dispatch(hideModal(type)),
});

export default connect(null, mapDispatchToProps)(BukrsTable);

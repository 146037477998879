import moment from 'moment';
import { addZeroes } from '../../utils/addZeroes';
import { convertHoursToDecimal } from '../../utils/convertHoursToDecimal';
import { getTimeDuration } from '../../utils/timeDuration';
import { isEmpty, cloneDeep } from 'lodash';
import { insert } from '../../utils/insertItemInArray';
import { getDaysArray } from '../../utils/getDaysArray';
import { uniqueId } from '../../utils/uniqueId';

export const addTimeToHours = (
  hours,
  updatedTime,
  updatedCell,
  columnIdentifier
) => {
  if (updatedTime.lgart === '1000' && moment(columnIdentifier).isValid()) {
    let editedHours = cloneDeep(hours);

    if (editedHours.hasOwnProperty(columnIdentifier)) {
      const hourIndex = editedHours[columnIdentifier].findIndex(
        (hour) => hour.counter === updatedCell.counter
      );

      if (hourIndex === -1) {
        editedHours[columnIdentifier] = [
          ...editedHours[columnIdentifier],
          {
            counter: updatedCell.counter,
            kostlOrPosid: updatedTime.kostlOrPosid,
            kostlOrPosidText: updatedTime.kostlOrPosidText,
            quantity: updatedCell.quantity,
            shortText: updatedCell.shortText,
            longText: updatedCell.longText,
            isReadOnly: updatedCell.isReadOnly,
            deleted: '',
            lstar: '',
            isApproved: false,
            isKostlOrPosidValid: true,
            isFetchingKostlOrPosid: false,
            isLstarValid: true,
            isFetchingLstar: false,
          },
        ];
      } else {
        editedHours[columnIdentifier][hourIndex].quantity =
          updatedCell.quantity;
      }
    } else {
      editedHours = {
        ...editedHours,
        [columnIdentifier]: [
          {
            counter: updatedCell.counter,
            kostlOrPosid: updatedTime.kostlOrPosid,
            kostlOrPosidText: updatedTime.kostlOrPosidText,
            quantity: updatedCell.quantity,
            shortText: updatedCell.shortText,
            longText: updatedCell.longText,
            isReadOnly: updatedCell.isReadOnly,
            deleted: '',
            isApproved: false,
            lstar: '',
            isKostlOrPosidValid: true,
            isFetchingKostlOrPosid: false,
            isLstarValid: true,
            isFetchingLstar: false,
          },
        ],
      };
    }

    return editedHours;
  } else {
    return hours;
  }
};

export const addTimestampToHoursOnTimeEdit = (
  workdate,
  tempHours,
  tempTimestamps,
  timestampIndex
) => {
  const editedHours = cloneDeep(tempHours);

  if (tempTimestamps[workdate][timestampIndex].kostlOrPosid !== '') {
    const filteredTimestamps = Object.values(tempTimestamps[workdate]).filter(
      (timestamp) =>
        timestamp.kostlOrPosid ===
        tempTimestamps[workdate][timestampIndex].kostlOrPosid
    );
    const filteredTimestampsQuantity = filteredTimestamps.reduce(
      (sum, timestamp) =>
        sum +
        parseFloat(
          convertHoursToDecimal(addZeroes(getTimeDuration(timestamp)))
        ),
      0
    );

    const hourIndex = editedHours[workdate].findIndex(
      (hour) =>
        hour.kostlOrPosid ===
        tempTimestamps[workdate][timestampIndex].kostlOrPosid
    );

    if (hourIndex !== -1) {
      editedHours[workdate][hourIndex].quantity = filteredTimestampsQuantity;
    } else {
      editedHours[workdate] = [
        ...editedHours[workdate],
        {
          counter: `$${tempTimestamps[workdate][timestampIndex].counter.slice(
            1,
            tempTimestamps[workdate][timestampIndex].counter.length
          )}`,
          kostlOrPosid: tempTimestamps[workdate][timestampIndex].kostlOrPosid,
          kostlOrPosidText:
            tempTimestamps[workdate][timestampIndex].kostlOrPosidText,
          quantity: filteredTimestampsQuantity,
          shortText: '',
          longText: [],
          isReadOnly: true,
          deleted: '',
          isApproved: false,
          lstar: '',
          isKostlOrPosidValid: true,
          isFetchingKostlOrPosid: false,
          isLstarValid: true,
          isFetchingLstar: false,
        },
      ];
    }
  }
  return editedHours;
};

export const addTimestampToHoursOnKostlOrPosidEdit = (
  workdate,
  tempHours,
  tempTimestamps,
  timestampIndex,
  oldKostlOrPosid
) => {
  let editedHours = cloneDeep(tempHours);

  const filteredTimestamps = Object.values(tempTimestamps[workdate]).filter(
    (timestamp) =>
      timestamp.kostlOrPosid ===
      tempTimestamps[workdate][timestampIndex].kostlOrPosid
  );
  const filteredTimestampsQuantity = filteredTimestamps.reduce(
    (sum, timestamp) =>
      sum +
      parseFloat(convertHoursToDecimal(addZeroes(getTimeDuration(timestamp)))),
    0
  );

  if (editedHours.hasOwnProperty(workdate)) {
    const filteredHours = Object.values(tempHours[workdate] || []).filter(
      (hour) =>
        hour.kostlOrPosid ===
        tempTimestamps[workdate][timestampIndex].kostlOrPosid
    );

    const hourIndex = Object.values(tempHours[workdate] || []).findIndex(
      (hour) =>
        hour.kostlOrPosid ===
        tempTimestamps[workdate][timestampIndex].kostlOrPosid
    );

    const oldHourIndex = Object.values(tempHours[workdate] || []).findIndex(
      (hour) => hour.kostlOrPosid === oldKostlOrPosid
    );

    if (tempTimestamps[workdate][timestampIndex].kostlOrPosid === '') {
      const oldHourIndexCounter = Object.values(
        tempHours[workdate] || []
      ).findIndex((hour) =>
        tempTimestamps[workdate][timestampIndex].counter.includes(
          hour.counter,
          1
        )
      );
      if (oldHourIndexCounter !== -1) {
        editedHours[workdate] = editedHours[workdate].filter(
          (hour) =>
            !tempTimestamps[workdate][timestampIndex].counter.includes(
              hour.counter,
              1
            )
        );
        return editedHours;
      }
    }

    if (oldHourIndex !== -1) {
      const oldKostlOrPosidIndex = tempTimestamps[workdate].findIndex(
        (timestamp) => timestamp.kostlOrPosid === oldKostlOrPosid
      );
      const oldKostlOrPosidTimestampsQuantity = tempTimestamps[workdate].reduce(
        (sum, timestamp) =>
          sum +
          (timestamp.kostlOrPosid === oldKostlOrPosid
            ? parseFloat(
                convertHoursToDecimal(addZeroes(getTimeDuration(timestamp)))
              )
            : 0),
        0
      );

      if (oldKostlOrPosidIndex !== -1) {
        editedHours[workdate][
          oldHourIndex
        ].quantity = oldKostlOrPosidTimestampsQuantity;
      } else {
        editedHours[workdate][oldHourIndex].isReadOnly = false;
      }

      if (
        tempTimestamps[workdate][timestampIndex].counter.includes(
          editedHours[workdate][oldHourIndex].counter,
          1
        ) &&
        editedHours[workdate][oldHourIndex].kostlOrPosid !==
          tempTimestamps[workdate][timestampIndex].kostlOrPosid
      ) {
        editedHours[workdate] = editedHours[workdate].filter(
          (hour) =>
            hour.counter !== tempTimestamps[workdate][timestampIndex].counter
        );
      }
    }

    if (filteredHours.length === 1) {
      editedHours[workdate][hourIndex] = {
        ...editedHours[workdate][hourIndex],
        isReadOnly: true,
        quantity: filteredTimestampsQuantity,
      };
    } else if (
      hourIndex === -1 &&
      tempTimestamps[workdate][timestampIndex].kostlOrPosid !== ''
    ) {
      editedHours[workdate] = [
        ...editedHours[workdate],
        {
          counter: `$${tempTimestamps[workdate][timestampIndex].counter.slice(
            1,
            tempTimestamps[workdate][timestampIndex].counter.length
          )}`,
          kostlOrPosid: tempTimestamps[workdate][timestampIndex].kostlOrPosid,
          kostlOrPosidText:
            tempTimestamps[workdate][timestampIndex].kostlOrPosidText,
          quantity: filteredTimestampsQuantity,
          shortText:
            filteredTimestamps.length > 1
              ? ''
              : tempTimestamps[workdate][timestampIndex].shortText,
          longText:
            filteredTimestamps.length > 1
              ? ''
              : tempTimestamps[workdate][timestampIndex].longText,
          isReadOnly: filteredTimestamps.length <= 1,
          deleted: '',
          isApproved: false,
          lstar: '',
          isKostlOrPosidValid: true,
          isFetchingKostlOrPosid: false,
          isLstarValid: true,
          isFetchingLstar: false,
        },
      ];
    } else {
      editedHours[workdate][hourIndex] = {
        ...editedHours[workdate][hourIndex],
        isReadOnly: false,
        quantity: filteredTimestampsQuantity,
      };
    }
  } else {
    editedHours = {
      ...editedHours,
      [workdate]: [
        {
          counter:
            filteredTimestamps.length > 1
              ? uniqueId()
              : `$${tempTimestamps[workdate][timestampIndex].counter.slice(
                  1,
                  tempTimestamps[workdate][timestampIndex].counter.length
                )}`,
          kostlOrPosid: tempTimestamps[workdate][timestampIndex].kostlOrPosid,
          kostlOrPosidText:
            tempTimestamps[workdate][timestampIndex].kostlOrPosidText,
          quantity: filteredTimestampsQuantity,
          shortText:
            filteredTimestamps.length > 1
              ? ''
              : tempTimestamps[workdate][timestampIndex].shortText,
          longText:
            filteredTimestamps.length > 1
              ? ''
              : tempTimestamps[workdate][timestampIndex].longText,
          isReadOnly: true,
          deleted: '',
          isApproved: false,
          lstar: '',
          isKostlOrPosidValid: true,
          isFetchingKostlOrPosid: false,
          isLstarValid: true,
          isFetchingLstar: false,
        },
      ],
    };
  }

  return { ...editedHours };
};

export const mergeTimesFromHours = (
  workdate,
  mergedHours,
  times,
  columnInfo,
  absences
) => {
  let nonExistingTimeHours = cloneDeep(mergedHours);
  let tempTimes = cloneDeep(times);

  let nonDuplicateTimes = {};
  let lgtxt = '';
  let unit = '';
  let currency = '';

  let editedTimes = tempTimes.map((time, index) => {
    if (time.lgart === '1000' && lgtxt === '') {
      lgtxt = time.lgtxt;
      unit = time.unit;
      currency = time.currency;
    }

    if (
      time.lgart === '1000' &&
      mergedHours.hasOwnProperty(time.kostlOrPosid)
    ) {
      // This is needed to filter non-existing times from hours.
      delete nonExistingTimeHours[time.kostlOrPosid];

      if (!nonDuplicateTimes.hasOwnProperty(time.kostlOrPosid)) {
        let cellsObject = {};
        for (const [cellDate, cell] of Object.entries(time.cells)) {
          cellsObject = {
            ...cellsObject,
            [cellDate]:
              cellDate === workdate
                ? {
                    ...cell,
                    quantity: mergedHours[time.kostlOrPosid].quantity,
                    isReadOnly:
                      mergedHours[time.kostlOrPosid].count > 1 ||
                      mergedHours[time.kostlOrPosid].isReadOnly,
                    longText: mergedHours[time.kostlOrPosid].longText,
                    shortText: mergedHours[time.kostlOrPosid].shortText,
                  }
                : cell,
          };
        }

        nonDuplicateTimes = {
          ...nonDuplicateTimes,
          [time.kostlOrPosid]: {
            time,
            rowIndex: index,
            cellsObject,
          },
        };
      }

      if (time.cells.hasOwnProperty(workdate)) {
        time.cells[workdate] = {
          ...time.cells[workdate],
          quantity: mergedHours[time.kostlOrPosid].quantity,
          longText: mergedHours[time.kostlOrPosid].longText,
          shortText: mergedHours[time.kostlOrPosid].shortText,
        };
      }

      return { ...time, cells: { ...time.cells } };
    }
    return time;
  });

  if (!isEmpty(nonExistingTimeHours)) {
    for (const [i, time] of editedTimes.entries()) {
      if (time.kostlOrPosid === '' && time.lgart === '') {
        for (const [j, value] of Object.entries(nonExistingTimeHours)) {
          const daysOfMonth = getDaysArray(
            moment(workdate).year(),
            moment(workdate).month()
          );
          const tempCells = {};

          daysOfMonth.forEach((day) => {
            tempCells[day] = {
              counter:
                day === workdate ? nonExistingTimeHours[j].counter : uniqueId(),
              quantity: day === workdate ? nonExistingTimeHours[j].quantity : 0,
              shortText:
                day === workdate ? nonExistingTimeHours[j].shortText : '',
              longText:
                day === workdate ? nonExistingTimeHours[j].longText : [],
              isReadOnly:
                day === workdate
                  ? nonExistingTimeHours[j].count > 1 ||
                    nonExistingTimeHours[j].isReadOnly
                  : columnInfo[day].isReadonly,
              isHoliday: columnInfo[day].isHoliday,
              isWeekend: columnInfo[day].isWeekend,
            };
          });

          editedTimes = insert(editedTimes, i, {
            kostlOrPosid: j,
            kostlOrPosidText: value.kostlOrPosidText,
            lgart: '1000',
            lgtxt,
            sum: '',
            unit,
            currency,
            cells: tempCells,
            isKostlOrPosidValid: true,
            isFetchingKostlOrPosid: false,
            isLgartValid: true,
            isFetchingLgart: false,
          });
        }
        break;
      }
    }
  }

  for (const [i, time] of editedTimes.entries()) {
    if (nonDuplicateTimes.hasOwnProperty(time.kostlOrPosid)) {
      if (
        time.lgart === '1000' &&
        nonDuplicateTimes[time.kostlOrPosid].rowIndex !== i
      ) {
        for (const [cellDate, cell] of Object.entries(time.cells)) {
          let cellsObject = nonDuplicateTimes[time.kostlOrPosid].cellsObject;

          if (cellsObject.hasOwnProperty(cellDate)) {
            // if (cellsObject[cellDate].quantity !== 0) {
            //   cellsObject[cellDate].isReadOnly = true;
            // }

            if (
              cellDate === workdate &&
              (mergedHours[time.kostlOrPosid].count > 1 ||
                mergedHours[time.kostlOrPosid].isReadOnly)
            ) {
              cellsObject[cellDate].isReadOnly = true;
            }

            cellsObject[cellDate].quantity =
              cellDate !== workdate
                ? parseFloat(cellsObject[cellDate].quantity) +
                  parseFloat(cell.quantity)
                : cell.quantity;
          } else {
            cellsObject = {
              ...cellsObject,
              [cellDate]: cell,
            };
          }
          nonDuplicateTimes[time.kostlOrPosid].cellsObject = cellsObject;
        }
      }
    }
  }

  let mergedEditedTimes = [];

  for (const [i, time] of editedTimes.entries()) {
    let editedTime = time;

    if (
      time.lgart === '1000' &&
      !mergedHours.hasOwnProperty(time.kostlOrPosid)
    ) {
      let shouldDeleteTime = true;

      for (const [cellDate, cell] of Object.entries(time.cells)) {
        if (cell.quantity !== 0 && cellDate !== workdate) {
          shouldDeleteTime = false;
        }
      }

      if (shouldDeleteTime) {
        continue;
      } else {
        editedTime.cells[workdate].quantity = 0;
      }
    }

    if (nonDuplicateTimes.hasOwnProperty(time.kostlOrPosid)) {
      if (
        time.lgart === '1000' &&
        i === nonDuplicateTimes[time.kostlOrPosid].rowIndex
      ) {
        editedTime.cells = nonDuplicateTimes[time.kostlOrPosid].cellsObject;
      } else if (
        time.lgart === '1000' &&
        i !== nonDuplicateTimes[time.kostlOrPosid].rowIndex
      ) {
        continue;
      }
    }
    mergedEditedTimes = [...mergedEditedTimes, editedTime];
  }

  // Re-calculate total
  const hoursTotal = Object.values(mergedHours).reduce(
    (sum, { quantity }) => sum + parseFloat(quantity),
    0
  );
  const absencesTotal = absences.reduce((sum, data) => {
    if (data.awart !== '' && data.cells[workdate].quantity !== '') {
      sum += parseFloat(data.cells[workdate].quantity);
    }
    return sum;
  }, 0);

  return { mergedEditedTimes, hoursAbsencesTotal: hoursTotal + absencesTotal };
};

export const addCostCenterOrWageTypeToHours = (
  isCostCenter,
  costCenterOrWageType,
  costCenterOrWageTypeText,
  hours,
  cells,
  oldKostlOrPosid
) => {
  let editedHours = cloneDeep(hours);

  const areTimesMerged = Object.values(cells).findIndex(
    (cell) => cell.isReadOnly
  );

  for (const [cellDate, cell] of Object.entries(cells)) {
    if (cell.quantity !== 0 || cell.quantity) {
      if (editedHours.hasOwnProperty(cellDate)) {
        if (isCostCenter) {
          if (areTimesMerged !== -1) {
            editedHours[cellDate] = editedHours[cellDate].map((hour) => {
              return hour.kostlOrPosid === oldKostlOrPosid
                ? {
                    ...hour,
                    kostlOrPosid: costCenterOrWageType,
                    kostlOrPosidText: costCenterOrWageTypeText,
                  }
                : hour;
            });
          } else {
            const hourIndex = editedHours[cellDate].findIndex(
              (hour) => hour.counter === cell.counter
            );

            editedHours[cellDate][hourIndex] = {
              ...editedHours[cellDate][hourIndex],
              kostlOrPosid: costCenterOrWageType,
              kostlOrPosidText: costCenterOrWageTypeText,
            };
          }
        } else {
          const hourIndex = editedHours[cellDate].findIndex(
            (hour) => hour.counter === cell.counter
          );

          if (hourIndex === -1) {
            editedHours[cellDate] = [
              ...editedHours[cellDate],
              {
                counter: cell.counter,
                kostlOrPosid: costCenterOrWageType,
                kostlOrPosidText: costCenterOrWageTypeText,
                quantity: cell.quantity,
                shortText: cell.shortText,
                longText: cell.longText,
                isReadOnly: cell.isReadOnly,
                lstar: '',
                isApproved: false,
                deleted: '',
                isKostlOrPosidValid: true,
                isFetchingKostlOrPosid: false,
                isLstarValid: true,
                isFetchingLstar: false,
              },
            ];
          } else {
            editedHours[cellDate][hourIndex] = {
              ...editedHours[cellDate][hourIndex],
              kostlOrPosid: costCenterOrWageType,
              kostlOrPosidText: costCenterOrWageTypeText,
            };
          }
        }
      } else {
        editedHours = {
          ...editedHours,
          [cellDate]: [
            {
              counter: cell.counter,
              kostlOrPosid: costCenterOrWageType,
              kostlOrPosidText: costCenterOrWageTypeText,
              quantity: cell.quantity,
              shortText: cell.shortText,
              longText: cell.longText,
              isReadOnly: cell.isReadOnly,
              deleted: '',
              isApproved: false,
              lstar: '',
              isKostlOrPosidValid: true,
              isFetchingKostlOrPosid: false,
              isLstarValid: true,
              isFetchingLstar: false,
            },
          ],
        };
      }
    }
  }
  return editedHours;
};

export const removeHours = (hours, cells) => {
  let editedHours = cloneDeep(hours);
  let hourIndex = -1;
  for (const [cellDate, cell] of Object.entries(cells)) {
    if (editedHours.hasOwnProperty(cellDate)) {
      hourIndex = editedHours[cellDate].findIndex(
        (hour) => hour.counter === cell.counter
      );

      if (hourIndex !== -1) {
        editedHours[cellDate].splice(hourIndex, 1);
      }
    }
  }

  return editedHours;
};

export const calculateTotal = (
  absences,
  times,
  columnId,
  rowIndex = -1,
  lgartOrAwart = null,
  currentTotal = null,
  oldLgartValue,
  deletedRow = null
) => {
  let timesSum = 0;
  let absencesSum = 0;
  let updatedTotal = null;

  if (moment(columnId).isValid()) {
    timesSum = times.reduce((sum, data) => {
      if (data.lgart === '1000' && data.cells[columnId].quantity !== '') {
        sum += parseFloat(data.cells[columnId].quantity);
      }
      return sum;
    }, 0);

    absencesSum = cloneDeep(absences).reduce((sum, data) => {
      if (data.awart !== '' && data.cells[columnId].quantity !== '') {
        sum += parseFloat(data.cells[columnId].quantity);
      }
      return sum;
    }, 0);
  } else if (
    (columnId === 'lgart' || columnId === 'awart') &&
    rowIndex !== -1 &&
    (lgartOrAwart || lgartOrAwart === '') &&
    currentTotal
  ) {
    updatedTotal = cloneDeep(currentTotal);
    switch (columnId) {
      case 'lgart':
        if (lgartOrAwart !== '1000') {
          Object.entries(currentTotal).forEach((id) => {
            if (id[0] !== 'sum' && oldLgartValue === '1000') {
              if (!deletedRow) {
                updatedTotal[id[0]].quantity -= Number(
                  times[rowIndex].cells[id[0]].quantity
                );
              } else {
                updatedTotal[id[0]].quantity -= Number(
                  deletedRow.cells[id[0]].quantity
                );
              }
            }
          });
        } else {
          Object.entries(currentTotal).forEach((id) => {
            if (id[0] !== 'sum') {
              updatedTotal[id[0]].quantity += Number(
                times[rowIndex].cells[id[0]].quantity
              );
            }
          });
        }
        break;
      case 'awart':
        if (lgartOrAwart === '') {
          Object.entries(currentTotal).forEach((id) => {
            if (id[0] !== 'sum') {
              if (!deletedRow) {
                updatedTotal[id[0]].quantity -= Number(
                  absences[rowIndex].cells[id[0]].quantity
                );
              } else {
                updatedTotal[id[0]].quantity -= Number(
                  deletedRow.cells[id[0]].quantity
                );
              }
            }
          });
        } else {
          Object.entries(currentTotal).forEach((id) => {
            if (id[0] !== 'sum') {
              updatedTotal[id[0]].quantity += Number(
                absences[rowIndex].cells[id[0]].quantity
              );
            }
          });
        }
        break;
      default:
        break;
    }
  }

  if (rowIndex === -1 && lgartOrAwart === null && currentTotal === null) {
    return Number(timesSum) + Number(absencesSum);
  } else {
    return updatedTotal;
  }
};

export const addEmptyTimeOrAbsences = (isTime, currentMonth, columnInfo) => {
  const length = isTime ? 10 : 5;
  let array = [];
  const daysOfMonth = getDaysArray(currentMonth.year(), currentMonth.month());

  for (let i = 0; i < length; i++) {
    const tempCells = {};
    daysOfMonth.forEach((day) => {
      tempCells[day] = {
        counter: uniqueId(),
        quantity: 0,
        shortText: '',
        longText: [],
        isReadOnly: false,
        isHoliday: columnInfo[day].isHoliday,
        isWeekend: columnInfo[day].isWeekend,
      };
    });

    if (isTime) {
      array.push({
        kostlOrPosid: '',
        kostlOrPosidText: '',
        lgart: '',
        lgtxt: '',
        unit: '',
        currency: '',
        sum: '',
        cells: tempCells,
        isKostlOrPosidValid: true,
        isFetchingKostlOrPosid: false,
        isLgartValid: true,
        isFetchingLgart: false,
      });
    } else {
      array.push({
        awart: '',
        atext: '',
        unit: '',
        sum: '',
        cells: tempCells,
        isAwartValid: true,
        isFetchingAwart: false,
      });
    }
  }

  return array;
};

export const addCounterToCells = (timesOrAbsences, isAddingForTimes) => {
  const updatedTimesOrAbsences = [...timesOrAbsences].map((time) => {
    Object.entries(time.cells).forEach((id) => {
      if (time.cells[id[0]].counter === '') {
        time.cells[id[0]].counter = uniqueId();
      }
    });

    const errorHandlersForSearchHelpCells = isAddingForTimes
      ? {
          isKostlOrPosidValid: true,
          isFetchingKostlOrPosid: false,
          isLgartValid: true,
          isFetchingLgart: false,
        }
      : { isAwartValid: true, isFetchingAwart: false };

    return { ...time, ...errorHandlersForSearchHelpCells };
  });

  return updatedTimesOrAbsences;
};

export const addErrorLoadingToHoursOrTimestamps = (
  tempHoursOrTimestamps,
  isAddingErrorToHours = false
) => {
  let editedTempHoursOrTimestamps = tempHoursOrTimestamps;

  for (const [hourOrTimestampDate, hoursOrTimestamps] of Object.entries(
    editedTempHoursOrTimestamps
  )) {
    const editedHoursOrTimestamps = hoursOrTimestamps.map((hourOrTimestamp) => {
      const lstarErrorLoading = isAddingErrorToHours
        ? { isFetchingLstar: false, isLstarValid: true }
        : {};
      return {
        ...hourOrTimestamp,
        isFetchingKostlOrPosid: false,
        isKostlOrPosidValid: true,
        ...lstarErrorLoading,
      };
    });
    editedTempHoursOrTimestamps[hourOrTimestampDate] = editedHoursOrTimestamps;
  }

  return editedTempHoursOrTimestamps;
};

export const mergeHoursAndTimestampsWithDeleted = (
  hours,
  timestamps,
  deletedHours,
  deletedTimestamps
) => {
  let mergedHoursWithDeleted = hours;
  let mergedTimestampsWithDeleted = timestamps;

  for (let [key, value] of Object.entries(deletedHours)) {
    if (hours.hasOwnProperty(key)) {
      mergedHoursWithDeleted = {
        ...mergedHoursWithDeleted,
        [key]: [...mergedHoursWithDeleted[key], ...value],
      };
    } else {
      mergedHoursWithDeleted = {
        ...mergedHoursWithDeleted,
        [key]: [value],
      };
    }
  }

  for (let [key, value] of Object.entries(deletedTimestamps)) {
    if (hours.hasOwnProperty(key)) {
      mergedTimestampsWithDeleted = {
        ...mergedTimestampsWithDeleted,
        [key]: [...mergedTimestampsWithDeleted[key], ...value],
      };
    } else {
      mergedTimestampsWithDeleted = {
        ...mergedTimestampsWithDeleted,
        [key]: [value],
      };
    }
  }

  return {
    hours: mergedHoursWithDeleted,
    timestamps: mergedTimestampsWithDeleted,
  };
};

export const FETCH_PSP_ELEMENTS_START = 'FETCH_PSP_ELEMENTS_START';
export const FETCH_PSP_ELEMENTS_SUCCESS = 'FETCH_PSP_ELEMENTS_SUCCESS';
export const FETCH_PSP_ELEMENTS_FAILURE = 'FETCH_PSP_ELEMENTS_FAILURE';
export const FETCH_KOST_START = 'FETCH_KOST_START';
export const FETCH_KOST_SUCCESS = 'FETCH_KOST_SUCCESS';
export const FETCH_KOST_FAILURE = 'FETCH_KOST_FAILURE';
export const FETCHED_PSP_ELEMENTS = 'FETCHED_PSP_ELEMENTS';
export const FETCHED_KOST = 'FETCHED_KOST';
export const FETCH_PSP_KOST_START = 'FETCH_PSP_KOST_START';
export const FETCH_PSP_KOST_SUCCESS = 'FETCH_PSP_KOST_SUCCESS';
export const FETCH_PSP_KOST_FAILURE = 'FETCH_PSP_KOST_FAILURE';
export const FETCHED_PSP_KOST = 'FETCHED_PSP_KOST';

import API from '../../api/api';

import {
  FETCH_KOKRS_START,
  FETCH_KOKRS_SUCCESS,
  FETCH_KOKRS_FAILURE,
  FETCHED_KOKRS,
  CLEAR_ERRORS,
} from './kokrsTypes';

export const fetchKokrsStart = () => {
  return {
    type: FETCH_KOKRS_START,
  };
};

export const fetchKokrsFailure = (error) => {
  return {
    type: FETCH_KOKRS_FAILURE,
    payload: { error },
  };
};

export const fetchKokrsSuccess = (kokrs) => {
  return {
    type: FETCH_KOKRS_SUCCESS,
    payload: { kokrs },
  };
};

export const setHasFetchedKokrs = (fetched) => {
  return {
    type: FETCHED_KOKRS,
    payload: fetched,
  };
};

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const fetchKokrs = (auth, searchData) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchKokrsStart());

    try {
      const res = await API.get('/SIF/time/controlling_areas', config);
      dispatch(fetchKokrsSuccess(res.data || []));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchKokrsFailure(msg));
      } else {
        dispatch(fetchKokrsFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTable, useAbsoluteLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useExportData } from 'react-table-plugins';
import { Dropdown, Button, message } from 'antd';

import exportMenu from '../../../../zeit/menu/ExportMenu';
import favoriteMenu from '../../../../zeit/menu/FavoriteMenu';

import { FavoriteMenuItem } from '../../../../../constants/favoriteMenuItem';

import Icon from '@ant-design/icons';

import AddLineIcon from '../../../../../assets/AddLineIcon';
import ExportIcon from '../../../../../assets/ExportIcon';
import RemoveLineIcon from '../../../../../assets/RemoveLineIcon';
import { ReactComponent as FavoriteIcon } from '../../../../../assets/favorite.svg';
import { ReactComponent as VorschlageIcon } from '../../../../../assets/vorschlage.svg';

import {
  addTimestamp,
  fetchTimestampsDefaults,
  saveOrDeleteTimestampsDefaults,
  setSavedOrDeletedTimestampDefaults,
} from '../../../../../redux/time/timeActions';

import { SAVE_CHANGES } from '../../../modalTypes';
import { showModal } from '../../../../../redux/modals/modalActions';

import getExportFileBlob from '../../../../../utils/getExportFileBlob';
import { uniqueId } from '../../../../../utils/uniqueId';

import moment from 'moment';

import styles from './InfoAbsesen.module.css';
import DescribedButton from '../../../../button-tooltip/DescribedButton';
import { REMOVE_INFO_ABWESEN } from '../../../../../utils/isValidActionButton';

const InfoAbwesenTable = ({
  columns,
  data,
  updateMyData,
  addTimestamp,
  currentDate,
  fetchTimestampsDefaults,
  saveOrDeleteTimestampsDefaults,
  user,
  savedTimestampDefaults,
  deletedTimestampDefaults,
  isFetchingTimestampDefaults,
  setSavedOrDeletedTimestampDefaults,
  error,
  showModal,
  isInfoReadOnly,
  onlyDisplay,
}) => {
  const [areRowsReadOnly, setAreRowsReadOnly] = useState(false);

  useEffect(() => {
    shouldDisableFavoriteDefaultBtns();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetchingTimestampDefaults) {
      message.loading('Laden...');
    } else {
      message.destroy();
    }

    if (error) {
      message.error(error);
    } else {
      message.destroy();
    }
  }, [isFetchingTimestampDefaults, error]);

  useEffect(() => {
    if (savedTimestampDefaults) {
      message.success('Favoriten wurden gespeichert', 2);
    } else {
      message.destroy();
    }

    if (deletedTimestampDefaults) {
      message.success('Favoriten wurden gelöscht', 2);
    } else {
      message.destroy();
    }

    setSavedOrDeletedTimestampDefaults(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedTimestampDefaults, deletedTimestampDefaults]);

  const getExportFileName = () => {
    return 'timestamps-data';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    exportData,
    // selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getExportFileBlob,
      getExportFileName,
    },
    useSticky,
    useAbsoluteLayout,
    useExportData
  );

  const addEmptyTimestamp = () => {
    addTimestamp(moment(currentDate._d).format('YYYY-MM-DD'), {
      counter: uniqueId(),
      kostlOrPosid: '',
      kostlOrPosidText: '',
      timeFrom: '',
      timeTo: '',
      shortText: '',
      longText: [],
      isReadOnly: false,
      deleted: '',
      isFetchingKostlOrPosid: false,
      isKostlOrPosidValid: true,
    });
  };

  const hasApprovedTimestamps = () => {
    if (data.length === 0) return false;

    return data.some((timestamp) => timestamp.isApproved);
  };

  const favoriteData = (type) => {
    if (type === FavoriteMenuItem.LADEN) {
      fetchTimestampsDefaults(
        user,
        moment(currentDate._d).format('YYYY-MM-DD'),
        true
      );
    } else if (type === FavoriteMenuItem.SICHERN) {
      saveOrDeleteTimestampsDefaults(user, data, false);
    } else if (type === FavoriteMenuItem.LOSCHEN) {
      saveOrDeleteTimestampsDefaults(user, [], true);
    }
  };

  const shouldOverrideDataWithFavorites = (type) => {
    if (data.length === 0 && type !== FavoriteMenuItem.SICHERN) {
      favoriteData(type);
    } else if (data.length > 0) {
      showModal(SAVE_CHANGES, {
        shouldSaveChanges: (shouldSave) => {
          if (shouldSave) {
            favoriteData(type);
          }
        },
        question:
          type === FavoriteMenuItem.LOSCHEN
            ? 'Wollen Sie die Favoriten wirklich löschen?'
            : 'Es sind bereits Daten erfasst. Trotzdem überschreiben?',
        title: 'Warnung',
      });
    }
  };

  const shouldOverrideDataWithDefaults = () => {
    if (data.length === 0) {
      fetchTimestampsDefaults(
        user,
        moment(currentDate._d).format('YYYY-MM-DD'),
        false
      );
    } else if (data.length > 0) {
      showModal(SAVE_CHANGES, {
        shouldSaveChanges: (shouldSave) => {
          if (shouldSave) {
            fetchTimestampsDefaults(
              user,
              moment(currentDate._d).format('YYYY-MM-DD'),
              false
            );
          }
        },
        question: 'Es sind bereits Daten erfasst. Trotzdem überschreiben?',
        title: 'Warnung',
      });
    }
  };

  const shouldDisableFavoriteDefaultBtns = () => {
    let hasReadOnlyRows = false;

    for (const row of data) {
      if (row.isReadOnly) {
        hasReadOnlyRows = true;
        break;
      }
    }

    setAreRowsReadOnly(hasReadOnlyRows);
  };

  return (
    <Fragment>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Arbeitszeiten</h3>
        <div className={styles.zeitButtonsWrapper}>
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={AddLineIcon} />}
            tooltip={'Zeile hinzufügen'}
            onClick={addEmptyTimestamp}
            type={'add'}
            disabled={isInfoReadOnly || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={RemoveLineIcon} />}
            tooltip={'Zeile löschen'}
            type={REMOVE_INFO_ABWESEN}
            disabled={hasApprovedTimestamps() || isInfoReadOnly || onlyDisplay}
          />
          {/* DUPLICATE BUTTON HIDDEN
           <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={DuplicateLineIcon} />}
            tooltip={'Zeile duplizieren'}
            type={DUPLICATE_INFO_ABWESEN}
            disabled={isInfoReadOnly || onlyDisplay}
          /> */}
          <Dropdown overlay={exportMenu(exportData)} trigger={['click']}>
            <Button
              className={styles.zeitTableButtons}
              icon={<Icon component={ExportIcon} />}
            />
          </Dropdown>
        </div>
        <div className={styles.zeitButtonsWrapper}>
          <Button
            className={styles.zeitTableButtons}
            disabled={
              hasApprovedTimestamps() ||
              isInfoReadOnly ||
              areRowsReadOnly ||
              onlyDisplay
            }
            icon={
              <Icon
                component={VorschlageIcon}
                className={styles.zeitButtonIcons}
              />
            }
            onClick={() => shouldOverrideDataWithDefaults()}
          >
            Vorschlag
          </Button>
          <Dropdown
            overlay={favoriteMenu((type) =>
              type === FavoriteMenuItem.SICHERN
                ? favoriteData(type)
                : shouldOverrideDataWithFavorites(type)
            )}
            disabled={
              hasApprovedTimestamps() ||
              isInfoReadOnly ||
              areRowsReadOnly ||
              onlyDisplay
            }
            trigger={['click']}
          >
            <Button
              className={styles.zeitTableButtons}
              icon={
                <Icon
                  component={FavoriteIcon}
                  className={styles.zeitButtonIcons}
                />
              }
            >
              Favoriten
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="tableWrap">
        <table
          {...getTableProps()}
          className="table sticky"
          id="infoAbwesenTable"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps();
                  headerProps.style = {
                    width: `${column.width}px`,
                    textAlign: 'left',
                  };

                  return (
                    <th {...headerProps} className="th header">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="body">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let props = cell.getCellProps();
                    props.style = {
                      width: `${cell.column.width}px`,
                    };

                    if (
                      isInfoReadOnly ||
                      data[cell.row.index].isReadOnly ||
                      onlyDisplay
                    ) {
                      props.style['background'] = '#D5EBFF';
                    }

                    if (
                      cell.column.id === 'kostlOrPosidText' ||
                      cell.column.id === 'quantity'
                    ) {
                      props.style['background'] = '#D5EBFF';
                    }

                    return (
                      <td {...props} className="td">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getHeaderGroupProps()} className="tr">
                {footerGroup.headers.map((column) => {
                  let style = {
                    ...column.getHeaderProps().style,
                    position: 'sticky',
                    bottom: 0,
                    background: '#FFFFA3',
                    border: 'none',
                    fontWeight: 'bold',
                  };
                  delete style.top;

                  let props = column.getHeaderProps();
                  props.style = style;

                  return (
                    <td {...props} className="td footer">
                      {column.render('Footer')}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({
  auth: { user },
  time: {
    savedTimestampDefaults,
    deletedTimestampDefaults,
    isFetchingTimestampDefaults,
    error,
  },
}) => ({
  user,
  savedTimestampDefaults,
  deletedTimestampDefaults,
  isFetchingTimestampDefaults,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  addTimestamp: (workdate, timestamp) =>
    dispatch(addTimestamp(workdate, timestamp)),
  fetchTimestampsDefaults: (auth, date, fetchFavourites) =>
    dispatch(fetchTimestampsDefaults(auth, date, fetchFavourites)),
  saveOrDeleteTimestampsDefaults: (auth, defaultTimestamps, shouldDelete) =>
    dispatch(
      saveOrDeleteTimestampsDefaults(auth, defaultTimestamps, shouldDelete)
    ),
  showModal: (type, props) => dispatch(showModal(type, props)),
  setSavedOrDeletedTimestampDefaults: (savedOrDeleted) =>
    dispatch(setSavedOrDeletedTimestampDefaults(savedOrDeleted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoAbwesenTable);

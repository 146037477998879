import axios from 'axios';

import { getMiddleware } from '../utils/getMiddleware';

import { store } from '../redux/store';

store.subscribe(listener);

const instance = axios.create({
  responseType: 'json',
});

function select(state) {
  const { protocol, ip } = state.settings.selectedSystem;
  return `${protocol}${ip}`;
}

function listener() {
  let url = select(store.getState());
  instance.defaults.baseURL = `${getMiddleware()}/sitf/values/?url=${url}:8000`;
}

export default instance;

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { fetchLeisarts } from '../../../../redux/leisart/leisartActions';

import LeisArtTableStyle from './LeisArtTableStyle';
import styles from './LeisArtModal.module.css';

import { LEISART } from '../../modalTypes';

import moment from 'moment';

import LeisArtModalTable from './LeisArtModalTable';

const LeisArtModal = ({
  auth,
  currentModals,
  currentDate,
  hideModal,
  fetchLeisarts,
  leisarts,
  cell,
  selectedLstar,
}) => {
  const [columns] = useState([
    {
      Header: 'Leistungstarten',
      accessor: 'lstar',
      width: 60,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'ltext',
    },
  ]);

  useEffect(() => {
    fetchLeisarts(auth, {
      lstar: '',
      date: moment(currentDate._d).format('YYYY-MM-DD'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal(LEISART);
  };

  const handleOk = () => {
    hideModal(LEISART);
  };

  const onRowSelect = (row) => {
    hideModal(LEISART);
    selectedLstar(cell, row.values.lstar, row.values.ltext, cell);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(LEISART) ? true : false}
      title='Suchhilfe für Leistungsarten'
      onOk={handleOk}
      style={{ top: '155px' }}
      onCancel={handleCancel}
      width={550}
      closable={true}
      footer={null}
    >
      <div className={styles.container}>
        <LeisArtTableStyle>
          <LeisArtModalTable
            columns={columnsMemo}
            data={leisarts || []}
            onRowSelect={onRowSelect}
          />
        </LeisArtTableStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ auth, leisart: { leisarts } }) => ({
  auth,
  leisarts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLeisarts: (auth, lstar) => dispatch(fetchLeisarts(auth, lstar)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LeisArtModal);

import API from '../../api/api';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_LOADING,
  SET_AUTH,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_FAILURE,
  SET_HAS_CHANGED_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ERRORS,
} from './authTypes';

import { showModal } from '../modals/modalActions';

import { CHANGE_PASSWORD } from '../../components/modals/modalTypes';

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

export const changePasswordStart = () => {
  return {
    type: CHANGE_PASSWORD_START,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFailure = (error) => {
  return {
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
  };
};

export const setHasChangedPassword = (hasChanged) => {
  return {
    type: SET_HAS_CHANGED_PASSWORD,
    payload: hasChanged,
  };
};

export const checkPasswordState = (formData) => {
  return async (dispatch, store) => {
    dispatch(setLoading(true));

    const { username, password } = formData;

    const encodedCredentials = btoa(username + ':' + password);

    const config = {
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
      params: {
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    try {
      const res = await API.get('/SIF/user/user', config);
      // TODO: This type of sending user data to store is not secure, needs to be changed.
      if (res.data) {
        if (res.data.pwdState === 1) {
          dispatch(
            showModal(CHANGE_PASSWORD, {
              tempAuthData: res.data,
            })
          );
          dispatch(
            setAuth({ user: { ...res.data, headerAuth: encodedCredentials } })
          );
          dispatch(setLoading(false));
        } else {
          dispatch(login(formData));
        }
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(loginFailure('Überprüfen Sie Benutzername oder Passwort'));
        } else {
          let msg = 'Ein unbekannter Fehler ist aufgetreten';
          dispatch(loginFailure(msg));
        }
      } else {
        dispatch(loginFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const changePassword = (formData) => {
  return async (dispatch, store) => {
    const { password } = formData;

    const encodedCredentials = store().auth.user.headerAuth;
    const oldPassword = atob(encodedCredentials).split(':')[1];

    const encodedPasswords = btoa(oldPassword) + ':' + btoa(password);

    const config = {
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
      params: {
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(changePasswordStart());

    try {
      const res = await API.put('/SIF/user/password', encodedPasswords, config);
      // TODO: This type of sending user data to store is not secure, needs to be changed.
      if (res.status === 200) {
        dispatch(changePasswordSuccess());
      } else {
        dispatch(
          changePasswordFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    } catch (error) {
      dispatch(changePasswordFailure('Ein unbekannter Fehler ist aufgetreten'));
    }
  };
};

export const login = (formData) => {
  return async (dispatch, store) => {
    dispatch(setLoading(true));

    const { username, password } = formData;

    const encodedCredentials = btoa(username + ':' + password);

    const config = {
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
      params: {
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    try {
      const res = await API.get('/SIF/time/defaults', config);
      // TODO: This type of sending user data to store is not secure, needs to be changed.
      if (res.data) {
        dispatch(
          loginSuccess({
            ...res.data,
            headerAuth: encodedCredentials,
          })
        );
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(loginFailure('Überprüfen Sie Benutzername oder Passwort'));
        } else {
          let msg = 'Ein unbekannter Fehler ist aufgetreten';
          dispatch(loginFailure(msg));
        }
      } else {
        dispatch(loginFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const setLoading = (loading) => {
  return {
    type: SET_LOADING,
    payload: loading,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const setAuth = (auth) => {
  return {
    type: SET_AUTH,
    payload: auth,
  };
};

import API from '../../api/api';

import {
  SAVE_VORLAGE_START,
  SAVE_VORLAGE_FAILURE,
  SAVE_VORLAGE_SUCCESS,
  COPY_VORLAGE_START,
  COPY_VORLAGE_FAILURE,
  COPY_VORLAGE_SUCCESS,
  DELETE_VORLAGE_START,
  DELETE_VORLAGE_FAILURE,
  DELETE_VORLAGE_SUCCESS,
  TOGGLE_EDITED_VORLAGE,
} from './vorlageTypes';

export const saveVorlageStart = () => {
  return {
    type: SAVE_VORLAGE_START,
  };
};

export const copyVorlageStart = () => {
  return {
    type: COPY_VORLAGE_START,
  };
};

export const deleteVorlageStart = () => {
  return {
    type: DELETE_VORLAGE_START,
  };
};

export const saveVorlageSuccess = () => {
  return {
    type: SAVE_VORLAGE_SUCCESS,
  };
};

export const deleteVorlageSuccess = () => {
  return {
    type: DELETE_VORLAGE_SUCCESS,
  };
};

export const copyVorlageSuccess = () => {
  return {
    type: COPY_VORLAGE_SUCCESS,
  };
};

export const saveVorlageFailure = (error) => {
  return {
    type: SAVE_VORLAGE_FAILURE,
    payload: error,
  };
};

export const deleteVorlageFailure = (error) => {
  return {
    type: DELETE_VORLAGE_FAILURE,
    payload: error,
  };
};

export const copyVorlageFailure = (error) => {
  return {
    type: COPY_VORLAGE_FAILURE,
    payload: error,
  };
};

export const toggleEditedVorlage = () => {
  return {
    type: TOGGLE_EDITED_VORLAGE,
  };
};

export const saveVorlage = (auth, params, time) => {
  return async (dispatch, store) => {
    const { headerAuth } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        ...params,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(saveVorlageStart());

    try {
      const res = await API.post('/SIF/time/template', { ...time }, config);
      dispatch(saveVorlageSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(saveVorlageFailure(msg));
      } else {
        dispatch(saveVorlageFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const deleteVorlage = (auth, params) => {
  return async (dispatch, store) => {
    const { headerAuth } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        ...params,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(deleteVorlageStart());

    try {
      const res = await API.delete('/SIF/time/template', config);
      dispatch(deleteVorlageSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(deleteVorlageFailure(msg));
      } else {
        dispatch(
          deleteVorlageFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

export const copyVorlage = (auth, pernr_source, time) => {
  return async (dispatch, store) => {
    const { pernr, profile, headerAuth } = auth;

    dispatch(copyVorlageStart());

    const config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        profile,
        pernr_source,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    try {
      const res = await API.post(
        '/SIF/time/copy_template',
        { ...time },
        config
      );
      dispatch(copyVorlageSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';

        if (error.status === 403) {
          msg = 'Kopieren wird nicht unterstützt';
        } else if (error.status === 404) {
          msg = 'Keine Vorlage gefunden';
        }

        dispatch(copyVorlageFailure(msg));
      } else {
        dispatch(copyVorlageFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

import {
  FETCH_TIMES_SUCCESS,
  FETCH_TIMES_FAILURE,
  FETCH_TIMES_START,
  TOGGLE_FROZEN_COLUMNS,
  UPDATE_TIME,
  UPDATE_ABSENCE,
  ADD_EMPTY_TIME,
  ADD_EMPTY_ABSENCE,
  SAVE_TIMES_START,
  SAVE_TIMES_FAILURE,
  SAVE_TIMES_SUCCESS,
  SET_SAVED_TIMES,
  SET_SAVED_ABSENCES,
  UPDATE_SHORT_TEXT,
  UPDATE_LONG_TEXT,
  SET_CURRENT_MONTH,
  UPDATE_COST_CENTER_OR_WBS,
  UPDATE_WAGE_TYPE,
  UPDATE_ABSENCE_TYPE,
  ADD_HOUR,
  ADD_TIMESTAMP,
  UPDATE_HOUR,
  UPDATE_TIMESTAMP,
  FETCH_TIMESTAMPS_DEFAULTS_START,
  FETCH_TIMESTAMPS_DEFAULTS_SUCCESS,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE,
  SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS,
  RELEASE_TIMES_START,
  RELEASE_TIMES_FAILURE,
  RELEASE_TIMES_SUCCESS,
  SET_TIMESTAMP_HOURS_FROM_TEMP,
  RESET_TEMP_TIMESTAMP_HOURS,
  FILTER_TIMES_FROM_HOURS,
  SET_HAS_UPDATED_HOURS_OR_TIMESTAMP,
  REMOVE_ROW_FROM_TABLE,
  DUPLICATE_ROW,
  SET_ERROR_ON_SEARCH_CELL,
  SET_LOADING_ON_SEARCH_CELL,
  SAVE_ABSENCES_START,
  SAVE_ABSENCES_SUCCESS,
  SAVE_ABSENCES_FAILURE,
  SET_REMAINING_HOURS_IN_DAY,
  SET_REMAINING_HOURS_FOR_MONTH,
  SET_TRAVEL_TIME,
  SET_HAS_FETCHED_TIMES,
} from './timeTypes';

import {
  addTimeToHours,
  mergeTimesFromHours,
  calculateTotal,
  addEmptyTimeOrAbsences,
  addCostCenterOrWageTypeToHours,
  addCounterToCells,
  removeHours,
  addTimestampToHoursOnKostlOrPosidEdit,
  addTimestampToHoursOnTimeEdit,
  addErrorLoadingToHoursOrTimestamps,
} from './timeUtils';

import { getDaysArray } from '../../utils/getDaysArray';

import {
  STUNDEN,
  ABWESEN,
  INFO_HOURS,
  INFO_TIMESTAMPS,
} from '../../components/modals/modalParentTypes';

import moment from 'moment';
import { cloneDeep } from 'lodash';
import { uniqueId } from '../../utils/uniqueId';

const initialState = {
  currentMonth: moment(),
  times: [],
  absences: [],
  timeColumns: null,
  absenceColumns: null,
  regularHours: null,
  hours: {},
  tempHours: {},
  tempDeletedHours: {},
  deletedHours: {},
  tempDeletedTimestamps: {},
  deletedTimestamps: {},
  timestamps: {},
  tempTimestamps: null,
  total: null,
  loading: true,
  loadingWhenSavingTimes: false,
  loadingWhenReleasingTimes: false,
  isFetchingTimestampDefaults: false,
  loadingWhenSavingAbsences: false,
  fetchedTimes: false,
  savedTimes: false,
  savedAbsences: false,
  frozenColumns: true,
  error: null,
  errorMessages: null,
  errorFetchingTimes: null,
  errorSavingAbsences: null,
  savedTimestampDefaults: false,
  deletedTimestampDefaults: false,
  oldLgartValue: '',
  onlyDisplay: false,
};

const timeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMES_START:
      return {
        ...state,
        loading: true,
        errorFetchingTimes: null,
      };
    case FETCH_TIMES_SUCCESS:
      const {
        times,
        absences,
        timeColumns,
        absenceColumns,
        regularHours,
        total,
        hours,
        timestamps,
        periodClosed,
        onlyDisplay,
      } = action.payload;

      const editedTimestamps = addErrorLoadingToHoursOrTimestamps(
        cloneDeep(timestamps)
      );
      const editedHours = addErrorLoadingToHoursOrTimestamps(
        cloneDeep(hours),
        true
      );

      const tempTimestamps = editedTimestamps;
      const tempHours = editedHours;

      const initialTimes = [
        ...addCounterToCells(cloneDeep(times), true),
        ...addEmptyTimeOrAbsences(true, state.currentMonth, timeColumns),
      ];

      const initialAbsences = [
        ...addCounterToCells(cloneDeep(absences), false),
        ...addEmptyTimeOrAbsences(false, state.currentMonth, absenceColumns),
      ];

      return {
        ...state,
        loading: false,
        times: [...initialTimes],
        absences: [...initialAbsences],
        fetchedTimes: true,
        timeColumns,
        absenceColumns,
        regularHours,
        total,
        hours: editedHours,
        timestamps: editedTimestamps,
        tempTimestamps,
        tempHours,
        periodClosed,
        onlyDisplay,
      };
    case FETCH_TIMES_FAILURE:
      return {
        ...state,
        loading: false,
        errorFetchingTimes: action.payload,
      };
    case SET_HAS_FETCHED_TIMES:
      return {
        ...state,
        fetchedTimes: action.payload,
      };
    case TOGGLE_FROZEN_COLUMNS:
      return {
        ...state,
        frozenColumns: !state.frozenColumns,
      };
    case ADD_EMPTY_TIME:
      const daysOfMonth = getDaysArray(
        state.currentMonth.year(),
        state.currentMonth.month()
      );
      let timeCells = {};

      daysOfMonth.forEach((day) => {
        timeCells[day] = {
          counter: uniqueId(),
          quantity: 0,
          shortText: '',
          longText: [],
          isReadOnly: state.absenceColumns[day].isReadonly,
          isHoliday: state.absenceColumns[day].isHoliday,
          isWeekend: state.absenceColumns[day].isWeekend,
        };
      });

      return {
        ...state,
        times: [
          ...state.times,
          {
            kostlOrPosid: '',
            kostlOrPosidText: '',
            lgart: '',
            lgtxt: '',
            unit: '',
            currency: '',
            sum: '',
            cells: timeCells,
            isFetchingKostlOrPosid: false,
            isFetchingLgart: false,
            isKostlOrPosidValid: true,
            isLgartValid: true,
          },
        ],
      };
    case UPDATE_TIME:
      const updatedTimes = cloneDeep(state.times);
      const updatedTime = cloneDeep(updatedTimes[action.payload.rowIndex]);
      let isHour = false;
      let isLgart = false;
      let localOldLgartValue = '';

      if (moment(action.payload.columnId).isValid()) {
        isHour = true;
        updatedTime.cells[action.payload.columnId].quantity = Number(
          action.payload.value
        );
      } else {
        // isHour = action.payload.columnId === 'lgart'
        if (action.payload.columnId === 'lgart') {
          isLgart = true;
          localOldLgartValue = updatedTime[action.payload.columnId];
        }
        updatedTime[action.payload.columnId] = action.payload.value;
      }

      if (updatedTime && updatedTimes) {
        updatedTimes[action.payload.rowIndex] = updatedTime;
      }

      const updatedHours = addTimeToHours(
        { ...state.tempHours },
        { ...updatedTime },
        { ...updatedTime.cells[action.payload.columnId] },
        action.payload.columnId
      );

      return {
        ...state,
        times: [...updatedTimes],
        total: isHour
          ? {
              ...state.total,
              [action.payload.columnId]: {
                quantity: calculateTotal(
                  state.absences,
                  updatedTimes,
                  action.payload.columnId
                ),
              },
            }
          : { ...state.total },
        hours: { ...updatedHours },
        tempHours: cloneDeep(updatedHours),
        oldLgartValue: isLgart ? localOldLgartValue : '',
      };
    case ADD_EMPTY_ABSENCE:
      const days = getDaysArray(
        state.currentMonth.year(),
        state.currentMonth.month()
      );
      let cells = {};

      days.forEach((day) => {
        cells[day] = {
          counter: uniqueId(),
          quantity: 0,
          shortText: '',
          longText: [],
          isReadOnly: state.absenceColumns[day].isReadonly,
          isHoliday: state.absenceColumns[day].isHoliday,
          isWeekend: state.absenceColumns[day].isWeekend,
        };
      });

      return {
        ...state,
        absences: [
          ...state.absences,
          {
            awart: '',
            atext: '',
            unit: '',
            sum: '',
            cells,
            isFetchingAwart: false,
            isAwartValid: true,
          },
        ],
      };
    case UPDATE_ABSENCE:
      const updatedAbsences = cloneDeep(state.absences);
      const updatedAbsence = cloneDeep(
        updatedAbsences[action.payload.rowIndex]
      );
      let isHourAbs = false;

      if (moment(action.payload.columnId).isValid()) {
        isHourAbs = true;
        updatedAbsence.sum = Object.entries(updatedAbsence.cells).reduce(
          (sum, data) => {
            let quantity = '0.00';
            if (data[0] === action.payload.columnId) {
              quantity = action.payload.value;
            } else {
              quantity = !isNaN(data[1].quantity) ? data[1].quantity : '0.00';
            }
            return Number(quantity) + Number(sum);
          },
          0
        );
        updatedAbsence.cells[action.payload.columnId].quantity = Number(
          action.payload.value
        );
      } else {
        updatedAbsence[action.payload.columnId] = action.payload.value;
        if (
          action.payload.columnId === 'awart' &&
          action.payload.value === ''
        ) {
          updatedAbsence['atext'] = '';
        }
      }

      updatedAbsences[action.payload.rowIndex] = updatedAbsence;

      return {
        ...state,
        absences: [...updatedAbsences],
        total: isHourAbs
          ? {
              ...state.total,
              [action.payload.columnId]: {
                quantity: calculateTotal(
                  updatedAbsences,
                  state.times,
                  action.payload.columnId
                ),
              },
            }
          : { ...state.total },
      };
    case SAVE_TIMES_START:
      return {
        ...state,
        loadingWhenSavingTimes: true,
      };
    case SAVE_TIMES_SUCCESS:
      const savedHours = addErrorLoadingToHoursOrTimestamps(
        cloneDeep(action.payload.hours),
        true
      );
      const savedTimestamps = addErrorLoadingToHoursOrTimestamps(
        cloneDeep(action.payload.timestamps)
      );

      const savedTimes = [
        ...addCounterToCells(cloneDeep(action.payload.times), true),
        ...addEmptyTimeOrAbsences(true, state.currentMonth, state.timeColumns),
      ];

      const savedAbsences = [
        ...addCounterToCells(cloneDeep(action.payload.absences), false),
        ...addEmptyTimeOrAbsences(
          false,
          state.currentMonth,
          state.absenceColumns
        ),
      ];

      return {
        ...state,
        loadingWhenSavingTimes: false,
        ...action.payload,
        times: savedTimes,
        absences: savedAbsences,
        hours: savedHours,
        timestamps: savedTimestamps,
        tempHours: savedHours,
        tempTimestamps: savedTimestamps,
        savedTimes: true,
      };
    case SAVE_TIMES_FAILURE:
      return {
        ...state,
        loadingWhenSavingTimes: false,
        errorMessages: action.payload,
      };
    case RELEASE_TIMES_START:
      return {
        ...state,
        loadingWhenReleasingTimes: true,
      };
    case RELEASE_TIMES_SUCCESS:
      return {
        ...state,
        loadingWhenReleasingTimes: false,
        ...action.payload,
        savedTimes: true,
      };
    case RELEASE_TIMES_FAILURE:
      return {
        ...state,
        loadingWhenReleasingTimes: false,
        errorMessages: action.payload,
      };
    case SET_SAVED_TIMES:
      return {
        ...state,
        savedTimes: action.payload,
      };
    case SET_SAVED_ABSENCES:
      return {
        ...state,
        savedAbsences: action.payload,
      };
    case UPDATE_SHORT_TEXT:
      let updatedTimesWithShortText = cloneDeep(state.times);
      let updatedAbsencesWithShortText = cloneDeep(state.absences);

      if (action.payload.modalParent === STUNDEN) {
        updatedTimesWithShortText[action.payload.rowIndex].cells[
          action.payload.columnId
        ].shortText = action.payload.shortText;
      } else if (action.payload.modalParent === ABWESEN) {
        updatedAbsencesWithShortText[action.payload.rowIndex].cells[
          action.payload.columnId
        ].shortText = action.payload.shortText;
      }

      return {
        ...state,
        times: updatedTimesWithShortText,
        absences: updatedAbsencesWithShortText,
      };
    case UPDATE_LONG_TEXT:
      let updatedTimesWithLongText = cloneDeep(state.times);
      let updatedAbsencesWithLongText = cloneDeep(state.absences);

      if (action.payload.modalParent === STUNDEN) {
        updatedTimesWithLongText[action.payload.rowIndex].cells[
          action.payload.columnId
        ].longText = action.payload.longText;
      } else if (action.payload.modalParent === ABWESEN) {
        updatedAbsencesWithLongText[action.payload.rowIndex].cells[
          action.payload.columnId
        ].longText = action.payload.longText;
      }

      return {
        ...state,
        times: updatedTimesWithLongText,
        absences: updatedAbsencesWithLongText,
      };
    case SET_CURRENT_MONTH:
      return {
        ...state,
        currentMonth: action.payload,
      };
    case UPDATE_COST_CENTER_OR_WBS:
      const { rowIndex, costCenterOrWbs, text, parent, workdate, oldPspValue } =
        action.payload;

      let changedTimesWithCostCenter = cloneDeep(state.times);
      let changedTempHoursWithCostCenter = cloneDeep(state.tempHours);
      let changedTempTimestampsWithCostCenter = cloneDeep(state.tempTimestamps);

      if (parent === STUNDEN) {
        changedTimesWithCostCenter[rowIndex].kostlOrPosid = costCenterOrWbs;
        changedTimesWithCostCenter[rowIndex].kostlOrPosidText = text;

        if (changedTimesWithCostCenter[rowIndex].lgart === '1000') {
          changedTempHoursWithCostCenter = addCostCenterOrWageTypeToHours(
            true,
            costCenterOrWbs,
            text,
            state.hours,
            changedTimesWithCostCenter[rowIndex].cells,
            oldPspValue
          );
        }
      } else if (parent === INFO_HOURS) {
        changedTempHoursWithCostCenter[workdate][rowIndex].kostlOrPosid =
          costCenterOrWbs;
        changedTempHoursWithCostCenter[workdate][rowIndex].kostlOrPosidText =
          text;
      } else if (parent === INFO_TIMESTAMPS) {
        changedTempTimestampsWithCostCenter[workdate][rowIndex].kostlOrPosid =
          costCenterOrWbs;
        changedTempTimestampsWithCostCenter[workdate][
          rowIndex
        ].kostlOrPosidText = text;

        changedTempHoursWithCostCenter = addTimestampToHoursOnKostlOrPosidEdit(
          workdate,
          state.tempHours,
          changedTempTimestampsWithCostCenter,
          rowIndex,
          oldPspValue
        );
      }

      return {
        ...state,
        times: changedTimesWithCostCenter,
        tempHours: changedTempHoursWithCostCenter,
        hours:
          parent === STUNDEN ? changedTempHoursWithCostCenter : state.hours,
        timestamps:
          parent === STUNDEN
            ? changedTempTimestampsWithCostCenter
            : state.timestamps,
        tempTimestamps: changedTempTimestampsWithCostCenter,
        hasUpdatedHours: parent !== STUNDEN,
      };
    case UPDATE_WAGE_TYPE:
      const { rowIndex2, lgart, lgtxt, unit, currency, isTravel } =
        action.payload;

      const changedTimes3 = cloneDeep(state.times);
      changedTimes3[rowIndex2].lgart = lgart;
      changedTimes3[rowIndex2].lgtxt = lgtxt;
      changedTimes3[rowIndex2].unit = unit;
      changedTimes3[rowIndex2].currency = currency;
      changedTimes3[rowIndex2].isTravel = isTravel;

      let changedHoursWithWageType = cloneDeep(state.hours);

      if (lgart === '1000') {
        changedHoursWithWageType = addCostCenterOrWageTypeToHours(
          false,
          changedTimes3[rowIndex2].kostlOrPosid,
          changedTimes3[rowIndex2].kostlOrPosidText,
          state.hours,
          changedTimes3[rowIndex2].cells,
          state.times[rowIndex2].kostlOrPosid
        );
      } else {
        changedHoursWithWageType = removeHours(
          state.hours,
          changedTimes3[rowIndex2].cells
        );
      }

      return {
        ...state,
        times: changedTimes3,
        hours: changedHoursWithWageType,
        tempHours: changedHoursWithWageType,
        total: calculateTotal(
          state.absences,
          changedTimes3,
          'lgart',
          rowIndex2,
          lgart,
          { ...state.total },
          state.oldLgartValue
        ),
      };
    case UPDATE_ABSENCE_TYPE:
      const { rowIndex3 } = action.payload;
      const shouldUpdateTotal = state.absences[rowIndex3].atext === '';

      const absencesTypeEdit = cloneDeep(state.absences);
      absencesTypeEdit[rowIndex3].awart = action.payload.awart;
      absencesTypeEdit[rowIndex3].atext = action.payload.atext;

      return {
        ...state,
        absences: absencesTypeEdit,
        total: shouldUpdateTotal
          ? calculateTotal(
              absencesTypeEdit,
              state.times,
              'awart',
              rowIndex3,
              action.payload.awart,
              { ...state.total }
            )
          : { ...state.total },
      };
    case ADD_HOUR:
      let editedTempHoursWithNewHour = cloneDeep(state.tempHours);

      if (editedTempHoursWithNewHour.hasOwnProperty(action.payload.workdate)) {
        editedTempHoursWithNewHour[action.payload.workdate] = [
          ...editedTempHoursWithNewHour[action.payload.workdate],
          action.payload.hour,
        ];
      } else {
        editedTempHoursWithNewHour[action.payload.workdate] = [
          action.payload.hour,
        ];
      }

      return {
        ...state,
        tempHours: editedTempHoursWithNewHour,
      };
    case ADD_TIMESTAMP:
      let editedTempTimestampsWithNewTimestamp = cloneDeep(
        state.tempTimestamps
      );

      if (
        editedTempTimestampsWithNewTimestamp.hasOwnProperty(
          action.payload.workdate
        )
      ) {
        editedTempTimestampsWithNewTimestamp[action.payload.workdate] = [
          ...editedTempTimestampsWithNewTimestamp[action.payload.workdate],
          action.payload.timestamp,
        ];
      } else {
        editedTempTimestampsWithNewTimestamp[action.payload.workdate] = [
          action.payload.timestamp,
        ];
      }

      return {
        ...state,
        tempTimestamps: editedTempTimestampsWithNewTimestamp,
      };
    case UPDATE_HOUR:
      const { editedHour, hourColumn, hourIndex } = action.payload;

      let editedTempHours = cloneDeep(state.tempHours);
      editedTempHours[action.payload.workdate][hourIndex][hourColumn] =
        editedHour;

      return {
        ...state,
        tempHours: editedTempHours,
      };
    case UPDATE_TIMESTAMP:
      const { editedTimestamp, timestampColumn, timestampIndex } =
        action.payload;

      let editedTempTimestamps = cloneDeep(state.tempTimestamps);
      editedTempTimestamps[action.payload.workdate][timestampIndex][
        timestampColumn
      ] = editedTimestamp;

      return {
        ...state,
        tempTimestamps: editedTempTimestamps,
        tempHours:
          timestampColumn === 'timeFrom' || timestampColumn === 'timeTo'
            ? addTimestampToHoursOnTimeEdit(
                action.payload.workdate,
                state.tempHours,
                editedTempTimestamps,
                timestampIndex
              )
            : state.tempHours,
      };
    case FETCH_TIMESTAMPS_DEFAULTS_START:
      return {
        ...state,
        isFetchingTimestampDefaults: true,
        error: null,
      };
    case FETCH_TIMESTAMPS_DEFAULTS_SUCCESS:
      const { defaultTimesDate, defaultTimes } = action.payload;

      let editedTimestampDefaults = { ...state.tempTimestamps };

      editedTimestampDefaults[defaultTimesDate] = defaultTimes.map(
        (defaultTime) => ({
          counter: uniqueId(),
          kostlOrPosid: '',
          kostlOrPosidText: '',
          workdate: defaultTimesDate,
          timeFrom: defaultTime.timeFrom || '',
          timeTo: defaultTime.timeTo || '',
          shortText: '',
          longText: [],
          isReadOnly: false,
          deleted: '',
          isFetchingKostlOrPosid: false,
          isKostlOrPosidValid: true,
        })
      );

      return {
        ...state,
        isFetchingTimestampDefaults: false,
        error: null,
        tempTimestamps: editedTimestampDefaults,
      };
    case SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START:
      return {
        ...state,
        isFetchingTimestampDefaults: true,
        error: null,
      };
    case SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS:
      return {
        ...state,
        isFetchingTimestampDefaults: false,
        error: null,
        deletedTimestampDefaults: action.payload,
        savedTimestampDefaults: !action.payload,
      };
    case SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE:
      return {
        ...state,
        isFetchingTimestampDefaults: false,
        error: action.payload,
      };
    case SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS:
      return {
        ...state,
        savedTimestampDefaults: false,
        deletedTimestampDefaults: false,
      };
    case SET_TIMESTAMP_HOURS_FROM_TEMP:
      return {
        ...state,
        timestamps: { ...action.payload.tempTimestamps },
        hours: { ...action.payload.tempHours },
        tempDeletedHours: {},
        deletedHours: { ...state.tempDeletedHours },
        tempDeletedTimestamps: {},
        deletedTimestamps: { ...state.tempDeletedTimestamps },
      };
    case RESET_TEMP_TIMESTAMP_HOURS:
      return {
        ...state,
        tempTimestamps: cloneDeep(state.timestamps),
        tempHours: cloneDeep(state.hours),
        tempDeletedHours: {},
        tempDeletedTimestamps: {},
      };
    case FILTER_TIMES_FROM_HOURS:
      const { mergedEditedTimes, hoursAbsencesTotal } = mergeTimesFromHours(
        action.payload.workdate,
        action.payload.mergedHours,
        [...state.times],
        state.timeColumns,
        [...state.absences]
      );

      const editedTotal = cloneDeep(state.total);
      editedTotal[action.payload.workdate].quantity = hoursAbsencesTotal;

      return {
        ...state,
        times: mergedEditedTimes,
        total: editedTotal,
      };
    case SET_HAS_UPDATED_HOURS_OR_TIMESTAMP:
      return {
        ...state,
        hasUpdatedHours: false,
      };

    case REMOVE_ROW_FROM_TABLE:
      let shouldUpdateTotalAfterDelete = false;
      switch (action.payload.table) {
        case STUNDEN:
          let newTimes = [...state.times].filter((element, index) => {
            if (index !== action.payload.index) {
              return element;
            } else {
              shouldUpdateTotalAfterDelete = element.lgart === '1000';
              return null;
            }
          });

          let newTotal = shouldUpdateTotalAfterDelete
            ? calculateTotal(
                state.absences,
                newTimes,
                'lgart',
                action.payload.index,
                '',
                { ...state.total },
                '1000',
                [...state.times][action.payload.index]
              )
            : { ...state.total };

          let newHours = cloneDeep(state.hours);

          for (const time of Object.entries(
            [...state.times][action.payload.index].cells
          )) {
            if (newHours[time[0]]) {
              let hoursToIterate = cloneDeep(newHours)
                [time[0]].filter(
                  (hour) =>
                    hour.kostlOrPosid ===
                      state.times[action.payload.index].kostlOrPosid &&
                    state.times[action.payload.index].lgart === '1000'
                )
                .map((hour) => hour.counter);

              if (state.times[action.payload.index].cells[time[0]].isReadOnly) {
                let targetSum = 0;
                let tempHours = cloneDeep(newHours[time[0]]);
                newHours[time[0]] = [];
                for (let i = 0; i < tempHours.length; i++) {
                  if (hoursToIterate.includes(tempHours[i].counter)) {
                    targetSum += parseFloat(tempHours[i].quantity.toFixed(2));
                    if (
                      !(
                        targetSum <=
                        state.times[action.payload.index].cells[time[0]]
                          .quantity
                      )
                    ) {
                      newHours[time[0]].push(tempHours[i]);
                    }
                  }
                }
              } else {
                newHours[time[0]] = newHours[time[0]].filter(
                  (hour) =>
                    hour.counter !==
                    state.times[action.payload.index].cells[time[0]].counter
                );
              }
            }
          }

          return {
            ...state,
            times: newTimes,
            total: newTotal,
            tempHours: newHours,
            hours: newHours,
          };
        case ABWESEN:
          let newAbsences = [...state.absences].filter((element, index) => {
            if (index !== action.payload.index) {
              return element;
            } else {
              shouldUpdateTotalAfterDelete = element.awart !== '';
              return null;
            }
          });
          return {
            ...state,
            absences: newAbsences,
            total: shouldUpdateTotalAfterDelete
              ? calculateTotal(
                  newAbsences,
                  state.times,
                  'awart',
                  action.payload.index,
                  '',
                  { ...state.total },
                  '0200',
                  [...state.absences][action.payload.index]
                )
              : { ...state.total },
          };
        case INFO_HOURS:
          let infoHourToDelete = null;

          let newTempHours = cloneDeep(state.tempHours)[
            moment(action.payload.workdate).format('YYYY-MM-DD')
          ].filter((element, index) => {
            if (index === action.payload.index) {
              infoHourToDelete = { ...element, deleted: 'X' };
            }
            return index !== action.payload.index;
          });

          let tempDeletedHoursPerDay = [];

          if (
            state.tempDeletedHours.hasOwnProperty(
              moment(action.payload.workdate).format('YYYY-MM-DD')
            )
          ) {
            tempDeletedHoursPerDay = [
              ...state.tempDeletedHours[
                moment(action.payload.workdate).format('YYYY-MM-DD')
              ],
            ];
          }

          const tempDeletedHours = {
            ...state.tempDeletedHours,
            [moment(action.payload.workdate).format('YYYY-MM-DD')]: [
              ...tempDeletedHoursPerDay,
              infoHourToDelete,
            ],
          };

          return {
            ...state,
            tempHours: {
              ...state.tempHours,
              [moment(action.payload.workdate).format('YYYY-MM-DD')]:
                newTempHours,
            },
            tempDeletedHours,
          };
        case INFO_TIMESTAMPS:
          let infoTimestampToDelete = null;

          let newTimestamps = cloneDeep(state.tempTimestamps)[
            moment(action.payload.workdate).format('YYYY-MM-DD')
          ].filter((element, index) => {
            if (index === action.payload.index) {
              infoTimestampToDelete = { ...element, deleted: 'X' };
            }
            return index !== action.payload.index;
          });

          let tempDeletedTimestampsPerDay = [];

          if (
            state.tempDeletedTimestamps.hasOwnProperty(
              moment(action.payload.workdate).format('YYYY-MM-DD')
            )
          ) {
            tempDeletedTimestampsPerDay = [
              ...state.tempDeletedTimestamps[
                moment(action.payload.workdate).format('YYYY-MM-DD')
              ],
            ];
          }

          const tempDeletedTimestamps = {
            ...state.tempDeletedTimestamps,
            [moment(action.payload.workdate).format('YYYY-MM-DD')]: [
              ...tempDeletedTimestampsPerDay,
              infoTimestampToDelete,
            ],
          };

          return {
            ...state,
            tempTimestamps: {
              ...state.tempTimestamps,
              [moment(action.payload.workdate).format('YYYY-MM-DD')]:
                newTimestamps,
            },
            tempDeletedTimestamps,
          };
        default:
          break;
      }
      break;
    case DUPLICATE_ROW:
      let shouldUpdateTotalAfterDuplicate = false;
      switch (action.payload.table) {
        case STUNDEN:
          let timesAfterDuplicate = cloneDeep(state.times);
          let timeToDuplicate = cloneDeep(state.times)[action.payload.index];
          let hoursAfterDuplicate = cloneDeep(state.hours);

          let checkForEmptyTime = (time) =>
            time.kostlOrPosid === '' &&
            time.lgart === '' &&
            time.unit === '' &&
            (time.sum === '' || time.sum === 0);
          let firstFreeIndex = [...state.times].findIndex(checkForEmptyTime);

          addCounterToCells([timeToDuplicate], true);

          shouldUpdateTotalAfterDuplicate = timeToDuplicate.lgart === '1000';
          //firstFreeIndex is -1 when there isn't element that satisfies checkForEmptyTime boolean
          if (firstFreeIndex === -1) {
            timesAfterDuplicate = [...state.times, timeToDuplicate];
          } else {
            timesAfterDuplicate[firstFreeIndex] = timeToDuplicate;
          }

          let totalAfterDuplicate = cloneDeep(state.total);
          totalAfterDuplicate = shouldUpdateTotalAfterDuplicate
            ? calculateTotal(
                [...state.absences],
                timesAfterDuplicate,
                'lgart',
                firstFreeIndex === -1
                  ? timesAfterDuplicate.length
                  : firstFreeIndex,
                '1000',
                { ...state.total },
                ''
              )
            : { ...state.total };

          for (const timeCell of Object.entries(timeToDuplicate.cells)) {
            timeCell[1].counter = uniqueId();
            if (hoursAfterDuplicate[timeCell[0]]) {
              let itemToDuplicate = hoursAfterDuplicate[timeCell[0]].findIndex(
                (element) =>
                  (element ? element.kostlOrPosid : '') ===
                    (timeToDuplicate ? timeToDuplicate.kostlOrPosid : '') &&
                  (timeToDuplicate ? timeToDuplicate.lgart === '1000' : false)
              );

              if (itemToDuplicate !== -1) {
                let item = cloneDeep(state.hours)[timeCell[0]][itemToDuplicate];
                item.counter = timeCell[1].counter;
                if (
                  parseFloat(item.quantity) < parseFloat(timeCell[1].quantity)
                ) {
                  for (let i = 0; i < state.hours[timeCell[0]].length; i++) {
                    let innerItem = cloneDeep(state.hours)[timeCell[0]][i];
                    innerItem.counter = uniqueId();
                    let hoursSum = hoursAfterDuplicate[timeCell[0]].reduce(
                      (sum, data) => Number(sum) + Number(data.quantity),
                      0
                    );
                    if (
                      innerItem.kostlOrPosid === timeToDuplicate.kostlOrPosid &&
                      totalAfterDuplicate[timeCell[0]].quantity > hoursSum
                    ) {
                      hoursAfterDuplicate[timeCell[0]].push(innerItem);
                    }
                  }
                } else {
                  if (
                    timeCell[1].quantity === item.quantity &&
                    item.kostlOrPosid === timeToDuplicate.kostlOrPosid
                  )
                    hoursAfterDuplicate[timeCell[0]].push(item);
                }
              }
            }
          }

          return {
            ...state,
            times: timesAfterDuplicate,
            total: totalAfterDuplicate,
            hours: hoursAfterDuplicate,
            tempHours: hoursAfterDuplicate,
          };

        case ABWESEN:
          let absencesAfterDuplicate = [...state.absences];
          let absenceToDuplicate = [...state.absences][action.payload.index];
          let checkForEmptyAbsence = (absence) =>
            absence.awart === '' &&
            absence.unit === '' &&
            absence.unit === '' &&
            (absence.sum === '' || absence.sum === 0);
          let firstFreeIndexInAbsences = [...state.absences].findIndex(
            checkForEmptyAbsence
          );

          shouldUpdateTotalAfterDuplicate = absenceToDuplicate.awart !== '';
          //firstFreeIndex is -1 when there isn't element that satisfies checkForEmptyTime boolean
          if (firstFreeIndexInAbsences === -1) {
            absencesAfterDuplicate = [...state.absences, absenceToDuplicate];
          } else {
            absencesAfterDuplicate[firstFreeIndexInAbsences] =
              absenceToDuplicate;
          }
          return {
            ...state,
            absences: absencesAfterDuplicate,
            total: shouldUpdateTotalAfterDuplicate
              ? calculateTotal(
                  absencesAfterDuplicate,
                  [...state.times],
                  'awart',
                  firstFreeIndexInAbsences === -1
                    ? absencesAfterDuplicate.length
                    : firstFreeIndexInAbsences,
                  '0200',
                  { ...state.total },
                  ''
                )
              : { ...state.total },
          };
        case INFO_HOURS:
          let hoursAfterDuplication = cloneDeep(state.tempHours);
          let checkForEmptyHours = (hour) =>
            hour.kostlOrPosid === '' &&
            hour.shortText === '' &&
            (hour.quantity === '' || hour.quantity === 0);
          let hourToDuplicate = cloneDeep(state.tempHours)[
            action.payload.workdate
          ][action.payload.index];

          let firstFreeIndexInHours =
            hoursAfterDuplication[action.payload.workdate].findIndex(
              checkForEmptyHours
            );

          if (firstFreeIndexInHours === -1) {
            hoursAfterDuplication[action.payload.workdate] = [
              ...hoursAfterDuplication[action.payload.workdate],
              cloneDeep(hourToDuplicate),
            ];
          } else {
            hoursAfterDuplication[action.payload.workdate][
              firstFreeIndexInHours
            ] = hourToDuplicate;
          }

          return {
            ...state,
            tempHours: hoursAfterDuplication,
          };
        case INFO_TIMESTAMPS:
          let timestampsAfterDuplication = cloneDeep(state.tempTimestamps);
          let checkForEmptyTimestamp = (timestamp) =>
            timestamp.kostlOrPosid === '' &&
            timestamp.timeFrom === '' &&
            timestamp.timeTo === '' &&
            timestamp.shortText === '' &&
            timestamp.longText.length === 0;

          let timestampToDuplicate = cloneDeep(state.tempTimestamps)[
            action.payload.workdate
          ][action.payload.index];

          let firstFreeIndexInTimestamps = timestampsAfterDuplication[
            action.payload.workdate
          ].findIndex(checkForEmptyTimestamp);

          if (firstFreeIndexInTimestamps === -1) {
            timestampsAfterDuplication[action.payload.workdate] = [
              ...timestampsAfterDuplication[action.payload.workdate],
              cloneDeep(timestampToDuplicate),
            ];
          } else {
            timestampsAfterDuplication[action.payload.workdate][
              firstFreeIndexInTimestamps
            ] = cloneDeep(timestampToDuplicate);
          }
          return {
            ...state,
            tempTimestamps: timestampsAfterDuplication,
          };
        default:
          return state;
      }
    case SET_ERROR_ON_SEARCH_CELL:
      switch (action.payload.parent) {
        case STUNDEN:
          let editedTimesWithErrorsOnCell = cloneDeep(state.times);

          if (action.payload.columnId === 'kostlOrPosid') {
            editedTimesWithErrorsOnCell[
              action.payload.rowIndex
            ].isKostlOrPosidValid = action.payload.isValid;
          } else {
            editedTimesWithErrorsOnCell[action.payload.rowIndex].isLgartValid =
              action.payload.isValid;
          }

          return { ...state, times: editedTimesWithErrorsOnCell };
        case ABWESEN:
          let editedAbsencesWithErrorsOnCell = cloneDeep(state.absences);
          editedAbsencesWithErrorsOnCell[action.payload.rowIndex].isAwartValid =
            action.payload.isValid;

          return { ...state, absences: editedAbsencesWithErrorsOnCell };
        case INFO_HOURS:
          let editedTempHoursWithErrorsOnCell = cloneDeep(state.tempHours);

          if (action.payload.columnId === 'kostlOrPosid') {
            editedTempHoursWithErrorsOnCell[action.payload.workdate][
              action.payload.rowIndex
            ].isKostlOrPosidValid = action.payload.isValid;
          } else {
            editedTempHoursWithErrorsOnCell[action.payload.workdate][
              action.payload.rowIndex
            ].isLstarValid = action.payload.isValid;
          }

          return { ...state, tempHours: editedTempHoursWithErrorsOnCell };
        case INFO_TIMESTAMPS:
          let editedTempTiemstampsWithErrorsOnCell = cloneDeep(
            state.tempTimestamps
          );
          editedTempTiemstampsWithErrorsOnCell[action.payload.workdate][
            action.payload.rowIndex
          ].isKostlOrPosidValid = action.payload.isValid;

          return {
            ...state,
            tempTimestamps: editedTempTiemstampsWithErrorsOnCell,
          };
        default:
          break;
      }
      return state;
    case SET_LOADING_ON_SEARCH_CELL:
      switch (action.payload.parent) {
        case STUNDEN:
          let editedTimesWithLoadingOnCell = cloneDeep(state.times);

          if (action.payload.columnId === 'kostlOrPosid') {
            editedTimesWithLoadingOnCell[
              action.payload.rowIndex
            ].isFetchingKostlOrPosid = action.payload.isLoading;
          } else {
            editedTimesWithLoadingOnCell[
              action.payload.rowIndex
            ].isFetchingLgart = action.payload.isLoading;
          }

          return { ...state, times: editedTimesWithLoadingOnCell };
        case ABWESEN:
          let editedAbsencesWithLoadingOnCell = cloneDeep(state.absences);
          editedAbsencesWithLoadingOnCell[
            action.payload.rowIndex
          ].isFetchingAwart = action.payload.isLoading;

          return { ...state, absences: editedAbsencesWithLoadingOnCell };
        case INFO_HOURS:
          let editedTempHoursWithLoadingOnCell = cloneDeep(state.tempHours);

          if (action.payload.columnId === 'kostlOrPosid') {
            editedTempHoursWithLoadingOnCell[action.payload.workdate][
              action.payload.rowIndex
            ].isFetchingKostlOrPosid = action.payload.isLoading;
          } else {
            editedTempHoursWithLoadingOnCell[action.payload.workdate][
              action.payload.rowIndex
            ].isFetchingLstar = action.payload.isLoading;
          }

          return { ...state, tempHours: editedTempHoursWithLoadingOnCell };
        case INFO_TIMESTAMPS:
          let editedTempTiemstampsWithLoadingOnCell = cloneDeep(
            state.tempTimestamps
          );
          editedTempTiemstampsWithLoadingOnCell[action.payload.workdate][
            action.payload.rowIndex
          ].isFetchingKostlOrPosid = action.payload.isLoading;

          return {
            ...state,
            tempTimestamps: editedTempTiemstampsWithLoadingOnCell,
          };
        default:
          break;
      }
      return state;
    case SAVE_ABSENCES_START:
      return {
        ...state,
        loadingWhenSavingAbsences: true,
      };
    case SAVE_ABSENCES_SUCCESS:
      return {
        ...state,
        loadingWhenSavingAbsences: false,
        absences: action.payload.absences.map((absence) => ({
          isAwartValid: true,
          isFetchingAwart: false,
          ...absence,
        })),
        total: action.payload.total,
        errorSavingAbsences: null,
        savedAbsences: true,
      };
    case SAVE_ABSENCES_FAILURE:
      return {
        ...state,
        loadingWhenSavingAbsences: false,
        errorSavingAbsences: action.payload,
      };
    case SET_REMAINING_HOURS_IN_DAY:
      const timesWithRemainingHoursForDay = cloneDeep(state.times);
      const absencesWithRemainingHoursForDay = cloneDeep(state.absences);
      const totalWithRemaingHoursForDay = cloneDeep(state.total);
      let hoursWithRemainingOnesForDay = cloneDeep(state.hours);

      const isTotalForDayLessThanDefault =
        state.total[action.payload.column].quantity <
        state.regularHours[action.payload.column].quantity;

      if (isTotalForDayLessThanDefault) {
        if (
          action.payload.parent === ABWESEN &&
          absencesWithRemainingHoursForDay[action.payload.index].cells[
            action.payload.column
          ].quantity === 0
        ) {
          const remainingHoursQuantity =
            state.regularHours[action.payload.column].quantity -
            state.total[action.payload.column].quantity;

          absencesWithRemainingHoursForDay[action.payload.index].cells[
            action.payload.column
          ].quantity += remainingHoursQuantity;

          totalWithRemaingHoursForDay[action.payload.column].quantity =
            state.regularHours[action.payload.column].quantity;
        } else if (
          action.payload.parent === STUNDEN &&
          timesWithRemainingHoursForDay[action.payload.index].cells[
            action.payload.column
          ].quantity === 0
        ) {
          const remainingHoursQuantity =
            state.regularHours[action.payload.column].quantity -
            state.total[action.payload.column].quantity;

          timesWithRemainingHoursForDay[action.payload.index].cells[
            action.payload.column
          ].quantity += remainingHoursQuantity;

          const hourToAdd = {
            counter:
              timesWithRemainingHoursForDay[action.payload.index].cells[
                action.payload.column
              ].counter,
            kostlOrPosid:
              timesWithRemainingHoursForDay[action.payload.index].kostlOrPosid,
            kostlOrPosidText:
              timesWithRemainingHoursForDay[action.payload.index]
                .kostlOrPosidText,
            quantity: remainingHoursQuantity,
            shortText:
              timesWithRemainingHoursForDay[action.payload.index].cells[
                action.payload.column
              ].shortText,
            longText:
              timesWithRemainingHoursForDay[action.payload.index].cells[
                action.payload.column
              ].longText,
            isReadOnly: false,
            isFetchingKostlOrPosid: false,
            isKostlOrPosidValid: true,
            isFetchingLstar: false,
            isLstarValid: true,
            deleted: '',
          };

          hoursWithRemainingOnesForDay = {
            ...hoursWithRemainingOnesForDay,
            [action.payload.column]: hoursWithRemainingOnesForDay[
              action.payload.column
            ]
              ? [
                  ...hoursWithRemainingOnesForDay[action.payload.column],
                  hourToAdd,
                ]
              : [hourToAdd],
          };

          totalWithRemaingHoursForDay[action.payload.column].quantity =
            state.regularHours[action.payload.column].quantity;
        }
      }

      return {
        ...state,
        times: timesWithRemainingHoursForDay,
        absences: absencesWithRemainingHoursForDay,
        total: totalWithRemaingHoursForDay,
        hours: hoursWithRemainingOnesForDay,
        tempHours: { ...hoursWithRemainingOnesForDay },
      };

    case SET_REMAINING_HOURS_FOR_MONTH:
      const timesWithRemainingHoursForMonth = cloneDeep(state.times);
      const absencesWithRemainingHoursForMonth = cloneDeep(state.absences);
      const totalWithRemaingHoursForMonth = cloneDeep(state.total);
      let hoursWithRemainingOnesForMonth = cloneDeep(state.hours);

      for (const [cellDate, cell] of Object.entries(
        action.payload.parent === ABWESEN
          ? absencesWithRemainingHoursForMonth[action.payload.index].cells
          : timesWithRemainingHoursForMonth[action.payload.index].cells
      )) {
        const isTotalForDayLessThanDefault =
          state.total[cellDate].quantity <
          state.regularHours[cellDate].quantity;

        if (
          action.payload.parent === ABWESEN &&
          cell.quantity === 0 &&
          !state.absenceColumns[cellDate].isHoliday &&
          !state.absenceColumns[cellDate].isWeekend &&
          isTotalForDayLessThanDefault
        ) {
          absencesWithRemainingHoursForMonth[action.payload.index].cells[
            cellDate
          ].quantity +=
            state.regularHours[cellDate].quantity -
            totalWithRemaingHoursForMonth[cellDate].quantity;
          totalWithRemaingHoursForMonth[cellDate].quantity =
            state.regularHours[cellDate].quantity;
        } else if (
          action.payload.parent === STUNDEN &&
          cell.quantity === 0 &&
          !state.timeColumns[cellDate].isHoliday &&
          !state.timeColumns[cellDate].isWeekend &&
          isTotalForDayLessThanDefault
        ) {
          timesWithRemainingHoursForMonth[action.payload.index].cells[
            cellDate
          ].quantity +=
            state.regularHours[cellDate].quantity -
            totalWithRemaingHoursForMonth[cellDate].quantity;

          const hourToAdd = {
            counter:
              timesWithRemainingHoursForMonth[action.payload.index].cells[
                cellDate
              ].counter,
            kostlOrPosid:
              timesWithRemainingHoursForMonth[action.payload.index]
                .kostlOrPosid,
            kostlOrPosidText:
              timesWithRemainingHoursForMonth[action.payload.index]
                .kostlOrPosidText,
            quantity:
              state.regularHours[cellDate].quantity -
              totalWithRemaingHoursForMonth[cellDate].quantity,
            shortText:
              timesWithRemainingHoursForMonth[action.payload.index].cells[
                cellDate
              ].shortText,
            longText:
              timesWithRemainingHoursForMonth[action.payload.index].cells[
                cellDate
              ].longText,
            isReadOnly: false,
            isFetchingKostlOrPosid: false,
            isKostlOrPosidValid: true,
            isFetchingLstar: false,
            isLstarValid: true,
            deleted: '',
          };

          hoursWithRemainingOnesForMonth = {
            ...hoursWithRemainingOnesForMonth,
            [cellDate]: hoursWithRemainingOnesForMonth[cellDate]
              ? [...hoursWithRemainingOnesForMonth[cellDate], hourToAdd]
              : [hourToAdd],
          };

          totalWithRemaingHoursForMonth[cellDate].quantity = 9;
        }
      }

      return {
        ...state,
        times: timesWithRemainingHoursForMonth,
        absences: absencesWithRemainingHoursForMonth,
        total: totalWithRemaingHoursForMonth,
        hours: hoursWithRemainingOnesForMonth,
        tempHours: { ...hoursWithRemainingOnesForMonth },
      };
    case SET_TRAVEL_TIME: {
      const { rowIndex, cell, column } = action.payload;

      const times = cloneDeep(state.times);
      times[rowIndex].cells[column] = cell;

      return {
        ...state,
        times,
      };
    }
    default:
      return state;
  }
};

export default timeReducer;

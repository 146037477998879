import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';

import {
  fetchPersonProfiles,
  clearErrors,
} from '../../../../../redux/person-aus/personActions';

import PersonProfilesModalStyle from './PersonProfilesModalStyle';
import styles from './PersonProfilesModal.module.css';

import { PERSON_PROFILE } from '../../../modalTypes';

import PersonProfilesModalTable from './PersonProfilesModalTable';

const PersonProfilesModal = ({
  auth,
  currentModals,
  hideModal,
  fetchPersonProfiles,
  selectedProfile,
  profiles,
  personProfileError,
  clearErrors,
}) => {
  const [columns] = useState([
    {
      Header: 'Profil',
      accessor: 'profile',
      width: 60,
    },
    {
      Header: 'Text',
      accessor: 'description',
    },
  ]);

  useEffect(() => {
    if (personProfileError) {
      message.error(personProfileError);
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personProfileError]);

  const handleCancel = () => {
    hideModal(PERSON_PROFILE);
  };

  const handleOk = () => {
    hideModal(PERSON_PROFILE);
  };

  const onRowSelect = (row) => {
    hideModal(PERSON_PROFILE);
    selectedProfile({
      profile: row.values.profile,
      profileText: row.values.description,
    });
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(PERSON_PROFILE) ? true : false}
      title='Suchhilfe für Erfassungprofil'
      onOk={handleOk}
      onCancel={handleCancel}
      width={550}
      closable={true}
      footer={null}
    >
      <div className={styles.container}>
        <PersonProfilesModalStyle>
          <PersonProfilesModalTable
            columns={columnsMemo}
            data={profiles || []}
            onRowSelect={onRowSelect}
          />
        </PersonProfilesModalStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ auth }) => ({
  auth,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPersonProfiles: (auth) => dispatch(fetchPersonProfiles(auth)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonProfilesModal);

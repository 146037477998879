import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeader, Button, Descriptions, Dropdown, message, Tag } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as MoreIcon } from '../../../assets/more.svg';
import { ReactComponent as SaveIcon } from '../../../assets/save.svg';
// import { ReactComponent as UnlockIcon } from '../../../assets/unlock.svg';
import { ReactComponent as OptionsIcon } from '../../../assets/options.svg';
// import { ReactComponent as PeopleIcon } from '../../../assets/people.svg';
import {
  TIME_BALANCE,
  /*PERSONEN,
  RELEASE_TIMES, */
} from '../../modals/modalTypes';

import zeitHeaderMenu from './ZeitHeaderMenu';

import styles from './ZeitHeader.module.css';

import {
  clearErrors,
  fetchPrint,
  setHasFetchedPrint,
} from '../../../redux/print/printActions';
import { saveTimes, fetchTimes } from '../../../redux/time/timeActions';
import { showModal } from '../../../redux/modals/modalActions';
import { logout } from '../../../redux/auth/authActions';

import { Client } from '../../../constants/client';

const ZeitHeader = ({
  showModal,
  logout,
  user,
  loadingWhenSavingTimes,
  loadingWhenReleasingTimes,
  loading,
  saveTimes,
  timePost,
  currentMonth,
  doc,
  isFetchingDoc,
  hasFetchedDoc,
  fetchPrint,
  setHasFetchedPrint,
  errorFetchingDoc,
  fetchTimes,
  errorFetchingTimes,
  clearErrors,
  areTimesLocked,
}) => {
  const { pernr, ename, costCenter, costCenterText, headerAuth } = user;

  const logoutUser = () => {
    logout();
  };

  const fetchDocToPrint = () => {
    fetchPrint({ pernr, headerAuth }, currentMonth);
  };

  useEffect(() => {
    const key = 'printMessage';

    if (isFetchingDoc) {
      message.loading({ content: 'Wird geladen...', key, duration: 1 });
    }

    if (errorFetchingDoc) {
      setTimeout(
        () => message.error({ content: errorFetchingDoc, key, duration: 2 }),
        1000
      );
      clearErrors();
    }

    if (hasFetchedDoc) {
      setHasFetchedPrint(false);
      const docUrl = URL.createObjectURL(doc);

      const config = {
        key: 'openPdf',
        type: 'success',
        content: (
          <span>
            Stundenkarte wurde generiert
            <Button
              type="primary"
              size="small"
              onClick={() => {
                window.open(docUrl, '_blank');
                message.destroy('openPdf');
              }}
              style={{ marginLeft: '15px' }}
            >
              Öffnen
            </Button>
          </span>
        ),
        duration: 4,
      };

      message.open(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedDoc, isFetchingDoc, errorFetchingDoc]);

  // const releaseTimes = () => {
  //   saveTimes(user, timePost, currentMonth, 'X');
  // };

  const areValid = (timesOrAbsences, key) => {
    for (const timeOrAbsence of timesOrAbsences) {
      if (!timeOrAbsence[key] || timeOrAbsence[key] === '') {
        for (const cell of Object.values(timeOrAbsence.cells)) {
          if (
            (Number.isNaN(cell.quantity) && cell.quantity !== '') ||
            cell.quantity > 0
          ) {
            return false;
          }
        }
      }
    }

    return true;
  };

  const areAwartsLgartsValid = () => {
    let canSave = true;

    if (
      !areValid(timePost.times, 'lgart') ||
      !areValid(timePost.absences, 'awart')
    ) {
      canSave = false;
    }

    return canSave;
  };

  return (
    <PageHeader
      ghost={false}
      title="Zeiterfassung"
      subTitle="Erfassungssicht"
      tags={
        process.env.REACT_APP_CLIENT === Client.VANOLIDEV && [
          <Tag color="#FCE44F">Schulungssystem</Tag>,
        ]
      }
      extra={[
        // <Button
        //   key='5'
        //   icon={<Icon component={UnlockIcon} />}
        //   onClick={() => showModal(RELEASE_TIMES, { releaseTimes })}
        //   loading={loadingWhenReleasingTimes}
        //   disabled={errorFetchingTimes || loading}
        // >
        //   <span className={styles.zeitHeaderBtn}>Freigeben</span>
        // </Button>,
        <Button
          key="4"
          onClick={() => showModal(TIME_BALANCE)}
          icon={<Icon component={OptionsIcon} />}
        >
          <span className={styles.zeitHeaderBtn}>Zeitsaldo</span>
        </Button>,
        // <Button
        //   key='3'
        //   onClick={() => showModal(PERSONEN, { fetchTimes })}
        //   icon={<Icon component={PeopleIcon} />}
        // >
        //   <span className={styles.zeitHeaderBtn}>Person auswählen</span>
        // </Button>,
        <Button
          key="2"
          className={styles.saveButton}
          type="primary"
          icon={<Icon component={SaveIcon} />}
          loading={loadingWhenSavingTimes}
          onClick={() =>
            areAwartsLgartsValid()
              ? saveTimes(user, timePost, currentMonth)
              : message.warning(
                  'Bitte geben Sie eine Lohnart/Abwesenheitsart an'
                )
          }
          disabled={
            loading ||
            errorFetchingTimes ||
            timePost.periodClosed ||
            areTimesLocked ||
            timePost.onlyDisplay
          }
        >
          <span className={styles.zeitHeaderBtn}>Speichern</span>
        </Button>,
        <Dropdown
          key="1"
          overlay={zeitHeaderMenu(
            logoutUser,
            fetchDocToPrint,
            isFetchingDoc,
            (type, props) => showModal(type, props),
            errorFetchingTimes,
            loading,
            timePost.onlyDisplay
          )}
          trigger={['click']}
        >
          <Button
            style={{ fontSize: '14px' }}
            icon={<Icon component={MoreIcon} />}
          />
        </Dropdown>,
      ]}
    >
      <Descriptions size="small" column={3}>
        <Descriptions.Item label="Personalnummer" key="personalNummer">
          <span style={{ color: '#1890ff' }}>{Number(pernr) || ''}</span>
        </Descriptions.Item>
        <Descriptions.Item label="Kostenstelle" key="kostenstelle">
          <span style={{ color: '#1890ff' }}>{costCenter || ''}</span>
          <span style={{ margin: '0 5px' }}>-</span>
          {costCenterText || ''}
        </Descriptions.Item>
        <Descriptions.Item key="emptyDescription" />
        <Descriptions.Item label="Name" key="personalNummer">
          {ename || ''}
        </Descriptions.Item>
        <Descriptions.Item key="company" />
      </Descriptions>
    </PageHeader>
  );
};

const mapStateToProps = ({
  auth: { user },
  time: {
    loading,
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    loadingWhenSavingTimes,
    loadingWhenReleasingTimes,
    currentMonth,
    hours,
    errorFetchingTimes,
    timestamps,
    periodClosed,
    onlyDisplay,
  },
  lock: { areTimesLocked },
  print: { doc, hasFetchedDoc, isFetchingDoc, errorFetchingDoc },
}) => ({
  user,
  loading,
  timePost: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
    periodClosed,
    onlyDisplay,
  },
  loadingWhenSavingTimes,
  currentMonth,
  doc,
  hasFetchedDoc,
  isFetchingDoc,
  errorFetchingDoc,
  errorFetchingTimes,
  areTimesLocked,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type, props) => dispatch(showModal(type, props)),
  logout: () => dispatch(logout()),
  saveTimes: (auth, time, month, release) =>
    dispatch(saveTimes(auth, time, month, release)),
  fetchPrint: (auth, month) => dispatch(fetchPrint(auth, month)),
  setHasFetchedPrint: (fetched) => dispatch(setHasFetchedPrint(fetched)),
  fetchTimes: (auth, month) => dispatch(fetchTimes(auth, month)),
  clearErrors: () => dispatch(clearErrors()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ZeitHeader)
);

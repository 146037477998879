import React from 'react';

const RemoveLineIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#747474' fillRule='nonzero' transform='translate(-282 -180)'>
          <path d='M292.074 180c1.45 0 2.22.671 2.284 2.002l.005.186v1.143h1.348c1.457 0 2.221.671 2.285 1.996l.004.184v7.501c0 1.39-.7 2.123-2.094 2.184l-.195.004h-7.785c-1.457 0-2.22-.671-2.284-2.002l-.005-.186V191.8h-1.348c-1.467 0-2.224-.668-2.285-2.003l-.004-.178v-7.43c0-1.394.696-2.126 2.102-2.184l.187-.004h7.785zm3.623 4.467h-7.749c-.68 0-1.081.326-1.132.966l-.005.142v7.374c0 .685.356 1.063.996 1.11l.141.005h7.749c.673 0 1.074-.332 1.124-.967l.005-.141v-7.381c0-.734-.408-1.108-1.13-1.108zm-3.645-3.33h-7.749c-.68 0-1.075.331-1.124.972l-.005.142v7.304c0 .685.349 1.056.988 1.103l.141.005h1.334v-5.152c0-1.39.7-2.116 2.095-2.176l.194-.004h5.263v-1.08c0-.734-.415-1.115-1.137-1.115zm2.187 7.564c.342 0 .612.254.612.55 0 .304-.27.558-.612.558h-4.87c-.306 0-.583-.261-.583-.558 0-.282.277-.55.583-.55z'></path>
        </g>
      </g>
    </svg>
  );
};

export default RemoveLineIcon;

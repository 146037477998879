import React from 'react';
import { Tooltip, Button } from 'antd';
import PropTypes from 'prop-types';

const DescribedButton = ({
  style,
  icon,
  tooltip,
  placement,
  onClick,
  type,
  disabled = false,
}) => {
  return (
    <Tooltip title={tooltip} placement={placement}>
      <Button
        className={style + ' ' + type}
        disabled={disabled}
        icon={icon}
        onClick={onClick}
      />
    </Tooltip>
  );
};

DescribedButton.propTypes = {
  placement: PropTypes.string.isRequired,
};

DescribedButton.defaultProps = {
  placement: 'top',
};

export default DescribedButton;

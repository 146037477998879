import {
  FETCH_LEISART_SUCCESS,
  FETCH_LEISART_FAILURE,
  FETCH_LEISARTS_SUCCESS,
  FETCH_LEISARTS_FAILURE,
  FETCHED_LEISART,
  CLEAR_ERRORS,
} from './leisartTypes';

const initialState = {
  leisart: '',
  leisarts: [],
  error: null,
  hasFetchedLeisart: false,
  editedLeisartCell: null,
};

const leisartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEISART_SUCCESS:
      return {
        ...state,
        leisart: String(action.payload.leisart),
        hasFetchedLeisart: true,
        editedLeisartCell: action.payload.cellBeingEdited,
        error: null,
      };
    case FETCH_LEISART_FAILURE:
      return {
        ...state,
        leisart: String(action.payload.leisart),
        error: action.payload.error,
        hasFetchedLeisart: true,
        editedLeisartCell: action.payload.cellBeingEdited,
      };

    case FETCH_LEISARTS_SUCCESS:
      return {
        ...state,
        leisarts: action.payload,
        error: null,
      };
    case FETCH_LEISARTS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case FETCHED_LEISART:
      return {
        ...state,
        hasFetchedLeisart: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default leisartReducer;

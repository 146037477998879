export const SET_LOADING = 'SET_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH = 'SET_AUTH';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const SET_HAS_CHANGED_PASSWORD = 'SET_HAS_CHANGED_PASSWORD';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

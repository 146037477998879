import React, { Fragment, useState, useRef } from 'react';
import { connect } from 'react-redux';

import { Popover, Input, Button, Menu, Dropdown, message } from 'antd';

import SystemDetails from './SystemDetails';

import { saveSettings } from '../../redux/settings/settingsActions';

import styles from './SettingsPopover.module.css';

import {
  IoIosAddCircleOutline,
  IoIosCheckmarkCircle,
  IoIosRadioButtonOff,
} from 'react-icons/io';

const menu = (handleSystemEdit, handleSystemDelete, index, system) => (
  <Menu>
    <Menu.Item onClick={() => handleSystemEdit(system)}>Bearbeiten</Menu.Item>
    <Menu.Item onClick={() => handleSystemDelete(index, system)} danger>
      Löschen
    </Menu.Item>
  </Menu>
);

const SettingsContent = ({
  systems,
  selectedSystem,
  client,
  setIsSystemSettingsVisible,
  saveSettings,
}) => {
  const [isSystemDetailsVisible, setIsSystemDetailsVisible] = useState(false);

  const [tempSystems, setTempSystems] = useState(systems);
  const [tempSelectedSystem, setTempSelectedSystem] = useState(selectedSystem);
  const [tempClient, setTempClient] = useState(client);

  const [tempSystemBeingEdited, setTempSystemBeingEdited] = useState(null);

  let saveButtonRef = useRef(null);

  const handleSystemDetailsVisibility = (visible) => {
    setIsSystemDetailsVisible(visible);
  };

  const handleClientChange = (e) => {
    setTempClient(e.target.value);
  };

  const handleSaveSettings = () => {
    if (tempSelectedSystem === null) {
      message.warning('You need to select one system');
    } else if (tempClient === '' || tempClient === 0 || tempClient === '0') {
      message.warning('Client cannot be 0 or empty');
    } else {
      saveSettings(tempSystems, tempSelectedSystem, tempClient);
      setIsSystemSettingsVisible(false);
    }
  };

  const handleSystemDelete = (index, system) => {
    if (system.id === selectedSystem.id) {
      setTempSelectedSystem(null);
    }

    setTempSystems((old) =>
      old.filter((oldSystem, oldIndex) => index !== oldIndex)
    );
  };

  const handleSystemEdit = (system) => {
    setTempSystemBeingEdited(system);
    setIsSystemDetailsVisible(true);
  };

  const handleAddingSystem = () => {
    setTempSystemBeingEdited(null);
    setIsSystemDetailsVisible(true);
  };

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      handleSaveSettings();
    }
  };

  return (
    <Fragment>
      <div className={styles.settingsHeader}>
        <h3 className={styles.settingsHeaderTitle}>Wählen Sie Ihre Umgebung</h3>
        <Popover
          placement='left'
          destroyTooltipOnHide={true}
          content={
            <SystemDetails
              setIsSystemDetailsVisible={setIsSystemDetailsVisible}
              setTempSystems={setTempSystems}
              tempSelectedSystem={tempSelectedSystem}
              setTempSelectedSystem={setTempSelectedSystem}
              system={tempSystemBeingEdited}
              isNewSystem={!tempSystemBeingEdited}
            />
          }
          trigger='click'
          visible={isSystemDetailsVisible}
          overlayStyle={{ width: '300px' }}
          onVisibleChange={handleSystemDetailsVisibility}
        >
          <button
            type='submit'
            className={styles.settingsHeaderBtn}
            onClick={handleAddingSystem}
          >
            <IoIosAddCircleOutline
              size='20px'
              color='#1890FF'
              cursor='pointer'
            />
          </button>
        </Popover>
      </div>

      <ul className={styles.systemsList}>
        {tempSystems.map((system, index) => (
          <Dropdown
            overlay={menu(handleSystemEdit, handleSystemDelete, index, system)}
            trigger={['contextMenu']}
            key={system.id}
          >
            <li
              className={styles.systemsListItem}
              onClick={(e) => {
                e.stopPropagation();
                setTempSelectedSystem(system);
              }}
            >
              <span
                style={{
                  color:
                    tempSelectedSystem.id === system.id ? '#4091F7' : undefined,
                }}
              >
                {system.description}
              </span>
              {tempSelectedSystem.id === system.id ? (
                <IoIosCheckmarkCircle size='20px' color='#4091F7' />
              ) : (
                <IoIosRadioButtonOff size='20px' color='rgb(228, 228, 228' />
              )}
            </li>
          </Dropdown>
        ))}
      </ul>
      <form className={styles.form}>
        <div className={styles.formItem}>
          <label htmlFor='client-schreiben'>Client Schreiben:</label>
          <Input
            id='client-schreiben'
            value={tempClient}
            onChange={handleClientChange}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <div className={styles.formSubmit}>
          <Button
            ref={saveButtonRef}
            type='button'
            onClick={handleSaveSettings}
          >
            Speichern
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

const mapStateToProps = ({ settings }) => ({ ...settings });

const mapDispatchToProps = (dispatch) => ({
  saveSettings: (systems, selectedSystem, client) =>
    dispatch(saveSettings(systems, selectedSystem, client)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContent);

import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { PRCTR } from '../../modalTypes';
import ProfitCenterTable from './ProfitCenterTable';

import { SearchOutlined } from '@ant-design/icons';

import styles from './ProfitCenter.module.css';

import Styles from './ProfitCenterTableStyle';

import {
  setHasFetchedPrctr,
  fetchPrctr,
} from '../../../../redux/prctr/prctrActions';

const ProfitCenterModal = ({
  auth,
  currentModals,
  hideModal,
  cell,
  selectedPrctr,
  fetchPrctr,
  setHasFetchedPrctr,
  hasFetchedPrctr,
  prctrList,
  error,
  loading,
}) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    kokrs: '',
    ktext: '',
    prctr: '',
  });
  const [columns] = useState([
    {
      Header: 'KOKRS',
      accessor: 'kokrs',
      width: 40,
    },
    {
      Header: 'PRCTR',
      accessor: 'prctr',
      width: 40,
    },
    {
      Header: 'KTEXT',
      accessor: 'ktext',
      width: 60,
    },
  ]);

  useEffect(() => {
    if (hasFetchedPrctr) {
      setHasFetchedPrctr(true);
      setData(prctrList);
      if (error) {
        message.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPrctr]);

  useEffect(() => {
    setData([]);
    for (let i = 1; i <= 10; i++) {
      let row = {
        id: i,
        kokrs: '',
        ktext: '',
        prctr: '',
      };
      setData((data) => [...data, row]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchPrctr = () => {
    fetchPrctr(auth, searchData);
  };

  const onChange = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  const handleCancel = () => {
    hideModal(PRCTR);
  };

  const handleOk = () => {
    hideModal(PRCTR);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      searchPrctr();
    }
  };

  return (
    <Modal
      visible={currentModals.hasOwnProperty(PRCTR)}
      title='Suchhilfe für ProfitCenter'
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      style={{ top: '155px' }}
      destroyOnClose={true}
      closable={true}
      footer={null}
    >
      <Fragment>
        <div className={styles.prctrHeader}>
          <div className={styles.row1}>
            <Input
              placeholder='Profitcenter'
              className={styles.prctr}
              name='prctr'
              value={searchData.prctr}
              maxLength={8}
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
            <Input
              placeholder='Kostenrechnungskreis'
              className={styles.kokrs}
              name='kokrs'
              value={searchData.kokrs}
              maxLength={4}
              onKeyDown={onKeyDown}
              onChange={onChange}
              style={{ width: 200 }}
            />
          </div>
          <div className={styles.row2}>
            <Input
              placeholder='Bezeichnung'
              className={styles.ktext}
              name='ktext'
              onKeyDown={onKeyDown}
              value={searchData.ktext}
              maxLength={40}
              onChange={onChange}
            />
            <Button
              type='primary'
              className={styles.searchBtn}
              icon={<SearchOutlined />}
              loading={loading}
              onClick={searchPrctr}
            >
              Suchen
            </Button>
          </div>
        </div>
        <Styles>
          <ProfitCenterTable
            columns={columnsMemo}
            data={data}
            cell={cell}
            selectedPrctr={selectedPrctr}
          />
        </Styles>
      </Fragment>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  prctr: { loading, hasFetchedPrctr, prctrList, error },
}) => ({
  auth,
  loading,
  hasFetchedPrctr,
  prctrList,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPrctr: (auth, searchData) => dispatch(fetchPrctr(auth, searchData)),
  setHasFetchedPrctr: (fetched) => dispatch(setHasFetchedPrctr(fetched)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfitCenterModal);

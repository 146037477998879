import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { addZeroes } from '../../utils/addZeroes';
import { KOSTL_OR_POSID } from './EditableCellTypes';

import PropTypes from 'prop-types';
import { validateHoursCell } from '../../utils/validateHoursCell';
import TimeField from 'react-simple-timefield';
import { overlappingTimestamps } from '../../utils/overlappingTimestamps';
import { message } from 'antd';

import { removeRowFromTable, duplicateRow } from '../../redux/time/timeActions';

import {
  isValidActionButton,
  REMOVE_INFO_ABWESEN,
  REMOVE_INFO_STUNDEN,
  DUPLICATE_INFO_ABWESEN,
  DUPLICATE_INFO_STUNDEN,
} from '../../utils/isValidActionButton';

import moment from 'moment';

import { INFO_TIMESTAMPS, INFO_HOURS } from '../modals/modalParentTypes';

const EditableInfoCell = ({
  cell: { value: initialValue },
  row: { index, original },
  column: { id },
  updateMyData,
  alignRight,
  allowOnlyNumbers,
  numberFormat,
  rows,
  removeRowFromTable,
  setHasError,
  readOnly,
  parent,
  workdate,
  duplicateRow,
}) => {
  const [value, setValue] = useState(initialValue);
  const [style, setStyle] = useState(null);
  const [clickedBackspace, setClickedBackspace] = useState(false);
  const [timestampStyle, setTimestampStyle] = useState({ width: '100%' });
  const [maxLength, setMaxLength] = useState(Number.MAX_SAFE_INTEGER);
  const isTimestamp = numberFormat === '##:##:##';
  const editableCellRef = useRef(null);

  const handleButtonClick = (lostFocusTo) => {
    switch (lostFocusTo) {
      case REMOVE_INFO_ABWESEN:
        if (parent === INFO_TIMESTAMPS)
          if (!original.isReadOnly) {
            removeRowFromTable(
              index,
              parent,
              moment(workdate).format('YYYY-MM-DD')
            );
          }
        break;
      case REMOVE_INFO_STUNDEN:
        if (parent === INFO_HOURS)
          if (!original.isReadOnly) {
            removeRowFromTable(
              index,
              parent,
              moment(workdate).format('YYYY-MM-DD')
            );
          }

        break;
      case DUPLICATE_INFO_ABWESEN:
        if (parent === INFO_TIMESTAMPS)
          duplicateRow(index, parent, moment(workdate).format('YYYY-MM-DD'));
        break;
      case DUPLICATE_INFO_STUNDEN:
        if (parent === INFO_HOURS)
          duplicateRow(index, parent, moment(workdate).format('YYYY-MM-DD'));
        break;
      default:
        //do nothing
        break;
    }
  };

  const onChange = (e) => {
    setTimestampStyle({ width: '100%' });
    const onlyNumbersRegex = /^[0-9.\b]+$/;
    if (allowOnlyNumbers) {
      if (e.target.value === '' || onlyNumbersRegex.test(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }

    if (numberFormat === '##.##') {
      if (clickedBackspace) {
        setValue(e.target.value);
      } else if (e.target.value.length === 2 && !e.target.value.includes('.')) {
        setValue(e.target.value + '.');
      } else if (validateHoursCell(e.target.value)) {
        //string includes .
        setValue(e.target.value);
      } else {
        setValue(e.target.value);
      }
    } else if (isTimestamp) {
      setValue(e.target.value);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setClickedBackspace(true);
    } else {
      setClickedBackspace(false);
    }

    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const onBlur = (e) => {
    if (e.relatedTarget) {
      let lostFocusTo = isValidActionButton(e.relatedTarget.classList);
      if (lostFocusTo !== '') {
        handleButtonClick(lostFocusTo);
      }
    }

    const roundedNumber = allowOnlyNumbers ? addZeroes(value) : value;
    setValue(roundedNumber);
    let hasError = false;

    if (isTimestamp) {
      // eslint-disable-next-line
      rows.some((row) => {
        if (
          overlappingTimestamps(
            value,
            row.original.timeFrom,
            row.original.timeTo
          ) &&
          row.index !== index
        ) {
          hasError = true;
          message.error(
            'Intervalle dürfen sich nicht überschneiden. Wenn nötig abgrenzen'
          );
          setTimestampStyle({ color: 'red', width: '100%' });
          setHasError(true);
          editableCellRef.current.focus();
          return true;
        } else {
          hasError = false;
          // eslint-disable-next-line
          setHasError(false);
        }
      });
    }

    if (value !== initialValue && !hasError) {
      updateMyData(index, id, roundedNumber);
    }
  };

  useEffect(() => {
    setValue(allowOnlyNumbers ? addZeroes(initialValue) : initialValue);

    if (alignRight !== undefined && alignRight !== null) {
      setStyle({ textAlign: 'right' });
    }

    switch (id) {
      case KOSTL_OR_POSID:
        setMaxLength(24);
        break;
      case 'quantity':
        setMaxLength(5);
        break;
      default:
        setMaxLength(Number.MAX_SAFE_INTEGER);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <>
      {numberFormat === '##:##:##' ? (
        <TimeField
          value={value}
          onChange={onChange}
          showSeconds={true}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          inputRef={editableCellRef}
          style={timestampStyle}
          readOnly={readOnly}
        />
      ) : (
        <input
          ref={editableCellRef}
          value={value || ''}
          spellCheck='false'
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          style={style}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

EditableInfoCell.propTypes = {
  numberFormat: PropTypes.string.isRequired,
};

EditableInfoCell.defaultProps = {
  numberFormat: '',
};

const mapDispatchToProps = (dispatch) => ({
  removeRowFromTable: (index, parent, workdate) =>
    dispatch(removeRowFromTable(index, parent, workdate)),
  duplicateRow: (index, parent, workdate) =>
    dispatch(duplicateRow(index, parent, workdate)),
});

export default connect(null, mapDispatchToProps)(EditableInfoCell);

import React from 'react';
import { Menu } from 'antd';

import { ReactComponent as LogoutIcon } from '../../../assets/logout.svg';
import { ReactComponent as PrintIcon } from '../../../assets/print.svg';
import { ReactComponent as SaveLayoutIcon } from '../../../assets/saveLayout.svg';
import { ReactComponent as DeleteLayoutIcon } from '../../../assets/deleteLayout.svg';
import { ReactComponent as CopyLayout } from '../../../assets/copyLayout.svg';

// import { IoIosTimer } from 'react-icons/io';
import Icon from '@ant-design/icons';

import styles from './ZeitHeaderMenu.module.css';

import {
  SAVE_LAYOUT,
  DELETE_LAYOUT,
  COPY_LAYOUT,
} from '../../modals/modalTypes';

const zeitHeaderMenu = (
  logout,
  fetchDocToPrint,
  isFetchingDoc,
  showModal,
  errorFetchingTimes,
  loading,
  onlyDisplay
) => {
  return (
    <Menu className={styles.menu}>
      <Menu.Item
        icon={<Icon component={SaveLayoutIcon} className={styles.icons} />}
        onClick={() => showModal(SAVE_LAYOUT)}
        disabled={errorFetchingTimes || loading || onlyDisplay}
      >
        <span style={{ fontSize: '13px' }}>Aktuelle Vorlage speichern</span>
      </Menu.Item>
      <Menu.Item
        icon={<Icon component={CopyLayout} className={styles.icons} />}
        onClick={() => showModal(COPY_LAYOUT)}
        disabled={errorFetchingTimes || loading || onlyDisplay}
      >
        <span style={{ fontSize: '13px' }}>Aktuelle Vorlage kopieren</span>
      </Menu.Item>
      <Menu.Item
        icon={<Icon component={DeleteLayoutIcon} className={styles.icons} />}
        onClick={() => showModal(DELETE_LAYOUT)}
        disabled={errorFetchingTimes || loading || onlyDisplay}
      >
        <span style={{ fontSize: '13px' }}>Aktuelle Vorlage zurücksetzen</span>
      </Menu.Item>
      <Menu.Divider className={styles.menuDivider} />
      {/* <Menu.Item
        icon={
          <Icon
            component={IoIosTimer}
            style={{ color: '#1890FF', fontSize: '15px' }}
            className={styles.icons}
          />
        }
        disabled={loading}
      >
        <span style={{ fontSize: '13px' }}>Vorgabestunden</span>
      </Menu.Item>
      <Menu.Divider className={styles.menuDivider} /> */}
      <Menu.Item
        icon={<Icon component={PrintIcon} className={styles.icons} />}
        onClick={() => fetchDocToPrint()}
        disabled={errorFetchingTimes || loading}
      >
        <span style={{ fontSize: '13px' }}>Stundenkarte</span>
      </Menu.Item>
      <Menu.Divider className={styles.menuDivider} />
      <Menu.Item
        icon={<Icon component={LogoutIcon} className={styles.icons} />}
        onClick={() => logout()}
      >
        <span style={{ fontSize: '13px' }}>Abmelden</span>
      </Menu.Item>
    </Menu>
  );
};

export default zeitHeaderMenu;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Modal, Button, Input, Row, Col, message } from 'antd';

import { COPY_LAYOUT } from '../../modalTypes';

import {
  copyVorlage,
  toggleEditedVorlage,
} from '../../../../redux/vorlage/vorlageActions';

const CopyLayoutModal = ({
  currentModals,
  hideModal,
  copyVorlage,
  user,
  timePost,
  loading,
  copiedLayout,
  error,
  toggleEditedVorlage,
}) => {
  const [pernr, setPernr] = useState('');

  useEffect(() => {
    if (copiedLayout) {
      toggleEditedVorlage();
      hideModal(COPY_LAYOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedLayout]);

  useEffect(() => {
    if (error) {
      toggleEditedVorlage();
      message.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onClose = () => {
    hideModal(COPY_LAYOUT);
  };

  const onChange = (e) => {
    setPernr(e.target.value);
  };

  const onDone = () => {
    if (pernr === '') {
      message.warn('Please fill the PERNR Source you want to copy from!');
    } else {
      copyVorlage(user, pernr, timePost);
    }
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: '0' }}>
              Zeiterfassung: Erfassungssicht
            </h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(COPY_LAYOUT) ? true : false}
      onCancel={() => onClose(true)}
      width={400}
      footer={[
        <Button key='back' onClick={() => onClose(false)}>
          Abbrechen
        </Button>,
        <Button key='submit' type='primary' loading={loading} onClick={onDone}>
          Fertig
        </Button>,
      ]}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: 1, fontWeight: 500 }}>
          Kopieren von Personalnummer
        </div>
        <Input
          value={pernr}
          placeholder='PERNR'
          onChange={onChange}
          style={{ width: '30%' }}
          required={true}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth: { user },
  vorlage: { loading, error, copiedLayout },
  time: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
    total,
  },
}) => ({
  user,
  timePost: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
    total,
  },
  loading,
  error,
  copiedLayout,
});

const mapDispatchToProps = (dispatch) => ({
  copyVorlage: (auth, pernr_source, time) =>
    dispatch(copyVorlage(auth, pernr_source, time)),
  toggleEditedVorlage: () => dispatch(toggleEditedVorlage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CopyLayoutModal);

import React from 'react';
import { Menu } from 'antd';

import styles from './ContextMenu.module.css';

const Item = Menu.Item;

const ExportMenu = (exportData) => {
  const handleClick = (info) => {
    if (info.key) {
      exportData(info.key, true);
    }
  };

  return (
    <Menu className={styles.menu} selectable={false} onClick={handleClick}>
      <Item key='xlsx' className={styles.menuItem}>
        Export as Excel file
      </Item>
      <Item key='csv' className={styles.menuItem}>
        Export as CSV file
      </Item>
    </Menu>
  );
};

export default ExportMenu;

export const isValidActionButton = (classList) => {
  const allowedValues = [
    REMOVE_STUNDEN,
    DUPLICATE_STUNDEN,
    REMOVE_ABWESEN,
    DUPLICATE_ABWESEN,
    REMOVE_INFO_STUNDEN,
    REMOVE_INFO_ABWESEN,
    DUPLICATE_INFO_STUNDEN,
    DUPLICATE_INFO_ABWESEN,
  ];
  if (classList) {
    for (var i = 0; i < allowedValues.length; i++) {
      if (classList.contains(allowedValues[i])) {
        return allowedValues[i];
      }
    }
  }

  return '';
};

export const REMOVE_STUNDEN = 'remove-stunden';
export const DUPLICATE_STUNDEN = 'duplicate-stunden';
export const REMOVE_ABWESEN = 'remove-abwesen';
export const DUPLICATE_ABWESEN = 'duplicate-abwesen';
export const REMOVE_INFO_STUNDEN = 'remove-info-stunden';
export const REMOVE_INFO_ABWESEN = 'remove-info-abwesen';
export const DUPLICATE_INFO_STUNDEN = 'duplicate-info-stunden';
export const DUPLICATE_INFO_ABWESEN = 'duplicate-info-abwesen';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, message, Tag } from 'antd';
import sitfLogo from '../../../assets/sitfLogo.svg';
import vanoliLogo from '../../../assets/vanoliLogo.svg';
import { ReactComponent as PersonIcon } from '../../../assets/person.svg';
import { ReactComponent as LockIcon } from '../../../assets/lock.svg';
import settingsIcon from '../../../assets/settings.svg';
import SettingsPopover from '../../settings/SettingsPopover';
import {
  checkPasswordState,
  login,
  loginFailure,
} from '../../../redux/auth/authActions';

import { Client } from '../../../constants/client';

import { author, version } from '../../../../package.json';

import { showModal } from '../../../redux/modals/modalActions';

import Icon from '@ant-design/icons';

import styles from './Login.module.css';

const Login = ({
  history,
  match,
  isAuthenticated,
  loading,
  error,
  loginFailure,
  checkPasswordState,
}) => {
  const [form] = Form.useForm();
  const [year, setYear] = useState(0);

  useEffect(() => {
    getYear();

    if (isAuthenticated) {
      history.push(`${match.url}zeiterfassung`);
    }

    if (error) {
      showError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, history, match, error]);

  const onFinish = (values) => {
    const { username, password } = values;

    if (username === '' || password === '') {
      message.warning('Bitte füllen Sie alle Felder aus');
    } else {
      checkPasswordState(values);
    }
  };

  const getYear = () => {
    let date = new Date();
    setYear(date.getFullYear());
  };

  const showError = (errorMessage) => {
    message.error(errorMessage, 2).then(() => loginFailure(null));
  };

  const handleInput = (e) => {
    let ss = e.target.selectionStart;
    let se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  return isAuthenticated ? null : (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerTop}>
          <h1 className={styles.headerTitle}>
            WEB-Zeiterfassung
            {process.env.REACT_APP_CLIENT === Client.VANOLIDEV && (
              <Tag style={{ marginLeft: '15px' }} color='#FCE44F'>
                Schulungssystem
              </Tag>
            )}
          </h1>
          <SettingsPopover>
            <button className={styles.btnSettings}>
              <img src={settingsIcon} alt='settings' />
            </button>
          </SettingsPopover>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.formContainer}>
          <Form
            form={form}
            layout='vertical'
            name='normal_login'
            className={styles.loginForm}
            initialValues={{
              remember: true,
            }}
            size='large'
            onFinish={onFinish}
          >
            <Form.Item name='username' className={styles.loginFormItem}>
              <Input
                prefix={
                  <Icon style={{ color: 'gray' }} component={PersonIcon} />
                }
                placeholder='Username'
                onInput={(e) => handleInput(e)}
              />
            </Form.Item>
            <Form.Item name='password' className={styles.loginFormItem}>
              <Input
                prefix={<Icon component={LockIcon} />}
                type='password'
                placeholder='Password'
              />
            </Form.Item>
            <Form.Item className={styles.loginFormItem} shouldUpdate>
              {() => (
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                  style={{ width: '100%', borderRadius: '10px' }}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length
                  }
                  loading={loading}
                >
                  Login
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>

        <div className={styles.dividerContainer}>
          <div className={styles.divider} />
        </div>
        <img
          src={
            process.env.REACT_APP_CLIENT === Client.SITF ? sitfLogo : vanoliLogo
          }
          alt='SITF Logo'
          className={styles.sitfLogo}
        />
      </div>
      <div className={styles.footer}>
        <p className={styles.version}>
          <strong>Version {version}</strong>
        </p>
        <p className={styles.companyName}>
          ©{year} {author}
        </p>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth: { isAuthenticated, loading, error } }) => ({
  isAuthenticated,
  loading,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (formData) => dispatch(login(formData)),
  loginFailure: (error) => dispatch(loginFailure(error)),
  checkPasswordState: (formData) => dispatch(checkPasswordState(formData)),
  showModal: (type, props) => dispatch(showModal(type, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

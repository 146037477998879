export const validateHoursCell = (value, numbersAllowedBeforeDot) => {
  const onlyNumbersRegex = /^[0-9.\b]+$/;
  if (value.includes('.')) {
    return (
      onlyNumbersRegex.test(value) &&
      value.split('.')[0].length <= numbersAllowedBeforeDot &&
      value.split('.')[1].length <= 2 &&
      value.replace(/[^.]/g, '').length === 1
    );
  } else {
    return (
      value.length <= numbersAllowedBeforeDot && onlyNumbersRegex.test(value)
    );
  }
};

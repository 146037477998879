import API from '../../api/api';

import { STUNDEN } from '../../components/modals/modalParentTypes';
import {
  FETCH_PSP_ELEMENTS_START,
  FETCH_PSP_ELEMENTS_SUCCESS,
  FETCH_PSP_ELEMENTS_FAILURE,
  FETCH_KOST_START,
  FETCH_KOST_SUCCESS,
  FETCH_KOST_FAILURE,
  FETCHED_KOST,
  FETCHED_PSP_ELEMENTS,
  FETCH_PSP_KOST_START,
  FETCH_PSP_KOST_FAILURE,
  FETCH_PSP_KOST_SUCCESS,
  FETCHED_PSP_KOST,
} from './pspKostTypes';

export const fetchPspElementsStart = () => {
  return {
    type: FETCH_PSP_ELEMENTS_START,
  };
};

export const fetchPspElementsSuccess = (psp) => {
  return {
    type: FETCH_PSP_ELEMENTS_SUCCESS,
    payload: psp,
  };
};

export const fetchPspElementsFailure = (error) => {
  return {
    type: FETCH_PSP_ELEMENTS_FAILURE,
    payload: error,
  };
};

export const setHasFetchedPspElements = (fetched) => {
  return {
    type: FETCHED_PSP_ELEMENTS,
    payload: fetched,
  };
};

export const fetchKostStart = () => {
  return {
    type: FETCH_KOST_START,
  };
};

export const fetchKostSuccess = (kost) => {
  return {
    type: FETCH_KOST_SUCCESS,
    payload: { kost },
  };
};

export const fetchKostFailure = (error, cellBeingEdited) => {
  return {
    type: FETCH_KOST_FAILURE,
    payload: error,
  };
};

export const setHasFetchedKost = (fetched) => {
  return {
    type: FETCHED_KOST,
    payload: fetched,
  };
};

export const fetchPspKostStart = () => {
  return {
    type: FETCH_PSP_KOST_START,
  };
};

export const fetchPspKostFailure = (
  error,
  costCenterOrWbs,
  cellBeingEdited,
  parent,
  workdate
) => {
  return {
    type: FETCH_PSP_KOST_FAILURE,
    payload: { error, costCenterOrWbs, cellBeingEdited, parent, workdate },
  };
};

export const fetchPspKostSuccess = (
  pspKost,
  cellBeingEdited,
  parent,
  workdate,
  oldValue
) => {
  return {
    type: FETCH_PSP_KOST_SUCCESS,
    payload: {
      pspKost,
      cellBeingEdited,
      parent,
      workdate,
      oldValue,
    },
  };
};

export const setHasFetchedPspKost = (fetched) => {
  return {
    type: FETCHED_PSP_KOST,
    payload: fetched,
  };
};

export const fetchPspElements = (auth, searchData) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchPspElementsStart());

    try {
      const res = await API.get('/SIF/time/wbs_elements', config);
      dispatch(fetchPspElementsSuccess(res.data));
      dispatch(setHasFetchedPspElements(true));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchPspElementsFailure(msg));
      } else {
        dispatch(
          fetchPspElementsFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

export const fetchKost = (auth, searchData) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchKostStart());

    try {
      const res = await API.get('/SIF/time/cost_centers', config);
      dispatch(fetchKostSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchKostFailure(msg));
      } else {
        dispatch(fetchKostFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const fetchPspKost = (
  auth,
  month,
  searchData,
  cellBeingEdited,
  parent = STUNDEN,
  workdate,
  oldValue
) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    if (searchData.cost_center_or_wbs === '') {
      dispatch(
        fetchPspKostSuccess(
          { costCenterOrWbs: '', text: '' },
          cellBeingEdited,
          parent,
          workdate,
          oldValue
        )
      );
    } else {
      dispatch(fetchPspKostStart());

      try {
        const res = await API.get('/SIF/time/cost_center_or_wbs', config);
        dispatch(
          fetchPspKostSuccess(
            res.data,
            cellBeingEdited,
            parent,
            workdate,
            oldValue
          )
        );
      } catch (error) {
        if (error.response) {
          let msg =
            error.response.status === 404
              ? 'Nicht gefunden'
              : 'Ein unbekannter Fehler ist aufgetreten';
          dispatch(
            fetchPspKostFailure(
              msg,
              searchData.cost_center_or_wbs,
              cellBeingEdited,
              parent,
              workdate,
              oldValue
            )
          );
        } else {
          dispatch(
            fetchPspKostFailure(
              'Ein unbekannter Fehler ist aufgetreten',
              searchData.cost_center_or_wbs,
              cellBeingEdited,
              parent,
              workdate,
              oldValue
            )
          );
        }
      }
    }
  };
};

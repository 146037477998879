import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './auth/authReducer';
import modalReducer from './modals/modalReducer';
import timeReducer from './time/timeReducer';
import pspKostReducer from './psp-kost/pspKostReducer';
import wageTypeReducer from './wage-type/wageTypeReducer';
import absenceTypeReducer from './absence-type/absenceTypeReducer';
import printReducer from './print/printReducer';
import timeBalanceReducer from './time-balance/timeBalanceReducer';
import vorlageReducer from './vorlage/vorlageReducer';
import prctrReducer from './prctr/prctrReducer';
import bukrsReducer from './bukrs/bukrsReducer';
import kokrsReducer from './kokrs/kokrsReducer';
import personReducer from './person-aus/personReducer';
import settingsReducer from './settings/settingsReducer';
import lockReducer from './lock/lockReducer';
import leisartReducer from './leisart/leisartReducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['settings'],
};

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: [
    'loading',
    'error',
    'isChangingPassword',
    'changedPassword',
    'errorChangingPassword',
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  modal: modalReducer,
  time: timeReducer,
  pspKost: pspKostReducer,
  wageType: wageTypeReducer,
  absenceType: absenceTypeReducer,
  print: printReducer,
  timeBalance: timeBalanceReducer,
  vorlage: vorlageReducer,
  prctr: prctrReducer,
  bukrs: bukrsReducer,
  kokrs: kokrsReducer,
  personProfiles: personReducer,
  settings: settingsReducer,
  lock: lockReducer,
  leisart: leisartReducer,
});

export default persistReducer(rootPersistConfig, rootReducer);

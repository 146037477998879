import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import ZeitHeader from '../../zeit/header/ZeitHeader';
import ZeitContainer from './ZeitContainer';
import { Checkbox, DatePicker, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import 'moment/locale/de-ch';
import locale from 'antd/lib/date-picker/locale/de_DE';

import {
  fetchTimes,
  toggleFrozenColumns,
  setCurrentMonth,
  setHasFetchedTimes,
} from '../../../redux/time/timeActions';

import { lockTimes, unlockTimes } from '../../../redux/lock/lockActions';

// import { useInterval } from '../../../hooks/useInterval';

import Spinner from '../../spinner/Spinner';

import styles from './Zeit.module.css';

const Zeit = ({
  auth,
  showModal,
  fetchTimes,
  loading,
  toggleFrozenColumns,
  frozenColumns,
  loadingWhenSavingTimes,
  setCurrentMonth,
  currentMonth,
  errorFetchingTimes,
  fetchedTimes,
  setHasFetchedTimes,
  periodClosed,
  lockTimes,
  unlockTimes,
  isLockingOrUnlockingTimes,
  areTimesLocked,
  lockedMonth,
}) => {
  const [zeitState] = useState({
    frozenColumns: true,
  });

  useEffect(() => {
    if (currentMonth) {
      fetchTimes(auth, currentMonth);
    }

    // if (lockedMonth && lockedMonth !== currentMonth) {
    //   unlockTimes(lockedMonth);
    // }

    // return () => {
    //   if (lockedMonth) {
    //     unlockTimes(lockedMonth);
    //   }
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  // useInterval(() => {
  //   lockTimes(currentMonth);
  // }, 55000);

  useEffect(() => {
    if (fetchedTimes) {
      // lockTimes(currentMonth);
    }

    if (fetchedTimes && periodClosed) {
      message.warning(
        'Die Periode wurde bereits geschlossen. Kein Erfassen mehr möglich',
        5
      );
      setHasFetchedTimes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedTimes, periodClosed]);

  const monthDidChange = (date, dateString) => {
    setCurrentMonth(moment(date._d));
  };

  const setToLastMonth = () => {
    let currentMoment = currentMonth;
    currentMoment.subtract(1, 'M');

    setCurrentMonth(moment(currentMoment._d));
  };

  const setToUpcomingMonth = () => {
    let currentMoment = currentMonth;
    currentMoment.add(1, 'M');

    setCurrentMonth(moment(currentMoment._d));
  };

  return (
    <Fragment>
      <ZeitHeader zeitState={zeitState} />
      <div className={styles.zeitOptionsContainer}>
        <Checkbox
          checked={frozenColumns}
          onChange={() => toggleFrozenColumns()}
          className={styles.frozenColumnsCheckbox}
          disabled={loading || loadingWhenSavingTimes}
        >
          Spalten fixieren
        </Checkbox>
        <button
          className={styles.zeitMonthButtons}
          disabled={loading || loadingWhenSavingTimes}
        >
          <LeftOutlined onClick={setToLastMonth} />
        </button>
        <DatePicker
          onChange={monthDidChange}
          className={styles.zeitMonthPicker}
          allowClear={false}
          picker="month"
          locale={locale}
          bordered={false}
          inputReadOnly={true}
          value={currentMonth}
          format={'MMMM'}
          suffixIcon
          disabled={loading || loadingWhenSavingTimes}
        />
        <button
          className={styles.zeitMonthButtons}
          disabled={loading || loadingWhenSavingTimes}
        >
          <RightOutlined onClick={setToUpcomingMonth} />
        </button>
      </div>
      {loading ? <Spinner /> : <ZeitContainer showModal={showModal} />}
    </Fragment>
  );
};

const mapStateToProps = ({
  auth,
  time: {
    loading,
    frozenColumns,
    loadingWhenSavingTimes,
    currentMonth,
    errorFetchingTimes,
    fetchedTimes,
    periodClosed,
  },
  lock: { areTimesLocked, isLockingOrUnlockingTimes },
}) => ({
  auth,
  loading,
  frozenColumns,
  loadingWhenSavingTimes,
  currentMonth,
  errorFetchingTimes,
  fetchedTimes,
  periodClosed,
  areTimesLocked,
  isLockingOrUnlockingTimes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTimes: (auth, parameters) => dispatch(fetchTimes(auth, parameters)),
  toggleFrozenColumns: () => dispatch(toggleFrozenColumns()),
  setCurrentMonth: (month) => dispatch(setCurrentMonth(month)),
  setHasFetchedTimes: (hasFetched) => dispatch(setHasFetchedTimes(hasFetched)),
  lockTimes: (currentMonth) => dispatch(lockTimes(currentMonth)),
  unlockTimes: (lastMonth) => dispatch(unlockTimes(lastMonth)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Zeit);

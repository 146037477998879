import React, { useEffect } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';

import Spinner from '../../spinner/Spinner';

import { clearErrors } from '../../../redux/print/printActions';

const Print = ({ errorFetchingDoc, clearErrors }) => {
  useEffect(() => {
    if (errorFetchingDoc) {
      message.error(errorFetchingDoc);
      clearErrors();
    } else {
      message.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFetchingDoc]);

  return errorFetchingDoc ? null : <Spinner />;
};

const mapStateToProps = ({ print: { errorFetchingDoc } }) => ({
  errorFetchingDoc,
});

const mapDispatchToProps = (dispatch) => ({
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Print);

import moment from 'moment';
import { timeConvert } from './timeConvert';

export const getTimeDuration = (values) => {
  const { timeFrom, timeTo } = values;

  const difference = moment(timeTo, 'h:mm:ss').diff(
    moment(timeFrom, 'h:mm:ss')
  );
  const duration = moment.duration(difference).asMinutes();

  let { hours, minutes } = timeConvert(duration);

  if (hours < 0) {
    hours = 24 + hours;
  }

  return `${pad(hours)}.${pad(minutes)}`;
};

function pad(n) {
  return n < 10 ? '0' + n : n;
}

import React, { useState, Fragment, useEffect } from 'react';
import { Modal, Button, Row, Col, DatePicker, message } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import moment from 'moment';
import equal from 'fast-deep-equal';

import InfoAbwesen from './info-abwesen/InfoAbwesen';
import InfoStunden from './info-stunden/InfoStunden';

import { INFO, LANGTEXT, SAVE_CHANGES } from '../../modalTypes';

import styles from './InfoModal.module.css';

const InfoModal = ({
  currentModals,
  hideModal,
  showModal,
  cell,
  user,
  loadingWhenSavingTimes,
  savedTimes,
  timesPost,
  saveTimes,
  setTimestampHoursFromTemp,
  filterTimes,
  resetTempTimestampHours,
  hasFetchedPspKost,
  hasFetchedPspKostOnTimestamps,
  hasFetchedPspKostOnHours,
  isFetchingPspKost,
  error,
  hasUpdatedHours,
  setHasUpdatedHourOrTimestamp,
  areTimesLocked,
  ...props
}) => {
  const { timestamps, tempTimestamps, hours, tempHours } = props;

  const [canSetDayToYesterday, setCanSetDayToYesterday] = useState(true);
  const [canSetDayToTomorrow, setCanSetDayToTomorrow] = useState(true);

  const [loading, setLoading] = useState(loadingWhenSavingTimes);
  const [shouldWait, setShouldWait] = useState(false);

  const [currentDate, setCurrentDate] = useState(
    cell ? moment(cell.column.id) : moment()
  );

  const [isInfoReadOnly, setIsInfoReadOnly] = useState(
    () =>
      timesPost.timeColumns[moment(cell.column.id).format('YYYY-MM-DD')]
        .isReadonly ||
      timesPost.periodClosed ||
      areTimesLocked
  );

  useEffect(() => {
    setLoading(false);
    setShouldWait(false);
  }, []);

  useEffect(() => {
    setIsInfoReadOnly(
      timesPost.timeColumns[moment(currentDate._d).format('YYYY-MM-DD')]
        .isReadonly ||
        timesPost.periodClosed ||
        areTimesLocked
    );
    shouldDisableChangingCurrentDate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  useEffect(() => {
    if (loading) {
      if (hasUpdatedHours) {
        if (!error) {
          shouldSaveChanges(true);
        } else {
          if (error) {
            setLoading(false);
            message.warning(error);
          }
        }
      }

      if (isFetchingPspKost) {
        setShouldWait(true);
      } else if (!shouldWait && !error) {
        shouldSaveChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isFetchingPspKost, hasUpdatedHours]);

  const [hasError, setHasError] = useState(false);
  const { pernr, ename } = user;

  const shouldSaveChanges = (shouldSave) => {
    hideModal(INFO);
    setHasUpdatedHourOrTimestamp();
    if (shouldSave) {
      let mergedHours = {};

      if (tempHours[cell.column.id] && tempHours[cell.column.id].length > 0) {
        for (const hour of tempHours[cell.column.id]) {
          if (mergedHours.hasOwnProperty(hour.kostlOrPosid)) {
            mergedHours[hour.kostlOrPosid].quantity += parseFloat(
              hour.quantity
            );
            mergedHours[hour.kostlOrPosid].count += 1;

            if (hour.isReadOnly) {
              mergedHours[hour.kostlOrPosid].isReadOnly = false;
            }
          } else {
            mergedHours = {
              ...mergedHours,
              [hour.kostlOrPosid]: {
                quantity: parseFloat(hour.quantity),
                kostlOrPosidText: hour.kostlOrPosidText,
                count: 1,
                counter: hour.counter,
                longText: hour.longText,
                shortText: hour.shortText,
                isReadOnly: hour.isReadOnly,
              },
            };
          }
        }

        filterTimes(cell.column.id, mergedHours);
        setTimestampHoursFromTemp(tempTimestamps, tempHours);
      }
    } else {
      resetTempTimestampHours();
    }
  };

  const onClose = () => {
    if (!equal(timestamps, tempTimestamps) || !equal(hours, tempHours)) {
      showModal(SAVE_CHANGES, {
        shouldSaveChanges,
        question: 'Möchten Sie Änderungen speichern?',
        title: 'Änderungen erkannt',
      });
    } else {
      hideModal(INFO);
    }
  };

  const setDayToYesterday = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'days'));
  };

  const setDayToTomorrow = () => {
    setCurrentDate(moment(currentDate).add(1, 'days'));
  };

  const onChange = (date, dateString) => {
    setCurrentDate(date);
  };

  const disabledDate = (current) => {
    // Can not select days before this month and after
    return (
      moment(currentDate).endOf('month') < current ||
      moment(currentDate).startOf('month') > current
    );
  };

  const showLangTextModal = (cell, parent) => {
    showModal(LANGTEXT, {
      cell,
      parent,
      currentInfoDate: moment(currentDate._d).format('YYYY-MM-DD'),
      isInfoReadOnly,
    });
  };

  const shouldDisableChangingCurrentDate = () => {
    const current = moment(currentDate._d);
    const yesterday = moment(currentDate._d)
      .subtract(1, 'days')
      .format('yyyy-MM-DD');
    const tomorrow = moment(currentDate._d).add(1, 'days').format('yyyy-MM-DD');

    if (!current.isSame(yesterday, 'month')) {
      setCanSetDayToYesterday(false);
    } else {
      setCanSetDayToYesterday(true);
    }

    if (!current.isSame(tomorrow, 'month')) {
      setCanSetDayToTomorrow(false);
    } else {
      setCanSetDayToTomorrow(true);
    }
  };

  return (
    <Fragment>
      <Modal
        title={
          <Row align="middle">
            <Col flex="auto">
              <h3 style={{ marginBottom: '0' }}>
                Zeiterfassung: Erfassungssicht
              </h3>
            </Col>
          </Row>
        }
        closable={false}
        visible={currentModals.hasOwnProperty(INFO) ? true : false}
        onCancel={() => onClose()}
        width={1300}
        footer={[
          <Button key="back" onClick={() => onClose()}>
            Abbrechen
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading && !hasUpdatedHours}
            disabled={hasError || isInfoReadOnly || timesPost.onlyDisplay}
            onClick={() => {
              setLoading(true);
            }}
          >
            Fertig
          </Button>,
        ]}
      >
        <div className={styles.header}>
          <div className={styles.infoUser}>
            <span className={styles.infoUserLabel}>Personalnummer:</span>
            <span className={styles.infoUserId}>{Number(pernr) || ''}</span>
            <span className={styles.infoUserSeparator}>-</span>
            <span className={styles.infoUsername}>{ename || ''}</span>
          </div>
          <div className={styles.infoDate}>
            <button
              className={styles.dateButtonControls}
              onClick={setDayToYesterday}
              disabled={!canSetDayToYesterday}
            >
              <LeftOutlined />
            </button>
            <DatePicker
              className={styles.datePicker}
              disabledDate={disabledDate}
              allowClear={false}
              bordered={false}
              value={currentDate}
              format={'DD.MM.YYYY'}
              onChange={onChange}
              suffixIcon
            />
            <button
              className={styles.dateButtonControls}
              onClick={setDayToTomorrow}
              disabled={!canSetDayToTomorrow}
            >
              <RightOutlined />
            </button>
          </div>
        </div>
        <div className={styles.tablesContainer}>
          <div className={styles.infoStundenContainer}>
            <InfoStunden
              showLangTextModal={showLangTextModal}
              {...props}
              setLoading={setLoading}
              currentDate={currentDate}
              isInfoReadOnly={isInfoReadOnly}
              onlyDisplay={timesPost.onlyDisplay}
            />
          </div>
          <div className={styles.infoAbwesenContainer}>
            <InfoAbwesen
              showLangTextModal={showLangTextModal}
              {...props}
              setLoading={setLoading}
              currentDate={currentDate}
              setHasError={setHasError}
              isInfoReadOnly={isInfoReadOnly}
              onlyDisplay={timesPost.onlyDisplay}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default InfoModal;

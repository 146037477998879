import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
  PSP_KOST,
  ABSENCE_TYPES,
  WAGE_TYPES,
  LEISART,
} from '../modals/modalTypes';
import { KOSTL_OR_POSID, LGART, AWART, LSTAR } from './EditableCellTypes';

import { showModal } from '../../redux/modals/modalActions';
import {
  fetchPspKost,
  setHasFetchedPspKost,
} from '../../redux/psp-kost/pspKostActions';
import {
  removeRowFromTable,
  duplicateRow,
  setLoadingOnEditableSearchCell,
  setErrorOnEditableSearchCell,
} from '../../redux/time/timeActions';
import { fetchWageType } from '../../redux/wage-type/wageTypeActions';
import PropTypes from 'prop-types';
import { fetchAbsenceType } from '../../redux/absence-type/absenceTypeActions';

import { Button, Spin } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as searchIcon } from '../../assets/search.svg';
import { ReactComponent as loadingIcon } from '../../assets/loading.svg';

import styles from './EditableSearchCell.module.css';

import {
  STUNDEN,
  ABWESEN,
  INFO_TIMESTAMPS,
  INFO_HOURS,
} from '../modals/modalParentTypes';

import moment from 'moment';
import {
  isValidActionButton,
  REMOVE_STUNDEN,
  DUPLICATE_STUNDEN,
  REMOVE_ABWESEN,
  DUPLICATE_ABWESEN,
  REMOVE_INFO_ABWESEN,
  REMOVE_INFO_STUNDEN,
  DUPLICATE_INFO_STUNDEN,
  DUPLICATE_INFO_ABWESEN,
} from '../../utils/isValidActionButton';
import { fetchLeisart } from '../../redux/leisart/leisartActions';

const EditableSearchCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  alignCenter,
  showModal,
  cell,
  auth,
  currentMonth,
  fetchPspKost,
  fetchWageType,
  fetchAbsenceType,
  fetchLeisart,
  maxLength, //prop passed when maxLength is required - default value: Number.MAX_SAFE_INTEGER
  selectedAwart,
  selectedLstar,
  selectedCostCenterOrWbs,
  selectedWageType,
  parent = STUNDEN,
  readOnly,
  workdate,
  setLoading,
  removeRowFromTable,
  duplicateRow,
  times,
  absences,
  tempHours,
  tempTimestamps,
  setLoadingOnEditableSearchCell,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [showBtn, setShowBtn] = useState(false);
  const editableCellRef = useRef(null);
  const [hasChanged, setHasChanged] = useState(false);

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
    setHasChanged(true);
  };

  const handleButtonClick = (lostFocusTo) => {
    switch (lostFocusTo) {
      case REMOVE_STUNDEN:
        if (parent === STUNDEN) {
          let canRemove = true;

          for (const cell of Object.values(times[index - 2].cells)) {
            if (cell.isReadOnly) {
              canRemove = false;
              break;
            }
          }

          if (canRemove) {
            removeRowFromTable(index - 2, parent);
          }
        }
        break;
      case DUPLICATE_STUNDEN:
        if (parent === STUNDEN) duplicateRow(index - 2, parent);
        break;
      case REMOVE_ABWESEN:
        if (parent === ABWESEN) {
          let canRemove = true;

          for (const cell of Object.values(absences[index - 2].cells)) {
            if (cell.isReadOnly) {
              canRemove = false;
              break;
            }
          }

          if (canRemove) {
            removeRowFromTable(index - 2, parent);
          }
        }
        break;
      case DUPLICATE_ABWESEN:
        if (parent === ABWESEN) duplicateRow(index - 2, parent);
        break;
      case REMOVE_INFO_ABWESEN:
        if (parent === INFO_TIMESTAMPS) {
          if (
            !tempTimestamps[moment(workdate).format('YYYY-MM-DD')][index]
              .isReadOnly
          ) {
            removeRowFromTable(
              index,
              parent,
              moment(workdate).format('YYYY-MM-DD')
            );
          }
        }
        break;
      case REMOVE_INFO_STUNDEN:
        if (parent === INFO_HOURS) {
          if (
            !tempHours[moment(workdate).format('YYYY-MM-DD')][index].isReadOnly
          ) {
            removeRowFromTable(
              index,
              parent,
              moment(workdate).format('YYYY-MM-DD')
            );
          }
        }

        break;
      case DUPLICATE_INFO_STUNDEN:
        if (parent === INFO_HOURS)
          duplicateRow(index, parent, moment(workdate).format('YYYY-MM-DD'));
        break;
      case DUPLICATE_INFO_ABWESEN:
        if (parent === INFO_TIMESTAMPS)
          duplicateRow(index, parent, moment(workdate).format('YYYY-MM-DD'));
        break;
      default:
        //do nothing
        break;
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    if (e.relatedTarget) {
      if (e.relatedTarget.innerText === 'Fertig') {
        setLoading(true);
      }

      let lostFocusTo = isValidActionButton(e.relatedTarget.classList);
      if (lostFocusTo !== '') {
        handleButtonClick(lostFocusTo);
      }
    }

    if (value !== initialValue || hasChanged) {
      setHasChanged(false);
      updateMyData(index, id, value, parent);
      if (cell.column.id === KOSTL_OR_POSID) {
        fetchPspKost(
          auth,
          currentMonth,
          {
            cost_center_or_wbs: e.target.value,
          },
          cell,
          parent,
          moment(workdate).format('YYYY-MM-DD'),
          initialValue
        );

        if (e.target.value !== '') {
          setLoadingOnEditableSearchCell(
            cell.column.id,
            parent === STUNDEN ? cell.row.index - 2 : cell.row.index,
            true,
            parent,
            moment(workdate).format('YYYY-MM-DD')
          );
        }
      } else if (cell.column.id === LSTAR) {
        fetchLeisart(
          auth,
          {
            lstar: e.target.value,
            date: moment(workdate).format('YYYY-MM-DD'),
          },
          cell
        );

        if (e.target.value !== '') {
          setLoadingOnEditableSearchCell(
            cell.column.id,
            parent === STUNDEN ? cell.row.index - 2 : cell.row.index,
            true,
            parent,
            moment(workdate).format('YYYY-MM-DD')
          );
        }
      } else if (cell.column.id === LGART) {
        fetchWageType(
          auth,
          currentMonth,
          {
            wagetype: e.target.value,
          },
          cell
        );
        if (e.target.value !== '') {
          setLoadingOnEditableSearchCell(
            cell.column.id,
            cell.row.index - 2,
            true,
            parent,
            moment(workdate).format('YYYY-MM-DD')
          );
        }
      } else if (cell.column.id === AWART) {
        fetchAbsenceType(
          auth,
          currentMonth,
          {
            absence_type: e.target.value,
          },
          cell
        );
        if (e.target.value !== '') {
          setLoadingOnEditableSearchCell(
            cell.column.id,
            cell.row.index - 2,
            true,
            parent,
            moment(workdate).format('YYYY-MM-DD')
          );
        }
      }
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const showSearchHelp = () => {
    if (cell.column.id === KOSTL_OR_POSID) {
      showModal(PSP_KOST, { cell, selectedCostCenterOrWbs });
    } else if (cell.column.id === LGART) {
      showModal(WAGE_TYPES, { cell, selectedWageType });
    } else if (cell.column.id === AWART) {
      showModal(ABSENCE_TYPES, { cell, selectedAwart });
    } else if (cell.column.id === LSTAR) {
      showModal(LEISART, { cell, selectedLstar, currentDate: workdate });
    }
  };

  const isCellValid = () => {
    let isValid = true;

    switch (parent) {
      case STUNDEN:
        if (cell.column.id === KOSTL_OR_POSID) {
          isValid = times[cell.row.index - 2].isKostlOrPosidValid;
        } else if (cell.column.id === LGART) {
          isValid = times[cell.row.index - 2].isLgartValid;
        }
        break;
      case ABWESEN:
        isValid = absences[cell.row.index - 2].isAwartValid;
        break;
      case INFO_HOURS:
        if (
          tempHours[moment(workdate).format('YYYY-MM-DD')] &&
          tempHours[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
        ) {
          if (cell.column.id === KOSTL_OR_POSID) {
            isValid =
              tempHours[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
                .isKostlOrPosidValid;
          } else if (cell.column.id === LSTAR) {
            isValid =
              tempHours[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
                .isLstarValid;
          }
        }
        break;
      case INFO_TIMESTAMPS:
        if (
          tempTimestamps[moment(workdate).format('YYYY-MM-DD')] &&
          tempTimestamps[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
        ) {
          isValid =
            tempTimestamps[moment(workdate).format('YYYY-MM-DD')][
              cell.row.index
            ].isKostlOrPosidValid;
        }
        break;
      default:
        break;
    }

    if (editableCellRef.current && !isValid) {
      editableCellRef.current.focus();
    }

    return isValid;
  };

  const renderRightBtn = () => {
    if (!showBtn && readOnly) {
      return null;
    }

    switch (parent) {
      case STUNDEN:
        if (
          cell.column.id === KOSTL_OR_POSID &&
          times[cell.row.index - 2].isFetchingKostlOrPosid
        ) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        } else if (
          cell.column.id === LGART &&
          times[cell.row.index - 2].isFetchingLgart
        ) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        }
        break;
      case ABWESEN:
        if (absences[cell.row.index - 2].isFetchingAwart) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        }
        break;
      case INFO_HOURS:
        if (
          cell.column.id === KOSTL_OR_POSID &&
          tempHours[moment(workdate).format('YYYY-MM-DD')] &&
          tempHours[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
            .isFetchingKostlOrPosid
        ) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        } else if (
          cell.column.id === LSTAR &&
          tempHours[moment(workdate).format('YYYY-MM-DD')] &&
          tempHours[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
            .isFetchingLstar
        ) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        }
        break;
      case INFO_TIMESTAMPS:
        if (
          tempTimestamps[moment(workdate).format('YYYY-MM-DD')] &&
          tempTimestamps[moment(workdate).format('YYYY-MM-DD')][cell.row.index]
            .isFetchingKostlOrPosid
        ) {
          if (editableCellRef.current) {
            editableCellRef.current.readOnly = true;
          }

          return (
            <Spin
              className={styles.editableBtn}
              indicator={<Icon component={loadingIcon} spin />}
            />
          );
        }
        break;
      default:
        break;
    }

    if (editableCellRef.current && !readOnly) {
      editableCellRef.current.readOnly = false;
    }

    return showBtn && !readOnly ? (
      <Button
        className={styles.editableBtn}
        icon={<Icon component={searchIcon} />}
        onClick={showSearchHelp}
      />
    ) : null;
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShowBtn(true)}
      onMouseLeave={() => setShowBtn(false)}
    >
      <input
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        spellCheck='false'
        readOnly={readOnly}
        maxLength={maxLength}
        className={styles.editableInput}
        style={
          alignCenter !== undefined && alignCenter !== null
            ? { textAlign: 'center' }
            : { color: !isCellValid() ? 'red' : null }
        }
        ref={editableCellRef}
      />

      {renderRightBtn()}
    </div>
  );
};

const mapStateToProps = ({
  auth,
  time: { currentMonth, times, absences, tempHours, tempTimestamps },
  pspKost: {
    error: pspKostError,
    editedPspKostCell,
    hasFetchedPspKost,
    pspKost,
    pspErrorValue,
    hasFetchedFromModal,
  },
  wageType: { error: wageError, editedWageTypeCell, hasFetchedWageType },
  absenceType: {
    error: absenceError,
    editedAbsenceTypeCell,
    hasFetchedAbsenceType,
  },
}) => ({
  auth,
  currentMonth,
  pspKostError,
  wageError,
  hasFetchedFromModal,
  editedPspKostCell,
  hasFetchedPspKost,
  editedWageTypeCell,
  hasFetchedWageType,
  hasFetchedAbsenceType,
  absenceError,
  editedAbsenceTypeCell,
  pspKost,
  pspErrorValue,
  times,
  absences,
  tempHours,
  tempTimestamps,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type, props) => dispatch(showModal(type, props)),
  fetchPspKost: (
    auth,
    month,
    searchData,
    cellBeingEdited,
    parent,
    workdate,
    oldValue
  ) =>
    dispatch(
      fetchPspKost(
        auth,
        month,
        searchData,
        cellBeingEdited,
        parent,
        workdate,
        oldValue
      )
    ),
  fetchWageType: (auth, month, searchData, cellBeingEdited) =>
    dispatch(fetchWageType(auth, month, searchData, cellBeingEdited)),
  fetchAbsenceType: (auth, month, searchData, cellBeingEdited) =>
    dispatch(fetchAbsenceType(auth, month, searchData, cellBeingEdited)),
  fetchLeisart: (auth, searchData, cellBeingEdited) =>
    dispatch(fetchLeisart(auth, searchData, cellBeingEdited)),
  setHasFetchedPspKost: (fetched) => dispatch(setHasFetchedPspKost(fetched)),
  removeRowFromTable: (index, table, workdate) =>
    dispatch(removeRowFromTable(index, table, workdate)),
  duplicateRow: (index, table, workdate) =>
    dispatch(duplicateRow(index, table, workdate)),
  setLoadingOnEditableSearchCell: (
    columnId,
    rowIndex,
    isLoading,
    parent,
    workdate
  ) =>
    dispatch(
      setLoadingOnEditableSearchCell(
        columnId,
        rowIndex,
        isLoading,
        parent,
        workdate
      )
    ),
  setErrorOnEditableSearchCell: (
    columnId,
    rowIndex,
    isValid,
    parent,
    workdate
  ) =>
    dispatch(
      setErrorOnEditableSearchCell(
        columnId,
        rowIndex,
        isValid,
        parent,
        workdate
      )
    ),
});

EditableSearchCell.propTypes = {
  maxLength: PropTypes.number.isRequired,
};

EditableSearchCell.defaultProps = {
  maxLength: Number.MAX_SAFE_INTEGER,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditableSearchCell);

import {
  FETCH_PRINT_START,
  FETCH_PRINT_SUCCESS,
  FETCH_PRINT_FAILURE,
  FETCHED_PRINT,
  CLEAR_ERROR,
} from './printTypes';

const initialState = {
  doc: null,
  isFetchingDoc: false,
  errorFetchingDoc: null,
  hasFetchedDoc: false,
};

const printReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRINT_START:
      return {
        ...state,
        isFetchingDoc: true,
      };
    case FETCH_PRINT_SUCCESS:
      return {
        ...state,
        isFetchingDoc: false,
        doc: action.payload,
        errorFetchingDoc: null,
        hasFetchedDoc: true,
      };
    case FETCH_PRINT_FAILURE:
      return {
        ...state,
        errorFetchingDoc: action.payload,
        loading: false,
      };
    case FETCHED_PRINT:
      return {
        ...state,
        hasFetchedDoc: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        errorFetchingDoc: null,
      };
    default:
      return state;
  }
};

export default printReducer;

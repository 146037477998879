import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';

import styles from './PersaBtrtlModal.module.css';

import { PERSA_BTRTL } from '../../../modalTypes';

import { BTRTL, PERSA } from './PersaBtrtlTypes';

import PersaBtrtlModalTable from './PersaBtrtlModalTable';
import PersaBtrtlModalStyle from './PersaBtrtlModalStyle';

import {
  fetchPersaList,
  clearErrors,
  resetState,
  resetBtrtlList,
} from '../../../../../redux/person-aus/personActions';
import BtrtlHeader from './BtrtlHeader';

const PersaBtrtlModal = ({
  auth,
  currentModals,
  hideModal,
  persaList,
  btrtlList,
  TYPE,
  persaError,
  btrtlError,
  clearErrors,
  fetchPersaList,
  onSelectedPersa,
  onSelectedBtrtl,
  resetState,
  resetBtrtlList,
}) => {
  const [columns] = useState(
    TYPE === PERSA
      ? [
          {
            Header: 'PBer',
            accessor: 'persa',
            width: 60,
          },
          {
            Header: 'Personalbereichstext',
            accessor: 'name1',
          },
        ]
      : [
          {
            Header: 'PBer',
            accessor: 'werks',
            width: 60,
          },
          {
            Header: 'Personalbereichstext',
            accessor: 'btext',
          },
          {
            Header: 'TeilBer.',
            accessor: 'btrtl',
          },
        ]
  );

  useEffect(() => {
    if (TYPE === PERSA) {
      if (persaError) {
        message.error(persaError);
        clearErrors();
      }
    } else {
      if (btrtlError) {
        message.error(btrtlError);
        clearErrors();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btrtlError, persaError]);

  useEffect(() => {
    if (TYPE === PERSA) {
      fetchPersaList(auth, '', '');
    } else {
      resetBtrtlList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal(PERSA_BTRTL);
  };

  const handleOk = () => {
    hideModal(PERSA_BTRTL);
  };

  const onRowSelect = (row) => {
    hideModal(PERSA_BTRTL);
    if (TYPE === PERSA) {
      onSelectedPersa(row);
    } else {
      onSelectedBtrtl(row);
    }
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(PERSA_BTRTL) ? true : false}
      title={`Suchhilfe für ${
        TYPE === PERSA ? 'Personalbereich' : 'Personalteilbereich'
      }`}
      onOk={handleOk}
      onCancel={handleCancel}
      width={550}
      closable={true}
      footer={null}
    >
      {TYPE === BTRTL && <BtrtlHeader />}
      <div className={styles.container}>
        <PersaBtrtlModalStyle>
          <PersaBtrtlModalTable
            columns={columnsMemo}
            data={TYPE === PERSA ? persaList : btrtlList}
            onRowSelect={onRowSelect}
          />
        </PersaBtrtlModalStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  personProfiles: { persaList, btrtlList, btrtlError, persaError },
}) => ({
  auth,
  persaList,
  btrtlList,
  btrtlError,
  persaError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPersaList: (auth, persa, name1) =>
    dispatch(fetchPersaList(auth, persa, name1)),
  resetState: () => dispatch(resetState()),
  clearErrors: () => dispatch(clearErrors()),
  resetBtrtlList: () => dispatch(resetBtrtlList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersaBtrtlModal);

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { fetchWageTypes } from '../../../../redux/wage-type/wageTypeActions';

import WageTypeTableStyle from './WageTypeTableStyle';
import styles from './WageTypeModal.module.css';

import WageTypeModalTable from './WageTypeModalTable';

import { WAGE_TYPES } from '../../modalTypes';

const WageTypeModal = ({
  auth,
  currentMonth,
  currentModals,
  hideModal,
  fetchWageTypes,
  wageTypes,
  cell,
  selectedWageType,
}) => {
  const [columns] = useState([
    {
      Header: 'Lohnart',
      accessor: 'lgart',
      width: 40,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'lgtxt',
    },
    {
      Header: 'Mengeneinheit',
      accessor: 'unit',
      width: 60,
    },
    {
      Header: 'Währung',
      accessor: 'currency',
      width: 40,
    },
  ]);

  useEffect(() => {
    fetchWageTypes(auth, currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal(WAGE_TYPES);
  };

  const handleOk = () => {
    hideModal(WAGE_TYPES);
  };

  const onRowSelect = (row) => {
    hideModal(WAGE_TYPES);
    selectedWageType(
      cell,
      row.values.lgart,
      row.values.lgtxt,
      row.values.unit,
      row.values.currency,
      row.original.isTravel
    );
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(WAGE_TYPES) ? true : false}
      title='Suchhilfe für Lohnarten'
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      closable={true}
      footer={null}
    >
      <div className={styles.container}>
        <WageTypeTableStyle>
          <WageTypeModalTable
            columns={columnsMemo}
            data={wageTypes || []}
            onRowSelect={onRowSelect}
          />
        </WageTypeTableStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  time: { currentMonth },
  wageType: { wageTypes },
}) => ({
  auth,
  currentMonth,
  wageTypes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchWageTypes: (auth, month) => dispatch(fetchWageTypes(auth, month)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WageTypeModal);

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { fetchAbsenceTypes } from '../../../../redux/absence-type/absenceTypeActions';

import AbsenceTypeTableStyle from './AbsenceTypeTableStyle';
import styles from './AbsenceTypeModal.module.css';

import { ABSENCE_TYPES } from '../../modalTypes';

import AbsenceTypeModalTable from './AbsenceTypeModalTable';

const AbsenceTypeModal = ({
  auth,
  currentMonth,
  currentModals,
  hideModal,
  fetchAbsenceTypes,
  absenceTypes,
  setSelectedAbsenceType,
  cell,
  selectedAwart,
}) => {
  const [columns] = useState([
    {
      Header: 'Abwesenheitsart',
      accessor: 'awart',
      width: 60,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'atext',
    },
  ]);

  useEffect(() => {
    fetchAbsenceTypes(auth, currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal(ABSENCE_TYPES);
  };

  const handleOk = () => {
    hideModal(ABSENCE_TYPES);
  };

  const onRowSelect = (row) => {
    hideModal(ABSENCE_TYPES);
    selectedAwart(row.values.awart, row.values.atext, cell);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(ABSENCE_TYPES) ? true : false}
      title='Suchhilfe für Abwesenheitsarten'
      onOk={handleOk}
      onCancel={handleCancel}
      width={550}
      closable={true}
      footer={null}
    >
      <div className={styles.container}>
        <AbsenceTypeTableStyle>
          <AbsenceTypeModalTable
            columns={columnsMemo}
            data={absenceTypes || []}
            onRowSelect={onRowSelect}
          />
        </AbsenceTypeTableStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  time: { currentMonth },
  absenceType: { absenceTypes },
}) => ({
  auth,
  currentMonth,
  absenceTypes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAbsenceTypes: (auth, month) => dispatch(fetchAbsenceTypes(auth, month)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbsenceTypeModal);

import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, DatePicker, message } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import KostenstelleTable from './KostenstelleTable';

import {
  fetchKost,
  setHasFetchedKost,
} from '../../../../../redux/psp-kost/pspKostActions';

import Styles from '../PSPKostTableStyle';

import moment from 'moment';

import styles from './Kostenstelle.module.css';
import { showModal } from '../../../../../redux/modals/modalActions';

import { BUKRS, KOKRS, PRCTR } from '../../../modalTypes';

import { resetPrctrData } from '../../../../../redux/prctr/prctrActions';
import { removeLeadingZeroes } from '../../../../../utils/removeLeadingZeros';

const { Search } = Input;

const Kostenstelle = ({
  auth,
  fetchKost,
  loading,
  kost,
  setHasFetchedKost,
  hasFetchedKost,
  cell,
  selectedCostCenterOrWbs,
  showModal,
  error,
}) => {
  const { RangePicker } = DatePicker;

  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    kostl: '',
    kokrs: '',
    datab: '',
    datbi: '',
    ktext: '',
    ltext: '',
    verak: '',
    prctr: '',
    bukrs: '',
  });

  const {
    kostl,
    kokrs,
    datab,
    datbi,
    ktext,
    ltext,
    verak,
    prctr,
    bukrs,
  } = searchData;

  const [columns] = useState([
    {
      Header: 'Kostenst.',
      accessor: 'kostl',
      width: 70,
      minWidth: 70,
      Cell: (cellInfo) => (
        <Fragment>
          {removeLeadingZeroes(cellInfo.value ? cellInfo.value : '')}
        </Fragment>
      ),
    },
    {
      Header: 'KKrs',
      accessor: 'kokrs',
      width: 40,
      minWidth: 40,
    },
    {
      Header: 'Gültig ab',
      accessor: 'datab',
      width: 70,
      minWidth: 70,
    },
    {
      Header: 'Gültig bis',
      accessor: 'datbi',
      width: 70,
      minWidth: 70,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'ktext',
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'Beschreibung',
      accessor: 'ltext',
    },
    {
      Header: 'Verantwortlicher',
      accessor: 'verak',
      width: 100,
      minWidth: 100,
    },
    {
      Header: 'BUKRS',
      accessor: 'bukrs',
      width: 70,
      minWidth: 70,
    },
    {
      Header: 'PRCTR',
      accessor: 'prctr',
      width: 70,
      minWidth: 70,
    },
  ]);

  useEffect(() => {
    if (hasFetchedKost) {
      setData(kost);
      setHasFetchedKost(false);
      if (error) {
        message.error(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedKost]);

  const selectedPrctr = (prctr) => {
    setSearchData({
      ...searchData,
      prctr,
    });
  };

  const selectedBukrs = (bukrs) => {
    setSearchData({
      ...searchData,
      bukrs,
    });
  };

  const selectedKokrs = (kokrs) => {
    setSearchData({
      ...searchData,
      kokrs,
    });
  };

  useEffect(() => {
    for (let i = 1; i <= 10; i++) {
      let row = {
        id: i,
        kost: '',
        kokrs: '',
        datab: '',
        datbi: '',
        ktext: '',
        ltext: '',
        verak: '',
        prctr: '',
        bukrs: '',
      };
      setData((data) => [...data, row]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onDateChange = (dates, dateStrings) => {
    setSearchData({
      ...searchData,
      datab: dateStrings[0],
      databi: dateStrings[1],
    });
  };

  const onSearch = () => {
    if (
      kostl === '' &&
      kokrs === '' &&
      datab === '' &&
      datbi === '' &&
      ktext === '' &&
      ltext === '' &&
      verak === '' &&
      prctr === '' &&
      bukrs === ''
    ) {
      message.warning('Bitte fullen Sie mindestens eines der Felder aus!', 3);
    } else {
      fetchKost(auth, {
        kostl,
        kokrs,
        datab,
        datbi,
        ktext,
        ltext,
        verak,
        prctr,
        bukrs,
      });
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };

  const showPrctrModal = () => {
    showModal(PRCTR, {
      selectedPrctr,
    });
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Fragment>
      <div className={styles.kostHeader}>
        <div className={styles.row1}>
          <Input
            placeholder='Kostenstelle'
            className={styles.kostl}
            name='kostl'
            maxLength={10}
            value={kostl}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <div className={styles.kokrs}>
            <Search
              placeholder='Kostenrechnungskreis'
              name='kokrs'
              maxLength={4}
              value={kokrs}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onSearch={(value, e) => {
                if (e.keyCode === 13) {
                  e.target.blur();
                  onSearch();
                } else {
                  showModal(KOKRS, {
                    selectedKokrs,
                    kokrsFromSearchInput: value,
                  });
                }
              }}
            />
          </div>
          <RangePicker
            className={styles.range}
            ranges={{
              'Dieser Tag': [moment(), moment()],
              'Dieser Monat': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
            placeholder={['Gültig ab', 'Gültig bis']}
            showTime
            format='YYYY-MM-DD'
            onChange={onDateChange}
          />
          <Input
            placeholder='Verantwortlicher'
            className={styles.verant}
            name='verak'
            maxLength={20}
            value={verak}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </div>
        <div className={styles.row2}>
          <Input
            placeholder='Bezeichnung'
            className={styles.bezeichnung}
            name='ktext'
            maxLength={20}
            value={ktext}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Input
            placeholder='Beschreibung'
            className={styles.beschreibung}
            value={ltext}
            maxLength={40}
            name='ltext'
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <div className={styles.bukrs}>
            <Search
              placeholder='Buchungskreis'
              name='bukrs'
              maxLength={10}
              value={bukrs}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onSearch={(value, e) =>
                e.keyCode !== 13 && showModal(BUKRS, { selectedBukrs })
              }
            />
          </div>
          <div className={styles.profitCenter}>
            <Search
              placeholder='Profit-Center'
              name='prctr'
              maxLength={10}
              value={prctr}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onSearch={(value, e) => e.keyCode !== 13 && showPrctrModal()}
            />
          </div>
          <Button
            type='primary'
            className={styles.searchBtn}
            icon={<SearchOutlined />}
            loading={loading}
            onClick={onSearch}
          >
            Suchen
          </Button>
        </div>
      </div>
      <Styles>
        <KostenstelleTable
          columns={columnsMemo}
          data={data}
          cell={cell}
          selectedCostCenterOrWbs={selectedCostCenterOrWbs}
        />
      </Styles>
    </Fragment>
  );
};

const mapStateToProps = ({
  auth,
  pspKost: { kost, loading, hasFetchedKost, error },
  prctr: { hasFetchedPrctr, error: prctrError, prctrList },
}) => ({
  auth,
  kost,
  loading,
  hasFetchedKost,
  error,
  hasFetchedPrctr,
  prctrError,
  prctrList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchKost: (auth, searchData) => dispatch(fetchKost(auth, searchData)),
  setHasFetchedKost: (fetched) => dispatch(setHasFetchedKost(fetched)),
  showModal: (modalType, modalProps) =>
    dispatch(showModal(modalType, modalProps)),
  resetPrctrData: () => dispatch(resetPrctrData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Kostenstelle);

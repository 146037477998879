import React from 'react';
import { Empty } from 'antd';

export const NoDataTable = () => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>Keine Daten</span>}
    />
  );
};

import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import { SAVE_CHANGES } from '../modalTypes';

const SaveChangesModal = ({
  currentModals,
  hideModal,
  shouldSaveChanges,
  title,
  question,
}) => {
  const onClose = () => {
    hideModal(SAVE_CHANGES);
    shouldSaveChanges(false);
  };

  const onDone = () => {
    hideModal(SAVE_CHANGES);
    shouldSaveChanges(true);
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: '0' }}>{title}</h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(SAVE_CHANGES) ? true : false}
      onCancel={() => onClose(true)}
      width={370}
      centered={true}
      footer={[
        <Button key='submit' type='primary' onClick={onDone}>
          Ja
        </Button>,
        <Button key='back' onClick={onClose}>
          Nein
        </Button>,
      ]}
    >
      <div style={{ fontWeight: 500 }}>{question}</div>
    </Modal>
  );
};

export default SaveChangesModal;

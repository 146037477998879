import React, { useState } from 'react';

import { Popover } from 'antd';

import SettingsContent from './SettingsContent';

const SettingsPopover = (props) => {
  const [isSystemSettingsVisible, setIsSystemSettingsVisible] = useState(false);

  const handleSystemSettingsVisibility = (visible) => {
    setIsSystemSettingsVisible(visible);
  };

  return (
    <Popover
      placement='bottomRight'
      overlayStyle={{ width: '400px' }}
      destroyTooltipOnHide={true}
      content={
        <SettingsContent
          setIsSystemSettingsVisible={setIsSystemSettingsVisible}
        />
      }
      trigger='click'
      visible={isSystemSettingsVisible}
      onVisibleChange={handleSystemSettingsVisibility}
    >
      {props.children}
    </Popover>
  );
};

export default SettingsPopover;

import React, { useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import abwesenColumnType from './abwesenColumTypes';
import { ABWESEN } from '../../modals/modalParentTypes';

import AbwesenTable from './AbwesenTable';
import EditableCell from '../../editable-cells/EditableCell';
import EditableSearchCell from '../../editable-cells/EditableSearchCell';

import {
  updateAbsence,
  addEmptyAbsence,
  setChangedAbsencesData,
  updateAbsenceType,
  setHasSelectedAbsenceType,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
} from '../../../redux/time/timeActions';

import { setHasFetchedAbsenceType } from '../../../redux/absence-type/absenceTypeActions';

import StylesComponent from '../../pages/zeit/ZeitStyles';
import styles from './Abwesenheiten.module.css';
import { message } from 'antd';

const Abwesenheiten = ({
  absences,
  absenceColumns,
  total,
  regularHours,
  frozenColumns,
  updateAbsence,
  changedAbsencesData,
  setChangedAbsencesData,
  hasFetchedAbsenceType,
  setHasFetchedAbsenceType,
  updateAbsenceType,
  editedAbsenceTypeCell,
  absenceType,
  error,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
  periodClosed,
  areTimesLocked,
  onlyDisplay,
}) => {
  const { awart, atext, unit, sum } = abwesenColumnType;

  useEffect(() => {
    if (hasFetchedAbsenceType) {
      if (error) {
        message.error(error);
      } else {
        const { awart, atext } = absenceType;

        updateAbsenceType(editedAbsenceTypeCell.row.index - 2, awart, atext);
      }
      setErrorOnEditableSearchCell(
        awart,
        editedAbsenceTypeCell.row.index - 2,
        error ? false : true,
        ABWESEN
      );
      setLoadingOnEditableSearchCell(
        awart,
        editedAbsenceTypeCell.row.index - 2,
        false,
        ABWESEN
      );
      setHasFetchedAbsenceType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedAbsenceType]);

  const getMonthColumns = () => {
    let tempColumns = [];

    for (const key of Object.keys(absenceColumns)) {
      const names = Object.freeze(['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']);

      if (moment(key).isValid()) {
        let date = moment(key).toDate();

        const column = {
          accessor: key,
          Header: (props) => {
            return (
              <div style={{ textAlign: 'center', color: '#1890FF' }}>
                {names[date.getDay()]}
                <br />
                {('0' + date.getDate()).slice(-2)}
              </div>
            );
          },
          width: 58,
          Cell: (cellInfo) =>
            cellInfo.row.index !== 0 && cellInfo.row.index !== 1 ? (
              <EditableCell
                initialValue={cellInfo.value}
                {...cellInfo}
                updateMyData={updateMyData}
                alignCenter={true}
                showModalOnDoubleClick={true}
                allowOnlyNumbers={true}
                parent={ABWESEN}
                readOnly={
                  cellInfo.readOnly ||
                  periodClosed ||
                  areTimesLocked ||
                  onlyDisplay
                }
                isStunden={false}
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                {parseFloat(cellInfo.value).toFixed(2) !== '0.00'
                  ? parseFloat(cellInfo.value).toFixed(2)
                  : ''}
              </div>
            ),
        };
        tempColumns = [...tempColumns, column];
      }
    }
    return tempColumns;
  };

  const updateMyData = (rowIndex, columnId, value, times) => {
    if (value === '' && columnId === 'awart') {
      updateAbsenceType(rowIndex - 2, '', '');
    } else {
      updateAbsence(rowIndex - 2, columnId, value);
    }
  };

  const selectedAwart = (awart, atext, cell) => {
    if (cell.row.index > 1)
      updateAbsenceType(cell.row.index - 2, awart, atext, true);
  };

  const columnsMemo = [
    {
      accessor: awart,
      sticky: frozenColumns ? 'left' : '',
      Header: <div className={styles.headerStyle}>Abw</div>,
      width: 90,
      Cell: (cellInfo) =>
        !absenceColumns[awart].isReadonly &&
        cellInfo.row.index !== 0 &&
        cellInfo.row.index !== 1 ? (
          <EditableSearchCell
            initialValue={cellInfo.value}
            {...cellInfo}
            parent={ABWESEN}
            updateMyData={updateMyData}
            selectedAwart={selectedAwart}
            isAwartValid={absences[cellInfo.row.index - 2].isAwartValid}
            isFetchingAwart={absences[cellInfo.row.index - 2].isFetchingAwart}
            readOnly={periodClosed || areTimesLocked || onlyDisplay}
          />
        ) : (
          cellInfo.value
        ),
    },
    {
      accessor: atext,
      sticky: frozenColumns ? 'left' : '',
      Header: <div className={styles.headerStyle}>Lohnart-Langtext</div>,
      width: 530,
      Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
    },
    {
      accessor: unit,
      sticky: frozenColumns ? 'left' : '',
      Header: <div className={styles.headerStyle}>ME</div>,
      width: 40,
      Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
    },
    {
      accessor: sum,
      sticky: frozenColumns ? 'left' : '',
      Header: (
        <div
          className={styles.headerStyle}
          style={{ justifyContent: 'flex-end' }}
        >
          Summe
        </div>
      ),
      width: 70,
      Cell: (cellInfo) => (
        <div style={{ textAlign: 'right' }}>
          {parseFloat(
            cellInfo.row.cells
              .map((cell) => ({
                value: cell.value === '' ? '0.00' : cell.value,
                id: cell.column.id,
              }))
              .filter(
                (cell) =>
                  !isNaN(cell.value) && moment(cell.id, 'YYYY-MM-DD').isValid()
              )
              .reduce((sum, data) => Number(sum) + Number(data.value), 0)
          ).toFixed(2)}
        </div>
      ),
    },
    ...getMonthColumns(),
  ];

  const dataMemo = useMemo(() => {
    const totalData = Object.assign(
      {
        awart: '',
        atext: 'Summenzeile',
        unit: 'H',
        sum: '',
      },
      ...Object.entries(total).map(([k, v]) => {
        return moment(k).isValid() ? { [k]: v.quantity } : { [k]: v };
      })
    );

    const regularHoursData = Object.assign(
      {
        awart: '',
        atext: 'Normalarbeitszeit',
        unit: 'H',
        sum: '',
      },
      ...Object.entries(regularHours).map(([k, v]) => {
        return moment(k).isValid() ? { [k]: v.quantity } : { [k]: v };
      })
    );

    let absencesData = absences.map((absences, index) => {
      const { awart, atext, unit, sum, cells } = absences;

      const absencesCells = Object.assign(
        {},
        ...Object.entries(cells).map(([k, v]) => {
          return {
            [k]: parseFloat(v.quantity === '' ? 0 : v.quantity).toFixed(2),
          };
        })
      );

      return {
        awart,
        atext,
        unit,
        sum,
        ...absencesCells,
      };
    });

    return [regularHoursData, totalData, ...absencesData];
  }, [absences, regularHours, total]);

  return (
    <StylesComponent height={'30vh'}>
      <AbwesenTable
        columns={columnsMemo}
        data={dataMemo || []}
        updateMyData={updateMyData}
      />
    </StylesComponent>
  );
};

const mapStateToProps = ({
  time: {
    absences,
    absenceColumns,
    total,
    regularHours,
    frozenColumns,
    changedAbsencesData,
    hasSelectedAbsenceType,
    isUpdatedFromStunden,
    periodClosed,
    onlyDisplay,
  },
  absenceType: {
    absenceType,
    hasFetchedAbsenceType,
    editedAbsenceTypeCell,
    error,
  },
  lock: { areTimesLocked },
}) => ({
  absences,
  absenceColumns,
  periodClosed,
  total,
  regularHours,
  frozenColumns,
  changedAbsencesData,
  editedAbsenceTypeCell,
  absenceType,
  hasFetchedAbsenceType,
  hasSelectedAbsenceType,
  error,
  isUpdatedFromStunden,
  areTimesLocked,
  onlyDisplay,
});

const mapDispatchToProps = (dispatch) => ({
  updateAbsence: (rowIndex, columnId, value) =>
    dispatch(updateAbsence(rowIndex, columnId, value)),
  addEmptyAbsence: () => dispatch(addEmptyAbsence()),
  setChangedAbsencesData: (changed) =>
    dispatch(setChangedAbsencesData(changed)),
  updateAbsenceType: (rowIndex, awart, atext) =>
    dispatch(updateAbsenceType(rowIndex, awart, atext)),
  setHasFetchedAbsenceType: (fetched) =>
    dispatch(setHasFetchedAbsenceType(fetched)),
  setHasSelectedAbsenceType: (hasSelected) =>
    dispatch(setHasSelectedAbsenceType(hasSelected)),
  setErrorOnEditableSearchCell: (columnId, rowIndex, isValid, parent) =>
    dispatch(setErrorOnEditableSearchCell(columnId, rowIndex, isValid, parent)),
  setLoadingOnEditableSearchCell: (
    columnId,
    rowIndex,
    isLoading,
    parent,
    workdate
  ) =>
    dispatch(
      setLoadingOnEditableSearchCell(
        columnId,
        rowIndex,
        isLoading,
        parent,
        workdate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Abwesenheiten);

export const timeConvert = (num) => {
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);

  return {
    hours: rhours,
    minutes: rminutes,
  };
};

import API from '../../api/api';
import {
  FETCH_LEISART_START,
  FETCH_LEISART_SUCCESS,
  FETCH_LEISART_FAILURE,
  FETCH_LEISARTS_START,
  FETCH_LEISARTS_SUCCESS,
  FETCH_LEISARTS_FAILURE,
  CLEAR_ERRORS,
  FETCHED_LEISART,
} from './leisartTypes';

export const fetchLeisartStart = () => {
  return {
    type: FETCH_LEISART_START,
  };
};

export const fetchLeisartFailure = (error, leisart, cellBeingEdited) => {
  return {
    type: FETCH_LEISART_FAILURE,
    payload: { error, leisart, cellBeingEdited },
  };
};

export const fetchLeisartSuccess = (leisart, cellBeingEdited) => {
  return {
    type: FETCH_LEISART_SUCCESS,
    payload: { leisart, cellBeingEdited },
  };
};

export const fetchLeisartsStart = () => {
  return {
    type: FETCH_LEISARTS_START,
  };
};

export const fetchLeisartsFailure = (error) => {
  return {
    type: FETCH_LEISARTS_FAILURE,
    payload: { error },
  };
};

export const fetchLeisartsSuccess = (data) => {
  return {
    type: FETCH_LEISARTS_SUCCESS,
    payload: data,
  };
};

export const setHasFetchedLeisart = (fetched) => {
  return {
    type: FETCHED_LEISART,
    payload: fetched,
  };
};

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const fetchLeisarts = (auth, searchData) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth, pernr },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        ...searchData,
        pernr,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchLeisartsStart());

    try {
      const res = await API.get('/SIF/time/lstars', config);
      dispatch(fetchLeisartsSuccess(res.data || []));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.statusText || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchLeisartsFailure(msg));
      } else {
        dispatch(
          fetchLeisartsFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

export const fetchLeisart = (auth, searchData, cellBeingEdited) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    if (searchData.lstar === '') {
      dispatch(fetchLeisartSuccess('', cellBeingEdited));
    } else {
      dispatch(fetchLeisartStart());

      try {
        const res = await API.get('/SIF/time/lstar', config);
        dispatch(fetchLeisartSuccess(res.data, cellBeingEdited));
      } catch (error) {
        if (error.response) {
          let msg = error.response.statusText || 'Nicht gefunden';
          dispatch(fetchLeisartFailure(msg, searchData.lstar, cellBeingEdited));
        } else {
          dispatch(
            fetchLeisartFailure(
              'Ein unbekannter Fehler ist aufgetreten',
              searchData.lstar,
              cellBeingEdited
            )
          );
        }
      }
    }
  };
};

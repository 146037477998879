import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Modal, Button, Input, Row, Col, Form, message } from 'antd';

import { CHANGE_PASSWORD } from '../modalTypes';

import styles from './ChangePasswordModal.module.css';

import {
  changePassword,
  clearErrors,
  login,
  setHasChangedPassword,
} from '../../../redux/auth/authActions';

const ChangePasswordModal = ({
  currentModals,
  hideModal,
  changePassword,
  isChangingPassword,
  hasChangedPassword,
  errorChangingPassword,
  setHasChangedPassword,
  login,
  clearErrors,
  user,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (errorChangingPassword) {
      message.error(errorChangingPassword).then(() => clearErrors());
    } else if (hasChangedPassword) {
      const { password } = form.getFieldsValue(['password']);

      setHasChangedPassword(false);
      login({ username: user.uname, password });
      hideModal(CHANGE_PASSWORD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorChangingPassword, hasChangedPassword]);

  const handleSave = () => {
    const { password, confirmPassword } = form.getFieldsValue([
      'password',
      'confirmPassword',
    ]);

    if (
      !password ||
      !confirmPassword ||
      password === '' ||
      confirmPassword === ''
    ) {
      message.warning('Bitte füllen Sie alle Felder aus');
    } else if (password.length < 6) {
      message.warning('Passwort muss mindestens 6 Zeichen enthalten');
    } else if (password !== confirmPassword) {
      message.warning('Passwort stimmt nicht überein');
    } else {
      changePassword({ password });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSave();
    }
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: 0 }}>Passwort setzen</h3>
            <h5 style={{ marginBottom: 0, color: 'lightgray' }}>
              Wählen Sie Ihr neues Passwort
            </h5>
          </Col>
        </Row>
      }
      destroyOnClose
      closable={false}
      visible={Object.prototype.hasOwnProperty.call(
        currentModals,
        CHANGE_PASSWORD
      )}
      className={styles.modal}
      onCancel={() => hideModal()}
      width={420}
      footer={[
        <Button key='back' onClick={() => hideModal(CHANGE_PASSWORD)}>
          Abbrechen
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isChangingPassword}
          onClick={() => handleSave()}
        >
          Fertig
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' name='change-password'>
        <Form.Item label='Passwort' name='password' className={styles.formItem}>
          <Input.Password onKeyDown={handleKeyDown} />
        </Form.Item>

        <Form.Item
          label='Passwort bestätigen'
          name='confirmPassword'
          className={styles.formItem}
        >
          <Input.Password onKeyDown={handleKeyDown} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  auth: { isChangingPassword, hasChangedPassword, errorChangingPassword, user },
}) => ({
  isChangingPassword,
  hasChangedPassword,
  errorChangingPassword,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (formData) => dispatch(changePassword(formData)),
  setHasChangedPassword: (hasChanged) =>
    dispatch(setHasChangedPassword(hasChanged)),
  clearErrors: () => dispatch(clearErrors()),
  login: (formData) => dispatch(login(formData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);

export const PERSONEN = 'PERSONEN';
export const ABWESEN = 'ABWESEN';
export const KURZTEXT = 'KURZTEXT';
export const LANGTEXT = 'LANGTEXT';
export const INFO = 'INFO';
export const PSP_KOST = 'PSP_KOST';
export const ABSENCE_TYPES = 'ABSENCE_TYPES';
export const WAGE_TYPES = 'WAGE_TYPES';
export const TIME_BALANCE = 'TIME_BALANCE';
export const SAVE_LAYOUT = 'SAVE_LAYOUT';
export const COPY_LAYOUT = 'COPY_LAYOUT';
export const DELETE_LAYOUT = 'DELETE_LAYOUT';
export const RELEASE_TIMES = 'RELEASE_TIMES';
export const SAVE_CHANGES = 'SAVE_CHANGES';
export const PRCTR = 'PRCTR';
export const BUKRS = 'BUKRS';
export const KOKRS = 'KOKRS';
export const PERSON_PROFILE = 'PERSON_PROFILE';
export const EMPLOYEES = 'EMPLOYEES';
export const PERSA_BTRTL = 'PERSA_BTRTL';
export const TRAVEL_TIME = 'TRAVEL_TIME';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const LEISART = 'LEISART';

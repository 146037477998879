import moment from 'moment';
/**
 * This function is used to convert time to decimal values. Example: 2.30 is converted to 2.50
 * First parse num to float
 * make it only 2 digits after decimal .toFixed
 * Back to string and replace . with : to make it acceptable for momentjs
 * Get time in hours with .asHours() method and make it 2 digits after decimal.
 */
export const convertHoursToDecimal = (num) => {
  return moment
    .duration(parseFloat(num).toFixed(2).toString().replace('.', ':'))
    .asHours()
    .toFixed(2);
};

import React from 'react';
import { Menu } from 'antd';

import kurztextIcon from '../../../assets/kurztextIcon.svg';
import langtextIcon from '../../../assets/langtextIcon.svg';
import { IoIosTimer } from 'react-icons/io';

import { LANGTEXT, KURZTEXT } from '../../modals/modalTypes';
import { STUNDEN } from '../../modals/modalParentTypes';
import { VORGABESTUNDEN_MONAT, VORGABESTUNDEN_TAG } from './menuItemTypes';

import styles from './ContextMenu.module.css';

const Item = Menu.Item;

const contextMenu = (
  cell,
  shouldEnableItems,
  showModal,
  parent,
  setRemainingHoursInDay,
  setRemainingHoursForMonth,
  onlyDisplay
) => {
  const {
    row: { values },
    value,
  } = cell;

  const rowContainsLgart = (lgart) => {
    if (values.hasOwnProperty('lgart')) {
      if (values.lgart === lgart) {
        return true;
      }
    }
    return false;
  };

  const handleClick = (info) => {
    if (info.key) {
      switch (info.key) {
        case VORGABESTUNDEN_TAG:
          setRemainingHoursInDay(cell.row.index, cell.column.id);
          break;
        case VORGABESTUNDEN_MONAT:
          setRemainingHoursForMonth(cell.row.index);
          break;
        case LANGTEXT:
        case KURZTEXT:
          if (value !== '' && value && value !== undefined && value > 0) {
            showModal(info.key, { cell, parent });
          } else {
            showModal(info.key, { cell, parent });
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <Menu className={styles.menu} selectable={false} onClick={handleClick}>
      <Item
        key={VORGABESTUNDEN_TAG}
        disabled={
          (!rowContainsLgart('1000') && parent === STUNDEN) || onlyDisplay
        }
        className={styles.menuItem}
      >
        <IoIosTimer
          size="20px"
          color="#1890FF"
          style={{ marginRight: '9px' }}
        />
        Vorgabestunden (Tag)
      </Item>
      <Item
        key={VORGABESTUNDEN_MONAT}
        disabled={
          (!rowContainsLgart('1000') && parent === STUNDEN) || onlyDisplay
        }
        className={styles.menuItem}
      >
        <IoIosTimer
          size="20px"
          color="#1890FF"
          style={{ marginRight: '9px' }}
        />
        Vorgabestunden (Monat)
      </Item>
      <Item
        key={LANGTEXT}
        disabled={
          (!shouldEnableItems && !rowContainsLgart('1000')) ||
          !cell ||
          Number(cell.value) === 0 ||
          cell.value === ''
        }
        className={styles.menuItem}
      >
        <img
          src={langtextIcon}
          alt="langtext"
          style={{ marginRight: '10px' }}
        />
        Langtext
      </Item>
      <Item
        key={KURZTEXT}
        disabled={
          (!shouldEnableItems && !rowContainsLgart('1000')) ||
          !cell ||
          Number(cell.value) === 0 ||
          cell.value === ''
        }
        className={styles.menuItem}
      >
        <img
          src={kurztextIcon}
          alt="kurztext"
          style={{ marginRight: '10px' }}
        />
        Kurztext
      </Item>
    </Menu>
  );
};

export default contextMenu;

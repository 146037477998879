import { Button, Input } from 'antd';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { fetchBtrtlList } from '../../../../../redux/person-aus/personActions';

import styles from './BtrtlHeader.module.css';

const BtrtlHeader = ({ auth, fetchBtrtlList, loading }) => {
  const [searchData, setSearchData] = useState({
    werks: '',
    btrtl: '',
    btext: '',
  });

  const { werks, btrtl, btext } = searchData;

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onSearch = () => {
    fetchBtrtlList(auth, werks, btrtl, btext);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };

  return (
    <Fragment>
      <div className={styles.btrtlHeader}>
        <div className={styles.row1}>
          <Input
            placeholder='Werks'
            name='werks'
            value={werks}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Input
            placeholder='Btrtl'
            name='btrtl'
            onKeyDown={onKeyDown}
            value={btrtl}
            onChange={onChange}
          />
        </div>
        <div className={styles.row2}>
          <Input
            placeholder='Bezeichnung'
            className={styles.post1}
            name='btext'
            value={btext}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Button
            type='primary'
            className={styles.searchBtn}
            icon={<SearchOutlined />}
            loading={loading}
            onClick={onSearch}
          >
            Suchen
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ auth, personProfiles: { isFetchingBtrtl } }) => ({
  auth,
  loading: isFetchingBtrtl,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBtrtlList: (auth, persa, btrtl, btext) =>
    dispatch(fetchBtrtlList(auth, persa, btrtl, btext)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BtrtlHeader);

import {
  FETCH_PRCTR_START,
  FETCH_PRCTR_FAILURE,
  FETCHED_PRCTR,
  FETCH_PRCTR_SUCCESS,
  RESET_PRCTR_DATA,
} from './prctrTypes';

const initalState = {
  loading: false,
  prctrList: [],
  error: null,
  hasFetchedPrctr: false,
  isFetchingPrctr: false,
};

const prctrReducer = (state = initalState, action) => {
  switch (action.type) {
    case FETCH_PRCTR_START:
      return {
        ...state,
        loading: true,
        error: null,
        isFetchingPrctr: true,
        hasFetchedPrctr: false,
      };
    case FETCH_PRCTR_SUCCESS:
      return {
        ...state,
        error: null,
        prctrList: action.payload,
        loading: false,
        isFetchingPrctr: false,
        hasFetchedPrctr: true,
      };
    case FETCH_PRCTR_FAILURE:
      return {
        ...state,
        error: action.payload,
        prctrList: [],
        loading: false,
        isFetchingPrctr: false,
        hasFetchedPrctr: true,
      };

    case FETCHED_PRCTR:
      return {
        ...state,
        hasFetchedPrctr: action.payload,
        error: null,
      };
    case RESET_PRCTR_DATA:
      return {
        ...state,
        prctrList: [],
      };
    default:
      return state;
  }
};

export default prctrReducer;

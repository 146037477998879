import React from 'react';
import { connect } from 'react-redux';
import { useTable, useAbsoluteLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { setSelectedPspKost } from '../../../../../redux/time/timeActions';
import { hideModal } from '../../../../../redux/modals/modalActions';

import { PSP_KOST } from '../../../modalTypes';
import { NoDataTable } from '../../../../no-data/NoDataTable';

const PSPElementTable = ({
  columns,
  data,
  setSelectedPspKost,
  hideModal,
  cell,
  selectedCostCenterOrWbs,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSticky,
    useAbsoluteLayout
  );

  const onRowSelect = (row) => {
    hideModal(PSP_KOST);
    selectedCostCenterOrWbs(cell, row.values.posid, row.values.post1);
  };

  return (
    <div>
      {rows.length === 0 ? (
        <NoDataTable />
      ) : (
        <div className='tableWrap'>
          <table
            {...getTableProps()}
            className='table sticky'
            id='pspElementTable'
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()} className='tr'>
                  {headerGroup.headers.map((column) => {
                    const headerProps = column.getHeaderProps();
                    headerProps.style = {
                      width: `${column.width}px`,
                      textAlign: 'left',
                    };

                    return (
                      <th {...headerProps} className='th header'>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className='body'>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} onClick={() => onRowSelect(row)}>
                    {row.cells.map((cell) => {
                      let props = cell.getCellProps();
                      props.style = {
                        width: `${cell.column.width}px`,
                      };

                      return (
                        <td {...props} className='td'>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedPspKost: (pspKost) => dispatch(setSelectedPspKost(pspKost)),
  hideModal: (type) => dispatch(hideModal(type)),
});

export default connect(null, mapDispatchToProps)(PSPElementTable);

import React, { useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTable, useBlockLayout } from 'react-table';
import { useExportData } from 'react-table-plugins';
import { useSticky } from 'react-table-sticky';
import { Dropdown, Button } from 'antd';
import moment from 'moment';

import contextMenu from '../menu/ContextMenu';
import exportMenu from '../menu/ExportMenu';

import EditableCell from '../../editable-cells/EditableCell';

import { showModal } from '../../../redux/modals/modalActions';
import {
  setEditingCell,
  addEmptyTime,
  setRemainingHoursInDay,
  setRemainingHoursForMonth,
} from '../../../redux/time/timeActions';

import getExportFileBlob from '../../../utils/getExportFileBlob';

import Icon from '@ant-design/icons';

import { STUNDEN } from '../../modals/modalParentTypes';

import AddLineIcon from '../../../assets/AddLineIcon';
import DuplicateLineIcon from '../../../assets/DuplicateLineIcon';
import ExportIcon from '../../../assets/ExportIcon';
import RemoveLineIcon from '../../../assets/RemoveLineIcon';

import styles from './StundenKontrolle.module.css';
import DescribedButton from '../../button-tooltip/DescribedButton';

import { KOSTL_OR_POSID, LGART } from '../../editable-cells/EditableCellTypes';

import {
  REMOVE_STUNDEN,
  DUPLICATE_STUNDEN,
} from '../../../utils/isValidActionButton';

const StundenTable = ({
  columns,
  data,
  updateMyData,
  showModal,
  addEmptyTime,
  timeColumns,
  times,
  timestamps,
  setRemainingHoursInDay,
  setRemainingHoursForMonth,
  periodClosed,
  areTimesLocked,
  onlyDisplay,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Cell: EditableCell,
    }),
    []
  );

  const getExportFileName = () => {
    return 'stunden-data';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      getExportFileBlob,
      getExportFileName,
    },
    useBlockLayout,
    useSticky,
    useExportData
  );

  const isDateValid = (date) => {
    const dateFormat = 'YYYY-MM-DD';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  };

  const isNonEditable = (cell) => {
    if (onlyDisplay) {
      return true;
    } else if (timeColumns[cell.column.id].isReadonly) {
      return true;
    } else if (isDateValid(cell.column.id)) {
      if (times[cell.row.index - 2]) {
        if (times[cell.row.index - 2].cells[cell.column.id]) {
          return times[cell.row.index - 2].cells[cell.column.id].isReadOnly;
        }
      }
    } else if (
      (cell.column.id === LGART || cell.column.id === KOSTL_OR_POSID) &&
      times[cell.row.index - 2].lgart !== ''
    ) {
      if (times[cell.row.index - 2]) {
        for (const [, v] of Object.entries(times[cell.row.index - 2].cells)) {
          if (v.isReadOnly) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }
  };

  const isHoliday = (id) => {
    return timeColumns[id].isHoliday;
  };

  const isWeekend = (id) => {
    return timeColumns[id].isWeekend;
  };

  const hasShortText = (cell) => {
    if (isDateValid(cell.column.id)) {
      if (times[cell.row.index - 2]) {
        if (times[cell.row.index - 2].cells[cell.column.id]) {
          return (
            times[cell.row.index - 2].cells[cell.column.id].shortText !== ''
          );
        }
      }
    }
    return false;
  };

  const hasLongText = (cell) => {
    if (isDateValid(cell.column.id)) {
      if (times[cell.row.index - 2]) {
        if (times[cell.row.index - 2].cells[cell.column.id]) {
          return (
            times[cell.row.index - 2].cells[cell.column.id].longText.length > 0
          );
        }
      }
    }
    return false;
  };

  const hasTimestamps = (cell) => {
    if (
      isDateValid(cell.column.id) &&
      times[cell.row.index - 2] &&
      times[cell.row.index - 2].lgart === '1000' &&
      cell.value > 0
    ) {
      if (timestamps[cell.column.id] && timestamps[cell.column.id].length > 0) {
        return true;
      }
    }
    return false;
  };

  const addEmptyLine = () => {
    addEmptyTime();
  };

  const colourTableCell = (cell) => {
    const style = { ...cell.getCellProps().style };
    let props = cell.getCellProps();
    let readOnly = false;
    let holiday = false;
    let weekend = false;
    let shortText = false;
    let longText = false;
    let timestamps = false;

    if (cell.row.index === 0) {
      style.background = '#D5EBFF';
      style.fontWeight = 600;
    } else if (cell.row.index === 1) {
      style.background = '#FFFFA3';
      style.fontWeight = 600;
    } else if (isWeekend(cell.column.id)) {
      style.background = '#F7CDCC';
      weekend = true;
    } else if (isHoliday(cell.column.id)) {
      style.background = '#F8E5C8';
      holiday = true;
    } else if (hasTimestamps(cell)) {
      style.background = '#FFFFD4';
      timestamps = true;
    }

    if (hasShortText(cell)) {
      style.background = '#C6F9C1';
      shortText = true;
    }

    if (hasLongText(cell)) {
      style.background = '#CCE3E3';
      longText = true;
    }

    if (cell.row.index > 1) {
      if (isNonEditable(cell)) {
        style.background = '#D5EBFF';
        readOnly = true;
      } else if (
        (periodClosed || areTimesLocked) &&
        !weekend &&
        !holiday &&
        !shortText &&
        !longText &&
        !timestamps
      ) {
        style.background = '#D5EBFF';
      }

      if (periodClosed || areTimesLocked || onlyDisplay) {
        readOnly = true;
      }
    }

    props.style = style;

    return { props, readOnly };
  };

  return (
    <Fragment>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Stundenkontrolle</h3>
        <div className={styles.zeitButtonsWrapper}>
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={AddLineIcon} />}
            tooltip={'Zeile hinzufügen'}
            onClick={addEmptyLine}
            type={'add'}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={RemoveLineIcon} />}
            tooltip={'Zeile löschen'}
            type={REMOVE_STUNDEN}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={DuplicateLineIcon} />}
            tooltip={'Zeile duplizieren'}
            type={DUPLICATE_STUNDEN}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
          <Dropdown overlay={exportMenu(exportData)} trigger={['click']}>
            <Button
              className={styles.zeitTableButtons}
              icon={<Icon component={ExportIcon} />}
            />
          </Dropdown>
        </div>
      </div>
      <div {...getTableProps()} className="table sticky" id="stundenTable">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => {
                return (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => {
                  const { props, readOnly } = colourTableCell(cell);

                  if (
                    cell.row.index !== 0 &&
                    cell.row.index !== 1 &&
                    moment(cell.column.id).isValid()
                  ) {
                    return (
                      <Dropdown
                        key={props.key}
                        overlay={contextMenu(
                          cell,
                          moment(cell.column.id).isValid() &&
                            cell.value !== '' &&
                            cell.value !== undefined,
                          (type, props) => showModal(type, props),
                          STUNDEN,
                          (index, column) =>
                            setRemainingHoursInDay(index - 2, column, STUNDEN),
                          (index) =>
                            setRemainingHoursForMonth(index - 2, STUNDEN),
                          onlyDisplay
                        )}
                        trigger={['contextMenu']}
                      >
                        <div {...props} className="td">
                          {cell.render('Cell', {
                            readOnly,
                          })}
                        </div>
                      </Dropdown>
                    );
                  } else {
                    return (
                      <div {...props} className="td">
                        {cell.render('Cell', { readOnly })}
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({
  time: { timeColumns, times, periodClosed, timestamps, onlyDisplay },
  lock: { areTimesLocked },
}) => ({
  timeColumns,
  times,
  periodClosed,
  timestamps,
  areTimesLocked,
  onlyDisplay,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type, props) => dispatch(showModal(type, props)),
  setEditingCell: (cell) => dispatch(setEditingCell(cell)),
  addEmptyTime: () => dispatch(addEmptyTime()),
  setRemainingHoursInDay: (index, column, parent) =>
    dispatch(setRemainingHoursInDay(index, column, parent)),
  setRemainingHoursForMonth: (index, parent) =>
    dispatch(setRemainingHoursForMonth(index, parent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StundenTable);

export const clearOrReplaceLocalStorage = (version) => {
  if (
    !localStorage.getItem('version_app') ||
    localStorage.getItem('version_app') !== version
  ) {
    localStorage.setItem('version_app', version);
    return true;
  }
  return false;
};

import {
  FETCH_KOKRS_START,
  FETCH_KOKRS_SUCCESS,
  FETCH_KOKRS_FAILURE,
  FETCHED_KOKRS,
  CLEAR_ERRORS,
} from './kokrsTypes';

const initialState = {
  kokrs: [],
  error: null,
  isFetchingKokrs: false,
  hasFetchedKokrs: false,
};

const kokrsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KOKRS_START:
      return {
        ...state,
        isFetchingKokrs: true,
        error: null,
      };
    case FETCH_KOKRS_SUCCESS:
      return {
        ...state,
        isFetchingKokrs: false,
        hasFetchedKokrs: true,
        kokrs: action.payload.kokrs,
        error: null,
      };
    case FETCH_KOKRS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        hasFetchedKokrs: false,
        isFetchingKokrs: false,
        kokrs: [],
      };
    case FETCHED_KOKRS:
      return {
        ...state,
        hasFetchedKokrs: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default kokrsReducer;

import React from 'react';

const AddAbwesenIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='17'
      viewBox='0 0 18 17'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#7B797A' fillRule='nonzero' transform='translate(-431 -644)'>
          <path d='M445.682 645.466c-.044-.596-.412-.92-1.047-.92h-6.278c-.635 0-.997.324-1.047.92h8.372zm1.25 1.917c-.082-.628-.418-.996-1.136-.996h-8.6c-.718 0-1.048.368-1.137.996h10.874zm-.679 12.143c1.41 0 2.171-.749 2.171-2.145v-6.786c0-1.396-.761-2.152-2.17-2.152h-9.528c-1.416 0-2.165.75-2.165 2.152v2.482a3.87 3.87 0 01.717-.07c.248 0 .495.026.737.076v-2.33c0-.577.292-.856.844-.856h9.267c.54 0 .845.28.845.857v6.468c0 .578-.305.85-.845.85h-6.74a3.572 3.572 0 01-.566 1.454h7.433zm-10.975 1.022c1.797 0 3.307-1.504 3.307-3.313 0-1.81-1.498-3.3-3.307-3.3s-3.307 1.49-3.307 3.3c0 1.815 1.498 3.313 3.307 3.313zm.007-1.142c-.26 0-.495-.203-.495-.47v-1.225h-1.156a.483.483 0 01-.47-.476c0-.254.216-.47.47-.47h1.156v-1.225c0-.28.228-.47.495-.47.26 0 .488.19.488.47v1.225h1.15c.26 0 .47.21.47.47 0 .254-.21.476-.47.476h-1.15v1.225c0 .267-.228.47-.488.47z'></path>
        </g>
      </g>
    </svg>
  );
};

export default AddAbwesenIcon;

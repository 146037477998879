import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import StundenKontrolle from '../../zeit/stunden/StundenKontrolle';
import { message, Result, Button } from 'antd';
import Abwesenheiten from '../../zeit/abwesen/Abwesenheiten';
import { ReloadOutlined } from '@ant-design/icons';
import $ from 'jquery';

import { saveTimesFailure, fetchTimes } from '../../../redux/time/timeActions';

const ZeitContainer = ({
  saveTimesFailure,
  errorMessages,
  loading,
  errorFetchingTimes,
  auth,
  currentMonth,
  fetchTimes,
}) => {
  useEffect(() => {
    if (!loading) {
      // Synchronize two horizontal scrolls of Abwesen/Stunden tables.
      $(function () {
        $('#abwesenTable').on('scroll', function () {
          $('#stundenTable').scrollLeft($(this).scrollLeft());
        });
        $('#stundenTable').on('scroll', function () {
          $('#abwesenTable').scrollLeft($(this).scrollLeft());
        });
      });
    }
  }, [loading]);

  const refreshPage = () => {
    fetchTimes(auth, currentMonth);
    // window.location.reload();
  };

  useEffect(() => {
    if (errorMessages) {
      let warningMsg = '';
      let errorMsg = '';

      if (Array.isArray(errorMessages)) {
        errorMessages.forEach((message) => {
          if (message.type.toLowerCase() === 'w') {
            warningMsg += message.message + '; ';
          } else if (message.type.toLowerCase() === 'e') {
            errorMsg += message.message + '; ';
          }
        });

        if (warningMsg !== '') {
          message.warning(warningMsg, 3).then(() => saveTimesFailure(null));
        }

        if (errorMsg !== '') {
          message.error(errorMsg, 3).then(() => saveTimesFailure(null));
        }
      } else {
        message.error(errorMessages, 3).then(() => saveTimesFailure(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages]);

  return (
    <Fragment>
      {!errorFetchingTimes ? (
        <div
          className='stundenContainer'
          style={{ margin: '0px 20px 20px 20px' }}
        >
          <StundenKontrolle />
          <div style={{ marginTop: '40px', paddingBottom: '20px' }}>
            <Abwesenheiten />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '60vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Result
            icon={<ReloadOutlined />}
            title={errorFetchingTimes}
            subTitle='Looks like something went wrong. Please check your connection and try again!'
            extra={[
              <Button type='primary' key='reload' onClick={refreshPage}>
                Try Again
              </Button>,
            ]}
          />
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = ({
  auth,
  time: { errorMessages, loading, errorFetchingTimes, currentMonth },
}) => ({
  errorMessages,
  loading,
  errorFetchingTimes,
  auth,
  currentMonth,
});

const mapDispatchToProps = (dispatch) => ({
  saveTimesFailure: (messages) => dispatch(saveTimesFailure(messages)),
  fetchTimes: (auth, month) => dispatch(fetchTimes(auth, month)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ZeitContainer);

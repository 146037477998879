import {
  FETCH_PERSON_PROFIL_START,
  FETCH_PERSON_PROFIL_SUCCESS,
  FETCH_PERSON_PROFIL_FAILURE,
  HAS_FETCHED_PERSON_PROFIL,
  CLEAR_ERROR,
  FETCH_EMPLOYEES_START,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_PERSA_START,
  FETCH_PERSA_SUCCESS,
  FETCH_PERSA_FAILURE,
  FETCH_BTRTL_START,
  FETCH_BTRTL_SUCCESS,
  FETCH_BTRTL_FAILURE,
  RESET_STATE_PERSON,
  RESET_BTRTL_LIST,
  CLEAR_LOCAL_EMPLOYEES,
  FETCH_EMPLOYEE_START,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
} from './personTypes';

const initialState = {
  profiles: [],
  employees: [],
  localEmployees: [],
  persaList: [],
  btrtlList: [],
  error: null,
  isFetchingProfiles: false,
  hasFetchedProfiles: false,
  isFetchingEmployees: false,
  hasFetchedEmployees: false,
  isFetchingPersa: false,
  hasFetchedPersa: false,
  isFetchingBtrtl: false,
  hasFetchedBtrtl: false,
  persaError: null,
  btrtlError: null,
};

const personReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PERSON_PROFIL_START:
      return {
        ...state,
        isFetchingProfiles: true,
        error: null,
      };
    case FETCH_PERSON_PROFIL_SUCCESS:
      return {
        ...state,
        profiles: action.payload.persons,
        error: null,
        hasFetchedProfiles: true,
        isFetchingProfiles: false,
      };
    case FETCH_PERSON_PROFIL_FAILURE:
      return {
        ...state,
        profiles: [],
        error: action.payload.error,
        hasFetchedProfiles: false,
        isFetchingProfiles: false,
      };
    case HAS_FETCHED_PERSON_PROFIL:
      return {
        ...state,
        hasFetchedProfiles: action.payload.fetched,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        persaError: null,
        btrtlError: null,
      };
    case FETCH_EMPLOYEES_START:
      return {
        ...state,
        isFetchingEmployees: true,
        error: null,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        isFetchingEmployees: false,
        hasFetchedEmployees: true,
        error: null,
        employees: action.payload.isFromSearchHelp
          ? action.payload.employees
          : [...state.employees],
        localEmployees: action.payload.isFromSearchHelp
          ? [...state.localEmployees]
          : action.payload.employees,
      };
    case FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        employees: [],
        isFetchingEmployees: false,
        hasFetchedEmployees: false,
        error: action.payload.error,
      };
    case FETCH_PERSA_START:
      return {
        ...state,
        persaList: [],
        isFetchingPersa: true,
        persaError: null,
      };
    case FETCH_PERSA_SUCCESS:
      return {
        ...state,
        persaList: action.payload.persaList,
        persaError: null,
        hasFetchedPersa: true,
        isFetchingPersa: false,
      };
    case FETCH_PERSA_FAILURE:
      return {
        ...state,
        persaList: [],
        isFetchingPersa: false,
        hasFetchedPersa: false,
        persaError: action.payload.error,
      };
    case FETCH_BTRTL_START:
      return {
        ...state,
        btrtlList: [],
        isFetchingBtrtl: true,
        btrtlError: null,
      };
    case FETCH_BTRTL_SUCCESS:
      return {
        ...state,
        btrtlList: action.payload.btrtlList,
        btrtlError: null,
        hasFetchedBtrtl: true,
        isFetchingBtrtl: false,
      };
    case FETCH_BTRTL_FAILURE:
      return {
        ...state,
        btrtlList: [],
        isFetchingBtrtl: false,
        hasFetchedBtrtl: false,
        btrtlError: action.payload.error,
      };
    case RESET_STATE_PERSON:
      return {
        ...state,
        employees: [],
        persaList: [],
        btrtlList: [],
        error: null,
        isFetchingProfiles: false,
        hasFetchedProfiles: false,
        isFetchingEmployees: false,
        hasFetchedEmployees: false,
        isFetchingPersa: false,
        hasFetchedPersa: false,
        isFetchingBtrtl: false,
        hasFetchedBtrtl: false,
        persaError: null,
        btrtlError: null,
      };
    case CLEAR_LOCAL_EMPLOYEES:
      return {
        ...state,
        localEmployees: [],
      };
    case RESET_BTRTL_LIST:
      return {
        ...state,
        btrtlList: [],
      };
    case FETCH_EMPLOYEE_START:
      return {
        ...state,
        isFetchingEmployees: true,
        hasFetchedEmployees: false,
        error: null,
        employees: [],
      };
    case FETCH_EMPLOYEE_FAILURE:
      return {
        ...state,
        isFetchingEmployees: false,
        hasFetchedEmployees: false,
        error: action.payload.error,
        employees: [],
      };
    case FETCH_EMPLOYEE_SUCCESS:
      return {
        ...state,
        localEmployees: [action.payload.employee],
        isFetchingEmployees: false,
        hasFetchedEmployees: true,
        error: null,
      };
    default:
      return state;
  }
};

export default personReducer;

import React, { useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';

import stundenColumnType from './stundenColumTypes';
import { STUNDEN } from '../../modals/modalParentTypes';

import { showModal } from '../../../redux/modals/modalActions';

import {
  updateTime,
  addEmptyTime,
  setAreTimesSaved,
  updateCostCenterOrWbs,
  updateWageType,
  filterTimesFromHours,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
} from '../../../redux/time/timeActions';

import { setHasFetchedPspKost } from '../../../redux/psp-kost/pspKostActions';
import { setHasFetchedWageType } from '../../../redux/wage-type/wageTypeActions';

import StundenTable from './StundenTable';
import EditableCell from '../../editable-cells/EditableCell';
import EditableSearchCell from '../../editable-cells/EditableSearchCell';

import StylesComponent from '../../pages/zeit/ZeitStyles';
import styles from './StundenKontrolle.module.css';
import { message } from 'antd';

const StundenKontrolle = ({
  times,
  timeColumns,
  total,
  regularHours,
  frozenColumns,
  updateTime,
  pspKost,
  wageType,
  updateCostCenterOrWbs,
  updateWageType,
  hasFetchedPspKost,
  setHasFetchedPspKost,
  hasFetchedWageType,
  setHasFetchedWageType,
  editedPspKostCell,
  editedWageTypeCell,
  pspKostError,
  wageError,
  filterTimesFromHours,
  absences,
  oldPspValue,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
  periodClosed,
  areTimesLocked,
  onlyDisplay,
}) => {
  const { kostlOrPosid, kostlOrPosidText, lgart, lgtxt, unit, currency, sum } =
    stundenColumnType;

  useEffect(() => {
    if (hasFetchedPspKost) {
      if (pspKostError) {
        message.error(pspKostError);
      } else {
        const { costCenterOrWbs, text } = pspKost;
        updateCostCenterOrWbs(
          editedPspKostCell.row.index - 2,
          costCenterOrWbs,
          text,
          STUNDEN,
          '',
          oldPspValue
        );
      }
      setErrorOnEditableSearchCell(
        kostlOrPosid,
        editedPspKostCell.row.index - 2,
        pspKostError ? false : true,
        STUNDEN
      );
      setLoadingOnEditableSearchCell(
        kostlOrPosid,
        editedPspKostCell.row.index - 2,
        false,
        STUNDEN
      );
      setHasFetchedPspKost(false);
    } else if (hasFetchedWageType) {
      if (wageError) {
        message.error(wageError);
      } else {
        const { lgart, lgtxt, unit, currency, isTravel } = wageType;
        updateWageType(
          editedWageTypeCell.row.index - 2,
          lgart,
          lgtxt,
          unit,
          currency,
          isTravel
        );
      }
      setErrorOnEditableSearchCell(
        lgart,
        editedWageTypeCell.row.index - 2,
        wageError ? false : true,
        STUNDEN
      );
      setLoadingOnEditableSearchCell(
        lgart,
        editedWageTypeCell.row.index - 2,
        false,
        STUNDEN
      );
      setHasFetchedWageType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPspKost, hasFetchedWageType]);

  const getMonthColumns = () => {
    //const columnId = cellInfo.cell.column.id;
    let tempColumns = [];

    for (const key of Object.keys(timeColumns)) {
      const names = Object.freeze(['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']);

      if (moment(key).isValid()) {
        let date = moment(key).toDate();

        const column = {
          accessor: key,
          Header: (props) => {
            return (
              <div style={{ textAlign: 'center', color: '#1890FF' }}>
                {names[date.getDay()]}
                <br />
                {('0' + date.getDate()).slice(-2)}
              </div>
            );
          },
          width: 58,
          Cell: (cellInfo) => {
            return cellInfo.row.index !== 0 && cellInfo.row.index !== 1 ? (
              <EditableCell
                initialValue={cellInfo.value}
                {...cellInfo}
                alignCenter={true}
                showModalOnDoubleClick={true}
                allowOnlyNumbers={true}
                parent={STUNDEN}
                filterTimes={filterTimes}
                readOnly={
                  cellInfo.readOnly ||
                  periodClosed ||
                  areTimesLocked ||
                  onlyDisplay
                }
                isStunden={true}
              />
            ) : (
              <div style={{ textAlign: 'center' }}>
                {parseFloat(cellInfo.value === '' ? 0 : cellInfo.value) === 0
                  ? ''
                  : parseFloat(
                      cellInfo.value === '' ? 0 : cellInfo.value
                    ).toFixed(2)}
              </div>
            );
          },
        };

        tempColumns = [...tempColumns, column];
      }
    }

    return tempColumns;
  };

  const updateMyData = (rowIndex, columnId, value, parent = STUNDEN) => {
    if (rowIndex > 1 && parent === STUNDEN) {
      updateTime(rowIndex - 2, columnId, value);
      if (value === '') {
        if (columnId === lgart) {
          updateWageType(rowIndex - 2, '', '', '', '', false);
        } else if (columnId === kostlOrPosid) {
          updateCostCenterOrWbs(rowIndex - 2, '', '', parent, '', oldPspValue);
        }
      }
    }
  };

  const filterTimes = (hoursDate, mergedHoursObject) => {
    filterTimesFromHours(hoursDate, mergedHoursObject);
  };

  const selectedCostCenterOrWbs = (cell, costCenterOrWbs, text) => {
    updateCostCenterOrWbs(cell.row.index - 2, costCenterOrWbs, text);
  };

  const selectedWageType = (cell, lgart, lgtxt, unit, currency, isTravel) => {
    updateWageType(cell.row.index - 2, lgart, lgtxt, unit, currency, isTravel);
  };

  const columnsMemo = useMemo(
    () => [
      {
        accessor: kostlOrPosid,
        Header: <div className={styles.headerStyle}>PSP/Kost</div>,
        width: 90,
        sticky: frozenColumns ? 'left' : '',
        Cell: (cellInfo, props) =>
          !timeColumns[kostlOrPosid].isReadonly &&
          cellInfo.row.index !== 0 &&
          cellInfo.row.index !== 1 ? (
            <EditableSearchCell
              initialValue={cellInfo.value}
              {...cellInfo}
              updateMyData={updateMyData}
              maxLength={24} //limit PSP/Kost to 24 characters
              selectedCostCenterOrWbs={selectedCostCenterOrWbs}
              readOnly={
                periodClosed ||
                cellInfo.readOnly ||
                areTimesLocked ||
                onlyDisplay
              }
            />
          ) : (
            cellInfo.value
          ),
      },
      {
        accessor: kostlOrPosidText,
        sticky: frozenColumns ? 'left' : '',
        Header: <div className={styles.headerStyle}>PSP/Kost.-Langtext</div>,
        width: 240,
        Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
      },
      {
        accessor: lgart,
        sticky: frozenColumns ? 'left' : '',
        Header: <div className={styles.headerStyle}>Lohnart</div>,
        width: 70,
        Cell: (cellInfo) =>
          !timeColumns[lgart].isReadonly &&
          cellInfo.row.index !== 0 &&
          cellInfo.row.index !== 1 ? (
            <EditableSearchCell
              initialValue={cellInfo.value}
              {...cellInfo}
              updateMyData={updateMyData}
              selectedWageType={selectedWageType}
              readOnly={
                cellInfo.readOnly ||
                periodClosed ||
                areTimesLocked ||
                onlyDisplay
              }
            />
          ) : (
            cellInfo.value
          ),
      },
      {
        accessor: lgtxt,
        sticky: frozenColumns ? 'left' : '',
        Header: <div className={styles.headerStyle}>Lohnart-Langtext</div>,
        width: 170,
        Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
      },
      {
        accessor: unit,
        sticky: frozenColumns ? 'left' : '',
        Header: <div className={styles.headerStyle}>ME</div>,
        width: 40,
        Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
      },
      {
        accessor: currency,
        sticky: frozenColumns ? 'left' : '',
        Header: <div className={styles.headerStyle}>Wäh.</div>,
        width: 50,
        Cell: (cellInfo) => <Fragment>{cellInfo.value}</Fragment>,
      },
      {
        accessor: sum,
        sticky: frozenColumns ? 'left' : '',
        Header: (
          <div
            className={styles.headerStyle}
            style={{ justifyContent: 'flex-end' }}
          >
            Summe
          </div>
        ),
        width: 70,
        Cell: (cellInfo) => (
          <div style={{ textAlign: 'right' }}>
            <div style={{ textAlign: 'center' }}>
              <div style={{ textAlign: 'right' }}>
                {parseFloat(
                  cellInfo.row.cells
                    .map((cell) => ({
                      value: cell.value === '' ? '0.00' : cell.value,
                      id: cell.column.id,
                    }))
                    .filter(
                      (cell) =>
                        !isNaN(cell.value) &&
                        moment(cell.id, 'YYYY-MM-DD').isValid()
                    )
                    .reduce((sum, data) => Number(sum) + Number(data.value), 0)
                ).toFixed(2)}
              </div>
            </div>
          </div>
        ),
      },
      ...getMonthColumns(),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [frozenColumns]
  );

  const dataMemo = useMemo(() => {
    const regularHoursData = Object.assign(
      {
        kostlOrPosid: '',
        kostlOrPosidText: '',
        lgart: '',
        lgtxt: 'Normalarbeitszeit',
        unit: 'H',
        currency: '',
      },
      ...Object.entries(regularHours).map(([k, v]) => {
        return moment(k).isValid() ? { [k]: v.quantity } : { [k]: v };
      })
    );

    const totalData = Object.assign(
      {
        kostlOrPosid: '',
        kostlOrPosidText: '',
        lgart: '',
        lgtxt: 'Summenzeile',
        unit: 'H',
        currency: '',
      },
      ...Object.entries(total).map(([k, v]) => {
        return moment(k).isValid() ? { [k]: v.quantity } : { [k]: v };
      })
    );

    let timesData = times.map((time, index) => {
      const {
        kostlOrPosid,
        kostlOrPosidText,
        lgart,
        lgtxt,
        sum,
        unit,
        currency,
        cells,
      } = time;

      const timeCells = Object.assign(
        {},
        ...Object.entries(cells).map(([k, v]) => {
          return {
            [k]: parseFloat(v.quantity === '' ? 0 : v.quantity).toFixed(2),
          };
        })
      );

      return {
        kostlOrPosid,
        kostlOrPosidText,
        lgart,
        lgtxt,
        sum,
        unit,
        currency,
        ...timeCells,
      };
    });

    return [regularHoursData, totalData, ...timesData];

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, absences, total, regularHours]);

  return (
    <StylesComponent>
      <StundenTable
        columns={columnsMemo}
        data={dataMemo || []}
        filterTimes={filterTimes}
        updateMyData={updateMyData}
      />
    </StylesComponent>
  );
};

const mapStateToProps = ({
  time: {
    times,
    timeColumns,
    regularHours,
    total,
    frozenColumns,
    savedTimes,
    changedTimesData,
    hasSelectedPspKost,
    hasSelectedWageType,
    isUpdatedFromStunden,
    absences,
    periodClosed,
    onlyDisplay,
  },
  pspKost: {
    hasFetchedPspKost,
    pspKost,
    editedPspKostCell,
    error: pspKostError,
    oldPspValue,
  },
  wageType: {
    hasFetchedWageType,
    wageType,
    editedWageTypeCell,
    error: wageError,
  },
  lock: { areTimesLocked },
}) => ({
  times,
  timeColumns,
  regularHours,
  total,
  frozenColumns,
  savedTimes,
  changedTimesData,
  editedPspKostCell,
  editedWageTypeCell,
  hasSelectedPspKost,
  hasFetchedPspKost,
  pspKost,
  wageType,
  hasFetchedWageType,
  hasSelectedWageType,
  pspKostError,
  wageError,
  isUpdatedFromStunden,
  absences,
  oldPspValue,
  periodClosed,
  areTimesLocked,
  onlyDisplay,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type) => dispatch(showModal(type)),
  updateTime: (rowIndex, columnId, value) =>
    dispatch(updateTime(rowIndex, columnId, value)),
  addEmptyTime: () => dispatch(addEmptyTime()),
  setAreTimesSaved: (savedTimes) => dispatch(setAreTimesSaved(savedTimes)),
  setHasFetchedPspKost: (hasFetchedPspKost) =>
    dispatch(setHasFetchedPspKost(hasFetchedPspKost)),
  setHasFetchedWageType: (hasFetchedWageType) =>
    dispatch(setHasFetchedWageType(hasFetchedWageType)),
  updateCostCenterOrWbs: (
    rowIndex,
    costCenterOrWbs,
    text,
    parent,
    workdate,
    oldPspValue
  ) =>
    dispatch(
      updateCostCenterOrWbs(
        rowIndex,
        costCenterOrWbs,
        text,
        parent,
        workdate,
        oldPspValue
      )
    ),
  updateWageType: (rowIndex, lgart, lgtxt, unit, currency, isTravel) =>
    dispatch(updateWageType(rowIndex, lgart, lgtxt, unit, currency, isTravel)),
  filterTimesFromHours: (workdate, mergedHours) =>
    dispatch(filterTimesFromHours(workdate, mergedHours)),
  setErrorOnEditableSearchCell: (columnId, rowIndex, isValid, parent) =>
    dispatch(setErrorOnEditableSearchCell(columnId, rowIndex, isValid, parent)),
  setLoadingOnEditableSearchCell: (
    columnId,
    rowIndex,
    isLoading,
    parent,
    workdate
  ) =>
    dispatch(
      setLoadingOnEditableSearchCell(
        columnId,
        rowIndex,
        isLoading,
        parent,
        workdate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(StundenKontrolle);

import { SAVE_SETTINGS } from './settingsTypes';
import { PURGE } from 'redux-persist';

import produce from 'immer';

import { System, ClientCode } from '../../constants/system';

const initialState = {
  systems: [System[process.env.REACT_APP_CLIENT]],
  selectedSystem: System[process.env.REACT_APP_CLIENT],
  client: ClientCode[process.env.REACT_APP_CLIENT],
};

const settingsReducer = produce((draft = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SAVE_SETTINGS: {
      draft = { ...payload };
      return draft;
    }
    case PURGE: {
      return {
        systems: [System[process.env.REACT_APP_CLIENT]],
        selectedSystem: System[process.env.REACT_APP_CLIENT],
        client: ClientCode[process.env.REACT_APP_CLIENT],
      };
    }
    default: {
      return draft;
    }
  }
});

export default settingsReducer;

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Input, message } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import { showModal } from '../../../../../redux/modals/modalActions';
import {
  fetchEmployees,
  clearErrors,
} from '../../../../../redux/person-aus/personActions';

import EmployeesModalStyle from './EmployeesModalStyle';

import { EMPLOYEES, PERSA_BTRTL } from '../../../modalTypes';

import styles from './EmployeesModal.module.css';
import EmployeesModalTable from './EmployeesModalTable';
import Modal from 'antd/lib/modal/Modal';
import { BTRTL, PERSA } from '../persa-btrtl/PersaBtrtlTypes';

const { Search } = Input;

const EmployeesModal = ({
  auth,
  loading,
  showModal,
  fetchEmployees,
  employees,
  isFetchingEmployees,
  hasFetchedEmployees,
  error,
  currentModals,
  hideModal,
  clearErrors,
  currentMonth,
  selectedPerson,
  resetState,
}) => {
  const [searchData, setSearchData] = useState({
    vorna: '',
    nachn: '',
    persa: '',
    btrtl: '',
  });

  useEffect(() => {
    if (error) {
      message.error(error);
      clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columns] = useState([
    {
      Header: 'Nachname',
      accessor: 'lastName',
    },
    {
      Header: 'Vorname',
      accessor: 'firstName',
    },
    {
      Header: 'PersNr',
      accessor: 'employeeNumber',
      width: 70,
    },
    {
      Header: 'PBer',
      accessor: 'personnelArea',
      width: 50,
    },
    {
      Header: 'TeilBer.',
      accessor: 'personnelSubArea',
      width: 50,
    },
  ]);

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onSearch = () => {
    fetchEmployees(auth, searchData, currentMonth);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };
  // eslint-disable-next-line no-unused-vars
  const selectedBukrs = (bukrs) => {
    setSearchData({ ...searchData, bukrs });
  };

  const handleOk = () => {
    hideModal(EMPLOYEES);
    resetState();
  };

  const handleCancel = () => {
    hideModal(EMPLOYEES);
    resetState();
  };

  const onRowSelect = (row) => {
    hideModal(EMPLOYEES);
    selectedPerson(row);
    resetState();
  };

  const onSelectedPersa = (row) => {
    setSearchData({ ...searchData, persa: row.values.persa });
  };

  const onSelectedBtrtl = (row) => {
    setSearchData({ ...searchData, btrtl: row.values.btrtl });
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(EMPLOYEES) ? true : false}
      title='Suchhilfe für Personalnummer'
      onOk={handleOk}
      onCancel={handleCancel}
      width={'55vw'}
      closable={true}
      footer={null}
    >
      <Fragment>
        <div className={styles.employeeHeader}>
          <div className={styles.row1}>
            <Input
              placeholder='Nachname'
              className={styles.nachna}
              name='nachn'
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
            <Input
              placeholder='Vorname'
              className={styles.vorna}
              name='vorna'
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
          </div>
          <div className={styles.row2}>
            <Search
              placeholder='Personalbereich'
              className={styles.persa}
              name='persa'
              value={searchData.persa}
              onKeyDown={onKeyDown}
              onChange={onChange}
              onSearch={(value, e) => {
                if (e.keyCode === 13) {
                  e.target.blur();
                  onSearch();
                } else {
                  showModal(PERSA_BTRTL, { onSelectedPersa, TYPE: PERSA });
                }
              }}
            />
            <Search
              placeholder='Personalteilbereich'
              className={styles.btrlt}
              name='btrtl'
              value={searchData.btrtl}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onSearch={(value, e) => {
                if (e.keyCode === 13) {
                  e.target.blur();
                  onSearch();
                } else {
                  showModal(PERSA_BTRTL, { onSelectedBtrtl, TYPE: BTRTL });
                }
              }}
            />
            <Button
              type='primary'
              className={styles.searchBtn}
              icon={<SearchOutlined />}
              loading={isFetchingEmployees}
              onClick={onSearch}
            >
              Suchen
            </Button>
          </div>
        </div>
        <EmployeesModalStyle>
          <EmployeesModalTable
            columns={columnsMemo}
            data={employees || []}
            onRowSelect={onRowSelect}
          />
        </EmployeesModalStyle>
      </Fragment>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  personProfiles: {
    employees,
    error,
    hasFetchedEmployees,
    isFetchingEmployees,
  },
  time: { currentMonth },
}) => ({
  auth,
  employees,
  error,
  hasFetchedEmployees,
  isFetchingEmployees,
  currentMonth,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type, props) => dispatch(showModal(type, props)),
  fetchEmployees: (auth, searchData, month) =>
    dispatch(fetchEmployees(auth, searchData, month)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesModal);

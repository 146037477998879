import React from 'react';
import { Checkbox } from 'antd';

import styles from './IndeterminateCheckbox.module.css';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className={styles.checkboxContainer}>
        <Checkbox ref={resolvedRef} {...rest} />
      </div>
    );
  }
);

export default IndeterminateCheckbox;

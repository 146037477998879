import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Redirect to='/' /> : <Component {...props} />
      }
    />
  );
};

const mapStateToProps = ({ auth: { isAuthenticated, loading } }) => ({
  isAuthenticated,
  loading,
});

export default connect(mapStateToProps)(PrivateRoute);

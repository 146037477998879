import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Button, Input, message } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import PSPElementTable from './PSPElementTable';

import {
  fetchPspElements,
  setHasFetchedPspElements,
} from '../../../../../redux/psp-kost/pspKostActions';
import { showModal } from '../../../../../redux/modals/modalActions';
import { BUKRS } from '../../../modalTypes';

import Styles from '../PSPKostTableStyle';

import styles from './PSPElement.module.css';

const { Search } = Input;

const PSPElement = ({
  auth,
  loading,
  pspElements,
  fetchPspElements,
  hasFetchedPspElements,
  setHasFetchedPspElements,
  cell,
  selectedCostCenterOrWbs,
  showModal,
}) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    posid: '',
    bukrs: '',
    post1: '',
  });

  const { posid, bukrs, post1 } = searchData;

  const [columns] = useState([
    {
      Header: 'PSP-Element',
      accessor: 'posid',
      width: 30,
    },
    {
      Header: 'Buchungskreis',
      accessor: 'bukrs',
      width: 32,
    },
    {
      Header: 'PSP-Bezeichnung',
      accessor: 'post1',
    },
  ]);

  useEffect(() => {
    if (hasFetchedPspElements) {
      setData(pspElements);
      setHasFetchedPspElements(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPspElements]);

  useEffect(() => {
    for (let i = 1; i <= 10; i++) {
      let row = {
        id: i,
        posid: '',
        bukrs: '',
        post1: '',
      };
      setData((data) => [...data, row]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onSearch = () => {
    if (posid === '' && bukrs === '' && post1 === '') {
      message.warning('Bitte fullen Sie mindestens eines der Felder aus!', 3);
    } else {
      fetchPspElements(auth, {
        posid,
        bukrs,
        post1,
      });
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };

  const selectedBukrs = (bukrs) => {
    setSearchData({ ...searchData, bukrs });
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Fragment>
      <div className={styles.pspHeader}>
        <div className={styles.row1}>
          <Input
            placeholder='PSP-Element'
            className={styles.posid}
            name='posid'
            maxLength={8}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Search
            placeholder='Buchungskreis'
            className={styles.bukrs}
            name='bukrs'
            maxLength={4}
            value={searchData.bukrs}
            onChange={onChange}
            onSearch={(value, e) => {
              if (e.keyCode === 13) {
                e.target.blur();
                onSearch();
              } else {
                showModal(BUKRS, {
                  selectedBukrs,
                });
              }
            }}
          />
        </div>
        <div className={styles.row2}>
          <Input
            placeholder='PSP-Bezeichnung'
            className={styles.post1}
            name='post1'
            maxLength={40}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Button
            type='primary'
            className={styles.searchBtn}
            icon={<SearchOutlined />}
            loading={loading}
            onClick={onSearch}
          >
            Suchen
          </Button>
        </div>
      </div>
      <Styles>
        <PSPElementTable
          columns={columnsMemo}
          data={data}
          cell={cell}
          selectedCostCenterOrWbs={selectedCostCenterOrWbs}
        />
      </Styles>
    </Fragment>
  );
};

const mapStateToProps = ({
  auth,
  pspKost: { pspElements, loading, hasFetchedPspElements },
}) => ({
  auth,
  pspElements,
  loading,
  hasFetchedPspElements,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPspElements: (auth, searchData) =>
    dispatch(fetchPspElements(auth, searchData)),
  setHasFetchedPspElements: (fetched) =>
    dispatch(setHasFetchedPspElements(fetched)),
  showModal: (type, props) => dispatch(showModal(type, props)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PSPElement);

import React, { Fragment, useState } from 'react';
import { Button, Input, message, Select } from 'antd';

import { uniqueId } from '../../utils/uniqueId';

import styles from './SystemDetails.module.css';

const { Option } = Select;

const SystemDetails = ({
  setIsSystemDetailsVisible,
  isNewSystem = true,
  system,
  setTempSystems,
  tempSelectedSystem,
  setTempSelectedSystem,
}) => {
  const [bezeichnung, setBezeichnung] = useState(
    isNewSystem ? '' : system.description
  );
  const [protocol, setProtocol] = useState(
    isNewSystem ? 'http://' : system.protocol
  );
  const [ipAdresse, setIpAdresse] = useState(isNewSystem ? '' : system.ip);

  const saveSystem = () => {
    if (bezeichnung === '' || ipAdresse === '') {
      message.warning('Bitte füllen Sie alle Felder aus');
    } else {
      if (isNewSystem) {
        setTempSystems((old) => {
          return [
            ...old,
            {
              id: uniqueId(),
              description: bezeichnung,
              protocol,
              ip: ipAdresse,
            },
          ];
        });
      } else {
        setTempSystems((old) =>
          old.map((oldSystem) =>
            oldSystem.id === system.id
              ? {
                  ...oldSystem,
                  description: bezeichnung,
                  protocol,
                  ip: ipAdresse,
                }
              : oldSystem
          )
        );
        if (tempSelectedSystem.id === system.id) {
          setTempSelectedSystem({
            ...tempSelectedSystem,
            description: bezeichnung,
            protocol,
            ip: ipAdresse,
          });
        }
      }
      setIsSystemDetailsVisible(false);
    }
  };

  const handleDescriptionChange = (e) => {
    setBezeichnung(e.target.value);
  };

  const handleIpAdresseChange = (e) => {
    setIpAdresse(e.target.value);
  };

  const handleProtocolChange = (value) => {
    setProtocol(value);
  };

  const handleOnKeyDown = (e) => {
    e.stopPropagation();
    if (e.key === 'Enter' || e.keyCode === 13) {
      saveSystem();
    }
  };

  const selectBefore = (
    <Select
      defaultValue={protocol}
      value={protocol}
      className='select-before'
      onChange={handleProtocolChange}
    >
      <Option value='http://'>http://</Option>
      <Option value='https://'>https://</Option>
    </Select>
  );

  return (
    <Fragment>
      <h3 className={styles.title}>
        {isNewSystem ? 'Neue System' : 'System bearbeiten'}
      </h3>
      <form className={styles.form}>
        <div className={styles.formItem}>
          <Input
            placeholder='Bezeichnung'
            value={bezeichnung}
            onChange={handleDescriptionChange}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <div className={styles.formItem}>
          <Input
            addonBefore={selectBefore}
            placeholder='
Domain / IP Adresse'
            value={ipAdresse}
            onChange={handleIpAdresseChange}
            onKeyDown={handleOnKeyDown}
          />
        </div>
        <div className={styles.formSubmit}>
          <Button
            type='default'
            onClick={() => setIsSystemDetailsVisible(false)}
          >
            Abbrechen
          </Button>
          <Button type='primary' onClick={saveSystem}>
            Fertig
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default SystemDetails;

import API from '../../api/api';

import {
  FETCHED_ABSENCE_TYPE,
  FETCH_ABSENCE_TYPE_START,
  FETCH_ABSENCE_TYPE_FAILURE,
  FETCH_ABSENCE_TYPE_SUCCESS,
  FETCH_ABSENCE_TYPES_START,
  FETCH_ABSENCE_TYPES_FAILURE,
  FETCH_ABSENCE_TYPES_SUCCESS,
  CLEAR_ERRORS,
} from './absenceTypeTypes';

export const fetchAbsenceTypeStart = () => {
  return {
    type: FETCH_ABSENCE_TYPE_START,
  };
};

export const fetchAbsenceTypeFailure = (
  error,
  absenceType,
  cellBeingEdited
) => {
  return {
    type: FETCH_ABSENCE_TYPE_FAILURE,
    payload: { error, absenceType, cellBeingEdited },
  };
};

export const fetchAbsenceTypeSuccess = (absenceType, cellBeingEdited) => {
  return {
    type: FETCH_ABSENCE_TYPE_SUCCESS,
    payload: { absenceType, cellBeingEdited },
  };
};

export const setHasFetchedAbsenceType = (fetched) => {
  return {
    type: FETCHED_ABSENCE_TYPE,
    payload: fetched,
  };
};

export const fetchAbsenceTypesStart = () => {
  return {
    type: FETCH_ABSENCE_TYPES_START,
  };
};

export const fetchAbsenceTypesFailure = (error, absencetypes) => {
  return {
    type: FETCH_ABSENCE_TYPES_FAILURE,
    payload: error,
  };
};

export const fetchAbsenceTypesSuccess = (absenceTypes) => {
  return {
    type: FETCH_ABSENCE_TYPES_SUCCESS,
    payload: absenceTypes,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const fetchAbsenceType = (auth, month, searchData, cellBeingEdited) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchAbsenceTypeStart());

    try {
      const res = await API.get('/SIF/time/absence_type', config);
      dispatch(fetchAbsenceTypeSuccess(res.data, cellBeingEdited));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(
          fetchAbsenceTypeFailure(msg, searchData.absence_type, cellBeingEdited)
        );
      } else {
        dispatch(
          fetchAbsenceTypeFailure(
            'Ein unbekannter Fehler ist aufgetreten',
            searchData.absence_type,
            cellBeingEdited
          )
        );
      }
    }
  };
};

export const fetchAbsenceTypes = (auth, month) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchAbsenceTypesStart());

    try {
      const res = await API.get('/SIF/time/absence_types', config);
      dispatch(fetchAbsenceTypesSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchAbsenceTypesFailure(msg));
      } else {
        dispatch(
          fetchAbsenceTypesFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

import API from '../../api/api';

import {
  FETCH_TIME_BALANCE_START,
  FETCH_TIME_BALANCE_SUCCESS,
  FETCH_TIME_BALANCE_FAILURE,
} from './timeBalanceTypes';

export const fetchTimeBalanceStart = () => {
  return {
    type: FETCH_TIME_BALANCE_START,
  };
};

export const fetchTimeBalanceFailure = (error) => {
  return {
    type: FETCH_TIME_BALANCE_FAILURE,
  };
};

export const fetchTimeBalanceSuccess = (timeBalance) => {
  return {
    type: FETCH_TIME_BALANCE_SUCCESS,
    payload: timeBalance,
  };
};

export const fetchTimeBalance = (auth, month) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchTimeBalanceStart());

    try {
      const res = await API.get('/SIF/time/time_balance', config);
      dispatch(fetchTimeBalanceSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchTimeBalanceFailure(msg));
      } else {
        dispatch(
          fetchTimeBalanceFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

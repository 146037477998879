import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Row, Col, message } from 'antd';

import { DELETE_LAYOUT } from '../../modalTypes';

import {
  deleteVorlage,
  toggleEditedVorlage,
} from '../../../../redux/vorlage/vorlageActions';

const DeleteLayoutModal = ({
  currentModals,
  hideModal,
  deleteVorlage,
  timePost,
  user,
  loading,
  deletedLayout,
  toggleEditedVorlage,
  error,
}) => {
  useEffect(() => {
    if (deletedLayout) {
      toggleEditedVorlage();
      hideModal(DELETE_LAYOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedLayout]);

  useEffect(() => {
    if (error) {
      message.error(error);
    }
  }, [error]);

  const onClose = () => {
    hideModal(DELETE_LAYOUT);
  };

  const onDone = () => {
    const { profile, pernr } = user;
    deleteVorlage(user, { profile, pernr }, timePost);
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: '0' }}>Zeiterfassung</h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(DELETE_LAYOUT) ? true : false}
      onCancel={() => onClose(true)}
      width={400}
      footer={[
        <Button key='submit' type='primary' loading={loading} onClick={onDone}>
          Ja
        </Button>,
        <Button key='back' onClick={() => onClose(false)}>
          Nein
        </Button>,
      ]}
    >
      <div style={{ fontWeight: 500 }}>
        Wollen Sie die Vorlage wirklich löschen?
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth: { user },
  vorlage: { loading, error, deletedLayout },
  time: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
  },
}) => ({
  user,
  timePost: {
    timeColumns,
    absenceColumns,
    times,
    absences,
    regularHours,
    hours,
    timestamps,
  },
  loading,
  error,
  deletedLayout,
});

const mapDispatchToProps = (dispatch) => ({
  deleteVorlage: (auth, params, time) =>
    dispatch(deleteVorlage(auth, params, time)),
  toggleEditedVorlage: () => dispatch(toggleEditedVorlage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteLayoutModal);

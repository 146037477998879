import React from 'react';
import { Menu } from 'antd';

import styles from './ContextMenu.module.css';

import { FavoriteMenuItem } from '../../../constants/favoriteMenuItem';

const Item = Menu.Item;

const FavoriteMenu = (shouldShowOverrideModal) => {
  const handleClick = (info) => {
    if (info.key) {
      shouldShowOverrideModal(info.key);
    }
  };

  return (
    <Menu className={styles.menu} selectable={false} onClick={handleClick}>
      <Item key={FavoriteMenuItem.LADEN} className={styles.menuItem}>
        Favoriten Laden
      </Item>
      <Item key={FavoriteMenuItem.SICHERN} className={styles.menuItem}>
        Favoriten Sichern
      </Item>
      <Item key={FavoriteMenuItem.LOSCHEN} className={styles.menuItem}>
        Favoriten Löschen
      </Item>
    </Menu>
  );
};

export default FavoriteMenu;

import {
  FETCHED_WAGE_TYPE,
  FETCH_WAGE_TYPE_FAILURE,
  FETCH_WAGE_TYPE_SUCCESS,
  FETCH_WAGE_TYPES_SUCCESS,
  FETCH_WAGE_TYPES_FAILURE,
} from './wageTypeTypes';

const initialState = {
  wageType: null,
  wageTypes: [],
  error: null,
  hasFetchedWageType: false,
  editedWageTypeCell: null,
};

const wageTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WAGE_TYPE_SUCCESS:
      return {
        ...state,
        wageType: action.payload.wageType,
        editedWageTypeCell: action.payload.cellBeingEdited,
        hasFetchedWageType: true,
        error: null,
      };
    case FETCH_WAGE_TYPE_FAILURE:
      return {
        ...state,
        wageType: {
          lgart: action.payload.wagetype,
          lgtxt: '',
        },
        error: action.payload.error,
        editedWageTypeCell: action.payload.cellBeingEdited,
        hasFetchedWageType: true,
      };
    case FETCHED_WAGE_TYPE:
      return {
        ...state,
        hasFetchedWageType: action.payload,
      };
    case FETCH_WAGE_TYPES_SUCCESS:
      return {
        ...state,
        wageTypes: action.payload,
        error: null,
      };
    case FETCH_WAGE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default wageTypeReducer;

import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import { RELEASE_TIMES } from '../../modalTypes';

const ReleaseTimesModal = ({ currentModals, hideModal, releaseTimes }) => {
  const onClose = () => {
    hideModal(RELEASE_TIMES);
  };

  const onDone = () => {
    hideModal(RELEASE_TIMES);
    releaseTimes();
  };

  return (
    <Modal
      title={
        <Row align='middle'>
          <Col flex='auto'>
            <h3 style={{ marginBottom: '0' }}>Zeiterfassung</h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(RELEASE_TIMES) ? true : false}
      onCancel={() => onClose(true)}
      width={400}
      footer={[
        <Button key='submit' type='primary' onClick={onDone}>
          Ja
        </Button>,
        <Button key='back' onClick={onClose}>
          Nein
        </Button>,
      ]}
    >
      <div style={{ fontWeight: 500 }}>
        Sind Sie sicher, dass alle Ihre Informationen korrekt und vollständig
        sind?
      </div>
    </Modal>
  );
};

export default ReleaseTimesModal;

import styled from 'styled-components';

const WageTypeTableStyle = styled.div`
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    max-width: 100%;
    border: 1px solid #ddd;
    overflow: scroll;
    overflow-x: hidden;
    max-height: 40vh;
  }

  .table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;

      :last-child {
        border-right: 0;
      }
    }

    th {
      background-color: #f5f5f5;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 5px;
    }

    td {
      padding: 3px 5px;
      color: #4f4f4f;

      input {
        font-size: inherit;
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        background: none;
        width: 100%;
      }
    }

    &.sticky {
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .footer {
        bottom: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }
`;

export default WageTypeTableStyle;

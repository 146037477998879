import React, { useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { useTable, useBlockLayout } from 'react-table';
import { useExportData } from 'react-table-plugins';
import { useSticky } from 'react-table-sticky';
import { Dropdown, Button } from 'antd';
import DescribedButton from '../../button-tooltip/DescribedButton';
import moment from 'moment';

import contextMenu from '../menu/ContextMenu';
import exportMenu from '../menu/ExportMenu';

import EditableCell from '../../editable-cells/EditableCell';

import { showModal } from '../../../redux/modals/modalActions';
import {
  setEditingCell,
  setRemainingHoursInDay,
  setRemainingHoursForMonth,
} from '../../../redux/time/timeActions';

import getExportFileBlob from '../../../utils/getExportFileBlob';

import Icon from '@ant-design/icons';

import { ABWESEN } from '../../modals/modalTypes';
import AddAbwesenIcon from '../../../assets/AddAbwesenIcon';
import AddLineIcon from '../../../assets/AddLineIcon';
import DuplicateLineIcon from '../../../assets/DuplicateLineIcon';
import ExportIcon from '../../../assets/ExportIcon';
import RemoveLineIcon from '../../../assets/RemoveLineIcon';
import { addEmptyAbsence } from '../../../redux/time/timeActions';

import {
  REMOVE_ABWESEN,
  DUPLICATE_ABWESEN,
} from '../../../utils/isValidActionButton';

import styles from './Abwesenheiten.module.css';
import { AWART } from '../../editable-cells/EditableCellTypes';

const AbwesenTable = ({
  columns,
  data,
  updateMyData,
  absenceColumns,
  absences,
  showModal,
  setEditingCell,
  addEmptyAbsence,
  setRemainingHoursInDay,
  setRemainingHoursForMonth,
  periodClosed,
  areTimesLocked,
  onlyDisplay,
}) => {
  const defaultColumn = useMemo(
    () => ({
      Cell: EditableCell,
    }),
    []
  );

  const getExportFileName = () => {
    return 'abwesen-data';
  };

  const addEmptyLine = () => {
    addEmptyAbsence();
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    exportData,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      getExportFileBlob,
      getExportFileName,
    },
    useBlockLayout,
    useSticky,
    useExportData
  );

  const isDateValid = (date) => {
    const dateFormat = 'YYYY-MM-DD';
    const toDateFormat = moment(new Date(date)).format(dateFormat);
    return moment(toDateFormat, dateFormat, true).isValid();
  };

  const isNonEditable = (cell) => {
    if (onlyDisplay) {
      return true;
    } else if (absenceColumns[cell.column.id].isReadonly) {
      return true;
    } else if (isDateValid(cell.column.id)) {
      if (absences[cell.row.index - 2]) {
        if (absences[cell.row.index - 2].cells[cell.column.id]) {
          return absences[cell.row.index - 2].cells[cell.column.id].isReadOnly;
        }
      }
    } else if (
      cell.column.id === AWART &&
      absences[cell.row.index - 2].awart !== ''
    ) {
      if (absences[cell.row.index - 2]) {
        for (const [, v] of Object.entries(
          absences[cell.row.index - 2].cells
        )) {
          if (v.isReadOnly) {
            return true;
          }
        }
        return false;
      }
    } else {
      return false;
    }
  };

  const isHoliday = (id) => {
    return absenceColumns[id].isHoliday;
  };

  const isWeekend = (id) => {
    return absenceColumns[id].isWeekend;
  };

  const hasShortText = (cell) => {
    if (isDateValid(cell.column.id)) {
      if (absences[cell.row.index - 2].cells[cell.column.id]) {
        return (
          absences[cell.row.index - 2].cells[cell.column.id].shortText !== ''
        );
      }
    }
    return false;
  };

  const hasLongText = (cell) => {
    if (isDateValid(cell.column.id)) {
      if (absences[cell.row.index - 2].cells[cell.column.id]) {
        return (
          absences[cell.row.index - 2].cells[cell.column.id].longText.length > 0
        );
      }
    }
    return false;
  };

  const colourTableCell = (cell) => {
    const style = { ...cell.getCellProps().style };
    const props = cell.getCellProps();
    let readOnly = false;
    let weekend = false;
    let holiday = false;
    let longText = false;
    let shortText = false;

    if (cell.row.index === 0) {
      style.background = '#D5EBFF';
      style.fontWeight = 600;
    } else if (cell.row.index === 1) {
      style.background = '#FFFFA3';
      style.fontWeight = 600;
    } else if (isNonEditable(cell)) {
      style.background = '#D5EBFF';
    } else if (isWeekend(cell.column.id)) {
      style.background = '#F7CDCC';
      weekend = true;
    } else if (isHoliday(cell.column.id)) {
      style.background = '#F8E5C8';
      holiday = true;
    } else if (hasLongText(cell)) {
      style.background = '#CCE3E3';
      longText = true;
    } else if (hasShortText(cell)) {
      style.background = '#C6F9C1';
      shortText = true;
    }

    if (
      cell.row.index > 1 &&
      (periodClosed || areTimesLocked || isNonEditable(cell))
    ) {
      if (
        !periodClosed ||
        areTimesLocked ||
        (periodClosed && !weekend && !holiday && !longText && !shortText) ||
        onlyDisplay
      ) {
        style.background = '#D5EBFF';
      }
      readOnly = true;
    }

    props.style = style;

    return { props, readOnly };
  };

  return (
    <Fragment>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Abwesenheiten</h3>
        <div className={styles.zeitButtonsWrapper}>
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={AddLineIcon} />}
            tooltip={'Zeile hinzufügen'}
            onClick={addEmptyLine}
            type={'add'}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={RemoveLineIcon} />}
            tooltip={'Zeile löschen'}
            type={REMOVE_ABWESEN}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={DuplicateLineIcon} />}
            tooltip={'Zeile duplizieren'}
            type={DUPLICATE_ABWESEN}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />

          <Dropdown overlay={exportMenu(exportData)} trigger={['click']}>
            <Button
              className={styles.zeitTableButtons}
              icon={<Icon component={ExportIcon} />}
            />
          </Dropdown>
        </div>

        <div className={styles.zeitButtonsWrapper}>
          <Button
            className={styles.zeitTableButtons}
            onClick={() => showModal(ABWESEN)}
            icon={<Icon component={AddAbwesenIcon} />}
            disabled={periodClosed || areTimesLocked || onlyDisplay}
          />
        </div>
      </div>
      <div {...getTableProps()} className="table sticky" id="abwesenTable">
        <div className="header">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column) => {
                return (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="body">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr">
                {row.cells.map((cell) => {
                  const { props, readOnly } = colourTableCell(cell);

                  if (cell.row.index > 1 && moment(cell.column.id).isValid()) {
                    return (
                      <Dropdown
                        key={props.key}
                        overlay={contextMenu(
                          cell,
                          moment(cell.column.id).isValid() &&
                            cell.value !== '' &&
                            cell.value !== undefined,
                          (type, props) => showModal(type, props),
                          ABWESEN,
                          (index, column) =>
                            setRemainingHoursInDay(index - 2, column, ABWESEN),
                          (index) =>
                            setRemainingHoursForMonth(index - 2, ABWESEN),
                          onlyDisplay
                        )}
                        trigger={['contextMenu']}
                      >
                        <div {...props} className="td">
                          {cell.render('Cell', { readOnly })}
                        </div>
                      </Dropdown>
                    );
                  } else {
                    return (
                      <div {...props} className="td">
                        {cell.render('Cell')}
                      </div>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({
  time: { absenceColumns, absences, periodClosed, onlyDisplay },
  lock: { areTimesLocked },
}) => ({
  absenceColumns,
  absences,
  periodClosed,
  onlyDisplay,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (type, props) => dispatch(showModal(type, props)),
  setEditingCell: (cell) => dispatch(setEditingCell(cell)),
  addEmptyAbsence: () => dispatch(addEmptyAbsence()),
  setRemainingHoursInDay: (index, column, parent) =>
    dispatch(setRemainingHoursInDay(index, column, parent)),
  setRemainingHoursForMonth: (index, parent) =>
    dispatch(setRemainingHoursForMonth(index, parent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbwesenTable);

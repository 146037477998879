import moment from 'moment'

export const formatDate = (date, format) => {
    if(moment(date).isValid()){
        return moment(date).format(format);
    } else {
        if(date.split("-") !== null){
            if(format.includes(".")){
                return date.split("-")[2] + "." + date.split("-")[1] + "." + date.split("-")[0];
            } else if(format.includes("/")){
                return date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0];
            } else {
                return date;
            }
        } else {
            return date;
        }
    }
} 
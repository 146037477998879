import { uniqueId } from '../utils/uniqueId';

export const System = Object.freeze({
  SITF: {
    id: uniqueId(),
    description: 'SED - Entwicklung',
    protocol: 'http://',
    ip: '192.168.1.32',
  },
  VANOLI: {
    id: uniqueId(),
    description: 'Produktives System',
    protocol: 'http://',
    ip: 'sappe0.vanoli-erp.ch',
  },
  VANOLIDEV: {
    id: uniqueId(),
    description: 'Schulungssystem',
    protocol: 'http://',
    ip: 'sapde0.vanoli-erp.ch',
  },
});

export const ClientCode = {
  SITF: 500,
  VANOLI: '001',
  VANOLIDEV: '011',
};

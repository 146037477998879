import API from '../../api/api';

import {
  FETCH_TIMES_SUCCESS,
  FETCH_TIMES_FAILURE,
  FETCH_TIMES_START,
  TOGGLE_FROZEN_COLUMNS,
  SET_EDITING_CELL,
  SAVE_TIMES_START,
  SAVE_TIMES_SUCCESS,
  SAVE_TIMES_FAILURE,
  UPDATE_TIME,
  UPDATE_ABSENCE,
  ADD_EMPTY_TIME,
  ADD_EMPTY_ABSENCE,
  SET_SAVED_TIMES,
  SET_SAVED_ABSENCES,
  UPDATE_SHORT_TEXT,
  UPDATE_LONG_TEXT,
  SET_CHANGED_ABSENCES_DATA,
  SET_SELECTED_PSP_KOST,
  SET_HAS_SELECTED_PSP_KOST,
  SET_SELECTED_ABSENCE_TYPE,
  SET_HAS_SELECTED_ABSENCE_TYPE,
  SET_SELECTED_WAGE_TYPE,
  SET_HAS_SELECTED_WAGE_TYPE,
  SET_CURRENT_MONTH,
  UPDATE_COST_CENTER_OR_WBS,
  UPDATE_WAGE_TYPE,
  UPDATE_ABSENCE_TYPE,
  ADD_HOUR,
  ADD_TIMESTAMP,
  UPDATE_HOUR,
  UPDATE_TIMESTAMP,
  FETCH_TIMESTAMPS_DEFAULTS_START,
  FETCH_TIMESTAMPS_DEFAULTS_SUCCESS,
  FETCH_TIMESTAMPS_DEFAULTS_FAILURE,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS,
  SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE,
  SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS,
  RELEASE_TIMES_START,
  RELEASE_TIMES_SUCCESS,
  RELEASE_TIMES_FAILURE,
  SET_TIMESTAMP_HOURS_FROM_TEMP,
  RESET_TEMP_TIMESTAMP_HOURS,
  FILTER_TIMES_FROM_HOURS,
  SET_HAS_UPDATED_HOURS_OR_TIMESTAMP,
  REMOVE_ROW_FROM_TABLE,
  DUPLICATE_ROW,
  SET_ERROR_ON_SEARCH_CELL,
  SET_LOADING_ON_SEARCH_CELL,
  SAVE_ABSENCES_START,
  SAVE_ABSENCES_SUCCESS,
  SAVE_ABSENCES_FAILURE,
  SET_REMAINING_HOURS_IN_DAY,
  SET_REMAINING_HOURS_FOR_MONTH,
  SET_TRAVEL_TIME,
  SET_HAS_FETCHED_TIMES,
} from './timeTypes';
import moment from 'moment';

import { STUNDEN } from '../../components/modals/modalParentTypes';

import { mergeHoursAndTimestampsWithDeleted } from './timeUtils';

export const fetchTimesStart = () => {
  return {
    type: FETCH_TIMES_START,
  };
};

export const fetchTimesSuccess = (time) => {
  return {
    type: FETCH_TIMES_SUCCESS,
    payload: time,
  };
};

export const fetchTimesFailure = (error) => {
  return {
    type: FETCH_TIMES_FAILURE,
    payload: error,
  };
};

export const setHasFetchedTimes = (hasFetched) => {
  return {
    type: SET_HAS_FETCHED_TIMES,
    payload: hasFetched,
  };
};

export const setAreTimesSaved = (savedTimes) => {
  return {
    type: SET_SAVED_TIMES,
    payload: savedTimes,
  };
};

export const setAreAbsencesSaved = (savedAbsences) => {
  return {
    type: SET_SAVED_ABSENCES,
    payload: savedAbsences,
  };
};

export const saveTimesStart = () => {
  return {
    type: SAVE_TIMES_START,
  };
};

export const saveTimesSuccess = (time) => {
  return {
    type: SAVE_TIMES_SUCCESS,
    payload: time,
  };
};

export const saveTimesFailure = (error) => {
  return {
    type: SAVE_TIMES_FAILURE,
    payload: error,
  };
};

export const releaseTimesStart = () => {
  return {
    type: RELEASE_TIMES_START,
  };
};

export const releaseTimesSuccess = (time) => {
  return {
    type: RELEASE_TIMES_SUCCESS,
    payload: time,
  };
};

export const releaseTimesFailure = (error) => {
  return {
    type: RELEASE_TIMES_FAILURE,
    payload: error,
  };
};

export const addEmptyTime = () => {
  return {
    type: ADD_EMPTY_TIME,
  };
};

export const updateTime = (rowIndex, columnId, value) => {
  return {
    type: UPDATE_TIME,
    payload: { rowIndex, columnId, value },
  };
};

export const updateCostCenterOrWbs = (
  rowIndex,
  costCenterOrWbs,
  text,
  parent = STUNDEN,
  workdate = '',
  oldPspValue
) => {
  return {
    type: UPDATE_COST_CENTER_OR_WBS,
    payload: { rowIndex, costCenterOrWbs, text, parent, workdate, oldPspValue },
  };
};

export const updateWageType = (
  rowIndex2,
  lgart,
  lgtxt,
  unit,
  currency,
  isTravel
) => {
  return {
    type: UPDATE_WAGE_TYPE,
    payload: { rowIndex2, lgart, lgtxt, unit, currency, isTravel },
  };
};

export const updateAbsenceType = (rowIndex3, awart, atext) => {
  return {
    type: UPDATE_ABSENCE_TYPE,
    payload: { rowIndex3, awart, atext },
  };
};

export const addEmptyAbsence = () => {
  return {
    type: ADD_EMPTY_ABSENCE,
  };
};

export const updateAbsence = (rowIndex, columnId, value) => {
  return {
    type: UPDATE_ABSENCE,
    payload: { rowIndex, columnId, value },
  };
};

export const fetchTimestampsDefaultsStart = () => {
  return {
    type: FETCH_TIMESTAMPS_DEFAULTS_START,
  };
};

export const fetchTimestampsDefaultsSuccess = (
  defaultTimesDate,
  defaultTimes
) => {
  return {
    type: FETCH_TIMESTAMPS_DEFAULTS_SUCCESS,
    payload: { defaultTimesDate, defaultTimes },
  };
};

export const fetchTimestampsDefaultsFailure = (error) => {
  return {
    type: FETCH_TIMESTAMPS_DEFAULTS_FAILURE,
    payload: error,
  };
};

export const saveOrDeleteDefaultsStart = () => {
  return {
    type: SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START,
  };
};

export const saveOrDeleteDefaultsSuccess = (deleted) => {
  return {
    type: SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS,
    payload: deleted,
  };
};

export const saveOrDeleteDefaultsFailure = (error) => {
  return {
    type: SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE,
    payload: error,
  };
};

export const setSavedOrDeletedTimestampDefaults = (savedOrDeleted) => {
  return {
    type: SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS,
    payload: savedOrDeleted,
  };
};

export const setErrorOnEditableSearchCell = (
  columnId,
  rowIndex,
  isValid,
  parent,
  workdate
) => {
  return {
    type: SET_ERROR_ON_SEARCH_CELL,
    payload: { columnId, rowIndex, isValid, parent, workdate },
  };
};

export const setLoadingOnEditableSearchCell = (
  columnId,
  rowIndex,
  isLoading,
  parent,
  workdate
) => {
  return {
    type: SET_LOADING_ON_SEARCH_CELL,
    payload: { columnId, rowIndex, isLoading, parent, workdate },
  };
};

export const saveAbsencesStart = () => {
  return {
    type: SAVE_ABSENCES_START,
  };
};

export const saveAbsencesSuccess = ({ absences, total }) => {
  return {
    type: SAVE_ABSENCES_SUCCESS,
    payload: { absences, total },
  };
};

export const saveAbsencesFailure = (error) => {
  return {
    type: SAVE_ABSENCES_FAILURE,
    payload: error,
  };
};

export const setTravelTime = (rowIndex, column, cell) => {
  return {
    type: SET_TRAVEL_TIME,
    payload: { rowIndex, cell, column },
  };
};

export const fetchTimes = (auth, tempMonth) => {
  return async (dispatch, store) => {
    const {
      user: { pernr, headerAuth, profile },
    } = auth;

    const month = moment(tempMonth);
    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        profile,
        pernr,
        begda,
        endda,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchTimesStart());

    try {
      const res = await API.get('/SIF/time/times', config);
      dispatch(fetchTimesSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.statusText || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchTimesFailure(msg));
      } else if (error.message) {
        let msg = error.message || 'Ein unbekannter Fehler ist aufgetreten';
        console.error(error.stack);
        dispatch(fetchTimesFailure(msg));
      } else {
        dispatch(fetchTimesFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const saveTimes = (auth, time, month, release = '') => {
  return async (dispatch, store) => {
    const { pernr, headerAuth, profile } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params:
        release === 'X'
          ? {
              profile,
              pernr,
              begda,
              endda,
              release,
              'sap-language': 'DE',
              'sap-client': `${store().settings.client}`,
            }
          : {
              profile,
              pernr,
              begda,
              endda,
              'sap-language': 'DE',
              'sap-client': `${store().settings.client}`,
            },
    };

    dispatch(release === 'X' ? releaseTimesStart() : saveTimesStart());

    const { hours, timestamps } = mergeHoursAndTimestampsWithDeleted(
      store().time.hours,
      store().time.timestamps,
      store().time.deletedHours,
      store().time.deletedTimestamps
    );

    const timePost = { ...time, hours, timestamps };

    try {
      const res = await API.post('/SIF/time/times', { ...timePost }, config);
      if (res.data) {
        if (res.data.hasEror || res.data.hasError) {
          dispatch(
            release === 'X'
              ? releaseTimesFailure(res.data.messages)
              : saveTimesFailure(res.data.messages)
          );
        } else {
          dispatch(
            release === 'X'
              ? releaseTimesSuccess(res.data.newData)
              : saveTimesSuccess(res.data.newData)
          );
        }
      }
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(
          release === 'X' ? releaseTimesFailure(msg) : saveTimesFailure(msg)
        );
      } else {
        dispatch(
          release === 'X'
            ? releaseTimesFailure('Ein unbekannter Fehler ist aufgetreten')
            : saveTimesFailure('Ein unbekannter Fehler ist aufgetreten')
        );
      }
    }
  };
};

export const addHour = (workdate, hour) => {
  return { type: ADD_HOUR, payload: { workdate, hour } };
};

export const setHasUpdatedHourOrTimestamp = () => {
  return {
    type: SET_HAS_UPDATED_HOURS_OR_TIMESTAMP,
  };
};

export const updateHour = (editedHour, hourColumn, hourIndex, workdate) => {
  return {
    type: UPDATE_HOUR,
    payload: { editedHour, hourColumn, hourIndex, workdate },
  };
};

export const addTimestamp = (workdate, timestamp) => {
  return { type: ADD_TIMESTAMP, payload: { workdate, timestamp } };
};

export const updateTimestamp = (
  editedTimestamp,
  timestampColumn,
  timestampIndex,
  workdate
) => {
  return {
    type: UPDATE_TIMESTAMP,
    payload: { editedTimestamp, timestampColumn, timestampIndex, workdate },
  };
};

export const fetchTimestampsDefaults = (
  auth,
  defaultTimesDate,
  fetchFavourites
) => {
  return async (dispatch, store) => {
    const { pernr, headerAuth } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: fetchFavourites
        ? { 'sap-language': 'DE', 'sap-client': `${store().settings.client}` }
        : {
            pernr,
            date: moment(defaultTimesDate, 'yyyy-MM-DD').format('YYYYMMDD'),
            'sap-language': 'DE',
            'sap-client': `${store().settings.client}`,
          },
    };

    dispatch(fetchTimestampsDefaultsStart());

    try {
      const res = await API.get(
        `${
          fetchFavourites
            ? '/SIF/time/timestamp_favourites'
            : '/SIF/time/timestamp_defaults'
        }`,
        config
      );
      if (res.data) {
        dispatch(fetchTimestampsDefaultsSuccess(defaultTimesDate, res.data));
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          dispatch(fetchTimestampsDefaultsSuccess(defaultTimesDate, []));
        }
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchTimestampsDefaultsFailure(msg));
      } else {
        dispatch(
          fetchTimestampsDefaultsFailure(
            'Ein unbekannter Fehler ist aufgetreten'
          )
        );
      }
    }
  };
};

export const saveOrDeleteTimestampsDefaults = (
  auth,
  defaultTimestamps,
  shouldDelete
) => {
  return async (dispatch, store) => {
    const { headerAuth } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchTimestampsDefaultsStart());

    try {
      const res = shouldDelete
        ? await API.delete('/SIF/time/timestamp_favourites', config)
        : await API.post(
            '/SIF/time/timestamp_favourites',
            [...defaultTimestamps],
            config
          );
      if (res.status === 200) {
        dispatch(saveOrDeleteDefaultsSuccess(shouldDelete, res.data));
      }
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.data.ERROR || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchTimestampsDefaultsFailure(msg));
      } else {
        dispatch(
          fetchTimestampsDefaultsFailure(
            'Ein unbekannter Fehler ist aufgetreten'
          )
        );
      }
    }
  };
};

export const saveAbsences = (auth, entry, absences, total) => {
  return async (dispatch, store) => {
    const { headerAuth, pernr } = auth.user;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(saveAbsencesStart());

    try {
      const res = await API.post(
        '/SIF/time/absences',
        { entry, absences, total },
        config
      );
      if (res.status === 200) {
        dispatch(saveAbsencesSuccess(res.data));
      }
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(saveAbsencesFailure(msg));
      } else {
        dispatch(saveAbsencesFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

export const toggleFrozenColumns = () => {
  return async (dispatch) => dispatch({ type: TOGGLE_FROZEN_COLUMNS });
};

export const setEditingCell = (cell) => {
  return async (dispatch) =>
    dispatch({ type: SET_EDITING_CELL, payload: cell });
};

export const setSelectedPspKost = (pspKost) => {
  return async (dispatch) =>
    dispatch({ type: SET_SELECTED_PSP_KOST, payload: pspKost });
};

export const setHasSelectedPspKost = (hasSelected) => {
  return async (dispatch) =>
    dispatch({ type: SET_HAS_SELECTED_PSP_KOST, payload: hasSelected });
};

export const setSelectedAbsenceType = (absenceType) => {
  return async (dispatch) =>
    dispatch({ type: SET_SELECTED_ABSENCE_TYPE, payload: absenceType });
};

export const setHasSelectedAbsenceType = (hasSelected) => {
  return async (dispatch) =>
    dispatch({ type: SET_HAS_SELECTED_ABSENCE_TYPE, payload: hasSelected });
};

export const setSelectedWageType = (wageType) => {
  return async (dispatch) =>
    dispatch({ type: SET_SELECTED_WAGE_TYPE, payload: wageType });
};

export const setHasSelectedWageType = (hasSelected) => {
  return async (dispatch) =>
    dispatch({ type: SET_HAS_SELECTED_WAGE_TYPE, payload: hasSelected });
};

export const updateShortText = (columnId, rowIndex, shortText, modalParent) => {
  return async (dispatch) =>
    dispatch({
      type: UPDATE_SHORT_TEXT,
      payload: { columnId, rowIndex, shortText, modalParent },
    });
};

export const updateLongText = (columnId, rowIndex, longText, modalParent) => {
  return async (dispatch) =>
    dispatch({
      type: UPDATE_LONG_TEXT,
      payload: { columnId, rowIndex, longText, modalParent },
    });
};

export const setChangedAbsencesData = (changedData) => {
  return async (dispatch) =>
    dispatch({ type: SET_CHANGED_ABSENCES_DATA, payload: changedData });
};

export const setCurrentMonth = (month) => {
  return async (dispatch) =>
    dispatch({ type: SET_CURRENT_MONTH, payload: month });
};

export const setTimestampHoursFromTemp = (tempTimestamps, tempHours) => {
  return async (dispatch) => {
    dispatch({
      type: SET_TIMESTAMP_HOURS_FROM_TEMP,
      payload: { tempTimestamps, tempHours },
    });
  };
};

export const resetTempTimestampHours = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_TEMP_TIMESTAMP_HOURS,
    });
  };
};

export const filterTimesFromHours = (workdate, mergedHours) => ({
  type: FILTER_TIMES_FROM_HOURS,
  payload: { workdate, mergedHours },
});

export const removeRowFromTable = (index, table, workdate = '') => ({
  type: REMOVE_ROW_FROM_TABLE,
  payload: { index, table, workdate },
});

export const duplicateRow = (index, table, workdate = '') => ({
  type: DUPLICATE_ROW,
  payload: { index, table, workdate },
});

export const setRemainingHoursInDay = (index, column, parent) => ({
  type: SET_REMAINING_HOURS_IN_DAY,
  payload: { index, column, parent },
});

export const setRemainingHoursForMonth = (index, parent) => ({
  type: SET_REMAINING_HOURS_FOR_MONTH,
  payload: { index, parent },
});

import styled from 'styled-components';

const Styles = styled.div`
  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    .th {
      background-color: #f5f5f5;
      font-weight: 600;
      font-size: 0.9rem;
      padding: 5px;
    }

    .td {
      padding: 1.5px 5px;
      color: #4f4f4f;

      input {
        font-size: inherit;
        padding: 0;
        margin: 0;
        border: 0;
        outline: none;
        background: none;
        width: 100%;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
      }

      .footer {
        bottom: 0;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }
    }
  }

  #stundenTable {
    max-height: 40vh;
  }

  #abwesenTable {
    max-height: 30vh;
  }

  #abwesenModalTable {
    max-height: 30vh;
  }
`;

export default Styles;

export const uniqueId = () => {
  return (
    '$' +
    (
      Number(String(Math.random()).slice(2)) +
      Date.now() +
      Math.round(performance.now())
    )
      .toString(36)
      .substring(0, 11)
  );
};

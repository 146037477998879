import {
  FETCH_TIME_BALANCE_START,
  FETCH_TIME_BALANCE_SUCCESS,
  FETCH_TIME_BALANCE_FAILURE,
} from './timeBalanceTypes';

const initialState = {
  balance: [],
  payrollAccountedUntil: null,
  payrollPeriodBegin: null,
  payrollPeriodEnd: null,
  pernr: null,
  pernrName: null,
  isFetchingTimeBalances: false,
  hasFetchedTimeBalances: false,
  errorFetchingTimeBalances: null,
};

const timeBalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIME_BALANCE_START:
      return {
        ...state,
        isFetchingTimeBalances: true,
      };
    case FETCH_TIME_BALANCE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetchingTimeBalances: false,
        hasFetchedTimeBalances: true,
        errorFetchingTimeBalances: null,
      };
    case FETCH_TIME_BALANCE_FAILURE:
      return {
        ...state,
        isFetchingTimeBalances: false,
        hasFetchedTimeBalances: true,
        errorFetchingTimeBalances: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default timeBalanceReducer;

import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Button,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Select,
  message,
} from 'antd';
import moment from 'moment';

import AbwesenModalTable from './AbwesenModalTable';

import {
  fetchAbsenceTypes,
  setHasFetchedAbsenceType,
  clearErrors,
} from '../../../../redux/absence-type/absenceTypeActions';
import {
  saveAbsences,
  setAreAbsencesSaved,
  saveAbsencesFailure,
} from '../../../../redux/time/timeActions';

import EditableCell from '../../../editable-cells/EditableCell';

import { ABWESEN } from '../../modalTypes';

import Styles from './AbwesenModalTableStyle';

const AbwesenModal = ({
  currentModals,
  hideModal,
  absenceTypes,
  fetchAbsenceTypes,
  hasFetchedAbsenceTypes,
  loadingWhenSavingAbsences,
  error,
  auth,
  currentMonth,
  clearErrors,
  saveAbsences,
  savedAbsences,
  setAreAbsencesSaved,
  errorSavingAbsences,
  saveAbsencesFailure,
  absences,
  total,
}) => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const [data, setData] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [entry, setEntry] = useState({
    dateFrom: '',
    dateTo: '',
    includeSaturday: false,
    includeSunday: false,
    onEach: 0,
    absences: [],
  });

  const [columns] = useState([
    {
      Header: 'Ab-/Anw...',
      accessor: 'awart',
      width: 40,
    },
    {
      Header: 'An-/AbwArtText',
      accessor: 'atext',
    },
    {
      Header: 'Menge',
      accessor: 'unit',
      width: 25,
      totalWidth: 25,
      maxWidth: 25,
    },
    {
      Header: 'Ganzer Tag',
      accessor: 'wholeDay',
      Cell: (cellInfo) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            checked={cellInfo.value}
            onChange={(e) =>
              updateMyData(
                cellInfo.row.index,
                cellInfo.column.id,
                e.target.checked
              )
            }
          />
        </div>
      ),
      width: 40,
      totalWidth: 40,
      maxWidth: 40,
    },
    {
      Header: 'Halber Tag',
      accessor: 'halfDay',
      Cell: (cellInfo) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Checkbox
            checked={cellInfo.value}
            onChange={(e) =>
              updateMyData(
                cellInfo.row.index,
                cellInfo.column.id,
                e.target.checked
              )
            }
          />
        </div>
      ),
      width: 40,
      totalWidth: 40,
      maxWidth: 40,
    },
    {
      Header: 'Anzahl',
      accessor: 'amount',
      Cell: (cellInfo) => (
        <EditableCell
          initialValue={cellInfo.value}
          {...cellInfo}
          updateMyData={updateMyData}
          readOnly={cellInfo.row.values.wholeDay || cellInfo.row.values.halfDay}
        />
      ),
      width: 50,
      totalWidth: 50,
      maxWidth: 50,
    },
  ]);

  useEffect(() => {
    fetchAbsenceTypes(auth, currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (savedAbsences) {
      hideModal(ABWESEN);
      setAreAbsencesSaved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedAbsences]);

  useEffect(() => {
    if (hasFetchedAbsenceTypes) {
      setData(
        absenceTypes.map((absence) => ({
          ...absence,
          wholeDay: false,
          halfDay: false,
          unit: 'H',
          amount: 0,
        }))
      );
      setHasFetchedAbsenceType(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedAbsenceTypes]);

  useEffect(() => {
    if (error) {
      message.error(error).then(() => clearErrors());
    } else if (errorSavingAbsences) {
      message.error(errorSavingAbsences).then(() => saveAbsencesFailure(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorSavingAbsences]);

  const onDateChange = (dates) => {
    if (dates) {
      const dateFrom = moment(dates[0]._d).format('YYYY-MM-DD');
      const dateTo = moment(dates[1]._d).format('YYYY-MM-DD');
      setEntry({
        ...entry,
        dateFrom,
        dateTo,
      });
    } else {
      setEntry({
        ...entry,
        dateFrom: '',
        dateTo: '',
      });
    }
  };

  const onCheckboxChange = (e) => {
    setEntry({
      ...entry,
      [e.target.name]: e.target.checked,
    });
  };

  const onSelectChange = (value) => {
    setEntry({
      ...entry,
      onEach: value,
    });
  };

  const onSelectSearch = (value) => {};

  const onRowSelect = (selectedFlatRows) => {
    setSelectedRows(selectedFlatRows);
  };

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          if (columnId === 'wholeDay') {
            return {
              ...old[rowIndex],
              halfDay: old[rowIndex][columnId] ? false : !value,
              wholeDay: value,
              amount:
                !old[rowIndex].halfDay && !old[rowIndex].wholeDay
                  ? 0
                  : old[rowIndex].amount,
            };
          } else if (columnId === 'halfDay') {
            return {
              ...old[rowIndex],
              wholeDay: old[rowIndex][columnId] ? false : !value,
              halfDay: value,
              amount:
                !old[rowIndex].halfDay && !old[rowIndex].wholeDay
                  ? 0
                  : old[rowIndex].amount,
            };
          } else {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
        }
        return row;
      })
    );
  };

  const disabledDate = (current) => {
    return entry.onEach === 0
      ? false
      : !(
          (moment(current).day() === 0 && entry.onEach === 7) ||
          moment(current).day() === entry.onEach
        );
  };

  const onClose = () => {
    hideModal(ABWESEN);
  };

  const onDone = () => {
    if (entry.dateFrom === '' && entry.dateTo === '') {
      message.warn('Bitte geben Sie Termine für Abwesenheiten ein');
      return;
    }

    const entryAbsences = selectedRows.map((row) => {
      const { awart, wholeDay, halfDay, amount } = data[row.index];
      return {
        awart,
        wholeDay,
        halfDay,
        amount,
      };
    });

    saveAbsences(auth, { ...entry, absences: entryAbsences }, absences, total);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      title={<h3 style={{ marginBottom: '0' }}>Abwesenheitserfassung</h3>}
      closable={false}
      visible={currentModals.hasOwnProperty(ABWESEN) ? true : false}
      onCancel={() => hideModal(ABWESEN)}
      width={1200}
      footer={[
        <Button key='back' onClick={onClose}>
          Abbrechen
        </Button>,
        <Button
          key='submit'
          type='primary'
          onClick={onDone}
          loading={loadingWhenSavingAbsences}
        >
          Fertig
        </Button>,
      ]}
    >
      <Row
        align='middle'
        gutter='30'
        style={{ marginBottom: '10px', fontSize: '13px' }}
      >
        <Col span={8} style={{ fontWeight: 500, fontSize: '14px' }}>
          Datumsauswahl:
        </Col>
        <Col span={9} style={{ fontWeight: 500, fontSize: '14px' }}>
          Weitere erfassung:
        </Col>
        <Col span={7} style={{ fontWeight: 500, fontSize: '14px' }}>
          An Jedem:
        </Col>
      </Row>
      <Row
        align='top'
        gutter='30'
        style={{ marginBottom: '24px', fontSize: '13px' }}
      >
        <Col span={8}>
          <RangePicker
            style={{ width: '90%' }}
            inputReadOnly={true}
            disabledDate={disabledDate}
            placeholder={['Datum von', 'Datum bis']}
            ranges={{
              'Dieser Tag': [moment(), moment()],
              'Dieser Monat': [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
            format='YYYY/MM/DD'
            onChange={onDateChange}
          />
        </Col>
        <Col span={9}>
          <Checkbox name='includeSaturday' onChange={onCheckboxChange}>
            Inklusive Samstag
          </Checkbox>
          <Checkbox name='includeSunday' onChange={onCheckboxChange}>
            Inklusive Sonntag
          </Checkbox>
        </Col>
        <Col span={7}>
          <Select
            placeholder=''
            style={{ width: '80%' }}
            onChange={onSelectChange}
            onSearch={onSelectSearch}
            defaultValue={entry.onEach}
          >
            <Option value={0}> </Option>
            <Option value={1}>Montag</Option>
            <Option value={2}>Dienstag</Option>
            <Option value={3}>Mittwoch</Option>
            <Option value={4}>Donnerstag</Option>
            <Option value={5}>Freitag</Option>
            <Option value={6}>Samstag</Option>
            <Option value={7}>Sonntag</Option>
          </Select>
        </Col>
      </Row>
      <Styles>
        <AbwesenModalTable
          columns={columnsMemo}
          data={data}
          updateMyData={updateMyData}
          onRowSelect={onRowSelect}
        />
      </Styles>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  time: {
    currentMonth,
    absences,
    loadingWhenSavingAbsences,
    errorSavingAbsences,
    savedAbsences,
    total,
  },
  absenceType: { absenceTypes, hasFetchedAbsenceTypes, error },
}) => ({
  absences,
  total,
  auth,
  currentMonth,
  absenceTypes,
  hasFetchedAbsenceTypes,
  loadingWhenSavingAbsences,
  error,
  errorSavingAbsences,
  savedAbsences,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAbsenceTypes: (auth, month) => dispatch(fetchAbsenceTypes(auth, month)),
  setHasFetchedAbsenceType: (hasFetched) =>
    dispatch(setHasFetchedAbsenceType(hasFetched)),
  clearErrors: () => dispatch(clearErrors),
  saveAbsences: (auth, entry, absences, total) =>
    dispatch(saveAbsences(auth, entry, absences, total)),
  setAreAbsencesSaved: (saved) => dispatch(setAreAbsencesSaved(saved)),
  saveAbsencesFailure: (error) => dispatch(saveAbsencesFailure(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AbwesenModal);

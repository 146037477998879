import {
  SAVE_VORLAGE_START,
  SAVE_VORLAGE_SUCCESS,
  SAVE_VORLAGE_FAILURE,
  COPY_VORLAGE_START,
  COPY_VORLAGE_SUCCESS,
  COPY_VORLAGE_FAILURE,
  DELETE_VORLAGE_START,
  DELETE_VORLAGE_SUCCESS,
  DELETE_VORLAGE_FAILURE,
  TOGGLE_EDITED_VORLAGE,
} from './vorlageTypes';

const initialState = {
  loading: false,
  error: null,
  timesLayout: null,
  savedLayout: false,
  copiedLayout: false,
  deletedLayout: false,
};

const vorlageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_VORLAGE_START:
      return { ...state, loading: true, error: null, savedLayout: false };
    case SAVE_VORLAGE_SUCCESS:
      return { ...state, loading: false, error: null, savedLayout: true };
    case SAVE_VORLAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case COPY_VORLAGE_START:
      return { ...state, loading: true, error: null, copiedLayout: false };
    case COPY_VORLAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        copiedLayout: true,
        timesLayout: action.payload,
      };
    case COPY_VORLAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case DELETE_VORLAGE_START:
      return { ...state, loading: true, error: null, deletedLayout: false };
    case DELETE_VORLAGE_SUCCESS:
      return { ...state, loading: false, error: null, deletedLayout: true };
    case DELETE_VORLAGE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case TOGGLE_EDITED_VORLAGE:
      return {
        ...state,
        savedLayout: false,
        deletedLayout: false,
        copiedLayout: false,
        error: null,
      };
    default:
      return state;
  }
};

export default vorlageReducer;

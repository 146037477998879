export const FETCH_TIMES_START = 'FETCH_TIMES_START';
export const FETCH_TIMES_SUCCESS = 'FETCH_TIMES_SUCCESS';
export const FETCH_TIMES_FAILURE = 'FETCH_TIMES_FAILURE';
export const SET_HAS_FETCHED_TIMES = 'SET_HAS_FETCHED_TIMES';
export const SAVE_TIMES_START = 'SAVE_TIMES_START';
export const SAVE_TIMES_SUCCESS = 'SAVE_TIMES_SUCCESS';
export const SAVE_TIMES_FAILURE = 'SAVE_TIMES_FAILURE';
export const TOGGLE_FROZEN_COLUMNS = 'TOGGLE_FROZEN_COLUMNS';
export const SET_EDITING_CELL = 'SET_EDITING_CELL';
export const UPDATE_TIME = 'UPDATE_TIME';
export const UPDATE_ABSENCE = 'UPDATE_ABSENCE';
export const ADD_EMPTY_TIME = 'ADD_EMPTY_TIME';
export const ADD_EMPTY_ABSENCE = 'ADD_EMPTY_ABSENCE';
export const SET_SAVED_TIMES = 'SET_SAVED_TIMES';
export const SET_SAVED_ABSENCES = 'SET_SAVED_ABSENCES';
export const UPDATE_SHORT_TEXT = 'UPDATE_SHORT_TEXT';
export const UPDATE_LONG_TEXT = 'UPDATE_LONG_TEXT';
export const SET_CHANGED_ABSENCES_DATA = 'SET_CHANGED_ABSENCES_DATA';
export const SET_SELECTED_PSP_KOST = 'SET_SELECTED_PSP_KOST';
export const SET_HAS_SELECTED_PSP_KOST = 'SET_HAS_SELECTED_PSP_KOST';
export const SET_SELECTED_ABSENCE_TYPE = 'SET_SELECTED_ABSENCE_TYPE';
export const SET_HAS_SELECTED_ABSENCE_TYPE = 'SET_HAS_SELECTED_ABSENCE_TYPE';
export const SET_SELECTED_WAGE_TYPE = 'SET_SELECTED_WAGE_TYPE';
export const SET_HAS_SELECTED_WAGE_TYPE = 'SET_HAS_SELECTED_WAGE_TYPE';
export const SET_CURRENT_MONTH = 'SET_CURRENT_MONTH';
export const UPDATE_COST_CENTER_OR_WBS = 'UPDATE_COST_CENTER_OR_WBS';
export const UPDATE_WAGE_TYPE = 'UPDATE_WAGE_TYPE';
export const UPDATE_ABSENCE_TYPE = 'UPDATE_ABSENCE_TYPE';
export const UPDATE_HOUR = 'UPDATE_HOUR';
export const UPDATE_TIMESTAMP = 'UPDATE_TIMESTAMP';
export const ADD_HOUR = 'ADD_HOUR';
export const ADD_TIMESTAMP = 'ADD_TIMESTAMP';
export const FETCH_TIMESTAMPS_DEFAULTS_START =
  'FETCH_TIMESTAMPS_DEFAULTS_START';
export const FETCH_TIMESTAMPS_DEFAULTS_FAILURE =
  'FETCH_TIMESTAMPS_DEFAULTS_FAILURE';
export const FETCH_TIMESTAMPS_DEFAULTS_SUCCESS =
  'FETCH_TIMESTAMPS_DEFAULTS_SUCCESS';
export const SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START =
  'SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_START';
export const SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE =
  'SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_FAILURE';
export const SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS =
  'SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS_SUCCESS';
export const SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS =
  'SET_SAVE_OR_DELETE_TIMESTAMPS_DEFAULTS';
export const RELEASE_TIMES_START = 'RELEASE_TIMES_START';
export const RELEASE_TIMES_SUCCESS = 'RELEASE_TIMES_SUCCESS';
export const RELEASE_TIMES_FAILURE = 'RELEASE_TIMES_FAILURE';
export const SET_TIMESTAMP_HOURS_FROM_TEMP = 'SET_TIMESTAMP_HOURS_FROM_TEMP';
export const RESET_TEMP_TIMESTAMP_HOURS = 'RESET_TEMP_TIMESTAMP_HOURS';
export const FILTER_TIMES_FROM_HOURS = 'FILTER_TIMES_FROM_HOURS';
export const SET_HAS_UPDATED_HOURS_OR_TIMESTAMP =
  'SET_HAS_UPDATED_HOURS_OR_TIMESTAMP';
export const REMOVE_ROW_FROM_TABLE = 'REMOVE_ROW_FROM_TABLE';
export const DUPLICATE_ROW = 'DUPLICATE_ROW';
export const SET_ERROR_ON_SEARCH_CELL = 'SET_ERROR_ON_SEARCH_CELL';
export const SET_LOADING_ON_SEARCH_CELL = 'SET_LOADING_ON_SEARCH_CELL';
export const SAVE_ABSENCES_START = 'SAVE_ABSENCES_START';
export const SAVE_ABSENCES_SUCCESS = 'SAVE_ABSENCES_SUCCESS';
export const SAVE_ABSENCES_FAILURE = 'SAVE_ABSENCES_FAILURE';
export const SET_REMAINING_HOURS_IN_DAY = 'SET_REMAINING_HOURS_IN_DAY';
export const SET_REMAINING_HOURS_FOR_MONTH = 'SET_REMAINING_HOURS_FOR_MONTH';
export const SET_TRAVEL_TIME = 'SET_TRAVEL_TIME';

import axios from 'axios';

import {
  LOCK_TIMES_START,
  LOCK_TIMES_FAILURE,
  LOCK_TIMES_SUCCESS,
  UNLOCK_TIMES_FAILURE,
  UNLOCK_TIMES_START,
  UNLOCK_TIMES_SUCCESS,
} from './lockTypes';

import moment from 'moment';
import { getMiddleware } from '../../utils/getMiddleware';

export const lockTimesStart = () => {
  return {
    type: LOCK_TIMES_START,
  };
};

export const lockTimesSuccess = (data) => {
  return {
    type: LOCK_TIMES_SUCCESS,
    payload: data,
  };
};

export const lockTimesFailure = (error, warning) => {
  return {
    type: LOCK_TIMES_FAILURE,
    payload: { error, warning },
  };
};

export const unlockTimesStart = () => {
  return {
    type: UNLOCK_TIMES_START,
  };
};

export const unlockTimesSuccess = (data) => {
  return {
    type: UNLOCK_TIMES_SUCCESS,
    payload: data,
  };
};

export const unlockTimesFailure = (error) => {
  return {
    type: UNLOCK_TIMES_FAILURE,
    payload: error,
  };
};

export const lockTimes = (month) => {
  return async (dispatch, store) => {
    const { headerAuth, pernr } = store().auth.user;
    const { client, selectedSystem } = store().settings;
    const { protocol, ip } = selectedSystem;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
    };

    const firstDate = moment(month).format('yyyy-MM-DD');

    dispatch(lockTimesStart());

    const url = `${getMiddleware()}/sitf/lock/?url=${
      protocol === 'http://' ? 'https://' : protocol
    }${ip}:1443/sap/bc/srt/rfc/sif/rs_mob_main_lock/${client}/mob_main_lock/mob_main_lock`;

    try {
      const res = await axios.post(
        url,
        {
          I_DATE: firstDate,
          I_MODULE: 'CATS_MAIN',
          I_LFNR: '',
          I_PERNR: pernr,
          I_USER: 'SHAXHIJA',
          I_LFART: '',
        },
        config
      );
      if (res.status === 200) {
        dispatch(lockTimesSuccess());
      }
    } catch (error) {
      if (error.response) {
        dispatch(
          lockTimesFailure(
            !error.response.error
              ? 'Ein unbekannter Fehler ist aufgetreten'
              : null,
            error.response.error
          )
        );
      } else {
        dispatch(
          lockTimesFailure('Ein unbekannter Fehler ist aufgetreten', null)
        );
      }
    }
  };
};

export const unlockTimes = (month) => {
  return async (dispatch, store) => {
    const { headerAuth, pernr } = store().auth.user;
    const { client, selectedSystem } = store().settings;
    const { protocol, ip } = selectedSystem;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        'sap-language': 'DE',
        'sap-client': `${client}`,
      },
    };

    const firstDate = moment(month).format('yyyy-MM-DD');

    dispatch(unlockTimesStart());

    const url = `${getMiddleware()}/sitf/Unlock/?url=${protocol}${ip}/sap/bc/srt/rfc/sif/rs_mob_main_unlock/${client}/mob_main_unlock/mob_main_unlock`;

    try {
      const res = await axios.post(
        url,
        {
          module: 'CATS_MAIN',
          user: '',
          lfnr: '',
          lfart: '',
          date: firstDate,
          pernr,
        },
        config
      );
      if (res.status === 200) {
        dispatch(unlockTimesSuccess(res.data));
      }
    } catch (error) {
      if (error.response) {
        let msg = 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(unlockTimesFailure(msg));
      } else {
        dispatch(unlockTimesFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

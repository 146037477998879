import API from '../../api/api';

import {
  FETCH_PERSON_PROFIL_START,
  FETCH_PERSON_PROFIL_SUCCESS,
  FETCH_PERSON_PROFIL_FAILURE,
  HAS_FETCHED_PERSON_PROFIL,
  CLEAR_ERROR,
  FETCH_EMPLOYEES_START,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_FAILURE,
  FETCH_PERSA_START,
  FETCH_PERSA_FAILURE,
  FETCH_PERSA_SUCCESS,
  FETCH_BTRTL_START,
  FETCH_BTRTL_SUCCESS,
  FETCH_BTRTL_FAILURE,
  RESET_STATE_PERSON,
  CLEAR_LOCAL_EMPLOYEES,
  RESET_BTRTL_LIST,
  FETCH_EMPLOYEE_START,
  FETCH_EMPLOYEE_SUCCESS,
  FETCH_EMPLOYEE_FAILURE,
} from './personTypes';

export const fetchPersonProfilesStart = () => {
  return {
    type: FETCH_PERSON_PROFIL_START,
  };
};

export const fetchPersonProfilesFailure = (error) => {
  return {
    type: FETCH_PERSON_PROFIL_FAILURE,
    payload: { error },
  };
};

export const fetchPersonProfilesSuccess = (persons) => {
  return {
    type: FETCH_PERSON_PROFIL_SUCCESS,
    payload: { persons },
  };
};

export const setHasFetchedPersons = (fetched) => {
  return {
    type: HAS_FETCHED_PERSON_PROFIL,
    payload: { fetched },
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERROR,
  };
};

export const fetchEmployeesStart = () => {
  return {
    type: FETCH_EMPLOYEES_START,
  };
};

export const fetchEmployeesSuccess = (employees, isFromSearchHelp) => {
  return {
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: { employees, isFromSearchHelp },
  };
};

export const fetchEmployeesFailure = (error) => {
  return {
    type: FETCH_EMPLOYEES_FAILURE,
    payload: { error },
  };
};

export const fetchSingleEmployeeStart = () => {
  return {
    type: FETCH_EMPLOYEE_START,
  };
};

export const fetchSingleEmployeeSuccess = (employee) => {
  return {
    type: FETCH_EMPLOYEE_SUCCESS,
    payload: { employee },
  };
};

export const fetchSingleEmployeeFailure = (error) => {
  return {
    type: FETCH_EMPLOYEE_FAILURE,
    payload: { error },
  };
};

export const clearLocalEmployees = () => {
  return {
    type: CLEAR_LOCAL_EMPLOYEES,
  };
};

export const fetchPersaListStart = () => {
  return {
    type: FETCH_PERSA_START,
  };
};

export const fetchPersaListSuccess = (persaList) => {
  return {
    type: FETCH_PERSA_SUCCESS,
    payload: { persaList },
  };
};

export const fetchPersaListFailure = (error) => {
  return {
    type: FETCH_PERSA_FAILURE,
    payload: { error },
  };
};

export const fetchBtrtlListStart = () => {
  return {
    type: FETCH_BTRTL_START,
  };
};

export const fetchBtrtlListSuccess = (btrtlList) => {
  return {
    type: FETCH_BTRTL_SUCCESS,
    payload: { btrtlList },
  };
};

export const fetchBtrtlListFailure = (error) => {
  return {
    type: FETCH_BTRTL_FAILURE,
    payload: { error },
  };
};

export const resetState = () => {
  return {
    type: RESET_STATE_PERSON,
  };
};

export const resetBtrtlList = () => {
  return {
    type: RESET_BTRTL_LIST,
  };
};

export const fetchEmployees = (
  auth,
  searchData,
  month,
  isFromSearchHelp = true
) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth, profile },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        profile,
        begda,
        endda,
        ...searchData,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchEmployeesStart());

    try {
      const res = await API.get('/SIF/time/employees', config);
      dispatch(fetchEmployeesSuccess(res.data || [], isFromSearchHelp));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.statusText || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchEmployeesFailure(msg));
      } else {
        dispatch(fetchEmployeesFailure('Fehlr'));
      }
    }
  };
};

export const fetchSingleEmployee = (auth, pernr, month) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth },
    } = auth;

    const begda = month.startOf('month').format('YYYYMMDD');
    const endda = month.endOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr,
        begda,
        endda,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchSingleEmployeeStart());

    try {
      const res = await API.get('/SIF/time/employee', config);
      dispatch(fetchSingleEmployeeSuccess(res.data || []));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.statusText || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchSingleEmployeeFailure(msg));
      } else {
        dispatch(fetchSingleEmployeeFailure('Fehlr'));
      }
    }
  };
};

export const fetchPersonProfiles = (auth) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchPersonProfilesStart());

    try {
      const res = await API.get('/SIF/time/profiles', config);
      dispatch(fetchPersonProfilesSuccess(res.data || []));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.statusText || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchPersonProfilesFailure(msg));
      } else {
        dispatch(fetchPersonProfilesFailure('Fehlr'));
      }
    }
  };
};

export const fetchPersaList = (auth, persa, name1) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        persa,
        name1,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchPersaListStart());

    try {
      const res = await API.get('SIF/f4//SIF/HR_SH_PERSA', config);
      dispatch(fetchPersaListSuccess(res.data || []));
    } catch (error) {
      if (error.message) {
        let msg = error.message || 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchPersaListFailure(msg));
      } else {
        dispatch(fetchPersaListFailure('Fehlr'));
      }
    }
  };
};

export const fetchBtrtlList = (auth, werks, btrtl, btext) => {
  return async (dispatch, store) => {
    const {
      user: { headerAuth },
    } = auth;

    const config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        werks,
        btrtl,
        btext,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchBtrtlListStart());

    try {
      const res = await API.get('SIF/f4//SIF/HR_SH_BTRTL', config);
      dispatch(fetchBtrtlListSuccess(res.data || []));
    } catch (error) {
      if (error.message) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchBtrtlListFailure(msg));
      } else {
        dispatch(fetchBtrtlListFailure('Fehlr'));
      }
    }
  };
};

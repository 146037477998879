export const SAVE_VORLAGE_START = 'SAVE_VORLAGE_START';
export const SAVE_VORLAGE_SUCCESS = 'SAVE_VORLAGE_SUCCESS';
export const SAVE_VORLAGE_FAILURE = 'SAVE_VORLAGE_FAILURE';
export const COPY_VORLAGE_START = 'COPY_VORLAGE_START';
export const COPY_VORLAGE_SUCCESS = 'COPY_VORLAGE_SUCCESS';
export const COPY_VORLAGE_FAILURE = 'COPY_VORLAGE_FAILURE';
export const DELETE_VORLAGE_START = 'DELETE_VORLAGE_START';
export const DELETE_VORLAGE_SUCCESS = 'DELETE_VORLAGE_SUCCESS';
export const DELETE_VORLAGE_FAILURE = 'DELETE_VORLAGE_FAILURE';
export const TOGGLE_EDITED_VORLAGE = 'TOGGLE_EDITED_VORLAGE';

import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import Login from './components/pages/login/Login';
import Zeit from './components/pages/zeit/Zeit';
import PrivateRoute from './components/routing/PrivateRoute';
import Print from './components/pages/print/Print';

import ModalRoot from './components/modals/ModalRoot';

import { version } from '../package.json';

import { clearOrReplaceLocalStorage } from './utils/clearOrReplaceLocalStorage';

import { persistor } from './redux/store';

const App = () => {
  useEffect(() => {
    const didClearOrReplace = clearOrReplaceLocalStorage(version);
    if (didClearOrReplace) {
      setTimeout(() => persistor.purge(), 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch>
        <Route exact path='/' component={Login} />
        <PrivateRoute exact path='/print' component={Print} />
        <PrivateRoute exact path='/zeiterfassung' component={Zeit} />
      </Switch>
      <ModalRoot />
    </>
  );
};

export default App;

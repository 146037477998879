import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Modal, message } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import BukrsTable from './BukrsTable';

import {
  fetchBukrs,
  setHasFetchedBukrs,
  clearErrors,
} from '../../../../redux/bukrs/bukrsActions';

import BukrsTableStyle from './BukrsTableStyle';

import styles from './BukrsModal.module.css';
import { BUKRS } from '../../modalTypes';

const BukrsModal = ({
  auth,
  fetchBukrs,
  bukrs,
  error,
  isFetchingBukrs,
  hasFetchedBukrs,
  clearErrors,
  currentModals,
  hideModal,
  setHasFetchedBukrs,
  selectedBukrs,
}) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    bukrs: '',
    butxt: '',
  });

  const [columns] = useState([
    {
      Header: 'Buchungskreis',
      accessor: 'bukrs',
      width: 40,
      minWidth: 40,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'butxt',
    },
  ]);

  useEffect(() => {
    if (hasFetchedBukrs) {
      setData(bukrs);
      setHasFetchedBukrs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedBukrs]);

  useEffect(() => {
    if (error) {
      message.error(error).then(() => {
        clearErrors();
      });
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!hasFetchedBukrs) {
      for (let i = 1; i <= 10; i++) {
        let row = {
          id: i,
          bukrs: '',
          butxt: '',
        };
        setData((data) => [...data, row]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onSearch = () => {
    fetchBukrs(auth, {
      bukrs: searchData.bukrs,
      butxt: searchData.butxt,
    });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };

  const handleCancel = () => {
    hideModal(BUKRS);
  };

  const handleOk = () => {
    hideModal(BUKRS);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(BUKRS) ? true : false}
      title='Suchhilfe für Buchungskreis'
      onOk={handleOk}
      style={{ top: '155px' }}
      onCancel={handleCancel}
      width={1000}
      closable={true}
      footer={null}
    >
      <div className={styles.bukrsHeader}>
        <div className={styles.row1}>
          <Input
            placeholder='Buchungskreis'
            className={styles.bukrs}
            name='bukrs'
            value={searchData.bukrs}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Input
            placeholder='Bezeichnung'
            className={styles.bezeichnung}
            name='butxt'
            value={searchData.butxt}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Button
            type='primary'
            className={styles.searchBtn}
            icon={<SearchOutlined />}
            loading={isFetchingBukrs}
            onClick={onSearch}
          >
            Suchen
          </Button>
        </div>
      </div>
      <BukrsTableStyle>
        <BukrsTable
          columns={columnsMemo}
          data={data}
          selectedBukrs={selectedBukrs}
        />
      </BukrsTableStyle>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  bukrs: { bukrs, error, isFetchingBukrs, hasFetchedBukrs },
}) => ({
  auth,
  bukrs,
  error,
  isFetchingBukrs,
  hasFetchedBukrs,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBukrs: (auth, searchData) => dispatch(fetchBukrs(auth, searchData)),
  setHasFetchedBukrs: (fetched) => dispatch(setHasFetchedBukrs(fetched)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BukrsModal);

import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import InfoAbwesenTable from './InfoAbwesenTable';
import EditableInfoCell from '../../../../editable-cells/EditableInfoCell';
import EditableSearchCell from '../../../../editable-cells/EditableSearchCell';

import { ReactComponent as TextIcon } from '../../../../../assets/text.svg';
import { ReactComponent as TextIconFilled } from '../../../../../assets/textFilled.svg';
import Icon from '@ant-design/icons';
import { message } from 'antd';

import { addZeroes } from '../../../../../utils/addZeroes';

import { setHasFetchedPspKost } from '../../../../../redux/psp-kost/pspKostActions';
import {
  updateCostCenterOrWbs,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
} from '../../../../../redux/time/timeActions';

import moment from 'moment';

import Style from '../InfoTableStyle';
import { INFO_TIMESTAMPS } from '../../../modalParentTypes';

import { convertHoursToDecimal } from '../../../../../utils/convertHoursToDecimal';
import { getTimeDuration } from '../../../../../utils/timeDuration';

const InfoAbwesen = ({
  showLangTextModal,
  tempTimestamps,
  currentDate,
  updateTimestamp,
  setHasError,
  hasFetchedPspKostOnTimestamps,
  pspKost,
  editedPspKostCell,
  pspKostError,
  setHasFetchedPspKost,
  updateCostCenterOrWbs,
  oldPspValue,
  setLoading,
  setLoadingOnEditableSearchCell,
  setErrorOnEditableSearchCell,
  isInfoReadOnly,
  onlyDisplay,
}) => {
  const selectedCostCenterOrWbs = (cell, costCenterOrWbs, text) => {
    updateCostCenterOrWbs(
      cell.row.index,
      costCenterOrWbs,
      text,
      INFO_TIMESTAMPS,
      moment(currentDate._d).format('YYYY-MM-DD'),
      oldPspValue
    );
  };

  const [columns] = useState([
    {
      Header: <div>Beginnzeit</div>,
      Footer: '',
      accessor: 'timeFrom',
      width: 60,
      totalWidth: 60,
      maxWidth: 60,
      Cell: (cellInfo) => {
        return (
          <EditableInfoCell
            initialValue={cellInfo.value}
            {...cellInfo}
            workdate={currentDate._d}
            updateMyData={updateMyData}
            numberFormat={'##:##:##'}
            setHasError={setHasError}
            parent={INFO_TIMESTAMPS}
            readOnly={
              isInfoReadOnly || cellInfo.row.original.isReadOnly || onlyDisplay
            }
          />
        );
      },
    },
    {
      Header: <div>Endezeit</div>,
      Footer: '',
      accessor: 'timeTo',
      width: 50,
      totalWidth: 50,
      maxWidth: 50,
      Cell: (cellInfo) => (
        <EditableInfoCell
          initialValue={cellInfo.value}
          {...cellInfo}
          workdate={currentDate._d}
          updateMyData={updateMyData}
          parent={INFO_TIMESTAMPS}
          numberFormat={'##:##:##'}
          setHasError={setHasError}
          readOnly={
            isInfoReadOnly || cellInfo.row.original.isReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <div style={{ textAlign: 'right' }}>Stunden</div>,
      accessor: 'quantity',
      width: 50,
      totalWidth: 50,
      maxWidth: 50,
      Cell: (cellInfo) => {
        return (
          <div style={{ textAlign: 'right' }}>
            {convertHoursToDecimal(
              addZeroes(getTimeDuration(cellInfo.data[cellInfo.row.index]))
            ) || 0}
          </div>
        );
      },
      Footer: (cellInfo) => {
        // Only calculate total visits if rows change
        const total = React.useMemo(() => {
          const sum = cellInfo.rows.reduce(
            (sum, row) =>
              Number(
                convertHoursToDecimal(
                  Number(addZeroes(getTimeDuration(row.original)))
                )
              ) + sum,
            0
          );
          return sum;
        }, [cellInfo]);
        return (
          <div style={{ textAlign: 'right' }}>
            {parseFloat(total).toFixed(2)}
          </div>
        );
      },
    },
    {
      Header: <div>PSP/Kost.</div>,
      Footer: '',
      accessor: 'kostlOrPosid',
      width: 60,
      totalWidth: 60,
      maxWidth: 60,
      Cell: (cellInfo) => (
        <EditableSearchCell
          initialValue={cellInfo.value}
          {...cellInfo}
          setLoading={setLoading}
          workdate={currentDate._d}
          updateMyData={updateMyData}
          selectedCostCenterOrWbs={selectedCostCenterOrWbs}
          parent={INFO_TIMESTAMPS}
          readOnly={
            isInfoReadOnly || cellInfo.row.original.isReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <div>PSP/Kost.-Langtext</div>,
      Footer: '',
      accessor: 'kostlOrPosidText',
    },
    {
      Header: <div>Kurztext</div>,
      Footer: '',
      accessor: 'shortText',
      Cell: (cellInfo) => (
        <EditableInfoCell
          initialValue={cellInfo.value}
          {...cellInfo}
          workdate={currentDate._d}
          parent={INFO_TIMESTAMPS}
          updateMyData={updateMyData}
          readOnly={
            isInfoReadOnly || cellInfo.row.original.isReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <></>,
      Footer: '',
      accessor: 'action',
      Cell: (cellInfo) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon
            component={
              cellInfo.row.original.longText.length > 0
                ? TextIconFilled
                : TextIcon
            }
            style={{
              cursor: 'pointer',
              color:
                cellInfo.row.original.longText.length > 0
                  ? '#4091F7'
                  : undefined,
            }}
            onClick={() => showLangTextModal(cellInfo.cell, INFO_TIMESTAMPS)}
          />
        </div>
      ),
      width: 30,
      totalWidth: 30,
      maxWidth: 30,
    },
  ]);

  useEffect(() => {
    if (hasFetchedPspKostOnTimestamps) {
      if (pspKostError) {
        message.error(pspKostError);
      } else {
        const { costCenterOrWbs, text } = pspKost;
        updateCostCenterOrWbs(
          editedPspKostCell.row.index,
          costCenterOrWbs,
          text,
          INFO_TIMESTAMPS,
          moment(currentDate._d).format('YYYY-MM-DD'),
          oldPspValue
        );
      }
      setErrorOnEditableSearchCell(
        'kostlOrPosid',
        editedPspKostCell.row.index,
        pspKostError ? false : true,
        INFO_TIMESTAMPS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setLoadingOnEditableSearchCell(
        'kostlOrPosid',
        editedPspKostCell.row.index,
        false,
        INFO_TIMESTAMPS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setHasFetchedPspKost(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPspKostOnTimestamps]);

  const updateMyData = (rowIndex, columnId, value, isAbwesen = true) => {
    if (isAbwesen) {
      updateTimestamp(
        value,
        columnId,
        rowIndex,
        moment(currentDate._d).format('YYYY-MM-DD'),
        oldPspValue
      );
    }
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  const dataMemo = useMemo(
    () => {
      // let filteredTimestamps = [];

      // for (const [k, v] of Object.entries(tempTimestamps)) {
      //   if (k === moment(currentDate._d).format('YYYY-MM-DD')) {
      //     filteredTimestamps = v;
      //     break;
      //   }
      // }

      // return filteredTimestamps;
      return tempTimestamps[moment(currentDate._d).format('YYYY-MM-DD')];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, tempTimestamps]
  );

  return (
    <Style>
      <InfoAbwesenTable
        columns={columnsMemo}
        data={dataMemo || []}
        updateMyData={updateMyData}
        currentDate={currentDate}
        isInfoReadOnly={isInfoReadOnly}
        onlyDisplay={onlyDisplay}
      />
    </Style>
  );
};

const mapStateToProps = ({
  pspKost: {
    hasFetchedPspKostOnTimestamps,
    pspKost,
    editedPspKostCell,
    error: pspKostError,
    oldPspValue,
  },
}) => ({
  hasFetchedPspKostOnTimestamps,
  pspKost,
  editedPspKostCell,
  pspKostError,
  oldPspValue,
});

const mapDispatchToProps = (dispatch) => ({
  setHasFetchedPspKost: (hasFetchedPspKost) =>
    dispatch(setHasFetchedPspKost(hasFetchedPspKost)),
  updateCostCenterOrWbs: (
    rowIndex,
    costCenterOrWbs,
    text,
    parent,
    workdate,
    oldPspValue
  ) =>
    dispatch(
      updateCostCenterOrWbs(
        rowIndex,
        costCenterOrWbs,
        text,
        parent,
        workdate,
        oldPspValue
      )
    ),
  setErrorOnEditableSearchCell: (
    columnId,
    rowIndex,
    isValid,
    parent,
    workdate
  ) =>
    dispatch(
      setErrorOnEditableSearchCell(
        columnId,
        rowIndex,
        isValid,
        parent,
        workdate
      )
    ),
  setLoadingOnEditableSearchCell: (
    columnId,
    rowIndex,
    isLoading,
    parent,
    workdate
  ) =>
    dispatch(
      setLoadingOnEditableSearchCell(
        columnId,
        rowIndex,
        isLoading,
        parent,
        workdate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoAbwesen);

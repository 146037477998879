const stundenColumnType = {
  kostlOrPosid: 'kostlOrPosid',
  kostlOrPosidText: 'kostlOrPosidText',
  lgart: 'lgart',
  lgtxt: 'lgtxt',
  unit: 'unit',
  currency: 'currency',
  sum: 'sum',
  cells: 'cells',
};

export default stundenColumnType;

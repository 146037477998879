import API from '../../api/api';

import {
  FETCH_PRINT_START,
  FETCH_PRINT_FAILURE,
  FETCH_PRINT_SUCCESS,
  FETCHED_PRINT,
  CLEAR_ERROR,
} from './printTypes';

import { addLeadingZeroes } from '../../utils/addLeadingZeroes';
// import { getBasename } from '../../utils/getBasename';

export const fetchPrintStart = () => ({
  type: FETCH_PRINT_START,
});

export const fetchPrintSuccess = (doc) => ({
  type: FETCH_PRINT_SUCCESS,
  payload: doc,
});

export const fetchPrintFailure = (error) => ({
  type: FETCH_PRINT_FAILURE,
  payload: error,
});

export const setHasFetchedPrint = (hasFetched) => ({
  type: FETCHED_PRINT,
  payload: hasFetched,
});

export const clearErrors = () => ({
  type: CLEAR_ERROR,
});

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const fetchPrint = (user, month) => {
  // window.open(`${getBasename()}/print`, 'PDF Viewer');
  return async (dispatch, store) => {
    const { pernr, headerAuth } = user;

    const date = month.startOf('month').format('YYYYMMDD');

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        pernr: addLeadingZeroes(pernr, 10),
        date,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
      responseType: 'blob',
    };

    dispatch(fetchPrintStart());

    try {
      const res = await API.get('/SIF/time/print', config);
      dispatch(fetchPrintSuccess(res.data));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchPrintFailure(msg));
      } else {
        dispatch(fetchPrintFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

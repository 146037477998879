import { SHOW_MODAL, HIDE_MODAL } from './modaltypes';

export const showModal = (modalType, modalProps) => ({
  type: SHOW_MODAL,
  payload: { modalType, modalProps },
});

export const hideModal = (modalType) => ({
  type: HIDE_MODAL,
  payload: modalType,
});

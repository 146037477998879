import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Button, Input, Row, Col, Form, Radio, message } from 'antd';

import { TRAVEL_TIME } from '../../modalTypes';
import { TravelType } from '../../../../constants/travelType';

import { setTravelTime } from '../../../../redux/time/timeActions';

import styles from './TravelTimeModal.module.css';
import { addZeroes } from '../../../../utils/addZeroes';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const TravelTimeModal = ({
  currentModals,
  hideModal,
  cell,
  rowIndex,
  columnId,
  areTimesLocked,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { periodClosed, timeColumns, onlyDisplay } = useSelector(
    (state) => state.time
  );

  const onClose = () => {
    hideModal(TRAVEL_TIME);
  };

  const onDone = () => {
    const { menge, kurzText, travelType, travelDescriptionType } =
      form.getFieldsValue();

    if (!menge) {
      message.warn('Please fill out menge!');
    } else {
      let editedCell = { ...cell };
      editedCell.quantity = menge;
      editedCell.shortText = kurzText;

      // Reset travel options to set the appropriate one.
      editedCell.travelTime = false;
      editedCell.driveTime = false;
      editedCell.bothWays = false;
      editedCell.wayBack = false;
      editedCell.wayTo = false;

      if (travelType) {
        editedCell[travelType] = true;
      }

      if (travelDescriptionType) {
        editedCell[travelDescriptionType] = true;
      }

      hideModal(TRAVEL_TIME);
      dispatch(setTravelTime(rowIndex, columnId, editedCell));
    }
  };

  const handleChange = () => {
    form.setFieldsValue({ sights: [] });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onDone();
    }
  };

  const getTravelType = () => {
    let travelType = TravelType.TRAVEL_TIME;
    if (cell.driveTime) {
      travelType = TravelType.DRIVE_TIME;
    }
    return travelType;
  };

  const getTravelDescriptionType = () => {
    let travelWayType = TravelType.BOTH_WAYS;
    if (cell.wayTo) {
      travelWayType = TravelType.WAY_TO;
    } else if (cell.wayBack) {
      travelWayType = TravelType.WAY_BACK;
    }
    return travelWayType;
  };

  return (
    <Modal
      title={
        <Row align="middle">
          <Col flex="auto">
            <h3 style={{ marginBottom: '0' }}>Reisezeit</h3>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(TRAVEL_TIME) ? true : false}
      onCancel={() => onClose(true)}
      width={400}
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          Abbrechen
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onDone}
          disabled={
            timeColumns[columnId].isReadonly ||
            periodClosed ||
            areTimesLocked ||
            onlyDisplay
          }
        >
          Fertig
        </Button>,
      ]}
    >
      <Form
        labelCol={{
          span: 5,
        }}
        labelAlign="left"
        name="basic"
        initialValues={{
          menge: (cell && addZeroes(cell.quantity)) || 0,
          kurzText: (cell && cell.shortText) || '',
          travelType: getTravelType(),
          travelDescriptionType: getTravelDescriptionType(),
        }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        form={form}
      >
        <Form.Item label="Menge" name="menge">
          <Input
            style={{ width: '100px' }}
            disabled={
              timeColumns[columnId].isReadonly ||
              periodClosed ||
              areTimesLocked ||
              onlyDisplay
            }
          />
        </Form.Item>

        <Form.Item label="Kurztext" name="kurzText">
          <Input disabled={timeColumns[columnId].isReadonly || onlyDisplay} />
        </Form.Item>

        <div className={styles.radioButtonsContainer}>
          <Form.Item name="travelType">
            <Radio.Group
              disabled={
                timeColumns[columnId].isReadonly ||
                periodClosed ||
                areTimesLocked ||
                onlyDisplay
              }
            >
              <Radio style={radioStyle} value={TravelType.TRAVEL_TIME}>
                Reisezeit
              </Radio>
              <Radio style={radioStyle} value={TravelType.DRIVE_TIME}>
                Fahrzeit
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="travelDescriptionType">
            <Radio.Group
              disabled={
                timeColumns[columnId].isReadonly ||
                periodClosed ||
                areTimesLocked ||
                onlyDisplay
              }
            >
              <Radio style={radioStyle} value={TravelType.BOTH_WAYS}>
                Beide Wege
              </Radio>
              <Radio style={radioStyle} value={TravelType.WAY_TO}>
                Hinweg
              </Radio>
              <Radio style={radioStyle} value={TravelType.WAY_BACK}>
                Rückweg
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default TravelTimeModal;

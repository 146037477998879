export const addZeroes = (number, isSum = false) => {
  // Cast as number
  let num = Number(number);
  // If not a number, return 0
  if (isNaN(num)) {
    return 0;
  }
  // If there is no decimal, or the decimal is less than 2 digits, toFixed
  if (
    String(num).split('.').length < 2 ||
    String(num).split('.')[1].length <= 2
  ) {
    if (num > 99 && isSum) {
      num = `${String(num).split('.')[0]}.${String(num).split('.')[1]}`;
    }
    num = parseFloat(num).toFixed(2);
  }
  // Return the number
  return num;
};

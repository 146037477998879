import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTable, useAbsoluteLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import { useExportData } from 'react-table-plugins';
import { Dropdown, Button } from 'antd';

import exportMenu from '../../../../zeit/menu/ExportMenu';

import Icon from '@ant-design/icons';

import AddLineIcon from '../../../../../assets/AddLineIcon';
import DuplicateLineIcon from '../../../../../assets/DuplicateLineIcon';
import ExportIcon from '../../../../../assets/ExportIcon';
import RemoveLineIcon from '../../../../../assets/RemoveLineIcon';

import { addHour } from '../../../../../redux/time/timeActions';

import getExportFileBlob from '../../../../../utils/getExportFileBlob';
import { uniqueId } from '../../../../../utils/uniqueId';

import moment from 'moment';

import styles from './InfoStunden.module.css';
import DescribedButton from '../../../../button-tooltip/DescribedButton';
import {
  REMOVE_INFO_STUNDEN,
  DUPLICATE_INFO_STUNDEN,
} from '../../../../../utils/isValidActionButton';

const InfoStundenTable = ({
  columns,
  data,
  updateMyData,
  addHour,
  currentDate,
  isInfoReadOnly,
  timeColumns,
  onlyDisplay,
}) => {
  const getExportFileName = () => {
    return 'hours-data';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    exportData,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      updateMyData,
      getExportFileBlob,
      getExportFileName,
    },
    useSticky,
    useAbsoluteLayout,
    useExportData
  );

  useEffect(() => {
    setHiddenColumns(timeColumns.lstar.isHidden ? 'lstar' : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate]);

  const addEmptyHour = () => {
    addHour(moment(currentDate._d).format('YYYY-MM-DD'), {
      counter: uniqueId(),
      kostlOrPosid: '',
      kostlOrPosidText: '',
      quantity: 0,
      shortText: '',
      longText: [],
      isReadOnly: false,
      lstar: '',
      isApproved: false,
      deleted: '',
      isFetchingKostlOrPosid: false,
      isKostlOrPosidValid: true,
      isFetchingLstar: false,
      isLstarValid: true,
    });
  };

  const hasApprovedHours = () => {
    if (data.length === 0) return false;

    return data.some((hour) => hour.isApproved);
  };

  return (
    <Fragment>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>Stunden pro Projekt</h3>
        <div className={styles.zeitButtonsWrapper}>
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={AddLineIcon} />}
            tooltip={'Zeile hinzufügen'}
            onClick={addEmptyHour}
            type={'add'}
            disabled={isInfoReadOnly || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={RemoveLineIcon} />}
            tooltip={'Zeile löschen'}
            type={REMOVE_INFO_STUNDEN}
            disabled={hasApprovedHours() || isInfoReadOnly || onlyDisplay}
          />
          <DescribedButton
            style={styles.zeitTableButtons}
            icon={<Icon component={DuplicateLineIcon} />}
            tooltip={'Zeile duplizieren'}
            type={DUPLICATE_INFO_STUNDEN}
            disabled={isInfoReadOnly || onlyDisplay}
          />
          <Dropdown overlay={exportMenu(exportData)} trigger={['click']}>
            <Button
              className={styles.zeitTableButtons}
              icon={<Icon component={ExportIcon} />}
            />
          </Dropdown>
        </div>
      </div>
      <div className="tableWrap">
        <table
          {...getTableProps()}
          className="table sticky"
          id="infoStundenTable"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps();
                  headerProps.style = {
                    width: `${column.width}px`,
                    textAlign: 'left',
                  };

                  return (
                    <th {...headerProps} className="th header">
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="body">
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let props = cell.getCellProps();
                    props.style = {
                      width: `${cell.column.width}px`,
                    };

                    if (
                      isInfoReadOnly ||
                      data[row.index].isApproved ||
                      onlyDisplay
                    ) {
                      props.style['background'] = '#D5EBFF';
                    }

                    if (cell.column.id === 'kostlOrPosidText') {
                      props.style['background'] = '#D5EBFF';
                    }

                    if (
                      data[row.index].isReadOnly &&
                      (cell.column.id === 'kostlOrPosid' ||
                        cell.column.id === 'quantity')
                    ) {
                      props.style['background'] = '#D5EBFF';
                    }

                    return (
                      <td {...props} className="td">
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getHeaderGroupProps()} className="tr">
                {footerGroup.headers.map((column) => {
                  let style = {
                    ...column.getHeaderProps().style,
                    position: 'sticky',
                    bottom: 0,
                    background: '#FFFFA3',
                    border: 'none',
                    fontWeight: 'bold',
                  };
                  delete style.top;

                  let props = column.getHeaderProps();
                  props.style = style;

                  return (
                    <td {...props} className="td footer">
                      {column.render('Footer')}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ time: { timeColumns } }) => ({
  timeColumns,
});

const mapDispatchToProps = (dispatch) => ({
  addHour: (workdate, hour) => dispatch(addHour(workdate, hour)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoStundenTable);

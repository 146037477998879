import React from 'react';
import { connect } from 'react-redux';
import { useTable, useAbsoluteLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { hideModal } from '../../../../redux/modals/modalActions';
import { NoDataTable } from '../../../no-data/NoDataTable';

const TimeBalanceModalTable = ({ columns, data, hideModal }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSticky,
    useAbsoluteLayout
  );

  return (
    <div>
      <div className='tableWrap'>
        <table
          {...getTableProps()}
          className='table sticky'
          id='timeBalanceModalTable'
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className='tr'>
                {headerGroup.headers.map((column) => {
                  const headerProps = column.getHeaderProps();
                  headerProps.style = {
                    width: `${column.width}px`,
                    textAlign: 'left',
                  };

                  return (
                    <th {...headerProps} className='th header'>
                      {column.render('Header')}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className='body'>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    let props = cell.getCellProps();
                    props.style = {
                      width: `${cell.column.width}px`,
                    };

                    return (
                      <td {...props} className='td'>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {rows.length === 0 && <NoDataTable />}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
});

export default connect(null, mapDispatchToProps)(TimeBalanceModalTable);

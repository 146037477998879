import {
  FETCHED_ABSENCE_TYPE,
  FETCH_ABSENCE_TYPE_FAILURE,
  FETCH_ABSENCE_TYPE_SUCCESS,
  FETCH_ABSENCE_TYPES_FAILURE,
  FETCH_ABSENCE_TYPES_SUCCESS,
  CLEAR_ERRORS,
} from './absenceTypeTypes';

const initialState = {
  absenceType: null,
  absenceTypes: [],
  error: null,
  hasFetchedAbsenceType: false,
  hasFetchedAbsenceTypes: false,
  editedAbsenceTypeCell: null,
};

const absenceTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ABSENCE_TYPE_SUCCESS:
      return {
        ...state,
        absenceType: action.payload.absenceType,
        hasFetchedAbsenceType: true,
        editedAbsenceTypeCell: action.payload.cellBeingEdited,
        error: null,
      };
    case FETCH_ABSENCE_TYPE_FAILURE:
      return {
        ...state,
        absenceType: {
          awart: action.payload.absenceType.absence_type,
          atext: '',
        },
        error: action.payload.error,
        hasFetchedAbsenceType: true,
        editedAbsenceTypeCell: action.payload.cellBeingEdited,
      };
    case FETCHED_ABSENCE_TYPE:
      return {
        ...state,
        hasFetchedAbsenceType: action.payload,
        hasFetchedAbsenceTypes: action.payload,
      };
    case FETCH_ABSENCE_TYPES_SUCCESS:
      return {
        ...state,
        absenceTypes: action.payload,
        hasFetchedAbsenceTypes: true,
        error: null,
      };
    case FETCH_ABSENCE_TYPES_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default absenceTypeReducer;

import React, { useState } from 'react';
import { Modal, Menu } from 'antd';

import PSPElement from './psp/PSPElement';
import Kostenstelle from './kost/Kostenstelle';

import styles from './PSPKostModal.module.css';

import { PSP_KOST } from '../../modalTypes';

const PSPKostModal = ({
  currentModals,
  hideModal,
  cell,
  selectedCostCenterOrWbs,
}) => {
  const [current, setCurrent] = useState('psp');

  const handleCancel = () => {
    hideModal(PSP_KOST);
  };

  const handleOk = () => {
    hideModal(PSP_KOST);
  };

  const handleMenuClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Modal
      visible={currentModals.hasOwnProperty(PSP_KOST) ? true : false}
      title='Suchhilfe für PSP-Element/Kostenstelle'
      onOk={handleOk}
      onCancel={handleCancel}
      width={1200}
      closable={true}
      footer={null}
    >
      <div className={styles.menu}>
        <Menu
          onClick={handleMenuClick}
          selectedKeys={[current]}
          mode='horizontal'
        >
          <Menu.Item key='psp'>PSP-Element</Menu.Item>
          <Menu.Item key='kost'>Kostenstelle</Menu.Item>
        </Menu>
      </div>
      <div className={styles.content}>
        {current === 'psp' ? (
          <PSPElement
            cell={cell}
            selectedCostCenterOrWbs={selectedCostCenterOrWbs}
          />
        ) : (
          <Kostenstelle
            cell={cell}
            selectedCostCenterOrWbs={selectedCostCenterOrWbs}
          />
        )}
      </div>
    </Modal>
  );
};

export default PSPKostModal;

import {
  LOCK_TIMES_START,
  LOCK_TIMES_FAILURE,
  LOCK_TIMES_SUCCESS,
  UNLOCK_TIMES_FAILURE,
  UNLOCK_TIMES_START,
  UNLOCK_TIMES_SUCCESS,
} from './lockTypes';

const initialState = {
  areTimesLocked: false,
  warningLockingTimes: null,
  errorLockingTimes: null,
  isLockingOrUnlockingTimes: false,
  lockedMonth: null,
};

const lockReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOCK_TIMES_START: {
      return {
        ...state,
        isLockingOrUnlockingTimes: true,
      };
    }
    case LOCK_TIMES_SUCCESS: {
      return {
        ...state,
        isLockingOrUnlockingTimes: false,
      };
    }
    case LOCK_TIMES_FAILURE: {
      const { warning, error } = payload;

      return {
        ...state,
        isLockingOrUnlockingTimes: false,
        warningLockingTimes: warning,
        errorLockingTimes: error,
      };
    }
    case UNLOCK_TIMES_START: {
      return {
        ...state,
        isLockingOrUnlockingTimes: true,
      };
    }
    case UNLOCK_TIMES_SUCCESS: {
      return {
        ...state,
        isLockingOrUnlockingTimes: false,
      };
    }
    case UNLOCK_TIMES_FAILURE: {
      return {
        ...state,
        isLockingOrUnlockingTimes: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default lockReducer;

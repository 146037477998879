import React from 'react';
import { connect } from 'react-redux';
import { hideModal, showModal } from '../../redux/modals/modalActions';
import {
  setEditingCell,
  updateTimestamp,
  updateHour,
  saveTimes,
  setTimestampHoursFromTemp,
  resetTempTimestampHours,
  updateShortText,
  updateLongText,
  setCurrentMonth,
  setHasUpdatedHourOrTimestamp,
} from '../../redux/time/timeActions';

import PersonModal from './zeit/person/PersonModal';
import AbwesenModal from './zeit/abwesen/AbwesenModal';
import LangModal from './zeit/langtext/LangModal';
import KurzModal from './zeit/kurztext/KurzModal';
import InfoModal from './zeit/info/InfoModal';
import PSPKostModal from './zeit/psp-kost/PSPKostModal';
import AbsenceTypeModal from './zeit/absence-type/AbsenceTypeModal';
import WageTypeModal from './zeit/wage-type/WageTypeModal';
import TimeBalanceModal from './zeit/time-balance/TimeBalanceModal';
import SaveLayoutModal from './zeit/vorlage/SaveLayoutModal';
import CopyLayoutModal from './zeit/vorlage/CopyLayoutModal';
import DeleteLayoutModal from './zeit/vorlage/DeleteLayoutModal';
import ReleaseTimesModal from './zeit/release-times/ReleaseTimesModal';
import SaveChangesModal from './save-changes/SaveChangesModal';
import ProfitCenterModal from './zeit/prctr/ProfitCenterModal';
import BukrsModal from './zeit/bukrs/BukrsModal';
import KokrsModal from './zeit/kokrs/KokrsModal';
import PersonProfilesModal from './zeit/person/person-profiles/PersonProfilesModal';
import EmployeesModal from './zeit/person/employees/EmployeesModal';
import PersaBtrtlModal from './zeit/person/persa-btrtl/PersaBtrtlModal';
import TravelTimeModal from './zeit/travel-time/TravelTimeModal';
import ChangePasswordModal from './change-password/ChangePasswordModal';
import LeisArtModal from './zeit/leisart/LeisArtModal';

import {
  PERSONEN,
  ABWESEN,
  LANGTEXT,
  KURZTEXT,
  INFO,
  PSP_KOST,
  ABSENCE_TYPES,
  WAGE_TYPES,
  TIME_BALANCE,
  COPY_LAYOUT,
  DELETE_LAYOUT,
  SAVE_LAYOUT,
  RELEASE_TIMES,
  SAVE_CHANGES,
  PRCTR,
  BUKRS,
  KOKRS,
  PERSON_PROFILE,
  EMPLOYEES,
  PERSA_BTRTL,
  TRAVEL_TIME,
  CHANGE_PASSWORD,
  LEISART,
} from './modalTypes';

const MODAL_COMPONENTS = {
  [PERSONEN]: PersonModal,
  [ABWESEN]: AbwesenModal,
  [LANGTEXT]: LangModal,
  [KURZTEXT]: KurzModal,
  [INFO]: InfoModal,
  [PSP_KOST]: PSPKostModal,
  [ABSENCE_TYPES]: AbsenceTypeModal,
  [WAGE_TYPES]: WageTypeModal,
  [TIME_BALANCE]: TimeBalanceModal,
  [COPY_LAYOUT]: CopyLayoutModal,
  [SAVE_LAYOUT]: SaveLayoutModal,
  [DELETE_LAYOUT]: DeleteLayoutModal,
  [RELEASE_TIMES]: ReleaseTimesModal,
  [SAVE_CHANGES]: SaveChangesModal,
  [PRCTR]: ProfitCenterModal,
  [BUKRS]: BukrsModal,
  [KOKRS]: KokrsModal,
  [PERSON_PROFILE]: PersonProfilesModal,
  [EMPLOYEES]: EmployeesModal,
  [PERSA_BTRTL]: PersaBtrtlModal,
  [TRAVEL_TIME]: TravelTimeModal,
  [CHANGE_PASSWORD]: ChangePasswordModal,
  [LEISART]: LeisArtModal,
};

const ModalRoot = (props) => {
  const { currentModals } = props;

  if (
    !currentModals ||
    (Object.keys(currentModals).length === 0 &&
      currentModals.constructor === Object)
  ) {
    return null;
  }

  const shownModals = Object.entries(currentModals).map(
    ([modalType, modalProps], i) => {
      const SpecificModal = MODAL_COMPONENTS[modalType];
      return <SpecificModal {...modalProps} {...props} key={modalType} />;
    }
  );

  return shownModals;
};

const mapStateToProps = ({
  auth: { user },
  modal: { currentModals },
  time: {
    cellBeingEdited,
    hours,
    tempHours,
    times,
    absences,
    timestamps,
    tempTimestamps,
    timeColumns,
    absenceColumns,
    regularHours,
    loadingWhenSavingTimes,
    savedTimes,
    currentMonth,
    hasUpdatedHours,
    periodClosed,
    onlyDisplay,
  },
  pspKost: {
    isFetchingPspKost,
    hasFetchedPspKostOnHours,
    hasFetchedPspKostOnTimestamps,
    error,
  },
  prctr: { isFetchingPrctr, hasFetchedPrctr, error: prctrError, prctrList },
  personProfiles: {
    profiles,
    error: personProfileError,
    isFetchingProfiles,
    hasFetchedProfiles,
  },
  lock: { areTimesLocked },
}) => ({
  user,
  currentModals,
  times,
  absences,
  cellBeingEdited,
  hours,
  currentMonth,
  tempHours,
  timestamps,
  tempTimestamps,
  loadingWhenSavingTimes,
  savedTimes,
  timesPost: {
    timeColumns,
    absenceColumns,
    regularHours,
    absences,
    times,
    hours,
    periodClosed,
    onlyDisplay,
  },
  isFetchingPspKost,
  hasFetchedPspKostOnHours,
  hasFetchedPspKostOnTimestamps,
  error,
  hasUpdatedHours,
  isFetchingPrctr,
  profiles,
  personProfileError,
  isFetchingProfiles,
  hasFetchedProfiles,
  areTimesLocked,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: (modalType) => dispatch(hideModal(modalType)),
  showModal: (modalType, modalProps) =>
    dispatch(showModal(modalType, modalProps)),
  saveTimes: (auth, timesPost, month) =>
    dispatch(saveTimes(auth, timesPost, month)),
  setEditingCell: (cell) => dispatch(setEditingCell(cell)),
  updateTimestamp: (
    editedtimestamp,
    timestampColumn,
    timestampIndex,
    workdate
  ) =>
    dispatch(
      updateTimestamp(
        editedtimestamp,
        timestampColumn,
        timestampIndex,
        workdate
      )
    ),
  updateShortText: (columnId, rowIndex, shortText, parent) =>
    dispatch(updateShortText(columnId, rowIndex, shortText, parent)),
  updateLongText: (columnId, rowIndex, longText, parent) =>
    dispatch(updateLongText(columnId, rowIndex, longText, parent)),
  updateHour: (editedHour, hourColumn, hourIndex, workdate) =>
    dispatch(updateHour(editedHour, hourColumn, hourIndex, workdate)),
  setTimestampHoursFromTemp: (tempTimestamps, tempHours) =>
    dispatch(setTimestampHoursFromTemp(tempTimestamps, tempHours)),
  resetTempTimestampHours: () => dispatch(resetTempTimestampHours()),
  setCurrentMonth: (month) => dispatch(setCurrentMonth(month)),
  setHasUpdatedHourOrTimestamp: () => dispatch(setHasUpdatedHourOrTimestamp()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, message } from 'antd';
import { Modal, Button, Row, Col, Form } from 'antd';
import locale from 'antd/lib/date-picker/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de-ch';

import { setAuth } from '../../../../redux/auth/authActions';

import { EMPLOYEES, PERSONEN, PERSON_PROFILE } from '../../modalTypes';

import styles from './PersonModal.module.css';
import Search from 'antd/lib/input/Search';
import {
  fetchPersonProfiles,
  resetState,
  fetchEmployees,
  clearLocalEmployees,
  fetchSingleEmployee,
  clearErrors,
} from '../../../../redux/person-aus/personActions';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const PersonModal = ({
  currentModals,
  hideModal,
  auth,
  setAuth,
  setCurrentMonth,
  currentMonth,
  showModal,
  fetchPersonProfiles,
  profiles,
  resetState,
  localEmployees,
  clearLocalEmployees,
  fetchSingleEmployee,
  isFetchingEmployees,
  hasFetchedEmployees,
  clearErrors,
  error,
  onlyDisplay,
}) => {
  const { user } = auth;
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth || moment());
  const [selectedPersonLocal, setSelectedPersonLocal] = useState(user);
  const [shouldDisableFertig, setShouldDisableFertig] = useState(false);
  const [shouldDisablePernrField, setShouldDisablePernrField] =
    useState(isFetchingEmployees);
  const [clickedSearch, setClickedSearch] = useState(false);

  const [searchData, setSearchData] = useState({
    vorna: '',
    nachn: '',
    persa: '',
    btrtl: '',
    currentMonthSearch: currentMonth,
  });

  const {
    profile: authProfile,
    profileText: authProfileText,
    pernr: authPernr,
    ename: authEname,
  } = user;

  const [pernr, setPernr] = useState(authPernr || '');
  const [ename, setEname] = useState(authEname || '');
  const [profile, setProfile] = useState(authProfile || '');
  const [profileText, setProfileText] = useState(authProfileText || '');

  useEffect(() => {
    fetchPersonProfiles(auth);
    //fetchEmployees(auth, searchData, currentMonth, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShouldDisablePernrField(isFetchingEmployees);
    setShouldDisableFertig(isFetchingEmployees);
  }, [isFetchingEmployees]);

  useEffect(() => {
    if (error && !currentModals.hasOwnProperty(EMPLOYEES)) {
      message.error(error);
      clearErrors();
      setShouldDisableFertig(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (hasFetchedEmployees) {
      if (localEmployees[0]) {
        const employee = localEmployees[0];
        setPernr(employee.employeeNumber);
        setEname(employee.firstName + ' ' + employee.lastName);
        setSelectedPersonLocal({
          costCenter: employee.personnelArea,
          costCenterText: '',
          ename: employee.firstName + ' ' + employee.lastName,
          pernr: employee.employeeNumber,
        });
        setShouldDisableFertig(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedEmployees]);

  const handleCancel = () => {
    hideModal(PERSONEN);
  };

  const handleOk = () => {
    setLoading(true);
    user.pernr = pernr;
    user.costCenter = selectedPersonLocal.costCenter;
    user.ename = selectedPersonLocal.ename;
    user.costCenterText = selectedPersonLocal.costCenterText;
    user.profile = profile;
    user.profileText = profileText;
    setAuth(auth);
    setCurrentMonth(moment(selectedMonth));
    hideModal(PERSONEN);
  };

  const onFinish = (values) => {};

  const onFinishFailed = (errorInfo) => {};

  const monthDidChange = (date) => {
    clearLocalEmployees();
    setSelectedMonth(date._d);
    setSearchData({ ...searchData, currentMonthSearch: moment(date._d) });
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues['personnelNumber']) {
      setPernr(changedValues['personnelNumber'] || '');
    }
  };

  const onChangedProfile = (e) => {
    let profile = profiles.find(
      (profile) => profile.profile === e.target.value
    );
    if (profile) {
      setProfile(profile.profile);
      setProfileText(profile.description);
      setShouldDisableFertig(false);
    } else {
      setProfile(e.target.value);
      setProfileText('');
      setShouldDisableFertig(true);
    }
  };

  const selectedProfile = (row) => {
    setProfile(row.profile);
    setProfileText(row.profileText);
    setShouldDisableFertig(false);
  };

  const selectedPerson = (row) => {
    setPernr(row.values.employeeNumber);
    setEname(row.values.firstName + ' ' + row.values.lastName);
    setSelectedPersonLocal({
      costCenter: row.values.personnelArea,
      costCenterText: '',
      ename: row.values.firstName + ' ' + row.values.lastName,
      pernr: row.values.employeeNumber,
    });
  };

  const onPersonKeyDown = (e) => {
    if (e.keyCode === 13) {
      showModal(EMPLOYEES, { selectedPerson, resetState });
    }
  };

  const onPernrChanged = (e) => {
    setPernr(e.target.value);
    setEname('');
    setShouldDisableFertig(e.target.value === '');
  };

  const onSearchProfile = () => {
    showModal(PERSON_PROFILE, { selectedProfile });
  };

  const onSearchPerson = () => {
    setClickedSearch(true);
    showModal(EMPLOYEES, { selectedPerson, resetState });
  };

  const onPernrBlur = (e) => {
    if (clickedSearch) {
      setClickedSearch(!clickedSearch);
    } else {
      fetchSingleEmployee(auth, pernr, searchData.currentMonthSearch);
    }
  };

  return (
    <Modal
      visible={currentModals.hasOwnProperty(PERSONEN) ? true : false}
      title="Bitte wählen Sie eine Person aus"
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      closable={true}
      // footer={[
      //   <Button key='tempPerson' icon={<Icon component={TempPerson} />}>
      //     Temporary Person
      //   </Button>,
      //   <Button key='abstractPeriod' icon={<Icon component={Period} />}>
      //     Abstract of the period
      //   </Button>,
      // ]}
      footer={null}
    >
      <Form
        {...layout}
        style={{ marginTop: '20px', marginBottom: '20px' }}
        name="basic"
        initialValues={{
          remember: true,
          personnelNumber: pernr,
          entryProfile: profile,
          entryDate: currentMonth,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Row gutter={15} className={styles.pernrRows}>
          <Col span={13}>
            <Form.Item
              label="Erfassungsprofil"
              className={styles.pernrFormItem}
              style={{ verticalAlign: 'center' }}
            >
              <Search
                placeholder=""
                onChange={onChangedProfile}
                value={profile}
                onSearch={onSearchProfile}
                disabled={onlyDisplay}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <p className={styles.pernrDescriptions}>{profileText || ''}</p>
          </Col>
        </Row>

        <Row gutter={15} className={styles.pernrRows}>
          <Col span={13}>
            <Form.Item
              label="Erfassungsperiode"
              name="entryDate"
              className={styles.pernrFormItem}
            >
              <DatePicker
                className="pernrMonthPicker"
                onChange={monthDidChange}
                allowClear={false}
                picker="month"
                locale={locale}
                disabled={onlyDisplay}
                inputReadOnly={true}
                format={'MMMM'}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={11}></Col>
        </Row>

        <Row gutter={15} className={styles.pernrRows}>
          <Col span={13}>
            <Form.Item label="Personalnummer" className={styles.pernrFormItem}>
              <Search
                id="pernrInput"
                value={pernr}
                placeholder="Enter Number"
                disabled={shouldDisablePernrField || onlyDisplay}
                loading={shouldDisablePernrField}
                onSearch={onSearchPerson}
                onKeyDown={onPersonKeyDown}
                onChange={onPernrChanged}
                onBlur={onPernrBlur}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <p className={styles.pernrDescriptions}>{ename || ''}</p>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={13} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleOk}
              disabled={shouldDisableFertig || onlyDisplay}
              loading={loading}
              style={{ width: '40%', margin: '10px 0 15px 0' }}
            >
              Fertig
            </Button>
          </Col>
          <Col span={11}></Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  time: { currentMonth },
  personProfiles: {
    localEmployees,
    isFetchingEmployees,
    hasFetchedEmployees,
    error,
  },
}) => ({
  auth,
  currentMonth,
  localEmployees,
  isFetchingEmployees,
  hasFetchedEmployees,
  error,
});

const mapDispatchToProps = (dispatch) => ({
  setAuth: (auth) => dispatch(setAuth(auth)),
  fetchPersonProfiles: (auth) => dispatch(fetchPersonProfiles(auth)),
  resetState: () => dispatch(resetState()),
  fetchEmployees: (auth, searchData, month, isFromSearchHelp) =>
    dispatch(fetchEmployees(auth, searchData, month, isFromSearchHelp)),
  fetchSingleEmployee: (auth, pernr, month) =>
    dispatch(fetchSingleEmployee(auth, pernr, month)),
  clearLocalEmployees: () => dispatch(clearLocalEmployees()),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonModal);

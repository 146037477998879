import XLSX from 'xlsx';
import Papa from 'papaparse';
import moment from 'moment';

const getExportFileBlob = ({ columns, data, fileType, fileName }) => {
  if (fileType === 'csv') {
    // CSV example
    const headerNames = columns.map((c) => {
      if (moment(c.exportValue).isValid()) {
        return moment(c.exportValue).format('DD.MM.YYYY');
      }
      return c.Header.props.children;
    });
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: 'text/csv' });
  } else if (fileType === 'xlsx') {
    // XLSX example

    const header = columns.map((c) => {
      if (moment(c.exportValue).isValid()) {
        return moment(c.exportValue).format('DD.MM.YYYY');
      }
      return c.Header.props.children;
    });

    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });

    XLSX.utils.book_append_sheet(wb, ws1, 'Zeit Data');
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }

  // Other formats goes here
  return false;
};

export default getExportFileBlob;

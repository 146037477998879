import {
  INFO_HOURS,
  INFO_TIMESTAMPS,
  STUNDEN,
} from '../../components/modals/modalParentTypes';
import {
  FETCH_PSP_ELEMENTS_START,
  FETCH_PSP_ELEMENTS_SUCCESS,
  FETCH_PSP_ELEMENTS_FAILURE,
  FETCH_KOST_START,
  FETCH_KOST_SUCCESS,
  FETCH_KOST_FAILURE,
  FETCHED_PSP_ELEMENTS,
  FETCHED_KOST,
  FETCH_PSP_KOST_FAILURE,
  FETCH_PSP_KOST_SUCCESS,
  FETCHED_PSP_KOST,
  FETCH_PSP_KOST_START,
} from './pspKostTypes';

const initialState = {
  loading: false,
  pspElements: null,
  kost: null,
  error: null,
  pspKost: null,
  oldPspValue: null,
  hasFetchedPspKost: false,
  hasFetchedPspKostOnHours: false,
  hasFetchedPspKostOnTimestamps: false,
  hasFetchedPspElements: false,
  hasFetchedKost: false,
  isFetchingPspKost: false,
};

const pspKostReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PSP_ELEMENTS_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PSP_ELEMENTS_SUCCESS:
      return {
        ...state,
        error: null,
        pspElements: action.payload,
        loading: false,
      };
    case FETCH_PSP_ELEMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        pspElements: null,
        loading: false,
      };
    case FETCH_KOST_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_KOST_SUCCESS:
      return {
        ...state,
        error: null,
        kost: action.payload.kost,
        hasFetchedKost: true,
        loading: false,
      };
    case FETCH_KOST_FAILURE:
      return {
        ...state,
        error: action.payload,
        kost: [],
        loading: false,
        hasFetchedKost: true,
      };
    case FETCHED_PSP_ELEMENTS:
      return {
        ...state,
        hasFetchedPspElements: action.payload,
      };
    case FETCHED_KOST:
      return {
        ...state,
        hasFetchedKost: action.payload,
      };
    case FETCH_PSP_KOST_SUCCESS:
      return {
        ...state,
        pspKost: action.payload.pspKost,
        oldPspValue: action.payload.oldValue,
        hasFetchedPspKost: action.payload.parent === STUNDEN,
        hasFetchedPspKostOnHours: action.payload.parent === INFO_HOURS,
        hasFetchedPspKostOnTimestamps:
          action.payload.parent === INFO_TIMESTAMPS,
        editedPspKostCell: action.payload.cellBeingEdited,
        error: null,
        isFetchingPspKost: false,
      };
    case FETCH_PSP_KOST_FAILURE:
      return {
        ...state,
        pspKost: {
          costCenterOrWbs: action.payload.costCenterOrWbs,
          text: '',
        },
        hasFetchedPspKost: action.payload.parent === STUNDEN,
        hasFetchedPspKostOnHours: action.payload.parent === INFO_HOURS,
        hasFetchedPspKostOnTimestamps:
          action.payload.parent === INFO_TIMESTAMPS,
        editedPspKostCell: action.payload.cellBeingEdited,
        error: action.payload.error,
        pspErrorValue: action.payload.costCenterOrWbs,
        isFetchingPspKost: false,
      };
    case FETCHED_PSP_KOST:
      return {
        ...state,
        hasFetchedPspKost: action.payload,
        hasFetchedPspKostOnHours: action.payload,
        hasFetchedPspKostOnTimestamps: action.payload,
        error: null,
      };
    case FETCH_PSP_KOST_START:
      return {
        ...state,
        isFetchingPspKost: true,
      };
    default:
      return state;
  }
};

export default pspKostReducer;

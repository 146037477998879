import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { showModal } from '../../redux/modals/modalActions';
import { INFO, KURZTEXT, TRAVEL_TIME } from '../modals/modalTypes';
import { addZeroes } from '../../utils/addZeroes';
import {
  setEditingCell,
  removeRowFromTable,
  duplicateRow,
} from '../../redux/time/timeActions';
import { validateHoursCell } from '../../utils/validateHoursCell';

import {
  isValidActionButton,
  REMOVE_STUNDEN,
  DUPLICATE_STUNDEN,
  REMOVE_ABWESEN,
  DUPLICATE_ABWESEN,
} from '../../utils/isValidActionButton';

import { STUNDEN, ABWESEN } from '../modals/modalParentTypes';

const EditableCell = ({
  value: initialValue,
  row,
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  alignCenter,
  alignRight,
  allowOnlyNumbers,
  showModalOnDoubleClick,
  showModal,
  cell,
  parent,
  filterTimes,
  readOnly,
  removeRowFromTable,
  duplicateRow,
  times,
  absences,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);
  const [style, setStyle] = useState(null);
  const [clickedBackspace, setClickedBackspace] = useState(false);
  const { index, values } = row;

  const onChange = (e) => {
    const onlyNumbersRegex = /^[0-9.\b]+$/;

    if (allowOnlyNumbers) {
      if (e.target.value === '' || onlyNumbersRegex.test(e.target.value)) {
        setValue(e.target.value);
      }
    } else {
      setValue(e.target.value);
    }

    if (clickedBackspace) {
      setValue(e.target.value);
    } else if (
      e.target.value.length ===
        ((values.currency !== '' && values.currency) ||
        (values.unit && values.unit.toUpperCase() === 'KM')
          ? 4
          : 2) &&
      !e.target.value.includes('.')
    ) {
      setValue(e.target.value + '.');
    } else if (
      validateHoursCell(
        e.target.value,
        (values.currency !== '' && values.currency) ||
          (values.unit && values.unit.toUpperCase() === 'KM')
          ? 4
          : 2
      )
    ) {
      //string includes .
      setValue(e.target.value);
    } else {
      setValue(value);
      e.target.value = value;
    }
  };

  const handleButtonClick = (lostFocusTo) => {
    switch (lostFocusTo) {
      case REMOVE_STUNDEN:
        let canRemove = true;

        if (parent === STUNDEN) {
          for (const cell of Object.values(times[index - 2].cells)) {
            if (cell.isReadOnly) {
              canRemove = false;
              break;
            }
          }

          if (canRemove) {
            removeRowFromTable(index - 2, parent);
          }
        }
        break;
      case DUPLICATE_STUNDEN:
        if (parent === STUNDEN) {
          duplicateRow(index - 2, parent);
        }
        break;
      case REMOVE_ABWESEN:
        if (parent === ABWESEN) {
          let canRemove = true;

          for (const cell of Object.values(absences[index - 2].cells)) {
            if (cell.isReadOnly) {
              canRemove = false;
              break;
            }
          }

          if (canRemove) {
            removeRowFromTable(index - 2, parent);
          }
        }
        break;
      case DUPLICATE_ABWESEN:
        if (parent === ABWESEN) duplicateRow(index - 2, parent);
        break;
      default:
        //do nothing
        break;
    }
  };

  // We'll only update the external data when the input is blurred
  const onBlur = (e) => {
    if (e.relatedTarget) {
      let lostFocusTo = isValidActionButton(e.relatedTarget.classList);
      if (lostFocusTo !== '') {
        handleButtonClick(lostFocusTo);
      }
    }
    if (value !== '' && value !== '0.00' && Number(value).toFixed(2) !== '0') {
      const roundedNumber = addZeroes(value, id !== 'sum');
      if (roundedNumber !== '0.00') {
        setValue(roundedNumber);

        if (value !== initialValue) {
          updateMyData(index, id, roundedNumber);
        }
      } else {
        setValue('');
      }
    } else {
      setValue('');
      if (initialValue !== undefined && initialValue !== '0.00')
        updateMyData(index, id, '');
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setClickedBackspace(true);
    } else {
      setClickedBackspace(false);
    }
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  const showModalForTime = () => {
    if (values.hasOwnProperty('lgart')) {
      // This is arbeits stunden/working hours id
      if (values.lgart === '1000') {
        showModal(INFO, { cell, filterTimes });
      } else if (times[index - 2].isTravel) {
        showModal(TRAVEL_TIME, {
          cell: times[index - 2].cells[id],
          rowIndex: index - 2,
          columnId: id,
        });
      } else if (value !== '' && value !== undefined && value > 0) {
        showModal(KURZTEXT, { cell, parent });
      }
    } else if (value !== '' && value && value !== undefined && value > 0) {
      showModal(KURZTEXT, { cell, parent });
    }
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue !== '0.00' ? initialValue : '');

    if (alignCenter !== undefined && alignCenter !== null) {
      setStyle({ textAlign: 'center' });
    } else if (alignRight !== undefined && alignRight !== null) {
      setStyle({ textAlign: 'right' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <input
      value={value || ''}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      spellCheck='false'
      style={style}
      maxLength={
        (values.currency !== '' && values.currency) ||
        (values.unit && values.unit.toUpperCase() === 'KM')
          ? 7
          : 5
      }
      readOnly={readOnly}
      onDoubleClick={showModalOnDoubleClick ? () => showModalForTime() : null}
    />
  );
};

const mapStateToProps = ({ time: { times, absences } }) => ({
  times,
  absences,
});

const mapDispatchToProps = (dispatch) => ({
  showModal: (modalType, modalProps) =>
    dispatch(showModal(modalType, modalProps)),
  setEditingCell: (cell) => dispatch(setEditingCell(cell)),
  removeRowFromTable: (index, parent, workdate) =>
    dispatch(removeRowFromTable(index, parent, workdate)),
  duplicateRow: (index, parent, workdate) =>
    dispatch(duplicateRow(index, parent, workdate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditableCell);

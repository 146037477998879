import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Modal, message } from 'antd';

import { SearchOutlined } from '@ant-design/icons';

import KokrsTable from './KokrsTable';

import {
  fetchKokrs,
  setHasFetchedKokrs,
  clearErrors,
} from '../../../../redux/kokrs/kokrsActions';

import KokrsTableStyle from './KokrsTableStyle';

import styles from './KokrsModal.module.css';
import { KOKRS } from '../../modalTypes';

const KokrsModal = ({
  auth,
  fetchKokrs,
  kokrs,
  error,
  isFetchingKokrs,
  hasFetchedKokrs,
  clearErrors,
  currentModals,
  hideModal,
  setHasFetchedKokrs,
  selectedKokrs,
}) => {
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState({
    kokrs: '',
    bezei: '',
  });

  const [columns] = useState([
    {
      Header: 'Kostenrechungskreis',
      accessor: 'kokrs',
      width: 60,
      minWidth: 60,
    },
    {
      Header: 'Bezeichnung',
      accessor: 'bezei',
    },
  ]);

  useEffect(() => {
    if (hasFetchedKokrs) {
      setData(kokrs);
      setHasFetchedKokrs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedKokrs]);

  useEffect(() => {
    if (error) {
      message.error(error).then(() => {
        clearErrors();
      });
      setData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!hasFetchedKokrs) {
      for (let i = 1; i <= 10; i++) {
        let row = {
          id: i,
          kokrs: '',
          bezei: '',
        };
        setData((data) => [...data, row]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e) => {
    const name = e.target.name;
    const newValue = e.target.value;

    setSearchData({ ...searchData, [name]: newValue });
  };

  const onSearch = () => {
    fetchKokrs(auth, {
      kokrs: searchData.kokrs,
      bezei: searchData.bezei,
    });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      onSearch();
    }
  };

  const handleCancel = () => {
    hideModal(KOKRS);
  };

  const handleOk = () => {
    hideModal(KOKRS);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(KOKRS) ? true : false}
      title='Suchhilfe für Buchungskreis'
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      style={{ top: '155px' }}
      closable={true}
      footer={null}
    >
      <div className={styles.kokrsHeader}>
        <div className={styles.row1}>
          <Input
            placeholder='Kostenrechungskreis'
            className={styles.kokrs}
            name='kokrs'
            value={searchData.kokrs}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Input
            placeholder='Bezeichnung'
            className={styles.bezeichnung}
            name='bezei'
            value={searchData.bezei}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <Button
            type='primary'
            className={styles.searchBtn}
            icon={<SearchOutlined />}
            loading={isFetchingKokrs}
            onClick={onSearch}
          >
            Suchen
          </Button>
        </div>
      </div>
      <KokrsTableStyle>
        <KokrsTable
          columns={columnsMemo}
          data={data}
          selectedKokrs={selectedKokrs}
        />
      </KokrsTableStyle>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  kokrs: { kokrs, error, isFetchingKokrs, hasFetchedKokrs },
}) => ({
  auth,
  kokrs,
  error,
  isFetchingKokrs,
  hasFetchedKokrs,
});

const mapDispatchToProps = (dispatch) => ({
  fetchKokrs: (auth, searchData) => dispatch(fetchKokrs(auth, searchData)),
  setHasFetchedKokrs: (fetched) => dispatch(setHasFetchedKokrs(fetched)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KokrsModal);

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_LOADING,
  SET_AUTH,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SET_HAS_CHANGED_PASSWORD,
  CLEAR_ERRORS,
} from './authTypes';

const initialState = {
  isAuthenticated: null,
  loading: false,
  user: null,
  error: null,
  isChangingPassword: false,
  hasChangedPassword: false,
  errorChangingPassword: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        isChangingPassword: true,
        hasChangedPassword: false,
        errorChangingPassword: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false,
        hasChangedPassword: true,
        errorChangingPassword: null,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        hasChangedPassword: false,
        errorChangingPassword: action.payload,
      };
    case SET_HAS_CHANGED_PASSWORD:
      return {
        ...state,
        hasChangedPassword: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        errorChangingPassword: null,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: null,
        user: null,
      };
    case SET_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;

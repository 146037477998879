import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';

import { fetchTimeBalance } from '../../../../redux/time-balance/timeBalanceActions';

import TimeBalanceTableStyle from './TimeBalanceTableStyle';
import styles from './TimeBalanceModal.module.css';

import TimeBalanceModalTable from './TimeBalanceModalTable';

import { checkForValue, checkForArray } from '../../../../utils/checkForValue';
import { formatDate } from '../../../../utils/formatDate';

import { TIME_BALANCE } from '../../modalTypes';

const TimeBalanceModal = ({
  auth,
  currentMonth,
  currentModals,
  hideModal,
  fetchTimeBalance,
  balance,
  payrollAccountedUntil,
  payrollPeriodBegin,
  payrollPeriodEnd,
  pernr,
  pernrName,
}) => {
  const [columns] = useState([
    {
      Header: 'Lohnart',
      accessor: 'lgart',
      width: 40,
    },
    {
      Header: 'Lohnart-Langtext',
      accessor: 'lgtxt',
    },
    {
      Header: 'Anzahl',
      accessor: 'amount',
      width: 40,
    },
  ]);

  useEffect(() => {
    fetchTimeBalance(auth, currentMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    hideModal(TIME_BALANCE);
  };

  const handleOk = () => {
    hideModal(TIME_BALANCE);
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  return (
    <Modal
      visible={currentModals.hasOwnProperty(TIME_BALANCE) ? true : false}
      title='Anzeige der Zeitsaldi aus Abrechenungsergebnis (HR-RT)'
      onOk={handleOk}
      onCancel={handleCancel}
      width={800}
      closable={true}
      footer={null}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerRow}>
            <span className={styles.headerCol}>
              Personalnummer:
              <span
                className={styles.headerValues}
                style={{ color: '#40a9ff' }}
              >
                {checkForValue(pernr)}
              </span>
            </span>
            <div className={styles.headerCol}>
              <span>
                Gültig Ab:
                <span className={styles.headerValues}>
                  {payrollPeriodBegin &&
                    formatDate(checkForValue(payrollPeriodBegin), 'DD.MM.yyyy')}
                </span>
              </span>
              <span>
                Gültig bis:
                <span className={styles.headerValues}>
                  {payrollPeriodEnd &&
                    formatDate(checkForValue(payrollPeriodEnd), 'DD.MM.yyyy')}
                </span>
              </span>
            </div>
          </div>
          <div className={styles.headerRow}>
            <span className={styles.headerCol}>
              Name:
              <span className={styles.headerValues}>
                {checkForValue(pernrName)}
              </span>
            </span>
            <span className={styles.headerCol}>
              Zeitdaten abgerechnet bis:
              <span className={styles.headerValues}>
                {payrollAccountedUntil &&
                  formatDate(
                    checkForValue(payrollAccountedUntil),
                    'DD.MM.yyyy'
                  )}
              </span>
            </span>
          </div>
        </div>

        <TimeBalanceTableStyle>
          <TimeBalanceModalTable
            columns={columnsMemo}
            data={checkForArray(balance)}
          />
        </TimeBalanceTableStyle>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  auth,
  time: { currentMonth },
  timeBalance: {
    balance,
    payrollAccountedUntil,
    payrollPeriodBegin,
    payrollPeriodEnd,
    pernr,
    pernrName,
  },
}) => ({
  auth,
  currentMonth,
  balance,
  payrollAccountedUntil,
  payrollPeriodBegin,
  payrollPeriodEnd,
  pernr,
  pernrName,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTimeBalance: (auth, month) => dispatch(fetchTimeBalance(auth, month)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeBalanceModal);

import API from '../../api/api';

import {
  FETCH_PRCTR_START,
  FETCH_PRCTR_SUCCESS,
  FETCHED_PRCTR,
  FETCH_PRCTR_FAILURE,
  RESET_PRCTR_DATA,
} from './prctrTypes';

export const fetchPrctrStart = () => {
  return {
    type: FETCH_PRCTR_START,
  };
};

export const fetchPrctrSuccess = (prctrList) => {
  return {
    type: FETCH_PRCTR_SUCCESS,
    payload: prctrList,
  };
};

export const setHasFetchedPrctr = (fetched) => {
  return {
    type: FETCHED_PRCTR,
    payload: fetched,
  };
};

export const fetchPrctrFailure = (error) => {
  return {
    type: FETCH_PRCTR_FAILURE,
    payload: error,
  };
};

export const resetPrctrData = () => {
  return {
    type: RESET_PRCTR_DATA,
  };
};

export const fetchPrctr = (auth, searchData) => {
  return async (dispatch, store) => {
    const { kokrs, prctr, ktext } = searchData;

    const {
      user: { headerAuth },
    } = auth;

    let config = {
      headers: {
        Authorization: `Basic ${headerAuth}`,
      },
      params: {
        kokrs,
        prctr,
        ktext,
        'sap-language': 'DE',
        'sap-client': `${store().settings.client}`,
      },
    };

    dispatch(fetchPrctrStart());

    try {
      const res = await API.get('/SIF/time/profit_centers', config);
      dispatch(fetchPrctrSuccess(res.data));
      dispatch(setHasFetchedPrctr(true));
    } catch (error) {
      if (error.response) {
        let msg =
          error.response.status === 404
            ? 'Nicht gefunden'
            : 'Ein unbekannter Fehler ist aufgetreten';
        dispatch(fetchPrctrFailure(msg));
      } else {
        dispatch(fetchPrctrFailure('Ein unbekannter Fehler ist aufgetreten'));
      }
    }
  };
};

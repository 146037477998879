import React from 'react';

const AddLineIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#747474' fillRule='nonzero' transform='translate(-241 -180)'>
          <path d='M254.711 195.2c1.524 0 2.289-.734 2.289-2.188v-7.5c0-1.447-.765-2.181-2.289-2.181h-1.348v-1.143c0-1.454-.773-2.188-2.29-2.188h-7.784c-1.53 0-2.289.734-2.289 2.188v7.43c0 1.454.758 2.18 2.289 2.18h1.348v1.214c0 1.454.766 2.188 2.29 2.188h7.784zm-10.074-4.537h-1.334c-.729 0-1.13-.374-1.13-1.108v-7.304c0-.734.401-1.115 1.13-1.115h7.749c.722 0 1.137.381 1.137 1.115v1.08h-5.263c-1.523 0-2.289.727-2.289 2.18v5.152zm10.06 3.4h-7.749c-.729 0-1.137-.38-1.137-1.114v-7.374c0-.734.408-1.108 1.137-1.108h7.749c.721 0 1.13.374 1.13 1.108v7.381c0 .727-.409 1.108-1.13 1.108zm-3.864-1.862a.55.55 0 00.554-.558v-1.834h1.852c.342 0 .612-.254.612-.558 0-.296-.27-.55-.612-.55h-1.852v-1.828a.544.544 0 00-.554-.55.538.538 0 00-.546.55v1.828h-1.918c-.306 0-.583.268-.583.55 0 .297.277.558.583.558h1.918v1.834c0 .318.24.558.546.558z'></path>
        </g>
      </g>
    </svg>
  );
};

export default AddLineIcon;

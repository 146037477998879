import React, { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';

import InfoStundenTable from './InfoStundenTable';

import EditableInfoCell from '../../../../editable-cells/EditableInfoCell';
import EditableSearchCell from '../../../../editable-cells/EditableSearchCell';

import { ReactComponent as TextIcon } from '../../../../../assets/text.svg';
import { ReactComponent as TextIconFilled } from '../../../../../assets/textFilled.svg';
import Icon from '@ant-design/icons';
import { message } from 'antd';

import { addZeroes } from '../../../../../utils/addZeroes';

import { setHasFetchedPspKost } from '../../../../../redux/psp-kost/pspKostActions';
import {
  updateCostCenterOrWbs,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
} from '../../../../../redux/time/timeActions';
import { setHasFetchedLeisart } from '../../../../../redux/leisart/leisartActions';

import moment from 'moment';

import Style from '../InfoTableStyle';
import { INFO_HOURS } from '../../../modalParentTypes';
import { KOSTL_OR_POSID } from '../../../../editable-cells/EditableCellTypes';

const InfoStunden = ({
  showLangTextModal,
  tempHours,
  currentDate,
  updateHour,
  hasFetchedPspKostOnHours,
  pspKost,
  editedPspKostCell,
  pspKostError,
  setHasFetchedPspKost,
  updateCostCenterOrWbs,
  setLoading,
  setErrorOnEditableSearchCell,
  setLoadingOnEditableSearchCell,
  setHasFetchedLeisart,
  isInfoReadOnly,
  leisart,
  editedLeisartCell,
  hasFetchedLeisart,
  leisartError,
  onlyDisplay,
}) => {
  const selectedCostCenterOrWbs = (cell, costCenterOrWbs, text) => {
    updateMyData(cell.row.id, 'kostlOrPosid', costCenterOrWbs);
    updateMyData(cell.row.id, 'kostlOrPosidText', text);
  };

  const selectedLstar = (cell, lstar) => {
    updateMyData(cell.row.id, 'lstar', String(lstar));
  };

  const [columns] = useState([
    {
      Header: <div>PSP/Kost.</div>,
      Footer: '',
      accessor: 'kostlOrPosid',
      width: 40,
      Cell: (cellInfo) => (
        <EditableSearchCell
          initialValue={cellInfo.value}
          {...cellInfo}
          setLoading={setLoading}
          workdate={currentDate._d}
          updateMyData={updateMyData}
          selectedCostCenterOrWbs={selectedCostCenterOrWbs}
          parent={INFO_HOURS}
          readOnly={
            cellInfo.row.original.isReadOnly || isInfoReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <div>PSP/Kost.-Langtext</div>,
      Footer: '',
      accessor: 'kostlOrPosidText',
    },
    {
      Header: <div style={{ textAlign: 'right' }}>Stunden</div>,
      accessor: 'quantity',
      width: 35,
      totalWidth: 35,
      maxWidth: 35,
      Footer: (cellInfo) => {
        // Only calculate total visits if rows change
        const total = useMemo(
          () =>
            cellInfo.data.reduce((sum, data) => {
              let quantity = !isNaN(data.quantity) ? data.quantity : '0.00';
              return Number(quantity) + Number(sum);
            }, 0),
          [cellInfo]
        );

        return (
          <div style={{ textAlign: 'right' }}>{total.toFixed(2) || '0.00'}</div>
        );
      },
      Cell: (cellInfo) => (
        <EditableInfoCell
          initialValue={addZeroes(cellInfo.value)}
          {...cellInfo}
          value={addZeroes(cellInfo.value)}
          updateMyData={updateMyData}
          alignRight={true}
          workdate={currentDate._d}
          allowOnlyNumbers={true}
          parent={INFO_HOURS}
          numberFormat={'##.##'}
          readOnly={
            cellInfo.row.original.isReadOnly || isInfoReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <div>Kurztext</div>,
      accessor: 'shortText',
      Footer: '',
      Cell: (cellInfo) => (
        <EditableInfoCell
          initialValue={cellInfo.value}
          {...cellInfo}
          workdate={currentDate._d}
          parent={INFO_HOURS}
          updateMyData={updateMyData}
          readOnly={
            cellInfo.row.original.isApproved || isInfoReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <div>LeistArt</div>,
      Footer: '',
      accessor: 'lstar',
      width: 40,
      Cell: (cellInfo) => (
        <EditableSearchCell
          initialValue={cellInfo.value}
          {...cellInfo}
          setLoading={setLoading}
          workdate={currentDate._d}
          updateMyData={updateMyData}
          selectedLstar={selectedLstar}
          parent={INFO_HOURS}
          readOnly={
            cellInfo.row.original.isApproved || isInfoReadOnly || onlyDisplay
          }
        />
      ),
    },
    {
      Header: <></>,
      Footer: '',
      accessor: 'action',
      Cell: (cellInfo) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Icon
            component={
              cellInfo.row.original.longText.length > 0
                ? TextIconFilled
                : TextIcon
            }
            style={{
              cursor: 'pointer',
              color:
                cellInfo.row.original.longText.length > 0
                  ? '#4091F7'
                  : undefined,
            }}
            onClick={() => showLangTextModal(cellInfo.cell, INFO_HOURS)}
          />
        </div>
      ),
      width: 20,
      totalWidth: 20,
      maxWidth: 20,
    },
  ]);

  useEffect(() => {
    if (hasFetchedPspKostOnHours) {
      if (pspKostError) {
        message.error(pspKostError);
      } else {
        const { costCenterOrWbs, text } = pspKost;
        updateCostCenterOrWbs(
          editedPspKostCell.row.index,
          costCenterOrWbs,
          text,
          INFO_HOURS,
          moment(currentDate._d).format('YYYY-MM-DD')
        );
      }
      setErrorOnEditableSearchCell(
        'kostlOrPosid',
        editedPspKostCell.row.index,
        pspKostError ? false : true,
        INFO_HOURS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setLoadingOnEditableSearchCell(
        'kostlOrPosid',
        editedPspKostCell.row.index,
        false,
        INFO_HOURS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setHasFetchedPspKost(false);
    } else if (hasFetchedLeisart) {
      if (leisartError) {
        message.error(leisartError);
      } else {
        updateMyData(editedLeisartCell.row.index, 'lstar', leisart);
      }
      setErrorOnEditableSearchCell(
        'lstar',
        editedLeisartCell.row.index,
        leisartError ? false : true,
        INFO_HOURS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setLoadingOnEditableSearchCell(
        'lstar',
        editedLeisartCell.row.index,
        false,
        INFO_HOURS,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      setHasFetchedLeisart(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedPspKostOnHours, hasFetchedLeisart]);

  const updateMyData = (rowIndex, columnId, value) => {
    let isAbwesen = false;
    if (!isAbwesen) {
      updateHour(
        !isNaN(value) && columnId !== 'lstar' ? parseFloat(value) : value,
        columnId,
        rowIndex,
        moment(currentDate._d).format('YYYY-MM-DD')
      );
      if (value === '') {
        if (columnId === KOSTL_OR_POSID) {
          updateCostCenterOrWbs(
            rowIndex,
            '',
            '',
            INFO_HOURS,
            moment(currentDate._d).format('YYYY-MM-DD')
          );
        }
      }
    }
  };

  const columnsMemo = useMemo(() => columns, [columns]);

  const dataMemo = useMemo(
    () => {
      return tempHours[moment(currentDate._d).format('YYYY-MM-DD')];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, tempHours]
  );

  return (
    <Style>
      <InfoStundenTable
        columns={columnsMemo}
        data={dataMemo || []}
        updateMyData={updateMyData}
        currentDate={currentDate}
        isInfoReadOnly={isInfoReadOnly}
        onlyDisplay={onlyDisplay}
      />
    </Style>
  );
};

const mapStateToProps = ({
  pspKost: {
    hasFetchedPspKostOnHours,
    pspKost,
    editedPspKostCell,
    error: pspKostError,
  },
  leisart: {
    leisart,
    error: leisartError,
    hasFetchedLeisart,
    editedLeisartCell,
  },
}) => ({
  hasFetchedPspKostOnHours,
  pspKost,
  editedPspKostCell,
  pspKostError,
  leisart,
  leisartError,
  hasFetchedLeisart,
  editedLeisartCell,
});

const mapDispatchToProps = (dispatch) => ({
  setHasFetchedPspKost: (hasFetchedPspKost) =>
    dispatch(setHasFetchedPspKost(hasFetchedPspKost)),
  updateCostCenterOrWbs: (rowIndex, costCenterOrWbs, text, parent, workdate) =>
    dispatch(
      updateCostCenterOrWbs(rowIndex, costCenterOrWbs, text, parent, workdate)
    ),
  setHasFetchedLeisart: (fetched) => dispatch(setHasFetchedLeisart(fetched)),
  setErrorOnEditableSearchCell: (
    columnId,
    rowIndex,
    isValid,
    parent,
    workdate
  ) =>
    dispatch(
      setErrorOnEditableSearchCell(
        columnId,
        rowIndex,
        isValid,
        parent,
        workdate
      )
    ),
  setLoadingOnEditableSearchCell: (
    columnId,
    rowIndex,
    isLoading,
    parent,
    workdate
  ) =>
    dispatch(
      setLoadingOnEditableSearchCell(
        columnId,
        rowIndex,
        isLoading,
        parent,
        workdate
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoStunden);

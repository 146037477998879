export const FETCH_PERSON_PROFIL_START = 'FETCH_PERSON_PROFIL_START';
export const FETCH_PERSON_PROFIL_SUCCESS = 'FETCH_PERSON_PROFIL_SUCCESS';
export const FETCH_PERSON_PROFIL_FAILURE = 'FETCH_PERSON_PROFIL_FAILURE';
export const HAS_FETCHED_PERSON_PROFIL = 'HAS_FETCHED_PERSON_PROFIL';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const FETCH_EMPLOYEES_START = 'FETCH_EMPLOYEES_START';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';
export const FETCH_PERSA_START = 'FETCH_PERSA_START';
export const FETCH_PERSA_SUCCESS = 'FETCH_PERSA_SUCCESS';
export const FETCH_PERSA_FAILURE = 'FETCH_PERSA_FAILURE';
export const FETCH_BTRTL_START = 'FETCH_BTRTL_START';
export const FETCH_BTRTL_SUCCESS = 'FETCH_BTRTL_SUCCESS';
export const FETCH_BTRTL_FAILURE = 'FETCH_BTRTL_FAILURE';
export const RESET_STATE_PERSON = 'RESET_STATE_PERSON';
export const CLEAR_LOCAL_EMPLOYEES = 'CLEAR_LOCAL_EMPLOYEES';
export const RESET_BTRTL_LIST = 'RESET_BTRTL_LIST';
export const FETCH_EMPLOYEE_START = 'FETCH_EMPLOYEE_START';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

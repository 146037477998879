import React from 'react';

const ExportIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='18'
      viewBox='0 0 16 20'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='#747474' fillRule='nonzero' transform='translate(-364 -176)'>
          <path d='M370.248 186.401a.555.555 0 00.556-.543v-7.26l-.042-1.052.471.502 1.07 1.137c.098.113.246.17.38.17.288 0 .513-.206.513-.495a.504.504 0 00-.169-.374l-2.378-2.302c-.14-.142-.26-.184-.401-.184-.148 0-.267.042-.408.184l-2.378 2.302a.504.504 0 00-.17.374c0 .29.212.494.507.494a.528.528 0 00.387-.17l1.07-1.136.471-.502-.042 1.053v7.259c0 .296.26.543.563.543zm7.543 8.799c1.47 0 2.209-.727 2.209-2.189v-7.04c0-1.462-.739-2.19-2.21-2.19h-1.301v-1.08c0-1.454-.739-2.189-2.21-2.189h-1.962v1.137h1.942c.696 0 1.097.374 1.097 1.109v6.927c0 .734-.4 1.109-1.097 1.109h-8.036c-.703 0-1.09-.375-1.09-1.109v-6.927c0-.735.387-1.109 1.09-1.109h1.95v-1.137h-1.964c-1.47 0-2.209.728-2.209 2.19v7.04c0 1.461.739 2.189 2.21 2.189h1.9v1.08c0 1.455.738 2.189 2.208 2.189h7.473zm-.014-1.137h-7.438c-.696 0-1.097-.374-1.097-1.109v-1.023h5.038c1.47 0 2.209-.735 2.209-2.19v-4.822h1.288c.703 0 1.09.374 1.09 1.108v6.927c0 .735-.387 1.11-1.09 1.11z'></path>
        </g>
      </g>
    </svg>
  );
};

export default ExportIcon;

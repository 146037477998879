import React, { useState, useEffect } from 'react';
import { Modal, Button, Input, Tooltip, Row, Col } from 'antd';
import { CalendarOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { ABWESEN, KURZTEXT } from '../../modalTypes';
import { STUNDEN } from '../../modalParentTypes';

const KurzModal = ({
  currentModals,
  hideModal,
  cell,
  times,
  absences,
  parent,
  updateShortText,
  timesPost: { timeColumns, absenceColumns, periodClosed, onlyDisplay },
  areTimesLocked,
}) => {
  const [shortText, setShortText] = useState('');

  const isShortTextReadOnlyBasedOnParent = () => {
    if (parent === STUNDEN) {
      return (
        timeColumns[cell.column.id].isReadonly ||
        periodClosed ||
        areTimesLocked ||
        onlyDisplay
      );
    } else if (parent === ABWESEN) {
      return (
        absenceColumns[cell.column.id].isReadonly ||
        periodClosed ||
        areTimesLocked ||
        onlyDisplay
      );
    }
  };

  const [isShortTextReadOnly] = useState(() =>
    isShortTextReadOnlyBasedOnParent()
  );

  useEffect(() => {
    const monthlyCells =
      parent === STUNDEN
        ? times[cell.row.index - 2].cells
        : absences[cell.row.index - 2].cells;

    for (const [key, value] of Object.entries(monthlyCells)) {
      if (key === cell.column.id) {
        setShortText(value.shortText);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = ({ target: { value } }) => {
    setShortText(value);
  };

  const setShortTextToEditingCell = () => {
    updateShortText(cell.column.id, cell.row.index - 2, shortText, parent);
  };

  const onClose = (shouldSetShortText) => {
    if (shouldSetShortText && !periodClosed && !areTimesLocked) {
      setShortTextToEditingCell();
    }
    hideModal(KURZTEXT);
  };

  return (
    <Modal
      title={
        <Row align="middle">
          <Col flex="auto">
            <h3 style={{ marginBottom: '0' }}>Zelleninformation</h3>
          </Col>
          <Col flex="25px">
            <CalendarOutlined style={{ color: 'RGB(24, 144, 255)' }} />
          </Col>
          <Col flex="80px">
            <p
              style={{
                color: 'RGB(24, 144, 255)',
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '0',
              }}
            >
              {cell ? moment(cell.column.id).format('DD.MM.YYYY') : ''}
            </p>
          </Col>
        </Row>
      }
      closable={false}
      visible={currentModals.hasOwnProperty(KURZTEXT) ? true : false}
      onCancel={() => onClose(false)}
      width={800}
      footer={[
        <Button key="back" onClick={() => onClose(false)}>
          Abbrechen
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onClose(true)}
          disabled={isShortTextReadOnly}
        >
          Fertig
        </Button>,
      ]}
    >
      <Row align="middle">
        <Col flex="65px">
          <h4 style={{ margin: '0' }}>Kurztext:</h4>
        </Col>
        <Col flex="auto">
          <Input
            placeholder=""
            value={shortText}
            onChange={onChange}
            disabled={isShortTextReadOnly}
            suffix={
              <Tooltip title="Dieser Text ist auf 40 Zeichen begrenzt, bei längerer Texteingabe Langtext verwenden.">
                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
              </Tooltip>
            }
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default KurzModal;
